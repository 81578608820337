import { useMutation, useQueryClient } from 'react-query';

import QUERYKEYS from '../../../constants/queryKeys';
import { deleteRule } from './../../../services/RuleService';

export default function useDeleteRule() {
  const queryClient = useQueryClient();
  const { isError, isLoading, mutate } = useMutation(deleteRule, {
    onSuccess: () => queryClient.invalidateQueries(QUERYKEYS.RULES),
  });

  return {
    deleteMutation: mutate,
    isError,
    isLoading,
  };
}
