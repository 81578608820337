import * as React from 'react';

import PageHeader from '../../shared/PageHeader';
import PromoCodesTable from './../../../components/promoCodes/tables/PromoCodesTable';
import RequestErrorAlert from '../../shared/RequestErrorAlert';
import Section from '../../shared/Section';
import usePromoCodes from './../../../hooks/services/promoCodes/usePromoCodes';

const PromoCodePage = () => {
  const { data, isError, isLoading } = usePromoCodes();
  return (
    <div className="promoCodesPageRoot">
      <PageHeader title="Promo Codes" />
      <Section isLoading={isLoading}>
        <RequestErrorAlert
          isError={isError}
          description="An error occurred while attempting to load the data for this table."
        />
        <PromoCodesTable data={data ?? []} isLoading={false} key={'promoCodesTable'} />
      </Section>
    </div>
  );
};

export default PromoCodePage;
