const ROUTES = {
  ACCESS_DENIED: '/access-denied',
  AUTHORIZE: '/authorize',
  CMS: '/cms',
  CMS_COLLECTIONS: '/cms/collections',
  CMS_RELEASES: '/cms/releases',
  HOME: '/',
  PAGE_GROUPS: '/cms/page-groups',
  PAGES: '/cms/pages',
  PAGE_NOT_FOUND: '/page-not-found',
  PROMO_CODES: '/promo-codes',
  RULES: '/rules',
  VENDORS: '/vendors',
  VENDOR_CODES: '/vendors/vendor-codes',
  VENDOR_CODES_COMMERCIAL: '/vendors/vendor-codes/commercial',
  VENDOR_CODES_DIRECT_MAIL: '/vendors/vendor-codes/direct-mail',
  VENDOR_CODES_INTERNET: '/vendors/vendor-codes/internet',
};

export default ROUTES;
