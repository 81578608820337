import { useMutation, useQueryClient } from 'react-query';

import type { TVendorCodeCommercial } from './../../../models/vendor/VendorCodeModels';
import type { TCreateModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { createVendorCodeCommercial } from './../../../services/VendorCodesService';

const useCreateVendorCodeCommercial = (): TCreateModel<TVendorCodeCommercial> => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(createVendorCodeCommercial, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.VENDOR_CODES || query.queryKey[0] === QUERYKEYS.VENDORS,
      });
    },
  });

  return {
    create: mutate,
    isLoading,
  };
};

export default useCreateVendorCodeCommercial;
