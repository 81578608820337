import CmsPageGroupCreateButton from '../../cms/buttons/CmsPageGroupCreateButton';
import CmsPageGroupsTable from '../../cms/tables/CmsPageGroupsTable';
import PageHeader from '../../shared/PageHeader';
import ROUTES from '../../../constants/routes';
import React from 'react';
import Section from '../../shared/Section';
import { Space } from 'antd';
import useCmsPageGroups from '../../../hooks/services/cms/useCmsPageGroups';

const CmsPageGroupsPage = () => {
  const { data, isLoading } = useCmsPageGroups();

  return (
    <div>
      <PageHeader
        title="CMS Page Groups"
        breadcrumbRoutes={
          data
            ? [
                { breadcrumbName: 'Home', path: ROUTES.CMS },
                { breadcrumbName: 'Page Groups', path: ROUTES.PAGE_GROUPS },
              ]
            : undefined
        }
        extra={[
          <Space key="pageGroupsActions">
            <CmsPageGroupCreateButton />
          </Space>,
        ]}
      />
      <Section isLoading={isLoading}>
        <CmsPageGroupsTable data={data} />
      </Section>
    </div>
  );
};

export default CmsPageGroupsPage;
