import React from 'react';
import { Form, Input } from 'antd';
import type { Rule } from 'antd/lib/form';
import Editor from '@monaco-editor/react';

import type { CmsCollectionEntryVersionDataModel } from '../../../../../models/cms/collections/entryVersionData/CmsCollectionEntryVersionDataModel';
import { CmsCollectionEntryVersionDataTypeEnum } from '../../../../../enums/cms/CmsCollectionEntryVersionDataTypeEnum';
import type { CmsCollectionSchemaDataCodeEditorModel } from '../../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataCodeEditorModel';
import { CmsCollectionSchemaDataCodeEditorTypeEnum } from '../../../../../enums/cms/CmsCollectionSchemaDataCodeEditorTypeEnum';
import EditorReadOnlyWithInsufficientPermission from '../../../../hocs/editors/EditorReadOnlyWithInsufficientPermission';

type Props = {
  initialData?: CmsCollectionEntryVersionDataModel;
  schemaData: CmsCollectionSchemaDataCodeEditorModel;
  formLabel: React.ReactNode;
  index: number;
};

const EnhancedEditor = EditorReadOnlyWithInsufficientPermission(Editor);

export const CmsCollectionCodeEditorItems = ({
  initialData,
  schemaData,
  formLabel,
  index,
}: Props) => {
  const initialValues =
    initialData?.entryVersionDataType === CmsCollectionEntryVersionDataTypeEnum.String
      ? initialData
      : null;

  const getRules = (): Rule[] => {
    const codeEditorRules: Rule[] = [
      { whitespace: true, message: `${schemaData.fieldName} cannot be just whitespace` },
    ];

    if (schemaData.isRequired) {
      codeEditorRules.push({ required: true, message: `${schemaData.fieldName} is required` });
    }

    if (schemaData.codeEditorType === CmsCollectionSchemaDataCodeEditorTypeEnum.JSON) {
      codeEditorRules.push(() => ({
        validator(_, value) {
          if (!value) {
            return Promise.resolve();
          }

          try {
            JSON.parse(value);
            return Promise.resolve();
          } catch (_e) {
            return Promise.reject(new Error('The JSON is not valid'));
          }
        },
      }));
    }

    if (schemaData.codeEditorType === CmsCollectionSchemaDataCodeEditorTypeEnum.XML) {
      codeEditorRules.push(() => ({
        validator(_, value) {
          if (!value) {
            return Promise.resolve();
          }

          const doc = new DOMParser().parseFromString(value, 'text/xml');
          const errorNode = doc.querySelector('parsererror');
          if (errorNode) {
            return Promise.reject(new Error('The XML is not valid'));
          } else {
            return Promise.resolve();
          }
        },
      }));
    }
    return codeEditorRules;
  };

  return (
    <>
      <Form.Item
        label={formLabel}
        name={['entryVersions', 0, 'entryVersionData', index, 'value']}
        initialValue={initialValues?.value}
        rules={getRules()}
      >
        <EnhancedEditor
          height="400px"
          theme="vs-dark"
          defaultLanguage={
            schemaData.codeEditorType === CmsCollectionSchemaDataCodeEditorTypeEnum.JSON
              ? 'json'
              : 'xml'
          }
          // eslint-disable-next-line max-len
          //https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IStandaloneEditorConstructionOptions.html
          options={{
            minimap: {
              enabled: false,
            },
            colorDecorators: true,
            tabSize: 2,
            quickSuggestions: false,
          }}
        />
      </Form.Item>
      <Form.Item
        hidden
        initialValue={CmsCollectionEntryVersionDataTypeEnum.String}
        name={['entryVersions', 0, 'entryVersionData', index, 'entryVersionDataType']}
      >
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};
