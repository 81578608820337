import { useMutation, useQueryClient } from 'react-query';

import type { CmsCollectionEntryModel } from '../../../../models/cms/collections/CmsCollectionEntryModel';
import type { CmsCreateMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { createCollectionEntry } from '../../../../services/CmsCollectionService';

type CmsEntryCreateMutationResponse = CmsCreateMutationResponse<CmsCollectionEntryModel>;
export const useCreateCmsCollectionEntry = (): CmsEntryCreateMutationResponse => {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(createCollectionEntry, {
    onSuccess: (data) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === QUERYKEYS.COLLECTION && query.queryKey[1] === data.collectionId) ||
          query.queryKey === QUERYKEYS.COLLECTIONS,
      }),
  });

  return {
    create: mutate,
    isError,
    isLoading,
    isSuccess,
  };
};
