import { useMutation, useQueryClient } from 'react-query';

import type { CmsCollectionSchemaDataModel } from '../../../../models/cms/collections//schemaData/CmsCollectionSchemaDataModel';
import type { CmsCreateMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { createCollectionSchemaData } from '../../../../services/CmsCollectionService';

type CmsSchemaDataCreateMutationResponse = CmsCreateMutationResponse<CmsCollectionSchemaDataModel>;
function useCreateCmsCollectionSchemaData(): CmsSchemaDataCreateMutationResponse {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(createCollectionSchemaData, {
    onSuccess: (data) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === QUERYKEYS.COLLECTION && query.queryKey[1] === data?.collectionId,
      }),
  });

  return {
    create: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useCreateCmsCollectionSchemaData;
