import '../../css/pages/PageNotFoundPage.css';
import React from 'react';
import { Button } from 'antd';
import ROUTES from '../../constants/routes';

const PageNotFoundPage = () => {
  return (
    <div className="page-not-found-root">
      <div className="code">404</div>
      <div className="title">Page Not Found</div>
      <div className="description">
        <p>The page you&apos;re trying to access does not exist.</p>
      </div>
      <div className="call-to-action">
        <Button type="primary" ghost size="large" href={ROUTES.HOME}>
          Return Home
        </Button>
      </div>
    </div>
  );
};

export default PageNotFoundPage;
