import { Form, InputNumber, Switch } from 'antd';

import React from 'react';

export const CmsSchemaDataTextItems = () => {
  return (
    <>
      <Form.Item label="Min Length" name="minLength">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Max Length" name="maxLength">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Is long text?" name="isLongText" valuePropName="checked">
        <Switch />
      </Form.Item>
    </>
  );
};
