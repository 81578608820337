import { useMutation, useQueryClient } from 'react-query';

import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import type { CmsUpdateMutationResponse } from '../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../constants/queryKeys';
import { updateCmsPage } from '../../../services/CmsPageService';

function useUpdateCmsPage(): CmsUpdateMutationResponse<CmsPageMetadataModel> {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(updateCmsPage, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGES ||
          query.queryKey === QUERYKEYS.PAGES ||
          query.queryKey[0] === QUERYKEYS.PAGE ||
          query.queryKey[0] === QUERYKEYS.PAGE_CONTENT ||
          query.queryKey[0] === QUERYKEYS.PAGE_CONTENT_VERSIONS ||
          query.queryKey[0] === QUERYKEYS.PAGE_GROUP,
      }),
  });

  return {
    update: mutate,
    isError: isError,
    isLoading: isLoading,
    isSuccess: isSuccess,
  };
}

export default useUpdateCmsPage;
