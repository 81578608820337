import React, { useMemo, useState } from 'react';
import { Button, Popconfirm } from 'antd';

import { head } from 'lodash';
import { pluralize } from './../../../utils/displayUtils';
import routes from '../../../constants/routes';
import useCloneCmsPages from './../../../hooks/services/cms/useCloneCmsPages';
import { useParams } from 'react-router-dom';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';

type Props = {
  pageIds: Array<number>;
  isDisabled?: boolean;
};

const EnhancedButton = ButtonDisabledWithInsufficientPermission(Button);

const CmsPageCloneButton = ({ pageIds, isDisabled }: Props) => {
  const { pageGroupId } = useParams();
  const [newPages, setNewPages] = useState<any[]>([]);
  const { cloneMutation, isError, isLoading, isSuccess } = useCloneCmsPages();
  const successRecordText = pluralize(pageIds.length, 'record was', {
    plural: 'records were',
  });

  // Need to memorize the successDescription so it doesn't cause the hook to rerender.
  const successDescription = useMemo(
    () => (
      <span>
        The {successRecordText} successfully cloned.
        {newPages.length === 1 && pageGroupId && (
          <a
            target="_blank"
            href={`${routes.PAGE_GROUPS}/${pageGroupId}/page/${head(newPages)?.id}`}
            rel="noreferrer"
          >
            {' '}
            View
          </a>
        )}
      </span>
    ),
    [newPages, pageGroupId, successRecordText],
  );

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: successDescription,
  });

  return (
    <Popconfirm
      title={`Are you sure you want to clone?`}
      onConfirm={() => cloneMutation(pageIds, { onSuccess: (data) => setNewPages(data) })}
    >
      <EnhancedButton type="primary" disabled={isDisabled} loading={isLoading}>
        Clone
      </EnhancedButton>
    </Popconfirm>
  );
};

export default CmsPageCloneButton;
