import type { CmsPageGroupMetadataModel } from '../../../models/cms/CmsPageGroupMetadataModel';
import QUERYKEYS from '../../../constants/queryKeys';
import config from '../../../config/';
import { getPageGroups } from '../../../services/CmsPageGroupService';
import { useQuery } from 'react-query';

type TUseCmsPageGroups = {
  data?: Array<CmsPageGroupMetadataModel>;
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsPageGroups(): TUseCmsPageGroups {
  const { isLoading, isError, data } = useQuery(
    QUERYKEYS.PAGE_GROUPS,
    async () => getPageGroups(),
    {
      staleTime: config.cmsIdleTimeoutInMinutes * 60 * 1000,
    },
  );

  return {
    data,
    isError,
    isLoading,
  };
}
