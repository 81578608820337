import React from 'react';
import { Row } from 'antd';
import PageHeader from '../../shared/PageHeader';
import Section from '../../shared/Section';
import routes from '../../../constants/routes';
import { CmsReleasesTable } from '../../cms/tables/CmsReleasesTable';
import useCmsReleases from '../../../hooks/services/cms/releases/useCmsReleases';
import CmsReleaseCreateButton from '../../cms/buttons/releases/CmsReleaseCreateButton';

const CmsReleasesPage = () => {
  const { data, isLoading } = useCmsReleases();

  return (
    <div>
      <PageHeader
        title="CMS Releases"
        breadcrumbRoutes={[
          { breadcrumbName: 'Home', path: routes.CMS },
          { breadcrumbName: 'Releases', path: routes.CMS_RELEASES },
        ]}
        extra={[
          <Row key="actionBar" justify="end">
            <CmsReleaseCreateButton />
          </Row>,
        ]}
      />
      <Section isLoading={isLoading}>
        <CmsReleasesTable key="table1" data={data} />
      </Section>
    </div>
  );
};

export default CmsReleasesPage;
