import { useMutation, useQueryClient } from 'react-query';

import QUERYKEYS from '../../../constants/queryKeys';
import { deletePageGroups } from '../../../services/CmsPageGroupService';

type MutationCallbacks = {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
};

type $DeleteCmsPageGroup = {
  deleteMutation: (pageGroupIds: Array<number>, callbacks?: MutationCallbacks) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

function useDeleteCmsPageGroups(): $DeleteCmsPageGroup {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(deletePageGroups, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.PAGE_GROUPS ||
          query.queryKey === QUERYKEYS.PAGES ||
          query.queryKey === QUERYKEYS.PAGE ||
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGES ||
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGE_GROUPS,
      }),
  });

  return {
    deleteMutation: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useDeleteCmsPageGroups;
