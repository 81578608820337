import type {
  TVendorCodeCommercial,
  TVendorCodeDirectMail,
  TVendorCodesInternet,
} from '../../../models/vendor/VendorCodeModels';
import type { TQueryModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { getAllVendorCodes } from './../../../services/VendorCodesService';
import { useQuery } from 'react-query';

function useVendorCodes(): TQueryModel<
  (TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet)[]
> {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.VENDOR_CODES, getAllVendorCodes);

  return {
    data,
    isError,
    isLoading,
  };
}

export default useVendorCodes;
