import { CmsCollectionEntryModalForm } from './../../forms/collections/CmsCollectionEntryModalForm';
import type { CmsCollectionEntryModel } from '../../../../models/cms/collections/CmsCollectionEntryModel';
import { EditOutlined } from '@ant-design/icons';
import { IconTextButton } from '../../../shared/IconTextButton';
import React, { useState } from 'react';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  collectionEntry?: CmsCollectionEntryModel;
  styleType?: 'icon' | 'primary';
  isDisabled?: boolean;
};

const EnhancedIconTextButton = ButtonHiddenWithInsufficientPermission(IconTextButton);

export const CmsCollectionEntryEditButton = ({
  collectionEntry,
  styleType = 'icon',
  isDisabled,
}: Props) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <EnhancedIconTextButton
        buttonText="Edit"
        buttonIcon={<EditOutlined />}
        styleType={styleType}
        isDisabled={isDisabled}
        onClick={() => setIsModalShown(true)}
      />
      {collectionEntry && (
        <CmsCollectionEntryModalForm
          isShown={isModalShown}
          setIsShown={setIsModalShown}
          initialData={collectionEntry}
        />
      )}
    </>
  );
};
