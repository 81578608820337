import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Space, Table, Typography } from 'antd';
import { uniqBy } from 'lodash';

import ROUTES from '../../../constants/routes';
import { getDateDisplayValue, getModifiedBy, pluralize } from '../../../utils/displayUtils';

import CmsCollectionEntryArchiveButton from '../buttons/collections/CmsCollectionEntryArchiveButton';
import type { CmsCollectionEntryModel } from './../../../models/cms/collections/CmsCollectionEntryModel';
import type { CmsStatus } from '../../../enums/cms/CmsStatusEnum';
import CmsStatusText from '../displays/CmsStatusText';
import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import type { CmsLocaleEnum } from '../../../enums/cms/CmsLocaleEnum';
import { CmsLocaleDisplayNames } from '../../../enums/cms/CmsLocaleEnum';
import TableWithHeader from '../../hocs/tables/TableWithHeader';
import TableWithSelectableRows from '../../hocs/tables/TableWithSelectableRows';

type Props = {
  data?: Array<CmsCollectionEntryModel>;
  isLoading?: boolean;
};

const SelectableTableWithHeader = TableWithHeader(TableWithSelectableRows(Table));

const getColumns = (data: Array<CmsCollectionEntryModel>): any => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      render: (value: string, record: CmsCollectionEntryModel) => (
        <Link to={`${ROUTES.CMS_COLLECTIONS}/${record.collectionId}/entries/${record.id}`}>
          {value}
        </Link>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '20%',
      render: (value: CmsStatus) => <CmsStatusText status={value} />,
    },
    {
      title: 'Last Modified Date',
      dataIndex: 'updatedDate',
      width: '20%',
      sorter: (a: CmsPageMetadataModel, b: CmsPageMetadataModel): any =>
        new Date(a.updatedDate).getTime() - new Date(b.updatedDate).getTime(),
      defaultSortOrder: 'descend',
      render: (value: string) => getDateDisplayValue(value),
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedBy',
      width: '20%',
      sorter: (a, b) => a.updatedBy.toUpperCase().localeCompare(b.updatedBy.toUpperCase()),
      render: (value: string) => getModifiedBy(value),
    },
    {
      title: 'Locale',
      dataIndex: 'localeId',
      width: '20%',
      sorter: (a, b) =>
        CmsLocaleDisplayNames[a.localeId].localeCompare(CmsLocaleDisplayNames[b.localeId]),
      filterSearch: true,
      filters: uniqBy(data, 'localeId').map((record) => ({
        text: CmsLocaleDisplayNames[record.localeId],
        value: record.localeId,
      })),
      onFilter: (cmsLocaleEnum: CmsLocaleEnum, record: CmsPageMetadataModel): boolean =>
        record.localeId === cmsLocaleEnum,
      render: (value) => CmsLocaleDisplayNames[value],
    },
  ];
};

const getHeader = (selectedRows: Array<CmsCollectionEntryModel>) => (
  <Space>
    <CmsCollectionEntryArchiveButton
      isDisabled={!selectedRows.length}
      selectedCollectionEntries={selectedRows}
    />
    <Typography.Text>
      {selectedRows.length > 0 &&
        `${pluralize(selectedRows.length, 'item', { includeCount: true })} selected`}
    </Typography.Text>
  </Space>
);

const CmsCollectionEntriesTable = ({ data, isLoading = false }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const onSelect = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  return (
    <SelectableTableWithHeader
      header={getHeader(selectedRows)}
      bordered
      columns={getColumns(data ?? [])}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      rowKey="id"
      rowSelection={{ selectedRowKeys, onChange: onSelect }}
      size="small"
    />
  );
};

export default CmsCollectionEntriesTable;
