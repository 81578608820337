import { Row, Space, Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { getDateTimeDisplayValue, getModifiedBy, pluralize } from './../../../utils/displayUtils';
import ROUTES from '../../../constants/routes';

import CmsPageGroupActionButtons from '../buttons/CmsPageGroupActionButtons';
import type { CmsPageGroupMetadataModel } from './../../../models/cms/CmsPageGroupMetadataModel';
import TableWithSelectableRows from '../../hocs/tables/TableWithSelectableRows';
import TableWithHeader from '../../hocs/tables/TableWithHeader';
import { Link } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { useState } from 'react';

type Props = {
  data?: Array<CmsPageGroupMetadataModel>;
  extraHeader?: React.ReactNode;
  isLoading?: boolean;
};

const SelectableTableWithHeader = TableWithHeader(TableWithSelectableRows(Table));

const getColumns = (data: Array<CmsPageGroupMetadataModel>): any => [
  {
    title: 'Title',
    dataIndex: 'title',
    sorter: (a, b) => a.title.localeCompare(b.title),
    filterSearch: true,
    filters: uniqBy(data, 'title').map(({ title }) => ({
      text: title,
      value: title,
    })),
    render: (value, row) => <Link to={`${ROUTES.PAGE_GROUPS}/${row.id}`}>{value}</Link>,
    onFilter: (searchValue: string, record: CmsPageGroupMetadataModel): boolean =>
      searchValue === record.title,
  },
  {
    title: 'Slug',
    dataIndex: 'slug',
    sorter: (a, b) => a.slug.localeCompare(b.slug),
  },
  {
    title: 'Last Modified Date',
    dataIndex: 'updatedDate',
    render: getDateTimeDisplayValue,
    sorter: (a: CmsPageGroupMetadataModel, b: CmsPageGroupMetadataModel): any =>
      new Date(a.updatedDate).getTime() - new Date(b.updatedDate).getTime(),
    defaultSortOrder: 'descend',
  },
  {
    title: 'Last Modified',
    dataIndex: ['updatedBy', 'email'],
    sorter: (a, b) => a.updatedBy.email.localeCompare(b.updatedBy.email),
    filterSearch: true,
    filters: uniqBy(data, 'updatedBy.email').map((record) => ({
      text: getModifiedBy(record.updatedBy.email),
      value: record.updatedBy.email,
    })),
    onFilter: (searchValue: string, record: CmsPageGroupMetadataModel): boolean =>
      searchValue === record.updatedBy.email,
    render: (value: string) => getModifiedBy(value),
  },
];

const getHeader = (selectedRows: Array<CmsPageGroupMetadataModel>) => (
  <Space className="paddingVert8">
    <CmsPageGroupActionButtons data={selectedRows} />
    <Typography.Text>
      {selectedRows.length > 0 &&
        `${pluralize(selectedRows.length, 'item', { includeCount: true })} selected`}
    </Typography.Text>
  </Space>
);

const CmsPageGroupsTable = ({ data, extraHeader, isLoading = false }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelect = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  }, [data]);

  const header = getHeader(selectedRows);
  const tableHeader = extraHeader ? (
    <Row justify="space-between">
      {header}
      <Space className="paddingVert8">{extraHeader}</Space>
    </Row>
  ) : (
    header
  );

  return (
    <SelectableTableWithHeader
      header={tableHeader}
      bordered
      columns={getColumns(data ?? [])}
      dataSource={data}
      loading={isLoading}
      pagination={{
        hideOnSinglePage: true,
        responsive: true,
        total: data?.length ?? 0,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      }}
      size="small"
      rowKey="id"
      rowSelection={{ selectedRowKeys, onChange: onSelect }}
    />
  );
};

export default CmsPageGroupsTable;
