import '../../../css/cms/buttons/CmsPageHistoryMoreOptionsButton.css';

import { Dropdown, Menu } from 'antd';

import type { CmsPageContentVersionMetadataModel } from '../../../models/cms/CmsPageContentVersionMetadataModel';
import CmsPageSetEnvironmentsModal from '../modals/CmsPageSetEnvironmentsModal';
import { EllipsisOutlined } from '@ant-design/icons';
import React from 'react';

type Props = {
  contentVersions: CmsPageContentVersionMetadataModel[];
  isDraftVersion: boolean;
  selectedContentVersion: CmsPageContentVersionMetadataModel;
};

export default function CmsPageHistoryMenuButton({
  contentVersions,
  isDraftVersion,
  selectedContentVersion,
}: Props) {
  const [isAssignEnvironmentModalShown, setIsAssignEnvironmentModalShown] = React.useState(false);

  if (isDraftVersion) {
    return null;
  }

  const menuItems = [
    {
      label: 'Assign Environments',
      key: 'assign-environments',
    },
  ];

  const handleSelect = (key) => {
    if (key === 'assign-environments' && !isDraftVersion) {
      setIsAssignEnvironmentModalShown(true);
    }
  };

  return (
    <>
      <Dropdown.Button
        className="cmsPageHistoryMoreOptionsButtonRoot"
        icon={<EllipsisOutlined className="cmsPageHistoryMoreOptionsButtonIcon" />}
        overlay={<Menu items={menuItems} onClick={(e) => handleSelect(e.key)} />}
        trigger={['click']}
        type="text"
      ></Dropdown.Button>
      <CmsPageSetEnvironmentsModal
        contentVersions={contentVersions}
        isShown={isAssignEnvironmentModalShown}
        selectedContentVersion={selectedContentVersion}
        setIsShown={setIsAssignEnvironmentModalShown}
      />
    </>
  );
}
