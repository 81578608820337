import { RuleExpressionBooleanOperatorEnum } from '../../enums/rules/RuleExpressionBooleanOperatorEnum';
import { RuleExpressionValueTypeEnum } from '../../enums/rules/RuleExpressionValueTypeEnum';

export type $RuleBooleanExpressionType = {
  firstOperandType: RuleExpressionValueTypeEnum;
  operator: RuleExpressionBooleanOperatorEnum;
  secondOperandType: RuleExpressionValueTypeEnum;
};

// An exhaustive list of how value types can be compared using a boolean operator. The list is use
// for validation as all expressions must match one of these definitions.
export const RULE_BOOLEAN_EXPRESSION_TYPES: $RuleBooleanExpressionType[] = [
  {
    firstOperandType: RuleExpressionValueTypeEnum.ARRAYNUMBER,
    operator: RuleExpressionBooleanOperatorEnum.INCLUDES,
    secondOperandType: RuleExpressionValueTypeEnum.NUMBER,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.ARRAYSTRING,
    operator: RuleExpressionBooleanOperatorEnum.INCLUDES,
    secondOperandType: RuleExpressionValueTypeEnum.STRING,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.BOOLEAN,
    operator: RuleExpressionBooleanOperatorEnum.DOES_NOT_EQUAL,
    secondOperandType: RuleExpressionValueTypeEnum.BOOLEAN,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.BOOLEAN,
    operator: RuleExpressionBooleanOperatorEnum.EQUALS,
    secondOperandType: RuleExpressionValueTypeEnum.BOOLEAN,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.NUMBER,
    operator: RuleExpressionBooleanOperatorEnum.DOES_NOT_EQUAL,
    secondOperandType: RuleExpressionValueTypeEnum.NUMBER,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.NUMBER,
    operator: RuleExpressionBooleanOperatorEnum.EQUALS,
    secondOperandType: RuleExpressionValueTypeEnum.NUMBER,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.NUMBER,
    operator: RuleExpressionBooleanOperatorEnum.GREATER_THAN,
    secondOperandType: RuleExpressionValueTypeEnum.NUMBER,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.NUMBER,
    operator: RuleExpressionBooleanOperatorEnum.GREATER_THAN_OR_EQUAL,
    secondOperandType: RuleExpressionValueTypeEnum.NUMBER,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.NUMBER,
    operator: RuleExpressionBooleanOperatorEnum.IN,
    secondOperandType: RuleExpressionValueTypeEnum.ARRAYNUMBER,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.NUMBER,
    operator: RuleExpressionBooleanOperatorEnum.LESS_THAN,
    secondOperandType: RuleExpressionValueTypeEnum.NUMBER,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.NUMBER,
    operator: RuleExpressionBooleanOperatorEnum.LESS_THAN_OR_EQUAL,
    secondOperandType: RuleExpressionValueTypeEnum.NUMBER,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.STRING,
    operator: RuleExpressionBooleanOperatorEnum.STARTS_WITH,
    secondOperandType: RuleExpressionValueTypeEnum.STRING,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.STRING,
    operator: RuleExpressionBooleanOperatorEnum.CONTAINS,
    secondOperandType: RuleExpressionValueTypeEnum.STRING,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.STRING,
    operator: RuleExpressionBooleanOperatorEnum.DOES_NOT_EQUAL,
    secondOperandType: RuleExpressionValueTypeEnum.STRING,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.STRING,
    operator: RuleExpressionBooleanOperatorEnum.ENDS_WITH,
    secondOperandType: RuleExpressionValueTypeEnum.STRING,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.STRING,
    operator: RuleExpressionBooleanOperatorEnum.EQUALS,
    secondOperandType: RuleExpressionValueTypeEnum.STRING,
  },
  {
    firstOperandType: RuleExpressionValueTypeEnum.STRING,
    operator: RuleExpressionBooleanOperatorEnum.IN,
    secondOperandType: RuleExpressionValueTypeEnum.ARRAYSTRING,
  },
];
