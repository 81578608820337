import './css/App.css';

import React from 'react';

import AdminRoutes from './components/routing/AdminRoutes';

const App = () => {
  return (
    <div className="appRoot">
      <AdminRoutes />
    </div>
  );
};

export default App;
