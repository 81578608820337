import { head, includes } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import type { CmsCollectionEntryModel } from '../../../../models/cms/collections/CmsCollectionEntryModel';
import type { CmsDeleteMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { deleteCollectionEntries } from '../../../../services/CmsCollectionService';

type CmsEntryDeleteMutationResponse = CmsDeleteMutationResponse<Array<CmsCollectionEntryModel>>;

export const useDeleteCmsCollectionEntry = (): CmsEntryDeleteMutationResponse => {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(deleteCollectionEntries, {
    onSuccess: (_data, variables) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === QUERYKEYS.COLLECTION_ENTRY &&
            includes(
              variables.map((entry) => entry.id),
              query.queryKey[1],
            )) ||
          (query.queryKey[0] === QUERYKEYS.COLLECTION &&
            query.queryKey[1] === head(variables)?.collectionId) ||
          query.queryKey === QUERYKEYS.COLLECTIONS,
      }),
  });

  return {
    deleteMutation: mutate,
    isError,
    isLoading,
    isSuccess,
  };
};
