import React, { useEffect } from 'react';
import { Button, Popconfirm } from 'antd';

import type { CmsCollectionEntryModel } from './../../../../models/cms/collections/CmsCollectionEntryModel';
import { pluralize } from '../../../../utils/displayUtils';
import { useDeleteCmsCollectionEntry } from '../../../../hooks/services/cms/collections/useDeleteCmsCollectionEntry';
import useStatusNotifications from '../../../../hooks/shared/useStatusNotifications';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  selectedCollectionEntries: Array<CmsCollectionEntryModel>;
  isDisabled?: boolean;
  onDelete?: () => void;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const CmsCollectionEntryArchiveButton = ({
  isDisabled,
  selectedCollectionEntries,
  onDelete,
}: Props) => {
  const { deleteMutation, isError, isSuccess, isLoading } = useDeleteCmsCollectionEntry();
  const resultText = pluralize(selectedCollectionEntries.length, 'item was', {
    plural: 'items were',
  });

  useEffect(() => {
    isSuccess && onDelete?.();
  }, [isSuccess, onDelete]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The collection ${resultText} successfully archived.`,
  });
  return (
    <>
      <Popconfirm
        placement="topRight"
        title={`Are you sure you want to archive ${
          selectedCollectionEntries.length > 1 ? 'these collection items' : 'this collection item'
        }?`}
        onConfirm={() => deleteMutation(selectedCollectionEntries)}
      >
        <EnhancedButton type="primary" disabled={isDisabled} loading={isLoading} danger={true}>
          Archive
        </EnhancedButton>
      </Popconfirm>
    </>
  );
};

export default CmsCollectionEntryArchiveButton;
