import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Space, Table } from 'antd';
import { sortBy } from 'lodash';
import ROUTES from '../../../constants/routes';

import { getDateDisplayValue, getModifiedBy, pluralize } from '../../../utils/displayUtils';
import CmsReleaseArchiveButton from '../buttons/releases/CmsReleaseArchiveButton';
import type { CmsReleaseModel } from '../../../models/cms/releases/CmsReleaseModel';
import TableWithHeader from '../../hocs/tables/TableWithHeader';
import TableWithSelectableRows from '../../hocs/tables/TableWithSelectableRows';

type Props = {
  data?: Array<CmsReleaseModel>;
  extraHeader?: React.ReactNode;
  isLoading?: boolean;
};

const SelectableTableWithHeader = TableWithHeader(TableWithSelectableRows(Table));

const getColumns = (_data: Array<CmsReleaseModel>): any => [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: (a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name),
    render: (value: string, row: CmsReleaseModel) => (
      <Link to={`${ROUTES.CMS_RELEASES}/${row.id}`}>{value}</Link>
    ),
  },
  {
    title: 'Target Date',
    dataIndex: 'targetDate',
    sorter: (a: { targetDate: string }, b: { targetDate: string }) =>
      a.targetDate.localeCompare(b.targetDate),
    render: (value: string) => getDateDisplayValue(value),
  },
  {
    title: 'Last Modified By',
    dataIndex: 'updatedBy',
    sorter: (a: { targetDate: string }, b: { targetDate: string }) =>
      a.targetDate.localeCompare(b.targetDate),
    render: (value: string) => getModifiedBy(value),
  },
  {
    title: 'Last Modified Date',
    dataIndex: 'updatedDate',
    sorter: (a: { targetDate: string }, b: { targetDate: string }) =>
      a.targetDate.localeCompare(b.targetDate),
    render: (value: string) => getDateDisplayValue(value),
  },
];

const getHeader = (selectedRows: Array<CmsReleaseModel>) => (
  <>
    <Row key="actionBar" justify="space-between">
      <Space className="paddingVert8" size="middle">
        <CmsReleaseArchiveButton data={selectedRows} isDisabled={!selectedRows.length} />
        {selectedRows.length > 0 &&
          pluralize(selectedRows.length, 'item', { includeCount: true }) + ' selected'}
      </Space>
    </Row>
  </>
);

export const CmsReleasesTable = ({ data, isLoading, extraHeader }: Props) => {
  const sortedData = sortBy(data, ['targetDate']);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const onSelect = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  }, [data]);

  const header = getHeader(selectedRows);
  const tableHeader = extraHeader ? (
    <Row justify="space-between">
      {header}
      <Space className="paddingVert8">{extraHeader}</Space>
    </Row>
  ) : (
    header
  );

  return (
    <SelectableTableWithHeader
      header={tableHeader}
      bordered
      columns={getColumns(data ?? [])}
      dataSource={sortedData}
      loading={isLoading}
      pagination={{
        hideOnSinglePage: true,
        responsive: true,
        total: data?.length ?? 0,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      }}
      size="small"
      rowKey="id"
      rowSelection={{ selectedRowKeys, onChange: onSelect }}
    />
  );
};
