import React from 'react';
import { Form, Select } from 'antd';
import { map } from 'lodash';
import { CmsLocaleDisplayNames, CmsLocaleEnum } from '../../enums/cms/CmsLocaleEnum';
import InputDisabledWithInsufficientPermission from '../hocs/inputs/InputDisabledWithInsufficientPermission';

const { Option } = Select;

type Props = {
  label?: React.ReactNode;
  isDisabled?: boolean;
  isRequired?: boolean;
};

const EnhancedSelect = InputDisabledWithInsufficientPermission(Select);

const LocaleSelector = ({ label = 'Locale', isDisabled = false, isRequired = true }: Props) => {
  const selectItems = map(Object.entries(CmsLocaleDisplayNames), ([key, label]) => {
    return (
      <Option key={key} value={Number(key)}>
        {label}
      </Option>
    );
  });
  return (
    <Form.Item
      name="localeId"
      initialValue={CmsLocaleEnum.EN_US}
      label={label}
      rules={[{ message: '"${label}" is required', required: isRequired }]}
    >
      <EnhancedSelect disabled={isDisabled}>{selectItems}</EnhancedSelect>
    </Form.Item>
  );
};

export default LocaleSelector;
