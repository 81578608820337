import { Row } from 'antd';
import React from 'react';

import CmsCollectionCreateButton from '../../cms/buttons/collections/CmsCollectionCreateButton';
import { CmsCollectionsTable } from '../../cms/tables/CmsCollectionsTable';
import PageHeader from '../../shared/PageHeader';
import routes from '../../../constants/routes';
import Section from '../../shared/Section';
import useCmsCollections from '../../../hooks/services/cms/collections/useCmsCollections';

export const CmsCollectionsPage = () => {
  const { data, isLoading, isError } = useCmsCollections();

  return (
    <div>
      <PageHeader
        title="CMS Collections"
        breadcrumbRoutes={[
          { breadcrumbName: 'Home', path: routes.CMS },
          { breadcrumbName: 'Collections', path: routes.CMS_COLLECTIONS },
        ]}
        extra={[
          <Row key="actionBar" justify="end">
            <CmsCollectionCreateButton />
          </Row>,
        ]}
      />
      <Section isError={isError}>
        <CmsCollectionsTable key="collections" data={data} isLoading={isLoading} />
      </Section>
    </div>
  );
};
