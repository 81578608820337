import type { CmsCollectionEntryModel } from '../../../../models/cms/collections/CmsCollectionEntryModel';
import type { CmsQueryResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { getCollectionEntry } from '../../../../services/CmsCollectionService';
import { isNil } from 'lodash';
import { useQuery } from 'react-query';

export const useCmsCollectionEntry = ({
  collectionId,
  entryId,
}: {
  collectionId: number;
  entryId: number;
}): CmsQueryResponse<CmsCollectionEntryModel> => {
  const shouldFetch =
    !isNil(entryId) &&
    !isNaN(entryId) &&
    entryId !== 0 &&
    !isNil(collectionId) &&
    !isNaN(collectionId) &&
    collectionId !== 0;
  const { isLoading, isError, data } = useQuery(
    [QUERYKEYS.COLLECTION_ENTRY, entryId],
    async () => getCollectionEntry(collectionId, entryId),
    {
      staleTime: 0,
      enabled: shouldFetch,
      retry: false,
    },
  );

  return {
    data,
    isError,
    isLoading,
  };
};
