import type { CmsCollectionModel } from '../../../../models/cms/collections/CmsCollectionModel';

import { useQuery } from 'react-query';

import config from '../../../../config';
import QUERYKEYS from '../../../../constants/queryKeys';
import { getLastModifiedCollections } from '../../../../services/CmsCollectionService';

type TUseCmsLastModifiedCollections = {
  data?: CmsCollectionModel[];
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsLastModifiedCollections(): TUseCmsLastModifiedCollections {
  const { isLoading, isError, data } = useQuery(
    QUERYKEYS.LAST_MODIFIED_COLLECTIONS,
    async () => getLastModifiedCollections(),
    {
      staleTime: config.cmsIdleTimeoutInMinutes * 60 * 1000,
    },
  );

  return {
    data,
    isError,
    isLoading,
  };
}
