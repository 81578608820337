/* eslint-disable max-len */
import type { TConfig } from './index.js';

const config: TConfig = {
  environment: 'staging',
  adminGateway: 'https://gateway.staging.enduranceapi.com/admin/api',
  idleTimeoutInMinutes: 30,
  cmsIdleTimeoutInMinutes: 0,
  auth: {
    clientId: '104daab0-dbe6-4de2-b1ff-a6c25c40d5b6',
    authority: 'https://login.microsoftonline.com/daae7bc1-c893-46c4-8df4-4464a9a6f43e',
  },
  refetchOnWindowFocus: false,
  maxBulkUpdateFileSizeKb: 20,
};

export default config;
