import { useMutation, useQueryClient } from 'react-query';

import type { CmsCollectionSchemaDataModel } from '../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataModel';
import type { CmsUpdateMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { updateCollectionSchemaData } from '../../../../services/CmsCollectionService';

type CmsSchemaDataUpdateMutationResponse = CmsUpdateMutationResponse<CmsCollectionSchemaDataModel>;

function useUpdateCmsCollectionSchemaData(): CmsSchemaDataUpdateMutationResponse {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(updateCollectionSchemaData, {
    onSuccess: (_data, schemaData) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === QUERYKEYS.COLLECTION &&
          query.queryKey[1] === schemaData.collectionId,
      }),
  });

  return {
    update: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useUpdateCmsCollectionSchemaData;
