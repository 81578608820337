import React from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Popover } from 'antd';
import useCreatePromoCodeDiscount from '../../../hooks/services/promoCodes/useCreatePromoCodeDiscount';
import { PromoCodeDiscountType } from '../../../enums/promoCodes/PromoCodeDiscountTypeEnum';

type Props = {
  promoCodeId: number;
  isVisible?: boolean;
  isDisabled?: boolean;
  onSuccess?: () => void;
  onError?: (errors: any) => void;
};

const PromoCodeDiscountSaveButton = ({
  promoCodeId,
  isVisible,
  isDisabled,
  onSuccess,
  onError,
}: Props) => {
  const form = Form.useFormInstance();
  const createMutation = useCreatePromoCodeDiscount();

  const onSaveClick = async (promoCodeId: number) => {
    const data = await form.validateFields();
    const promoCodeDiscount = {
      id: 0,
      promoCodeId: promoCodeId,
      discountType: data.discountType || PromoCodeDiscountType.FLAT_AMOUNT,
      discount: data.discount,
      startDate: data.startDate,
      endDate: data.endDate,
    };

    await createMutation.create(promoCodeDiscount, {
      onSuccess: () => onSuccess?.(),
      onError: (error) => onError?.(error),
    });
  };

  isVisible ??= true;
  isDisabled ??= false;

  return isVisible ? (
    <Popover content={'Save'} placement="left">
      <Button
        className="promoCodeDiscountRowButton"
        icon={<SaveOutlined />}
        loading={createMutation.isLoading}
        shape="circle"
        disabled={isDisabled}
        onClick={() => onSaveClick(promoCodeId)}
      />
    </Popover>
  ) : null;
};

export default PromoCodeDiscountSaveButton;
