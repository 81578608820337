import { CmsButtonTypeEnum } from '../../../enums/cms/CmsButtonTypeEnum';
import CmsPageCreateButton from './CmsPageCreateButton';
import CmsPageGroupArchiveButton from './CmsPageGroupArchiveButton';
import CmsPageGroupEditButton from './CmsPageGroupEditButton';
import type { CmsPageGroupMetadataModel } from './../../../models/cms/CmsPageGroupMetadataModel';
import React from 'react';
import { Space } from 'antd';
import { head } from 'lodash';

type Props = {
  data: Array<CmsPageGroupMetadataModel>;
  buttonTypes?: Array<CmsButtonTypeEnum>;
  onDelete?: () => void;
};

const CmsPageGroupActionButtons = ({
  data,
  onDelete,
  buttonTypes = [CmsButtonTypeEnum.ARCHIVE],
}: Props) => {
  const pageGroupId = data.length === 1 ? head(data)?.id : undefined;
  const hasNoData = !(data?.length > 0);
  const getButtonByType = (type: CmsButtonTypeEnum, key: number) => {
    switch (type) {
      case CmsButtonTypeEnum.ARCHIVE:
        return (
          <CmsPageGroupArchiveButton
            pageGroups={data}
            key={key}
            isDisabled={hasNoData}
            onDelete={onDelete}
          />
        );
      case CmsButtonTypeEnum.CREATE:
        return (
          <CmsPageCreateButton
            pageGroupId={pageGroupId?.toString()}
            key={key}
            isDisabled={hasNoData}
          />
        );
      case CmsButtonTypeEnum.EDIT:
        return <CmsPageGroupEditButton pageGroup={head(data)} key={key} isDisabled={hasNoData} />;
      default:
        return null;
    }
  };
  return <Space>{buttonTypes.map((type, index) => getButtonByType(type, index))}</Space>;
};

export default CmsPageGroupActionButtons;
