import '../../../css/cms/displays/CmsStatusText.css';

import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { CmsStatus } from './../../../enums/cms/CmsStatusEnum';
import React from 'react';

type Props = {
  status: CmsStatus;
};

const CmsStatusText = ({ status }: Props) => {
  switch (status) {
    case CmsStatus.Published:
      return (
        <span className="cmsStatusTextPublished">
          <CheckCircleOutlined /> Published
        </span>
      );
    case CmsStatus.PublishedAndUnpublishedVersions:
      return (
        <>
          <span className="cmsStatusTextDraft">
            <InfoCircleOutlined /> Draft
          </span>
          {' / '}
          <span className="cmsStatusTextPublished">
            <CheckCircleOutlined /> Published
          </span>
        </>
      );
    default:
      return (
        <span className="cmsStatusTextDraft">
          <InfoCircleOutlined /> Draft
        </span>
      );
  }
};

export default CmsStatusText;
