import { CmsCollectionSchemaDataTypeEnum } from '../enums/cms/CmsCollectionSchemaDataTypeEnum';

export function convertTitleToSlug(title: string, delimiter = '-'): string {
  if (!title) {
    return '';
  }

  return title
    .toLowerCase()
    .replace(/[^a-z0-9\-_\s]/g, '') //ignore any character that is not valid
    .replace(/-/g, ' ') //replace dashes with spaces (so we treat '-' like ' ' in the next step)
    .replace(/\s+/g, delimiter); //replace spaces with dashes
}

export function getSlugValidationRegex(): RegExp {
  return new RegExp(/^([a-z]|[0-9]|-|_)+$/);
}

export const getCmsCollectionSchemaDataTypeDisplayName = (
  value: CmsCollectionSchemaDataTypeEnum,
) => {
  switch (value) {
    case CmsCollectionSchemaDataTypeEnum.Number:
      return 'Number';
    case CmsCollectionSchemaDataTypeEnum.Ref:
      return 'Nested Collection';
    case CmsCollectionSchemaDataTypeEnum.Select:
      return 'Select Dropdown';
    case CmsCollectionSchemaDataTypeEnum.Text:
      return 'Text';
    case CmsCollectionSchemaDataTypeEnum.CodeEditor:
      return 'Code Block';
  }
};
