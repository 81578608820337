import '../../../css/core/PasswordInput.css';

import { Alert, Button, Descriptions, Divider, Spin, notification, Input } from 'antd';
import PageHeader from '../../shared/PageHeader';
import ROUTES from '../../../constants/routes';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Section from '../../shared/Section';
import VendorCodeInternetModalForm from './../../vendor/forms/VendorCodeInternetModalForm';
import { VendorCodeType } from '../../../enums/vendor/VendorCodeTypeEnum';
import {
  getBooleanDisplayValue,
  getNumberDisplayValue,
  getPhoneNumberDisplayValue,
} from './../../../utils/displayUtils';
import { useParams } from 'react-router-dom';
import useVendorCodeInternet from './../../../hooks/services/vendor/useVendorCodeInternet';
interface LocationState {
  state: {
    isNew: boolean;
    name: string;
  };
}

const VendorCodeIntenerDetailPage = () => {
  const { vendorCodeId } = useParams();
  const { data, isError, isLoading } = useVendorCodeInternet(vendorCodeId!);
  const [isModalShown, setIsModalShown] = React.useState<boolean>(false);
  const locationInfo = useLocation();
  const { state } = locationInfo as LocationState;

  React.useEffect(() => {
    if (state && state.isNew) {
      notification.success({
        message: <strong>Successfully Created</strong>,
        description: (
          <span>
            Internet vendor code <strong>{state?.name}</strong> successfully created.
          </span>
        ),
        placement: 'bottomLeft',
      });
      state.isNew = false;
    }
  }, [state]);

  if (isLoading) {
    return <Spin />;
  }

  if (isError || !data) {
    return (
      <Alert
        message="Error"
        description="An error occurred while attempting to load the data for this vendor code."
        type="error"
        showIcon
      />
    );
  }

  return (
    <div className="vendorDetailsPageRoot">
      <PageHeader
        breadcrumbRoutes={[
          { breadcrumbName: 'Vendor Codes', path: ROUTES.VENDOR_CODES },
          { breadcrumbName: data.vendorName, path: `${ROUTES.VENDORS}/${data.vendorId}` },
          { breadcrumbName: data.legacyVendorId, path: window.location.pathname },
        ]}
        extra={[
          <Button
            type="primary"
            key="update"
            onClick={() => {
              setIsModalShown(true);
            }}
          >
            Update
          </Button>,
        ]}
        title={data.legacyVendorId}
        subTitle={VendorCodeType.INTERNET}
        isSubTitlePill={true}
        pillClassName="vendorCodeTypeInternet"
      />
      <Section isLoading={isLoading}>
        {state?.isNew &&
          notification['success']({
            message: 'Successfully Created',
            description: `${state?.name} successfully created.`,
          })}
        <Descriptions
          key={data.name}
          bordered={true}
          column={3}
          labelStyle={{ fontWeight: 'bold' }}
          size="small"
        >
          <Descriptions.Item label="Active:">
            {getBooleanDisplayValue(data.isActive)}
          </Descriptions.Item>
          <Descriptions.Item label="Vendor:">{data.vendorName}</Descriptions.Item>
          <Descriptions.Item label="API Username:">{data.name}</Descriptions.Item>
          <Descriptions.Item label="Secret:">
            <Input.Password className="style-secret-disp" value={data.secret} readOnly />
          </Descriptions.Item>
          <Descriptions.Item label="Duplicate Days:">{data.duplicateDays}</Descriptions.Item>
          <Descriptions.Item label="Max Years Old:">{data.lastNYears}</Descriptions.Item>
          <Descriptions.Item label="Max Odometer:">
            {getNumberDisplayValue(data.maxMileage)}
          </Descriptions.Item>
          <Descriptions.Item label="Tier:">{data.marketingTier}</Descriptions.Item>
          <Descriptions.Item label="Campaign:">{data.marketingCampaignName}</Descriptions.Item>
          <Descriptions.Item label="Toll Free Number:">
            {getPhoneNumberDisplayValue(data.tollFreeNumber)}
          </Descriptions.Item>
        </Descriptions>
      </Section>
      <Divider />
      <VendorCodeInternetModalForm
        initialData={data}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </div>
  );
};

export default VendorCodeIntenerDetailPage;
