import type { TVendor } from '../models/vendor/VendorModel';
import WebAPIClient from './api/WebAPIClient';

const PATH = 'vendors';

export const getVendor = async (vendorId: string): Promise<TVendor | undefined> => {
  const response = await WebAPIClient.get(`${PATH}/${vendorId}`);

  return response?.data;
};

export const getVendors = async (): Promise<Array<TVendor> | undefined> => {
  const response = await WebAPIClient.get(PATH);

  return response?.data ?? [];
};

export const createVendor = async (vendor: TVendor): Promise<TVendor | undefined> => {
  const response = await WebAPIClient.post(PATH, vendor);

  return response?.data;
};

export const updateVendor = async (vendor: TVendor): Promise<TVendor | undefined> => {
  const response = await WebAPIClient.put(PATH, vendor);

  return response?.data;
};
