import { CmsButtonTypeEnum } from '../../../enums/cms/CmsButtonTypeEnum';
import CmsPageArchiveButton from './CmsPageArchiveButton';
import CmsPageBulkPublishButton from './CmsPageBulkPublishButton';
import CmsPageCloneButton from './CmsPageCloneButton';
import CmsPageEditButton from './CmsPageEditButton';
import type { CmsPageMetadataModel } from './../../../models/cms/CmsPageMetadataModel';
import { CmsStatus } from './../../../enums/cms/CmsStatusEnum';
import React from 'react';
import { Space } from 'antd';
import { head } from 'lodash';

type Props = {
  data: Array<CmsPageMetadataModel>;
  buttonTypes?: Array<CmsButtonTypeEnum>;
};

const CmsPageActionButtons = ({
  data,
  buttonTypes = [CmsButtonTypeEnum.CLONE, CmsButtonTypeEnum.ARCHIVE],
}: Props) => {
  const hasDraft = data.every((item) => item.status !== CmsStatus.Published);
  const pageIds = data?.map(({ id }) => id);

  const hasNoData = !(data?.length > 0);
  const getButtonByType = (type: CmsButtonTypeEnum, key: number) => {
    switch (type) {
      case CmsButtonTypeEnum.ARCHIVE:
        return <CmsPageArchiveButton data={data} key={key} isDisabled={hasNoData} />;
      case CmsButtonTypeEnum.CLONE:
        return <CmsPageCloneButton pageIds={pageIds} key={key} isDisabled={hasNoData} />;
      case CmsButtonTypeEnum.EDIT:
        return (
          <CmsPageEditButton pageModel={head(data)} isDisabled={hasNoData && data.length === 1} />
        );
      case CmsButtonTypeEnum.PUBLISH:
        return (
          <CmsPageBulkPublishButton
            isDisabled={hasNoData && !hasDraft}
            data={data.map((pageModel) => pageModel.id)}
            key={key}
          />
        );
      default:
        return null;
    }
  };
  return <Space>{buttonTypes.map((type, index) => getButtonByType(type, index))}</Space>;
};

export default CmsPageActionButtons;
