import { Row, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { getDateTimeDisplayValue, getModifiedBy, pluralize } from '../../../utils/displayUtils';
import ROUTES from '../../../constants/routes';

import CmsPageActionButtons from '../buttons/CmsPageActionButtons';
import type { CmsPageMetadataModel } from './../../../models/cms/CmsPageMetadataModel';
import type { CmsStatus } from '../../../enums/cms/CmsStatusEnum';
import CmsStatusText from '../displays/CmsStatusText';
import { Link } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { CmsLocaleDisplayNames } from '../../../enums/cms/CmsLocaleEnum';
import type { CmsLocaleEnum } from '../../../enums/cms/CmsLocaleEnum';
import TableWithHeader from '../../hocs/tables/TableWithHeader';
import TableWithSelectableRows from '../../hocs/tables/TableWithSelectableRows';

type CmsPagesColumnKey =
  | 'title'
  | 'slug'
  | 'status'
  | 'pageGroup'
  | 'lastModifiedDate'
  | 'lastModifiedBy'
  | 'locale';

type Props = {
  data?: Array<CmsPageMetadataModel>;
  extraHeader?: React.ReactNode;
  columnConfig?: Array<CmsPagesColumnKey>;
  showFooter?: boolean;
};

const SelectableTableWithHeader = TableWithHeader(TableWithSelectableRows(Table));

const getColumns = (
  data: Array<CmsPageMetadataModel>,
  columnNames?: Array<CmsPagesColumnKey>,
): any => {
  const columnConfig = {
    title: {
      title: 'Title',
      dataIndex: 'title',
      render: (value: string, row: CmsPageMetadataModel) => (
        <Link to={`${ROUTES.PAGE_GROUPS}/${row.group.id}/page/${row.id}`}>{value}</Link>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
      filterSearch: true,
      filters: uniqBy(data, 'title').map((record) => ({
        text: record.title,
        value: record.title,
      })),
      onFilter: (searchValue: string, record: CmsPageMetadataModel): boolean =>
        searchValue === record.title,
    },
    slug: {
      title: 'Slug',
      dataIndex: 'slug',
      sorter: (a, b) => a.slug.localeCompare(b.slug),
    },
    status: {
      title: 'Status',
      dataIndex: 'status',
      render: (value: CmsStatus) => <CmsStatusText status={value} />,
      sorter: (a, b) => (a.status > b.status ? 1 : -1),
    },
    pageGroup: {
      title: 'Page Group',
      dataIndex: ['group', 'slug'],
      render: (value: number, row: CmsPageMetadataModel) => (
        <Link to={`${ROUTES.PAGE_GROUPS}/${row.group.id}`}>{value}</Link>
      ),
      sorter: (a, b) => a.group.slug.localeCompare(b.group.slug),
      filterSearch: true,
      filters: uniqBy(data, 'group.slug').map((record) => ({
        text: record.group.slug,
        value: record.group.slug,
      })),
      onFilter: (searchValue: string, record: CmsPageMetadataModel): boolean =>
        searchValue === record.group.slug,
    },
    lastModifiedDate: {
      title: 'Last Modified Date',
      dataIndex: 'updatedDate',
      render: (value: string): string => getDateTimeDisplayValue(value),
      sorter: (a: CmsPageMetadataModel, b: CmsPageMetadataModel): any =>
        new Date(a.updatedDate).getTime() - new Date(b.updatedDate).getTime(),
      defaultSortOrder: 'descend',
    },
    lastModifiedBy: {
      title: 'Last Modified',
      dataIndex: ['updatedBy', 'email'],
      sorter: (a, b) => a.updatedBy.email.localeCompare(b.updatedBy.email),
      filterSearch: true,
      filters: uniqBy(data, 'updatedBy.email').map((record) => ({
        text: getModifiedBy(record.updatedBy.email),
        value: record.updatedBy.email,
      })),
      onFilter: (searchValue: string, record: CmsPageMetadataModel): boolean =>
        searchValue === record.updatedBy.email,
      render: (value: string) => getModifiedBy(value),
    },
    locale: {
      title: 'Locale',
      dataIndex: 'localeId',
      sorter: (a, b) =>
        CmsLocaleDisplayNames[a.localeId].localeCompare(CmsLocaleDisplayNames[b.localeId]),
      filterSearch: true,
      filters: uniqBy(data, 'localeId').map((record) => ({
        text: CmsLocaleDisplayNames[record.localeId],
        value: record.localeId,
      })),
      onFilter: (cmsLocaleEnum: CmsLocaleEnum, record: CmsPageMetadataModel): boolean =>
        record.localeId === cmsLocaleEnum,
      render: (value) => CmsLocaleDisplayNames[value],
    },
  };
  return columnNames ? columnNames.map((name) => columnConfig[name]) : [];
};

const getHeader = (selectedRows: Array<CmsPageMetadataModel>) => (
  <Space className="paddingVert8" size="middle">
    <CmsPageActionButtons data={selectedRows} />
    {selectedRows.length > 0 &&
      `${pluralize(selectedRows.length, 'item', { includeCount: true })} selected`}
  </Space>
);

export default function CmsPagesTable({
  data,
  extraHeader,
  columnConfig = [
    'title',
    'slug',
    'status',
    'pageGroup',
    'lastModifiedDate',
    'lastModifiedBy',
    'locale',
  ],
  showFooter = true,
}: Props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const onSelect = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  }, [data]);

  const header = showFooter && getHeader(selectedRows);
  const tableHeader = extraHeader ? (
    <Row justify="space-between">
      {header}
      <Space className="paddingVert8">{extraHeader}</Space>
    </Row>
  ) : (
    header
  );

  return (
    <SelectableTableWithHeader
      header={tableHeader}
      bordered
      columns={getColumns(data ?? [], columnConfig)}
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
        responsive: true,
        total: data?.length ?? 0,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      }}
      rowSelection={{ selectedRowKeys, onChange: onSelect }}
      size="small"
      rowKey="id"
    />
  );
}
