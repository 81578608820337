import React from 'react';
import { Form, Input } from 'antd';
import type { Rule } from 'antd/lib/form';
import { isNil } from 'lodash';

import type { CmsCollectionEntryVersionDataModel } from '../../../../../models/cms/collections/entryVersionData/CmsCollectionEntryVersionDataModel';
import { CmsCollectionEntryVersionDataTypeEnum } from '../../../../../enums/cms/CmsCollectionEntryVersionDataTypeEnum';
import type { CmsCollectionSchemaDataTextModel } from '../../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataTextModel';
import InputDisabledWithInsufficientPermission from '../../../../hocs/inputs/InputDisabledWithInsufficientPermission';

type Props = {
  initialData?: CmsCollectionEntryVersionDataModel;
  schemaData: CmsCollectionSchemaDataTextModel;
  formLabel: React.ReactNode;
  index: number;
};

const EnhancedInput = InputDisabledWithInsufficientPermission(Input);
const EnhancedTextArea = InputDisabledWithInsufficientPermission(Input.TextArea);

export const CmsCollectionTextItems = ({ initialData, schemaData, formLabel, index }: Props) => {
  const initialValues =
    initialData?.entryVersionDataType === CmsCollectionEntryVersionDataTypeEnum.String
      ? initialData
      : null;

  const getRules = (): Rule[] => {
    const textRules: Rule[] = [
      { whitespace: true, message: `${schemaData.fieldName} cannot be just whitespace` },
    ];

    if (schemaData.isRequired) {
      textRules.push({ required: true, message: `${schemaData.fieldName} is required` });
    }

    if (!isNil(schemaData.maxLength)) {
      textRules.push({
        type: 'string',
        max: schemaData.maxLength,
        message: `${schemaData.fieldName} cannot be greater than ${schemaData.maxLength} chars`,
      });
    }

    if (!isNil(schemaData.minLength)) {
      textRules.push({
        type: 'string',
        min: schemaData.minLength,
        message: `${schemaData.fieldName} cannot be less than ${schemaData.minLength}chars`,
      });
    }
    return textRules;
  };

  return (
    <>
      <Form.Item
        label={formLabel}
        name={['entryVersions', 0, 'entryVersionData', index, 'value']}
        initialValue={initialValues?.value}
        rules={getRules()}
      >
        {schemaData.isLongText ? (
          <EnhancedTextArea
            maxLength={schemaData.maxLength}
            minLength={schemaData.minLength}
            showCount={!isNil(schemaData.maxLength)}
          />
        ) : (
          <EnhancedInput
            maxLength={schemaData.maxLength}
            minLength={schemaData.minLength}
            showCount={!isNil(schemaData.maxLength)}
          />
        )}
      </Form.Item>
      <Form.Item
        hidden
        initialValue={CmsCollectionEntryVersionDataTypeEnum.String}
        name={['entryVersions', 0, 'entryVersionData', index, 'entryVersionDataType']}
      >
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};
