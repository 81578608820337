import React, { useState } from 'react';
import { Button } from 'antd';
import CmsCollectionSchemaDataModalForm from '../../forms/collections/CmsCollectionSchemaDataModalForm';
import type { CmsCollectionSchemaDataModel } from '../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataModel';

type Props = {
  schemaData: CmsCollectionSchemaDataModel;
  isDisabled?: boolean;
};

const CmsCollectionSchemaDataEditButton = ({ schemaData, isDisabled }: Props) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <Button type="primary" disabled={isDisabled} onClick={() => setIsModalShown(true)}>
        Edit
      </Button>
      <CmsCollectionSchemaDataModalForm
        initialData={schemaData}
        isNew={false}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </>
  );
};

export default CmsCollectionSchemaDataEditButton;
