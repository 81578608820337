import React from 'react';
import { Select } from 'antd';
import useVendors from './../../../hooks/services/vendor/useVendors';

type Props = {
  value?: number;
  onChange?: (e: any) => void;
  disabled?: boolean;
};

const VendorInput = ({ value, onChange, disabled }: Props) => {
  const { data } = useVendors();

  return (
    <Select
      value={value}
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a vendor"
      optionFilterProp="label"
      showSearch
      options={data
        ?.filter((vendor) => vendor.isActive || vendor.id === value)
        ?.map((vendor) => ({ value: vendor?.id, label: vendor?.name, disabled: !vendor?.isActive }))
        .sort((a, b) => a.label.localeCompare(b.label))}
    />
  );
};

export default VendorInput;
