import type { NotificationPlacementEnum } from '../../enums/shared/NotificationPlacementEnum';
import type { NotificationTypeEnum } from '../../enums/shared/NotificationTypeEnum';
import type { ReactNode } from 'react';
import { notification } from 'antd';
import { useCallback } from 'react';

export type NotificationContent = {
  icon?: ReactNode;
  message: ReactNode;
  description: ReactNode;
  placement: NotificationPlacementEnum;
};

type NotificationConfig = {
  type: NotificationTypeEnum;
  content: NotificationContent;
};

type NotificationReturnType = {
  openNotification: (config: NotificationConfig) => void;
};

export default function useNotification(): NotificationReturnType {
  const openNotification = useCallback((config) => {
    notification[config.type](config.content);
  }, []);
  return {
    openNotification,
  };
}
