import '../../css/shared/Section.css';

import { Divider, Spin } from 'antd';

import React from 'react';
import RequestErrorAlert from './RequestErrorAlert';

type Props = {
  children: React.ReactNode;
  errorMessage?: string;
  headerLabel?: string;
  isError?: boolean;
  isLoading?: boolean;
};

const Section = ({
  children,
  errorMessage,
  headerLabel,
  isError = false,
  isLoading = false,
}: Props) => {
  return (
    <div className="sectionRoot">
      {headerLabel && <Divider className="sectionDivider">{headerLabel}</Divider>}
      <div className="sectionMain">
        {isLoading && (
          <div className="sectionLoading">
            <Spin />
          </div>
        )}
        <div className="sectionContent">
          <RequestErrorAlert isError={isError} description={errorMessage} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Section;
