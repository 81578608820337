import { useMutation, useQueryClient } from 'react-query';

import type { CmsPageContentVersionEnvironmentModel } from '../../../models/cms/CmsPageContentVersionEnvironmentModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { updateCmsPageContentEnvironment } from '../../../services/CmsPageContentEnvironment';

type MutationCallbacks = {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
};

type TUpdatePageContentEnvironment = {
  update: (
    pageContentVersionEnvironment: CmsPageContentVersionEnvironmentModel,
    callbacks?: MutationCallbacks,
  ) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

function useUpdatePageContentEnvironment(): TUpdatePageContentEnvironment {
  const queryClient = useQueryClient();
  const { isSuccess, isError, isLoading, mutate } = useMutation(updateCmsPageContentEnvironment, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGES ||
          query.queryKey[0] === QUERYKEYS.PAGE ||
          query.queryKey[0] === QUERYKEYS.PAGE_CONTENT ||
          query.queryKey[0] === QUERYKEYS.PAGE_CONTENT_VERSIONS,
      });
    },
  });

  return {
    update: mutate,
    isSuccess,
    isError,
    isLoading,
  };
}

export default useUpdatePageContentEnvironment;
