import React, { useState } from 'react';
import { Button } from 'antd';
import CmsPageGroupModalForm from './../forms/CmsPageGroupModalForm';
import ButtonHiddenWithInsufficientPermission from '../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const CmsPageGroupCreateButton = () => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <EnhancedButton
        type="primary"
        onClick={() => {
          setIsModalShown(true);
        }}
      >
        Add New Page Group
      </EnhancedButton>
      <CmsPageGroupModalForm isShown={isModalShown} setIsShown={setIsModalShown} />
    </>
  );
};

export default CmsPageGroupCreateButton;
