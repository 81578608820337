import { Form, Input } from 'antd';

import type { CmsCollectionEntryVersionDataModel } from '../../../../../models/cms/collections/entryVersionData/CmsCollectionEntryVersionDataModel';
import { CmsCollectionEntryVersionDataTypeEnum } from '../../../../../enums/cms/CmsCollectionEntryVersionDataTypeEnum';
import { CmsCollectionRefEntrySelect } from '../../../inputs/CmsCollectionRefEntrySelect';
import type { CmsCollectionSchemaDataRefModel } from '../../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataRefModel';
import { CmsCollectionSchemaDataRefTypeEnum } from '../../../../../enums/cms/CmsCollectionSchemaDataRefTypeEnum';
import React from 'react';
import type { Rule } from 'antd/lib/form';

type Props = {
  initialData?: CmsCollectionEntryVersionDataModel;
  schemaData: CmsCollectionSchemaDataRefModel;
  formLabel: React.ReactNode;
  index: number;
};

export const CmsCollectionRefItems = ({ initialData, schemaData, formLabel, index }: Props) => {
  const initialValues =
    initialData?.entryVersionDataType === CmsCollectionEntryVersionDataTypeEnum.Reference
      ? initialData
      : null;

  const formEntryItemName = [
    'entryVersions',
    0,
    'entryVersionData',
    index,
    'entryVersionDataRefEntries',
  ];

  const getRules = (): Rule[] => {
    const refRules: Rule[] = [];

    const isOneToOne = schemaData.refType === CmsCollectionSchemaDataRefTypeEnum.OneToOne;
    if (schemaData.isRequired) {
      refRules.push({ type: 'array', min: 1, message: `${schemaData.fieldName} is required` });
    }

    if (isOneToOne) {
      refRules.push({
        type: 'array',
        max: 1,
        message: `There can only be at most one value since the relationship is one to one`,
      });
    }
    return refRules;
  };

  return (
    <>
      <Form.Item
        initialValue={
          initialValues?.entryVersionDataRefEntries?.map((item) => {
            return { entryId: item.entryId };
          }) ?? []
        }
        name={['entryVersions', 0, 'entryVersionData', index, 'entryVersionDataRefEntries']}
        label={formLabel}
        rules={getRules()}
      >
        <CmsCollectionRefEntrySelect schemaData={schemaData} formItemName={formEntryItemName} />
      </Form.Item>
      <Form.Item
        hidden
        initialValue={CmsCollectionEntryVersionDataTypeEnum.Reference}
        name={['entryVersions', 0, 'entryVersionData', index, 'entryVersionDataType']}
      >
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};
