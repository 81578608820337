import { useMutation, useQueryClient } from 'react-query';

import type { TCreateModel } from '../../../utils/hookUtils';
import type { TVendorCodeDirectMail } from './../../../models/vendor/VendorCodeModels';
import QUERYKEYS from '../../../constants/queryKeys';
import { createVendorCodeDirectMail } from './../../../services/VendorCodesService';

const useCreateVendorCodeDirectMail = (): TCreateModel<TVendorCodeDirectMail> => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(createVendorCodeDirectMail, {
    onSuccess: (data) =>
      queryClient.setQueryData(QUERYKEYS.VENDOR_CODES, (vendorCodesDirectMail: any) => [
        ...vendorCodesDirectMail,
        data,
      ]),
  });

  return {
    create: mutate,
    isLoading,
  };
};

export default useCreateVendorCodeDirectMail;
