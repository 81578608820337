import './../../css/rules/RuleForm.css';

import { Button, Divider, Form, type FormInstance, Input, Row, Select, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import type { $RuleBooleanExpressionModel } from '../../models/rules/RuleExpressionModel';
import type { $RuleModel } from '../../models/rules/RuleModel';
import { INPUT_SCHEMA_NAMES } from '../../constants/rules/ruleInputSchemas';
import React from 'react';
import RuleExpressionForm from './RuleExpressionForm';
import Section from '../shared/Section';
import useInputSchemas from './../../hooks/services/rules/useInputSchemas';
import { v4 as uuidv4 } from 'uuid';

const DEFAULT_RULE: Partial<$RuleModel> = {
  name: '',
};

const FORM_LAYOUT = {};

const REQUIRED_RULE = { message: '"${label}" is required', required: true };

type $RuleExpression = {
  booleanExpression?: Partial<$RuleBooleanExpressionModel> | null;
  id: string;
};

function getExpression(booleanExpression?: $RuleBooleanExpressionModel): $RuleExpression {
  return {
    booleanExpression,
    id: uuidv4(),
  };
}

type Props = {
  form: FormInstance<$RuleModel>;
  initialData?: Partial<$RuleModel | null>;
};

function RuleForm({ form, initialData = DEFAULT_RULE }: Props): React.ReactElement {
  const [expressions, setExpressions] = React.useState<$RuleExpression[]>([]);
  const schemaType = Form.useWatch('inputSchema', form);
  const { data: inputSchemas, isError, isLoading } = useInputSchemas();

  const inputSchema = React.useMemo(
    () => inputSchemas?.find((schema) => schema.type === schemaType),
    [inputSchemas, schemaType],
  );

  const onAddBooleanExpression = React.useCallback(() => {
    setExpressions((previousExpressions) => [...previousExpressions, getExpression()]);
  }, []);

  const onDeleteBooleanExpression = React.useCallback((id: string) => {
    setExpressions((previousExpressions) =>
      previousExpressions.filter((expression) => expression.id !== id),
    );
  }, []);

  const setExpression = React.useCallback(
    (id: string, booleanExpression?: Partial<$RuleBooleanExpressionModel>) =>
      setExpressions((previousExpressions) =>
        previousExpressions.map((expression) =>
          expression.id === id ? { id, booleanExpression } : expression,
        ),
      ),
    [],
  );

  React.useEffect(() => {
    form.setFieldValue(
      'booleanExpressions',
      expressions.map((expression) => expression.booleanExpression),
    );
  }, [expressions, form]);

  React.useEffect(() => {
    setExpressions(initialData?.booleanExpressions?.map(getExpression) ?? [getExpression()]);
  }, [initialData]);

  return (
    <Section
      errorMessage="An error occurred while attempting to load the data for this form."
      isError={isError}
      isLoading={isLoading}
    >
      <Form
        {...FORM_LAYOUT}
        className="ruleFormRoot"
        form={form}
        initialValues={initialData ?? DEFAULT_RULE}
      >
        <Form.Item label="Name" name="name" rules={[REQUIRED_RULE]}>
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item label="Input Schema" name="inputSchema" rules={[REQUIRED_RULE]}>
          <Select>
            {inputSchemas?.map((schema) => (
              <Select.Option key={schema.type} value={schema.type}>
                {INPUT_SCHEMA_NAMES[schema.type]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item hidden={true} name="booleanExpressions">
          <Input />
        </Form.Item>
      </Form>
      <Typography.Title level={4}>Expressions</Typography.Title>
      {expressions.map(({ booleanExpression, id }, index) => (
        <React.Fragment key={id}>
          {index > 0 && <Divider orientation="left">And</Divider>}
          <Row align="middle" className="ruleFormExpression" wrap={false}>
            <RuleExpressionForm
              booleanExpression={booleanExpression}
              inputFields={inputSchema?.fields}
              setBooleanExpression={(booleanExpression) => setExpression(id, booleanExpression)}
            />
            {expressions.length > 1 && (
              <MinusCircleOutlined
                onClick={() => onDeleteBooleanExpression(id)}
                style={{ fontSize: 24 }}
              />
            )}
          </Row>
        </React.Fragment>
      ))}
      <Button block type="dashed" onClick={onAddBooleanExpression} icon={<PlusOutlined />}>
        Add expression
      </Button>
    </Section>
  );
}

export default RuleForm;
