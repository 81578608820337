import React from 'react';
import { Button, Form } from 'antd';
import type { PromoCodeModel } from '../../../models/promoCodes/PromoCodeModel';
import useCreatePromoCode from '../../../hooks/services/promoCodes/useCreatePromoCode';
import useUpdatePromoCode from '../../../hooks/services/promoCodes/useUpdatePromoCode';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import ButtonHiddenWithInsufficientPermission from '../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  promoCode: PromoCodeModel;
  isLoading?: boolean;
  isDisabled?: boolean;
  onCreateSuccess?: () => void;
  onUpdateSuccess?: () => void;
  onError?: (error: any) => void;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const PromoCodeSaveButton = ({
  promoCode,
  isLoading,
  isDisabled,
  onCreateSuccess,
  onUpdateSuccess,
  onError,
}: Props) => {
  const createMutation = useCreatePromoCode();
  const updateMutation = useUpdatePromoCode();
  const form = Form.useFormInstance();
  const isNewPromoCode = promoCode === undefined || promoCode?.id <= 0;

  const onSaveClick = async (): Promise<void> => {
    const data = await form.validateFields();
    const hasDiscount = data.discount !== undefined;
    const newPromoCode: PromoCodeModel = {
      id: promoCode?.id || 0,
      name: data.name,
      code: (promoCode?.code || data.code).toUpperCase(),
      isEnabled: data.isEnabled,
      status: 0,
      discounts: [],
    };

    if (hasDiscount)
      newPromoCode.discounts.push({
        id: 0,
        promoCodeId: 0,
        discountType: data.discountType,
        discount: data.discount,
        startDate: data.startDate,
        endDate: data.endDate,
      });

    if (isNewPromoCode) {
      await createMutation.create(newPromoCode, {
        onSuccess: () => onCreateSuccess?.(),
        onError: (error) => onError?.(error),
      });
    } else {
      await updateMutation.update(newPromoCode, {
        onSuccess: () => onUpdateSuccess?.(),
        onError: (error) => onError?.(error),
      });
    }
  };

  useStatusNotifications({
    isSuccess: !!createMutation.isSuccess || !!updateMutation.isSuccess,
    isError: !!createMutation.isError || !!updateMutation.isError,
    successDescription: `Promo code was successfully ${isNewPromoCode ? 'created' : 'updated'}.`,
  });

  isLoading ??= false;
  isDisabled ??= false;

  return (
    <EnhancedButton
      type="primary"
      onClick={onSaveClick}
      loading={isLoading || createMutation.isLoading || updateMutation.isLoading}
      disabled={isDisabled}
    >
      Save
    </EnhancedButton>
  );
};

export default PromoCodeSaveButton;
