import type {
  CmsPageEditContextAction,
  CmsPageEditContextState,
} from './CmsPageEditContextActions';

export function CmsPageEditContextReducer(
  state: CmsPageEditContextState,
  action: CmsPageEditContextAction,
) {
  return { ...state, [action.type]: action.value };
}
