import { useMsal, useAccount } from '@azure/msal-react';

type IdTokenClaims = {
  email: string | undefined;
  family_name: string | undefined;
  given_name: string | undefined;
  name: string | undefined;
  preferred_username: string | undefined;
  roles: Array<string>;
};

export type AccountInfo = {
  idTokenClaims: IdTokenClaims;
  name: string | undefined;
  username: string | undefined;
  homeAccountId: string | undefined;
};

const useCurrentUser = (): [AccountInfo, boolean] => {
  const { accounts, inProgress } = useMsal();
  const currentUser = useAccount(accounts[0] || {});
  const isInProgress = inProgress !== 'none';

  const claims: IdTokenClaims = {
    email: currentUser?.idTokenClaims?.['email'],
    family_name: currentUser?.idTokenClaims?.['family_name'],
    given_name: currentUser?.idTokenClaims?.['given_name'],
    name: currentUser?.idTokenClaims?.['name'],
    preferred_username: currentUser?.idTokenClaims?.['preferred_username'],
    roles: currentUser?.idTokenClaims?.['roles'],
  };

  const user: AccountInfo = {
    name: currentUser?.name,
    username: currentUser?.username,
    homeAccountId: currentUser?.homeAccountId,
    idTokenClaims: claims,
  };

  return [user, isInProgress];
};

export default useCurrentUser;
