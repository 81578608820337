import '../../../css/core/PasswordInput.css';
import './../../../css/vendor/tables/VendorCodesInternetTable.css';

import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Input, Row, Table } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { uniqBy } from 'lodash';
import { getBooleanDisplayValue, getNumberDisplayValue } from './../../../utils/displayUtils';
import ROUTES from '../../../constants/routes';
import COLORS from '../../../constants/colors';

import type { TVendorCodesInternet } from './../../../models/vendor/VendorCodeModels';
import VendorCodeInternetModalForm from './../../vendor/forms/VendorCodeInternetModalForm';
import TableWithActionsColumn from '../../hocs/tables/TableWithActionsColumn';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';
import TextSearchColumnFilter from '../../shared/columnFilters/TextSearchColumnFilter';

type Props = {
  data: Array<TVendorCodesInternet>;
  isLoading?: boolean;
  vendorId?: number;
  allowCreate: boolean;
};

const ActionableTable = TableWithActionsColumn(Table);
const VendorCodeCreateButton = ButtonDisabledWithInsufficientPermission(Button);

const VendorCodesInternetTable = ({ data, isLoading = false, vendorId, allowCreate }: Props) => {
  const navigate = useNavigate();
  const shouldShowVendorName = !vendorId;

  const [selectedVendorCodeInternet, setSelectedVendorCodeInternet] = useState<any>(null);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [filteredDataLength, setFilteredDataLength] = useState<number>(0);

  const onUpdate = useCallback((record: TVendorCodesInternet) => {
    setSelectedVendorCodeInternet(record);
    setIsModalShown(true);
  }, []);
  const columns = useMemo(
    () => getColumns(data, shouldShowVendorName, onUpdate),
    [data, onUpdate, shouldShowVendorName],
  );

  return (
    <div className="vendorCodesInternetTableRoot">
      <Row key="actionBar" justify="end">
        {allowCreate && (
          <VendorCodeCreateButton
            type="primary"
            onClick={() => {
              setSelectedVendorCodeInternet(null);
              setIsModalShown(true);
            }}
          >
            Create Vendor Code - Internet
          </VendorCodeCreateButton>
        )}
      </Row>
      <ActionableTable
        className="vendorMarginTopOffset20"
        bordered
        onChange={(pagination, filters, sorter, extra) => {
          setFilteredDataLength(extra.currentDataSource.length);
        }}
        columns={columns}
        dataSource={data}
        loading={isLoading}
        onRow={(record) => ({
          onDoubleClick: () => navigate(`${ROUTES.VENDOR_CODES_INTERNET}/${record.id}`),
        })}
        size="small"
        pagination={{
          hideOnSinglePage: false,
          responsive: true,
          total: filteredDataLength,
          showSizeChanger: true,
          defaultPageSize: 100,
          position: ['topRight', 'bottomRight'],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        rowKey="id"
      />
      <VendorCodeInternetModalForm
        initialData={selectedVendorCodeInternet}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        vendorId={vendorId}
      />
    </div>
  );
};

function getColumns(
  data: Array<TVendorCodesInternet>,
  shouldShowVendorName: boolean,
  onUpdate: (record: TVendorCodesInternet) => void,
): Array<ColumnType<TVendorCodesInternet>> {
  const columns: Array<ColumnType<TVendorCodesInternet>> = [
    {
      title: 'Code',
      dataIndex: 'legacyVendorId',
      render: (value, row) => <Link to={`${ROUTES.VENDOR_CODES_INTERNET}/${row.id}`}>{value}</Link>,
      sorter: (a, b) => a.legacyVendorId.localeCompare(b.legacyVendorId),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.legacyVendorId
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendorName',
    },
    {
      title: 'API Secret',
      dataIndex: 'secret',
      render: (value) => <Input.Password className="style-secret-disp" value={value} readOnly />,
    },
    {
      title: 'Duplicate Days',
      dataIndex: 'duplicateDays',
      sorter: (a, b) => a.duplicateDays - b.duplicateDays,
      filterSearch: true,
      filters: uniqBy(data, 'duplicateDays')
        .sort((a, b) => a.duplicateDays - b.duplicateDays)
        .map((record) => ({
          text: record.duplicateDays,
          value: record.duplicateDays,
        })),
      onFilter: (searchValue: number, record: TVendorCodesInternet): boolean =>
        searchValue === record.duplicateDays,
    },
    {
      title: 'Max Years Old',
      dataIndex: 'lastNYears',
      sorter: (a, b) => a.lastNYears - b.lastNYears,
      filterSearch: true,
      filters: uniqBy(data, 'lastNYears')
        .sort((a, b) => a.lastNYears - b.lastNYears)
        .map((record) => ({
          text: record.lastNYears,
          value: record.lastNYears,
        })),
      onFilter: (searchValue: number, record: TVendorCodesInternet): boolean =>
        searchValue === record.lastNYears,
    },
    {
      title: 'Max Odometer',
      dataIndex: 'maxMileage',
      render: getNumberDisplayValue,
      sorter: (a, b) => a.maxMileage - b.maxMileage,
      filterSearch: true,
      filters: uniqBy(data, 'maxMileage')
        .sort((a, b) => a.maxMileage - b.maxMileage)
        .map((record) => ({
          text: getNumberDisplayValue(record.maxMileage),
          value: record.maxMileage,
        })),
      onFilter: (searchValue: number, record: TVendorCodesInternet): boolean =>
        searchValue === record.maxMileage,
    },
    {
      title: 'Tier',
      dataIndex: 'marketingTier',
      sorter: (a, b) => a.marketingTier - b.marketingTier,
      filterSearch: true,
      filters: uniqBy(data, 'marketingTier').map((record) => ({
        text: record.marketingTier,
        value: record.marketingTier,
      })),
      onFilter: (searchValue: number, record: TVendorCodesInternet): boolean =>
        searchValue === record.marketingTier,
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      render: getBooleanDisplayValue,
      sorter: (a, b) => Number(a.isActive) - Number(b.isActive),
      filterSearch: true,
      filters: [true, false].map((vciType) => ({
        text: getBooleanDisplayValue(vciType),
        value: vciType,
      })),
      onFilter: (isActive: boolean, record: TVendorCodesInternet): boolean =>
        record.isActive === isActive,
      defaultFilteredValue: ['true'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => onUpdate(record)}>
            Update
          </Button>
        </div>
      ),
    },
  ];

  return shouldShowVendorName ? columns : columns.filter((column) => column.title !== 'Vendor');
}

export default VendorCodesInternetTable;
