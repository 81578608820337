import { Button, Popconfirm } from 'antd';

import type { CmsCollectionSchemaDataModel } from '../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataModel';
import React from 'react';
import useDeleteCmsCollectionSchemaData from '../../../../hooks/services/cms/collections/useDeleteCmsCollectionSchemaData';
import useStatusNotifications from '../../../../hooks/shared/useStatusNotifications';

type Props = {
  schemaData: CmsCollectionSchemaDataModel;
  isDisabled?: boolean;
};

const CmsCollectionSchemaDataArchivedButton = ({ schemaData, isDisabled }: Props) => {
  const { deleteMutation, isError, isLoading, isSuccess } = useDeleteCmsCollectionSchemaData();

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The schema data was successfully archived.`,
  });

  // TODO: Change the warning to be more dire if we have collection entries.
  return (
    <Popconfirm
      placement="topRight"
      title={`Are you sure you want to archive this schema data?`}
      onConfirm={() => deleteMutation(schemaData)}
    >
      <Button type="primary" disabled={isDisabled} danger={true} loading={isLoading}>
        Archive
      </Button>
    </Popconfirm>
  );
};

export default CmsCollectionSchemaDataArchivedButton;
