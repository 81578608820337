import type { CmsPageContentVersionEnvironmentModel } from '../models/cms/CmsPageContentVersionEnvironmentModel';
import WebAPIClient from './api/WebAPIClient';

const PATH = `cms/page-content-environment`;

export const updateCmsPageContentEnvironment = async (
  cmsPageContentEnvironment: CmsPageContentVersionEnvironmentModel,
): Promise<CmsPageContentVersionEnvironmentModel> => {
  const response = await WebAPIClient.patch(
    `${PATH}/${cmsPageContentEnvironment.id}`,
    cmsPageContentEnvironment,
  );
  return response?.data;
};
