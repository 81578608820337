import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Row, Table } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';

import ROUTES from '../../../constants/routes';
import COLORS from '../../../constants/colors';
import type { TVendorCodeCommercial } from './../../../models/vendor/VendorCodeModels';
import VendorCodeCommercialModalForm from './../../vendor/forms/VendorCodeCommercialModalForm';
import { getBooleanDisplayValue } from './../../../utils/displayUtils';
import TableWithActionsColumn from '../../hocs/tables/TableWithActionsColumn';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';
import TextSearchColumnFilter from '../../shared/columnFilters/TextSearchColumnFilter';

import './../../../css/vendor/tables/VendorCodesInternetTable.css';

type Props = {
  data: Array<TVendorCodeCommercial>;
  isLoading?: boolean;
  vendorId?: number;
  allowCreate: boolean;
};

const ActionableTable = TableWithActionsColumn(Table);
const VendorCodeCreateButton = ButtonDisabledWithInsufficientPermission(Button);

const VendorCodesCommercialTable = ({ data, isLoading = false, vendorId, allowCreate }: Props) => {
  const navigate = useNavigate();
  const shouldShowVendorName = !vendorId;

  const [selectedVendorCode, setSelectedVendorCode] = useState<any>(null);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [filteredDataLength, setFilteredDataLength] = useState<number>(0);

  const onUpdate = useCallback((record: TVendorCodeCommercial) => {
    setSelectedVendorCode(record);
    setIsModalShown(true);
  }, []);
  const columns = useMemo(
    () => getColumns(data, shouldShowVendorName, onUpdate),
    [data, onUpdate, shouldShowVendorName],
  );

  return (
    <div className="vendorCodesCommercialTableRoot">
      <Row key="actionBar" justify="end">
        {allowCreate && (
          <VendorCodeCreateButton
            type="primary"
            onClick={() => {
              setSelectedVendorCode(null);
              setIsModalShown(true);
            }}
          >
            Create Vendor Code - TFN Only
          </VendorCodeCreateButton>
        )}
      </Row>
      <ActionableTable
        className="vendorMarginTopOffset20"
        bordered
        onChange={(pagination, filters, sorter, extra) => {
          setFilteredDataLength(extra.currentDataSource.length);
        }}
        columns={columns}
        dataSource={data}
        loading={isLoading}
        onRow={(record) => ({
          onDoubleClick: () => navigate(`${ROUTES.VENDOR_CODES_COMMERCIAL}/${record.id}`),
        })}
        size="small"
        pagination={{
          hideOnSinglePage: false,
          responsive: true,
          total: filteredDataLength,
          showSizeChanger: true,
          defaultPageSize: 100,
          position: ['topRight', 'bottomRight'],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        rowKey="id"
      />
      <VendorCodeCommercialModalForm
        initialData={selectedVendorCode}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        vendorId={vendorId}
      />
    </div>
  );
};

function getColumns(
  data: Array<TVendorCodeCommercial>,
  shouldShowVendorName: boolean,
  onUpdate: (record: TVendorCodeCommercial) => void,
): Array<ColumnType<TVendorCodeCommercial>> {
  const columns: Array<ColumnType<TVendorCodeCommercial>> = [
    {
      title: 'Code',
      dataIndex: 'legacyVendorId',
      render: (value, row) => (
        <Link to={`${ROUTES.VENDOR_CODES_COMMERCIAL}/${row.id}`}>{value}</Link>
      ),
      sorter: (a, b) => a.legacyVendorId.localeCompare(b.legacyVendorId),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.legacyVendorId
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.name.toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendorName',
      sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
    },
    {
      title: 'Creative',
      dataIndex: 'creative',
      sorter: (a, b) => a.creative?.localeCompare(b.creative),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.creative.toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
    {
      title: 'Media Type',
      dataIndex: 'mediaType',
      sorter: (a, b) => (a.mediaType || '').localeCompare(b.mediaType || ''),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.mediaType.toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
    {
      title: 'Station',
      dataIndex: 'station',
      sorter: (a, b) => (a.station || '').localeCompare(b.station || ''),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.station.toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      render: getBooleanDisplayValue,
      sorter: (a, b) => Number(a.isActive) - Number(b.isActive),
      filterSearch: true,
      filters: [true, false].map((vccType) => ({
        text: getBooleanDisplayValue(vccType),
        value: vccType,
      })),
      onFilter: (isActive: boolean, record: TVendorCodeCommercial): boolean =>
        record.isActive === isActive,
      defaultFilteredValue: ['true'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => onUpdate(record)}>
            Update
          </Button>
        </div>
      ),
    },
  ];

  return shouldShowVendorName ? columns : columns.filter((column) => column?.title !== 'Vendor');
}

export default VendorCodesCommercialTable;
