import { useMutation, useQueryClient } from 'react-query';

import type { CmsSavePageContentModel } from '../../../models/cms/CmsSavePageContentModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { savePageContent } from '../../../services/CmsPageService';

type MutationCallbacks = {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
};

type $SaveCmsPageContent = {
  save: (cmsPageContent: CmsSavePageContentModel, callbacks?: MutationCallbacks) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

function useSaveCmsPageContent(): $SaveCmsPageContent {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(savePageContent, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGES ||
          query.queryKey === QUERYKEYS.PAGES ||
          query.queryKey[0] === QUERYKEYS.PAGE ||
          query.queryKey[0] === QUERYKEYS.PAGE_CONTENT ||
          query.queryKey[0] === QUERYKEYS.PAGE_CONTENT_VERSIONS,
      }),
  });

  return {
    save: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useSaveCmsPageContent;
