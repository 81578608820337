import React from 'react';
import { Modal, Row, Spin, Typography } from 'antd';

import CmsPageContentDiffEditor from '../displays/CmsPageContentDiffEditor';
import type { CmsPageContentVersionMetadataModel } from '../../../models/cms/CmsPageContentVersionMetadataModel';
import useCmsPageContentVersion from '../../../hooks/services/cms/useCmsPageContentVersion';
import useCmsPageEditContext from '../../../hooks/contexts/useCmsPageEditContext';
import useSaveCmsPageContent from '../../../hooks/services/cms/useSaveCmsPageContent';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import ModalOkButtonDisabledWithInsufficientPermission from '../../hocs/modals/ModalOkButtonDisabledWithInsufficientPermission';

type Props = {
  contentVersion: CmsPageContentVersionMetadataModel;
  isShown: boolean;
  setIsShown: (value) => void;
};

const EnhancedModal = ModalOkButtonDisabledWithInsufficientPermission(Modal);

const CmsPageRevertModal = ({ contentVersion, isShown, setIsShown }: Props) => {
  const { state } = useCmsPageEditContext();
  const { pageModel, editorPageContent } = state;

  const pageId = pageModel?.id ?? 0;
  const { data } = useCmsPageContentVersion(pageId, contentVersion.version, isShown);

  const newContent = data?.content;
  const { isLoading, isError, isSuccess, save } = useSaveCmsPageContent();
  const onCancel = React.useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsShown(false);
  }, [isLoading, setIsShown]);
  const isContentEqual = newContent === editorPageContent;
  const onSubmit = React.useCallback(() => {
    !isContentEqual &&
      save(
        {
          pageId: pageId,
          content: newContent,
        },
        { onSuccess: () => setIsShown(false) },
      );
  }, [isContentEqual, save, pageId, newContent, setIsShown]);
  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: 'Successfully saved the reverted version as drafted',
  });

  return (
    <EnhancedModal
      open={isShown}
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Revert Changes"
      destroyOnClose={true}
      title={`Revert back to version ${contentVersion.version}?`}
      width={'100%'}
      footer={isContentEqual || !data ? null : undefined}
    >
      {isContentEqual && (
        <Row>
          <Typography.Text type="warning" italic>
            There are no content differences between the two versions.
          </Typography.Text>
        </Row>
      )}
      {data ? (
        <CmsPageContentDiffEditor newContent={editorPageContent} originalContent={data?.content} />
      ) : (
        <Row justify="center">
          <Spin size="large" />
        </Row>
      )}
    </EnhancedModal>
  );
};

export default CmsPageRevertModal;
