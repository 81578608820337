import type { CmsPageModel } from '../../../models/cms/CmsPageModel';

enum CmsPageEditContextActionTypeEnum {
  EDITOR_PAGE_CONTENT = 'editorPageContent',
  PAGE_MODEL = 'pageModel',
  PREVIEW_FRAME_REF = 'previewFrameRef',
}

export type CmsPageEditContextState = {
  pageModel?: CmsPageModel;
  editorPageContent: string;
  previewFrameRef?: React.RefObject<HTMLIFrameElement>;
};

export type CmsPageEditContextAction =
  | {
      type: CmsPageEditContextActionTypeEnum.EDITOR_PAGE_CONTENT;
      value: string;
    }
  | {
      type: CmsPageEditContextActionTypeEnum.PAGE_MODEL;
      value: CmsPageModel;
    }
  | {
      type: CmsPageEditContextActionTypeEnum.PREVIEW_FRAME_REF;
      value: React.RefObject<HTMLIFrameElement>;
    };

export function setEditorPageContent(value: string): CmsPageEditContextAction {
  return { type: CmsPageEditContextActionTypeEnum.EDITOR_PAGE_CONTENT, value };
}

export function setPageModel(value: CmsPageModel): CmsPageEditContextAction {
  return { type: CmsPageEditContextActionTypeEnum.PAGE_MODEL, value };
}

export function setPreviewFrameRef(
  value: React.RefObject<HTMLIFrameElement>,
): CmsPageEditContextAction {
  return { type: CmsPageEditContextActionTypeEnum.PREVIEW_FRAME_REF, value };
}
