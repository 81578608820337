import React from 'react';
import { Button, Popconfirm } from 'antd';

import type { CmsCollectionModel } from '../../../../models/cms/collections/CmsCollectionModel';
import useDeleteCollection from '../../../../hooks/services/cms/collections/useDeleteCmsCollection';
import useStatusNotifications from '../../../../hooks/shared/useStatusNotifications';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  collection?: CmsCollectionModel;
  isDisabled?: boolean;
  onDelete?: () => void;
  style?: React.CSSProperties;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const CmsCollectionArchivedButton = ({ collection, isDisabled, onDelete, style }: Props) => {
  const { deleteMutation, isError, isLoading, isSuccess } = useDeleteCollection();

  React.useEffect(() => {
    isSuccess && onDelete?.();
  }, [isSuccess, onDelete]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The collection was successfully archived.`,
  });

  // TODO: Change the warning to be more dire if we have collection entries.
  return (
    <Popconfirm
      placement="topRight"
      title={`Are you sure you want to archive this collection?`}
      onConfirm={() => collection && deleteMutation(collection.id)}
    >
      <EnhancedButton
        type="primary"
        disabled={isDisabled}
        danger={true}
        loading={isLoading}
        style={style}
      >
        Archive
      </EnhancedButton>
    </Popconfirm>
  );
};

export default CmsCollectionArchivedButton;
