import { Checkbox, Form, Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CmsLocaleEnum } from '../../../enums/cms/CmsLocaleEnum';
import CmsPageGroupSelectInput from './items/CmsPageGroupSelectInput';
import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import CmsTitleSlugInputs from './items/CmsTitleSlugInputs';
import LocaleSelector from '../../shared/LocaleSelector';
import { getErrorDisplayValues } from '../../../utils/displayUtils';
import routes from '../../../constants/routes';
import useCreateCmsPage from './../../../hooks/services/cms/useCreateCmsPage';
import { useNavigate } from 'react-router-dom';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import useUpdateCmsPage from './../../../hooks/services/cms/useUpdateCmsPage';

const FORM_LAYOUT = {
  labelCol: { span: 6 },
};

type FormValues = {
  title?: string;
  slug?: string;
  pageGroupId?: string;
  localeId?: CmsLocaleEnum;
};

const DEFAULT_DATA: FormValues = {
  title: '',
  slug: '',
  localeId: CmsLocaleEnum.EN_US,
};

type Props = {
  initialData?: CmsPageMetadataModel;
  initialFormValues?: FormValues;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
};

const CmsPageModalForm = ({ initialData, initialFormValues, isShown, setIsShown }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const initialValues = useMemo(() => {
    const pageGroupId = Number(initialFormValues?.pageGroupId);
    if (initialData) {
      return { ...initialData, pageGroupId: initialData.group.id };
    } else {
      return {
        ...DEFAULT_DATA,
        ...initialFormValues,
        pageGroupId: !isNaN(pageGroupId) ? pageGroupId : undefined,
      };
    }
  }, [initialData, initialFormValues]);

  const {
    create,
    isLoading: isCreating,
    isSuccess: isCreated,
    isError: isCreateError,
  } = useCreateCmsPage();
  const {
    update,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateCmsPage();

  const isLoading = isCreating || isUpdating;
  const isSuccess = isCreated || isUpdated;
  const isError = isCreateError || isUpdateError;

  const isNewPage = !initialData;
  const mutate = isNewPage ? create : update;
  const modalTitle = `${isNewPage ? 'Create' : 'Update'} Page`;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = React.useState(false);
  const onCancel = useCallback(() => {
    if (isLoading) {
      return;
    }

    form.resetFields();
    setIsShown(false);
  }, [form, isLoading, setIsShown]);

  const onSubmit = useCallback(async () => {
    const formValues = form.getFieldsValue();
    const page = isNewPage
      ? formValues
      : {
          ...initialData,
          title: formValues.title,
          slug: formValues.slug,
          localeId: formValues.localeId,
          group: { id: formValues.pageGroupId },
        };

    try {
      await form.validateFields();
      mutate(page, {
        onSuccess: (data) => {
          onCancel();
          if (isNewPage)
            navigate(`${routes.PAGE_GROUPS}/${formValues.pageGroupId}/page/${data.id}`);
        },
        onError: (err) => {
          const errors = getErrorDisplayValues(err);
          if (errors.length > 0) {
            const errorMessage = errors.join(' ');
            setErrorMessage(errorMessage);
          }
        },
      });
      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [form, initialData, isNewPage, mutate, navigate, onCancel]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The page was successfully ${isNewPage ? 'created' : 'updated'} `,
    errorDescription: errorMessage,
  });

  // Keep the formInstance initialValues in sync with the initialData props
  useEffect(() => {
    isShown && form.setFieldsValue(initialData);
    form.setFieldsValue({ pageGroupId: initialValues.pageGroupId });
  }, [initialValues, initialData, isShown, form]);

  return (
    <Modal
      okButtonProps={{ loading: isLoading, disabled: !isNewPage && !isChecked }}
      onOk={onSubmit}
      destroyOnClose={true}
      onCancel={onCancel}
      title={modalTitle}
      open={isShown}
    >
      <Form
        {...FORM_LAYOUT}
        className="cmsPageGroupModalFormRoot"
        form={form}
        initialValues={initialValues}
      >
        <CmsTitleSlugInputs />
        <CmsPageGroupSelectInput />
        <LocaleSelector />

        {!isNewPage && (
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 16,
            }}
          >
            <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
              I understand that changing the <b>Slug</b>, <b>Locale</b> or <b>Group</b> can impact
              production pages.
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CmsPageModalForm;
