import React, { useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import { pluralize } from '../../../utils/displayUtils';
import type { PromoCodeModel } from '../../../models/promoCodes/PromoCodeModel';
import useDeletePromoCodes from '../../../hooks/services/promoCodes/useDeletePromoCodes';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';

type Props = {
  data: Array<PromoCodeModel>;
  isDisabled?: boolean;
  onClick?: () => void;
};

const PromoCodeArchiveButton = ({ data, isDisabled, onClick }: Props) => {
  const { deleteMutation, isError, isSuccess, isLoading } = useDeletePromoCodes();
  const recordText = pluralize(data.length, 'this record', {
    plural: 'these records',
  });
  const successRecordText = pluralize(data.length, 'record was', {
    plural: 'records were',
  });

  useEffect(() => {
    isSuccess && onClick?.();
  }, [isSuccess, onClick]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The ${successRecordText} successfully deleted.`,
  });

  return (
    <Popconfirm
      placement="right"
      title={`Are you sure you want to delete ${recordText}?`}
      onConfirm={() => deleteMutation(data.map((item) => item.id))}
    >
      <Button type="primary" danger={true} disabled={isDisabled} loading={isLoading}>
        Archive
      </Button>
    </Popconfirm>
  );
};

export default PromoCodeArchiveButton;
