import { Input, InputNumber, Select } from 'antd';

import type { $RuleBooleanExpressionType } from '../../constants/rules/ruleBooleanExpressionTypes';
import type { $RuleInputSchemaFieldModel } from '../../models/rules/RuleInputSchemaModel';
import ListInput from './../core/ListInput';
import React from 'react';
import { RuleExpressionValueTypeEnum } from '../../enums/rules/RuleExpressionValueTypeEnum';
import { isArray } from 'lodash';

type Props = {
  expressionType?: $RuleBooleanExpressionType;
  inputField?: $RuleInputSchemaFieldModel;
  onChange: (value: any) => void;
  value?: any;
};

function RuleExpressionSecondOperandInput({
  expressionType,
  inputField,
  onChange,
  value,
}: Props): React.ReactElement {
  if (!expressionType || !inputField) {
    return <Input disabled value={value} />;
  }

  if (
    (expressionType.secondOperandType === RuleExpressionValueTypeEnum.ARRAYNUMBER ||
      expressionType.secondOperandType === RuleExpressionValueTypeEnum.ARRAYSTRING) &&
    (isArray(value) || value == null)
  ) {
    return (
      <ListInput
        onChange={onChange}
        type={
          expressionType.secondOperandType === RuleExpressionValueTypeEnum.ARRAYNUMBER
            ? 'number'
            : 'text'
        }
        value={value}
      />
    );
  }

  if (expressionType.secondOperandType === RuleExpressionValueTypeEnum.BOOLEAN) {
    return (
      <Select onChange={onChange} value={value}>
        <Select.Option value={false}>False</Select.Option>
        <Select.Option value={true}>True</Select.Option>
      </Select>
    );
  }

  if (inputField.oneOf) {
    return (
      <Select onChange={onChange} value={value}>
        {inputField.oneOf.map((value) => (
          <Select.Option key={value} value={value}>
            {value}
          </Select.Option>
        ))}
      </Select>
    );
  }

  if (expressionType.secondOperandType === RuleExpressionValueTypeEnum.NUMBER) {
    return <InputNumber onChange={onChange} value={value} />;
  }

  return <Input maxLength={100} onChange={(e) => onChange(e.target.value)} value={value} />;
}

export default RuleExpressionSecondOperandInput;
