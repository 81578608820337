import type { CmsCollectionModel } from '../../../../models/cms/collections/CmsCollectionModel';
import type { CmsCreateMutationResponse } from '../../../../models/cms/CmsResponseModels';

import { useMutation, useQueryClient } from 'react-query';

import { createCollection } from '../../../../services/CmsCollectionService';
import QUERYKEYS from '../../../../constants/queryKeys';

function useCreateCmsCollection(): CmsCreateMutationResponse<CmsCollectionModel> {
  const queryClient = useQueryClient();

  const { isError, isLoading, isSuccess, mutate } = useMutation(createCollection, {
    onSuccess: () => queryClient.invalidateQueries(QUERYKEYS.COLLECTIONS),
  });

  return {
    create: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useCreateCmsCollection;
