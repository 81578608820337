import '../../../../../css/cms/forms/collections/entryVersionData/CmsCollectionEntryDataItems.css';

import { Col, Collapse, Divider, Form, Input, Row, Tooltip } from 'antd';
import { keyBy, sortBy } from 'lodash';

import { CmsCollectionCodeEditorItems } from './CmsCollectionCodeEditorItems';
import type { CmsCollectionEntryVersionDataModel } from '../../../../../models/cms/collections/entryVersionData/CmsCollectionEntryVersionDataModel';
import { CmsCollectionNumberItems } from './CmsCollectionNumberItems';
import { CmsCollectionRefItems } from './CmsCollectionRefItems';
import type { CmsCollectionSchemaDataModel } from '../../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataModel';
import { CmsCollectionSchemaDataTypeEnum } from '../../../../../enums/cms/CmsCollectionSchemaDataTypeEnum';
import { CmsCollectionSelectItems } from './CmsCollectionSelectItems';
import { CmsCollectionTextItems } from './CmsCollectionTextItems';
import LocaleSelector from '../../../../shared/LocaleSelector';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { REQUIRED_RULE } from '../../../../../constants/formRules';
import React from 'react';
import { getCmsCollectionSchemaDataTypeDisplayName } from '../../../../../utils/cmsUtils';
import InputDisabledWithInsufficientPermission from '../../../../hocs/inputs/InputDisabledWithInsufficientPermission';

type Props = {
  initialData: Array<CmsCollectionEntryVersionDataModel>;
  schemaData: Array<CmsCollectionSchemaDataModel>;
  includeMetadataFields?: boolean;
};

const EnhancedInput = InputDisabledWithInsufficientPermission(Input);

export const CmsCollectionEntryDataItems = ({
  schemaData,
  initialData = [],
  includeMetadataFields = false,
}: Props) => {
  const initialDataMapping = initialData ? keyBy(initialData, 'schemaDataId') : null;
  const getEntryDataFormItemsBySchema = (
    field: CmsCollectionSchemaDataModel,
    index: number,
    fieldValues?: CmsCollectionEntryVersionDataModel,
  ) => {
    const label = (
      <Row justify="space-between">
        <Col>
          <>
            {field.isRequired && <span className="red">* </span>}
            <b>{field.fieldName} </b>
          </>
          {field.fieldDescription && (
            <Tooltip title={field.fieldDescription}>
              <QuestionCircleOutlined />
            </Tooltip>
          )}
        </Col>
        <Col>{getCmsCollectionSchemaDataTypeDisplayName(field.schemaDataType)}</Col>
      </Row>
    );

    const hiddenFormItems = (
      <Form.Item
        hidden
        name={['entryVersions', 0, 'entryVersionData', index, 'schemaDataId']}
        initialValue={field.id}
      >
        <Input type="hidden" />
      </Form.Item>
    );

    let schemaFormItem;
    switch (field.schemaDataType) {
      case CmsCollectionSchemaDataTypeEnum.Number:
        schemaFormItem = (
          <CmsCollectionNumberItems
            initialData={fieldValues}
            schemaData={field}
            formLabel={null}
            index={index}
          />
        );
        break;
      case CmsCollectionSchemaDataTypeEnum.Text:
        schemaFormItem = (
          <CmsCollectionTextItems
            initialData={fieldValues}
            schemaData={field}
            formLabel={null}
            index={index}
          />
        );
        break;
      case CmsCollectionSchemaDataTypeEnum.Ref:
        schemaFormItem = (
          <CmsCollectionRefItems
            initialData={fieldValues}
            schemaData={field}
            formLabel={null}
            index={index}
          />
        );
        break;
      case CmsCollectionSchemaDataTypeEnum.Select:
        schemaFormItem = (
          <CmsCollectionSelectItems
            initialData={fieldValues}
            schemaData={field}
            formLabel={null}
            index={index}
          />
        );
        break;
      case CmsCollectionSchemaDataTypeEnum.CodeEditor:
        schemaFormItem = (
          <CmsCollectionCodeEditorItems
            initialData={fieldValues}
            schemaData={field}
            formLabel={null}
            index={index}
          />
        );
        break;
    }

    return (
      <Collapse.Panel forceRender key={field.id} header={label}>
        {schemaFormItem}
        {hiddenFormItems}
      </Collapse.Panel>
    );
  };

  return (
    <div className="cmsCollectionEntryDataItemsRoot">
      <div className="cmsCollectionEntryContent">
        {includeMetadataFields && (
          <>
            <div className="cmsCollectionEntrySection">
              <Row gutter={[24, 0]}>
                <Col span={18}>
                  <Form.Item name="name" rules={[REQUIRED_RULE]} label={<b>Name</b>}>
                    <EnhancedInput maxLength={128} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <LocaleSelector label={<b>Locale</b>} />
                </Col>
              </Row>
            </div>
            <Divider orientation="left">Fields ({schemaData.length})</Divider>
          </>
        )}
        <div className="cmsCollectionEntrySection">
          <Collapse bordered={false} defaultActiveKey={schemaData.map((schema) => schema.id)}>
            {schemaData &&
              sortBy(schemaData, 'order').map((field, ii) =>
                getEntryDataFormItemsBySchema(
                  field,
                  ii,
                  initialDataMapping ? initialDataMapping[field.id] : undefined,
                ),
              )}
          </Collapse>
        </div>
      </div>
    </div>
  );
};
