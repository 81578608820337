import React from 'react';
import useCurrentUserHasAccessToArea from '../../../hooks/shared/useCurrentUserHasAccessToArea';
import type { AreasEnum } from '../../../enums/shared/AreasEnum';
import useAreaContext from '../../../hooks/contexts/useAreaContext';

const InputDisabledWithInsufficientPermission = (Input) => {
  return function GetInputDisabledWithInsufficientPermission(props) {
    const areaContext: AreasEnum = useAreaContext();
    const userPermissions = useCurrentUserHasAccessToArea(areaContext);

    const { disabled } = props;
    const isDisabled = (disabled ?? false) || !userPermissions.canEdit;

    return <Input {...props} disabled={isDisabled} />;
  };
};

export default InputDisabledWithInsufficientPermission;
