import ROUTES from '../../constants/routes';

export enum AreasEnum {
  CMS = 'CMS',
  PROMO_CODES = 'PromoCodes',
  RULES = 'Rules',
  VENDORS = 'Vendor',
  NONE = 'None',
}

export const AreaRoute = new Map<string, string>([
  [AreasEnum.CMS, ROUTES.CMS],
  [AreasEnum.PROMO_CODES, ROUTES.PROMO_CODES],
  [AreasEnum.RULES, ROUTES.RULES],
  [AreasEnum.VENDORS, ROUTES.VENDORS],
  [AreasEnum.NONE, ROUTES.HOME],
]);
