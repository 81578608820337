import React from 'react';
import useCurrentUserHasAccessToArea from '../../../hooks/shared/useCurrentUserHasAccessToArea';
import type { AreasEnum } from '../../../enums/shared/AreasEnum';
import useAreaContext from '../../../hooks/contexts/useAreaContext';
import { filterNulls } from '../../../utils/arrayUtils';

const TableWithSelectableRows = (Table) => {
  return function GetTableWithSelectableRows(props) {
    const areaContext: AreasEnum = useAreaContext();
    const userPermissions = useCurrentUserHasAccessToArea(areaContext);
    const isDisabled = !userPermissions.canEdit;
    const columns = filterNulls(props.columns);

    return (
      <Table
        {...props}
        columns={columns}
        rowSelection={!isDisabled ? props.rowSelection : undefined}
      />
    );
  };
};

export default TableWithSelectableRows;
