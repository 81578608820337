import { Modal } from 'antd';
import React, { useCallback } from 'react';
import useDeleteRule from '../../hooks/services/rules/useDeleteRule';
import type { $RuleModel } from '../../models/rules/RuleModel';
import RequestErrorAlert from '../shared/RequestErrorAlert';

type Props = Readonly<{
  selectedRule: $RuleModel | null;
  isShown: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}>;

export default function RuleDeleteConfirmModal({
  isShown,
  onCancel,
  onSubmit,
  selectedRule,
}: Props) {
  const { isError, isLoading, deleteMutation } = useDeleteRule();
  const onConfirm = useCallback(async () => {
    if (!selectedRule?.id) {
      return;
    }
    deleteMutation(selectedRule.id, {
      onSuccess: () => {
        onSubmit();
      },
    });
  }, [onSubmit, deleteMutation, selectedRule?.id]);

  return (
    <Modal
      okText="Confirm"
      okButtonProps={{ loading: isLoading }}
      onOk={onConfirm}
      onCancel={onCancel}
      title="Delete Rule"
      open={isShown}
    >
      <RequestErrorAlert isError={isError} />
      Are you sure you want to delete this rule?
    </Modal>
  );
}
