import React from 'react';
import type { TTableColumn } from '../../../utils/tableUtils';
import useCurrentUserHasAccessToArea from '../../../hooks/shared/useCurrentUserHasAccessToArea';
import type { AreasEnum } from '../../../enums/shared/AreasEnum';
import useAreaContext from '../../../hooks/contexts/useAreaContext';
import { filterNulls } from '../../../utils/arrayUtils';

const TableWithActionsColumn = (Table) => {
  return function GetTableWithActionsColumn(props) {
    const areaContext: AreasEnum = useAreaContext();
    const userPermissions = useCurrentUserHasAccessToArea(areaContext);
    const isDisabled = !userPermissions.canEdit;
    const columnsWithNullsRemoved: Array<TTableColumn> = filterNulls(props.columns);
    const columns: Array<TTableColumn> = filterActionsColumn(columnsWithNullsRemoved, !isDisabled);

    return <Table {...props} columns={columns} />;
  };
};

function filterActionsColumn(items: Array<TTableColumn>, canEdit: boolean): Array<TTableColumn> {
  if (!canEdit) {
    return items.filter((item) => item.title !== 'Actions');
  }
  return items;
}

export default TableWithActionsColumn;
