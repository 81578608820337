import type { CmsPageModel } from '../../../models/cms/CmsPageModel';
import type { GetCmsPageRequest } from './../../../services/types/CmsPages/GetCmsPageRequest';
import QUERYKEYS from '../../../constants/queryKeys';
import { getPage } from '../../../services/CmsPageService';
import { useQuery } from 'react-query';

type TUseCmsPage = {
  data?: CmsPageModel;
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsPage(request: GetCmsPageRequest): TUseCmsPage {
  const response = useQuery({
    queryKey: [QUERYKEYS.PAGE, { request }],
    queryFn: async () => getPage(request),
    staleTime: 0,
  });

  return response;
}
