import './../../css/shared/RequestErrorAlert.css';

import { Alert } from 'antd';
import React from 'react';
import { joinClasses } from '../../utils/styleUtils';

type Props = {
  className?: string;
  isError?: boolean;
  description?: string;
};

const RequestErrorAlert = ({ className, isError, ...props }: Props): React.ReactElement => {
  return (
    <>
      {isError && (
        <div className="requestErrorAlertRoot">
          <Alert
            className={joinClasses(className, 'requestErrorAlert')}
            message="Error"
            description="An error occurred during the request."
            type="error"
            showIcon
            {...props}
          />
        </div>
      )}
    </>
  );
};

export default RequestErrorAlert;
