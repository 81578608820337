import './../../../css/promoCodes/displays/PromoCodeStatusText.css';

import React from 'react';
import { PromoCodeStatus } from './../../../enums/promoCodes/PromoCodeStatusEnum';

type Props = {
  status: PromoCodeStatus;
};

const PromoCodeStatusText = ({ status }: Props) => {
  switch (status) {
    case PromoCodeStatus.PLANNING:
      return <span className="promoCodeStatusPlanning pill">Planning</span>;
    case PromoCodeStatus.UPCOMING:
      return <span className="promoCodeStatusUpcoming pill">Upcoming</span>;
    case PromoCodeStatus.ACTIVE:
      return <span className="promoCodeStatusActive pill">Active</span>;
    case PromoCodeStatus.EXPIRED:
      return <span className="promoCodeStatusExpired pill">Expired</span>;
    case PromoCodeStatus.CANCELLED:
      return <span className="promoCodeStatusCancelled pill">Cancelled</span>;
    default:
      return <span className="promoCodeStatusNotSet pill">Unknown</span>;
  }
};

export default PromoCodeStatusText;
