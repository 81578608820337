import './../../css/rules/RuleExpressionForm.css';

import { RULE_BOOLEAN_EXPRESSION_TYPES } from '../../constants/rules/ruleBooleanExpressionTypes';
import type { $RuleBooleanExpressionType } from '../../constants/rules/ruleBooleanExpressionTypes';
import { Col, Form, Row, Select } from 'antd';

import type { $RuleBooleanExpressionModel } from '../../models/rules/RuleExpressionModel';
import type { $RuleInputSchemaFieldModel } from '../../models/rules/RuleInputSchemaModel';
import React from 'react';
import type { RuleExpressionBooleanOperatorEnum } from '../../enums/rules/RuleExpressionBooleanOperatorEnum';
import RuleExpressionSecondOperandInput from './RuleExpressionSecondOperandInput';

const DEFAULT_BOOLEAN_EXPRESSION: Partial<$RuleBooleanExpressionModel> = {};

const FORM_LAYOUT = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const REQUIRED_RULE = { message: '"${label}" is required', required: true };

function getBooleanExpressionType(
  booleanExpressionTypes: $RuleBooleanExpressionType[],
  booleanOperator?: RuleExpressionBooleanOperatorEnum | null,
  inputField?: $RuleInputSchemaFieldModel,
): $RuleBooleanExpressionType | undefined {
  return booleanExpressionTypes.find(
    (expressionType) =>
      expressionType.firstOperandType === inputField?.type &&
      expressionType.operator === booleanOperator,
  );
}

type Props = {
  booleanExpression?: Partial<$RuleBooleanExpressionModel> | null;
  inputFields?: $RuleInputSchemaFieldModel[];
  setBooleanExpression: (booleanExpression?: Partial<$RuleBooleanExpressionModel>) => void;
};

function RuleExpressionForm({
  booleanExpression,
  inputFields,
  setBooleanExpression,
}: Props): React.ReactElement {
  const [form] = Form.useForm();
  const inputFieldName = Form.useWatch('inputField', form);
  const booleanOperator = Form.useWatch('booleanOperator', form);

  const inputField = React.useMemo(
    () => inputFields?.find((field) => field.name === inputFieldName),
    [inputFields, inputFieldName],
  );
  const booleanExpressionTypes = React.useMemo(
    () =>
      RULE_BOOLEAN_EXPRESSION_TYPES.filter(
        (expressionType) => expressionType.firstOperandType === inputField?.type,
      ),
    [inputField?.type],
  );
  const booleanExpressionType = React.useMemo(
    () => getBooleanExpressionType(booleanExpressionTypes, booleanOperator, inputField),
    [booleanOperator, booleanExpressionTypes, inputField],
  );

  const onInputFieldNameChange = React.useCallback(() => {
    setBooleanExpression(undefined);
    form.resetFields(['operator', 'rawValue']);
  }, [setBooleanExpression, form]);

  const onOperatorChange = React.useCallback(
    (value: RuleExpressionBooleanOperatorEnum) => {
      const newBooleanExpressionType = getBooleanExpressionType(
        booleanExpressionTypes,
        value,
        inputField,
      );

      if (
        newBooleanExpressionType?.secondOperandType === booleanExpressionType?.secondOperandType
      ) {
        return;
      }

      form.setFieldValue('rawValue', undefined);
      setBooleanExpression({
        ...booleanExpression,
        rawValue: null,
      });
    },
    [
      booleanExpression,
      booleanExpressionType?.secondOperandType,
      booleanExpressionTypes,
      form,
      inputField,
      setBooleanExpression,
    ],
  );

  const onRawValueChange = React.useCallback(
    (rawValue: any) => {
      booleanOperator &&
        inputField &&
        setBooleanExpression({
          booleanOperator,
          inputField,
          rawValue,
        });
    },
    [booleanOperator, inputField, setBooleanExpression],
  );

  return (
    <Form
      {...FORM_LAYOUT}
      className="ruleExpressionFormRoot"
      form={form}
      initialValues={
        booleanExpression
          ? { ...booleanExpression, inputField: booleanExpression?.inputField?.name }
          : DEFAULT_BOOLEAN_EXPRESSION
      }
      layout="inline"
    >
      <Row className="ruleExpressionFormRow">
        <Col span={8}>
          <Form.Item label="Input field" name="inputField" rules={[REQUIRED_RULE]}>
            <Select
              showSearch
              dropdownMatchSelectWidth={false}
              disabled={!inputFields}
              onChange={onInputFieldNameChange}
            >
              {inputFields?.map((field) => (
                <Select.Option key={field.name} value={field.name}>
                  {field.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            dependencies={['inputField']}
            label="Operator"
            name="booleanOperator"
            rules={[REQUIRED_RULE]}
          >
            <Select disabled={!inputField} onChange={onOperatorChange}>
              {booleanExpressionTypes?.map((expressionTypes) => (
                <Select.Option key={expressionTypes.operator} value={expressionTypes.operator}>
                  {expressionTypes.operator}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            dependencies={['booleanOperator', 'inputField']}
            label="Value"
            name="rawValue"
            rules={[REQUIRED_RULE]}
          >
            <RuleExpressionSecondOperandInput
              expressionType={booleanExpressionType}
              inputField={inputField}
              onChange={onRawValueChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default RuleExpressionForm;
