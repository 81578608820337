import './../../../css/promoCodes/tables/PromoCodesTable.css';
import React, { useEffect, useState } from 'react';
import { Badge, Divider, Space, Table, Tooltip } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { orderBy } from 'lodash';

import { CalendarOutlined } from '@ant-design/icons';
import COLORS from '../../../constants/colors';
import {
  getBooleanDisplayValue,
  getDateDisplayValue,
  getMoneyDisplayValue,
  getPercentageDisplayValue,
  getDateUnixValueFromISO,
} from '../../../utils/displayUtils';

import { CheckOutlined } from '@ant-design/icons';
import type { PromoCodeModel } from '../../../models/promoCodes/PromoCodeModel';
import { PromoCodeDiscountType } from '../../../enums/promoCodes/PromoCodeDiscountTypeEnum';
import { PromoCodeStatus } from './../../../enums/promoCodes/PromoCodeStatusEnum';
import PromoCodeStatusText from '../displays/PromoCodeStatusText';
import DateRangeColumnFilter from '../../shared/columnFilters/DateRangeColumnFilter';
import PromoCodeActionButtons from '../buttons/PromoCodeActionButtons';
import PromoCodeOpenButton from '../buttons/PromoCodeOpenButton';
import { filterNulls } from './../../../utils/arrayUtils';
import TableWithSelectableRows from '../../hocs/tables/TableWithSelectableRows';
import TableWithHeader from '../../hocs/tables/TableWithHeader';

type Props = {
  data: Array<PromoCodeModel>;
  isLoading?: boolean;
};

const SelectableTableWithHeader = TableWithHeader(TableWithSelectableRows(Table));

const PromoCodesTable = ({ data, isLoading = false }: Props) => {
  // States
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredRecordCount, setFilteredRecordCount] = useState<number>(0);

  // Events
  const onSelect = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRows);
  };

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  }, [data]);

  // Table components
  const getFooter = (promoCodes: Array<PromoCodeModel>) => {
    const countOfActivePromoCodes = promoCodes.filter(
      (promoCode) => promoCode.status === PromoCodeStatus.ACTIVE,
    ).length;
    const countOfUpcomingPromoCodes = promoCodes.filter(
      (promoCode) => promoCode.status === PromoCodeStatus.UPCOMING,
    ).length;
    const countOfPlanningPromoCodes = promoCodes.filter(
      (promoCode) => promoCode.status === PromoCodeStatus.PLANNING,
    ).length;
    const countOfCancelledPromoCodes = promoCodes.filter(
      (promoCode) => promoCode.status === PromoCodeStatus.CANCELLED,
    ).length;
    const countOfExpiredPromoCodes = promoCodes.filter(
      (promoCode) => promoCode.status === PromoCodeStatus.EXPIRED,
    ).length;
    const countOfAllPromoCodes = promoCodes.length;

    return (
      <div className="promoCodesTableFooter">
        <Space split={<Divider type="vertical" />}>
          {countOfActivePromoCodes > 0 && (
            <Badge
              color={COLORS.PROMO_CODES_ACTIVE_PILL}
              text={`Active (${countOfActivePromoCodes})`}
            />
          )}
          {countOfUpcomingPromoCodes > 0 && (
            <Badge
              color={COLORS.PROMO_CODES_UPCOMING_PILL}
              text={`Upcoming (${countOfUpcomingPromoCodes})`}
            />
          )}
          {countOfPlanningPromoCodes > 0 && (
            <Badge
              color={COLORS.PROMO_CODES_PLANNING_PILL}
              text={`Planning (${countOfPlanningPromoCodes})`}
            />
          )}
          {countOfExpiredPromoCodes > 0 && (
            <Badge
              color={COLORS.PROMO_CODES_EXPIRED_PILL}
              text={`Expired (${countOfExpiredPromoCodes})`}
            />
          )}
          {countOfCancelledPromoCodes > 0 && (
            <Badge
              color={COLORS.PROMO_CODES_CANCELLED_PILL}
              text={`Cancelled (${countOfCancelledPromoCodes})`}
            />
          )}
          {countOfAllPromoCodes > 0 && <span>Total ({countOfAllPromoCodes})</span>}
        </Space>
      </div>
    );
  };

  data = sortPromoCodesByStatusInSpecificOrder(data);
  return (
    <SelectableTableWithHeader
      header={<PromoCodeActionButtons data={selectedRows} />}
      className="promoCodesTableRoot"
      bordered
      columns={getColumns(data)}
      onChange={(pagination, filters, sorter, extra) => {
        setFilteredRecordCount(extra.currentDataSource.length);
      }}
      dataSource={data}
      loading={isLoading}
      size="small"
      rowKey={(record) => record.id}
      footer={getFooter}
      pagination={{
        hideOnSinglePage: true,
        defaultPageSize: 100,
        responsive: true,
        total: filteredRecordCount,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      }}
      rowSelection={{ selectedRowKeys, onChange: onSelect }}
    />
  );
};

function getColumns(data: Array<PromoCodeModel>): Array<ColumnType<PromoCodeModel>> {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (_, record) => <PromoCodeOpenButton promoCode={record} />,
      sorter: (a, b) => a.name.localeCompare(b.name),
      align: 'left',
      filterSearch: true,
      filters: data.map((record) => ({ text: record.name, value: record.name })),
      onFilter: (searchValue: string, record: PromoCodeModel): boolean =>
        searchValue === record.name,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      render: (value: string) => <span className="code-column">{value}</span>,
      sorter: (a, b) => a.code.localeCompare(b.code),
      align: 'left',
      filterSearch: true,
      filters: data.map((record) => ({ text: record.code, value: record.code })),
      onFilter: (searchValue: string, record: PromoCodeModel): boolean =>
        searchValue === record.code,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value: PromoCodeStatus) => <PromoCodeStatusText status={value} />,
      align: 'center',
      sorter: (a, b) =>
        getPromoCodeStatusText(a.status).localeCompare(getPromoCodeStatusText(b.status)),
      filterSearch: true,
      filters: [
        PromoCodeStatus.ACTIVE,
        PromoCodeStatus.PLANNING,
        PromoCodeStatus.UPCOMING,
        PromoCodeStatus.EXPIRED,
        PromoCodeStatus.CANCELLED,
      ].map((promoCodeStatus) => ({
        text: getPromoCodeStatusText(promoCodeStatus),
        value: promoCodeStatus,
      })),
      onFilter: (promoCodeStatus: PromoCodeStatus, record: PromoCodeModel): boolean =>
        record.status === promoCodeStatus,
    },
    {
      title: 'Discount',
      dataindex: 'activePromoCodeDiscount',
      render: (_, row: PromoCodeModel) => {
        if (row.activePromoCodeDiscountType === PromoCodeDiscountType.FLAT_AMOUNT) {
          return getMoneyDisplayValue(row.activePromoCodeDiscount || 0);
        } else if (row.activePromoCodeDiscountType === PromoCodeDiscountType.PERCENTAGE) {
          return getPercentageDisplayValue(row.activePromoCodeDiscount || 0, false);
        } else {
          return '-';
        }
      },
      sorter: (a, b) => (a.activePromoCodeDiscount ?? 0) - (b.activePromoCodeDiscount ?? 0),
    },
    {
      title: 'Start Date',
      dataIndex: 'activePromoCodeStartDate',
      render: (value) => (value ? getDateDisplayValue(value) : '-'),
      sorter: (a, b) =>
        getDateUnixValueFromISO(a.activePromoCodeStartDate) -
        getDateUnixValueFromISO(b.activePromoCodeStartDate),
      filterSearch: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateRangeColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (
        <Tooltip title="Filter to dates that fall between">
          <CalendarOutlined />
        </Tooltip>
      ),
      onFilter: (selectedDate: string, record: PromoCodeModel): boolean => {
        if (record.status !== PromoCodeStatus.ACTIVE) return false;
        if (!record.activePromoCodeStartDate) return true;
        return (
          getDateUnixValueFromISO(record.activePromoCodeStartDate, true) ===
          getDateUnixValueFromISO(selectedDate, true)
        );
      },
    },
    {
      title: 'End Date',
      dataIndex: 'activePromoCodeEndDate',
      render: (value) => (value ? getDateDisplayValue(value) : '-'),
      sorter: (a, b) =>
        getDateUnixValueFromISO(a.activePromoCodeEndDate) -
        getDateUnixValueFromISO(b.activePromoCodeEndDate),
      filterSearch: true,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateRangeColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filterIcon: (
        <Tooltip title="Filter to dates that fall between">
          <CalendarOutlined />
        </Tooltip>
      ),
      onFilter: (selectedDate: string, record: PromoCodeModel): boolean => {
        if (record.status !== PromoCodeStatus.ACTIVE) return false;
        if (!record.activePromoCodeEndDate) return true;
        return (
          getDateUnixValueFromISO(record.activePromoCodeEndDate, true) ===
          getDateUnixValueFromISO(selectedDate, true)
        );
      },
    },
    {
      title: 'Enabled',
      dataIndex: 'isEnabled',
      render: (_, row: PromoCodeModel) =>
        row.isEnabled ? (
          <CheckOutlined className="enabledIcon" color={COLORS.PROMO_CODES_CHECK_MARK} />
        ) : (
          <span></span>
        ),
      align: 'center',
      filterSearch: true,
      filters: [true, false].map((enabledStatus) => ({
        text: getBooleanDisplayValue(enabledStatus),
        value: enabledStatus,
      })),
      onFilter: (enabledStatus: boolean, record: PromoCodeModel): boolean =>
        record.isEnabled === enabledStatus,
      defaultFilteredValue: [true],
    },
  ];

  return filterNulls(columns);
}

function getPromoCodeStatusText(status: PromoCodeStatus): string {
  switch (status) {
    case PromoCodeStatus.PLANNING:
      return 'Planning';
    case PromoCodeStatus.UPCOMING:
      return 'Upcoming';
    case PromoCodeStatus.ACTIVE:
      return 'Active';
    case PromoCodeStatus.EXPIRED:
      return 'Expired';
    case PromoCodeStatus.CANCELLED:
      return 'Cancelled';
    default:
      return 'Unknown';
  }
}

function sortPromoCodesByStatusInSpecificOrder(
  promoCodes: Array<PromoCodeModel>,
): Array<PromoCodeModel> {
  const activePromoCodes = promoCodes.filter(
    (promoCode) => promoCode.status === PromoCodeStatus.ACTIVE,
  );
  const upcomingPromoCodes = promoCodes.filter(
    (promoCode) => promoCode.status === PromoCodeStatus.UPCOMING,
  );
  const planningPromoCodes = promoCodes.filter(
    (promoCode) => promoCode.status === PromoCodeStatus.PLANNING,
  );
  const expiredPromoCodes = promoCodes.filter(
    (promoCode) => promoCode.status === PromoCodeStatus.EXPIRED,
  );
  const cancelledPromoCodes = promoCodes.filter(
    (promoCode) => promoCode.status === PromoCodeStatus.CANCELLED,
  );

  const sortedActivePromoCodes = orderBy(activePromoCodes, ['startDate', 'name'], ['asc', 'asc']);
  const sortedUpcomingPromoCodes = orderBy(
    upcomingPromoCodes,
    ['startDate', 'name'],
    ['asc', 'asc'],
  );
  const sortedPlanningPromoCodes = orderBy(
    planningPromoCodes,
    ['startDate', 'name'],
    ['asc', 'asc'],
  );
  const sortedExpiredPromoCodes = orderBy(expiredPromoCodes, ['startDate', 'name'], ['asc', 'asc']);
  const sortedCancelledPromoCodes = orderBy(
    cancelledPromoCodes,
    ['startDate', 'name'],
    ['asc', 'asc'],
  );

  return [
    ...sortedActivePromoCodes,
    ...sortedUpcomingPromoCodes,
    ...sortedPlanningPromoCodes,
    ...sortedExpiredPromoCodes,
    ...sortedCancelledPromoCodes,
  ];
}

export default PromoCodesTable;
