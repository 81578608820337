import type { $RuleInputSchemaModel } from '../../../models/rules/RuleInputSchemaModel';
import type { TQueryModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { getInputSchemas } from './../../../services/RuleService';
import { useQuery } from 'react-query';

const useInputSchemas = (): TQueryModel<$RuleInputSchemaModel[]> => {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.RULE_INPUT_SCHEMAS, getInputSchemas);

  return {
    data,
    isError,
    isLoading,
  };
};

export default useInputSchemas;
