import { Form, Input, Modal, Switch } from 'antd';
import React, { useState } from 'react';
import {
  convertTitleToSlug,
  getCmsCollectionSchemaDataTypeDisplayName,
} from '../../../../utils/cmsUtils';

import type { CmsCollectionSchemaDataModel } from '../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataModel';
import { CmsCollectionSchemaDataTypeEnum } from '../../../../enums/cms/CmsCollectionSchemaDataTypeEnum';
import { CmsSchemaDataCodeEditorItems } from './CmsSchemaDataCodeEditorItems';
import { CmsSchemaDataNumberItems } from './CmsSchemaDataNumberItems';
import { CmsSchemaDataRefItems } from './CmsSchemaDataRefItems';
import { CmsSchemaDataSelectItems } from './CmsSchemaDataSelectItems';
import { CmsSchemaDataTextItems } from './CmsSchemaDataTextItems';
import { REQUIRED_RULE } from '../../../../constants/formRules';
import { getErrorDisplayValues } from '../../../../utils/displayUtils';
import useCreateCmsCollectionSchemaData from '../../../../hooks/services/cms/collections/useCreateCmsCollectionSchemaData';
import useStatusNotifications from '../../../../hooks/shared/useStatusNotifications';
import useUpdateCmsCollectionSchemaData from '../../../../hooks/services/cms/collections/useUpdateCmsCollectionSchemaData';

type Props = {
  initialData:
    | CmsCollectionSchemaDataModel
    | {
        collectionId: number;
        order: number;
        schemaDataType: CmsCollectionSchemaDataTypeEnum;
      };
  isNew: boolean;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
};

const CmsCollectionSchemaDataModalForm = ({ initialData, isNew, isShown, setIsShown }: Props) => {
  const {
    create,
    isLoading: isCreating,
    isSuccess: isCreated,
    isError: isCreateError,
  } = useCreateCmsCollectionSchemaData();
  const {
    update,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateCmsCollectionSchemaData();

  const [form] = Form.useForm();
  const isLoading = isCreating || isUpdating;
  const isSuccess = isCreated || isUpdated;
  const isError = isCreateError || isUpdateError;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const onCancel = React.useCallback(() => {
    if (isLoading) {
      return;
    }

    form.resetFields();
    setIsShown(false);
  }, [isLoading, form, setIsShown]);

  const onSubmit = React.useCallback(async () => {
    const initialId = 'id' in initialData ? initialData.id : undefined;
    const collectionSchemaData = isNew
      ? { ...form.getFieldsValue(), ...initialData }
      : {
          id: initialId,
          ...form.getFieldsValue(),
        };

    try {
      await form.validateFields();

      if (isNew) {
        create(collectionSchemaData, {
          onSuccess: () => {
            form.resetFields();
            setIsShown(false);
          },
          onError: (err) => {
            const errors = getErrorDisplayValues(err);
            if (errors.length > 0) {
              const errorMessage = errors.join(' ');
              setErrorMessage(errorMessage);
            }
          },
        });
      } else {
        update(collectionSchemaData, {
          onSuccess: () => {
            form.resetFields();
            setIsShown(false);
          },
          onError: (err) => {
            const errors = getErrorDisplayValues(err);
            if (errors.length > 0) {
              const errorMessage = errors.join(' ');
              setErrorMessage(errorMessage);
            }
          },
        });
      }

      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [create, form, initialData, isNew, setIsShown, update]);

  const getSchemaDataFormItemsByType = (value: CmsCollectionSchemaDataTypeEnum) => {
    switch (value) {
      case CmsCollectionSchemaDataTypeEnum.Number:
        return <CmsSchemaDataNumberItems />;
      case CmsCollectionSchemaDataTypeEnum.Text:
        return <CmsSchemaDataTextItems />;
      case CmsCollectionSchemaDataTypeEnum.Ref:
        return <CmsSchemaDataRefItems collectionId={initialData?.collectionId} />;
      case CmsCollectionSchemaDataTypeEnum.Select:
        return <CmsSchemaDataSelectItems isNew={isNew} />;
      case CmsCollectionSchemaDataTypeEnum.CodeEditor:
        return <CmsSchemaDataCodeEditorItems />;
    }
  };

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The field was successfully ${isNew ? 'added' : 'updated'}.`,
    errorDescription: errorMessage,
  });

  const actionText = `${isNew ? 'Add' : 'Update'} ${getCmsCollectionSchemaDataTypeDisplayName(
    initialData.schemaDataType,
  )} Field`;

  const isRef = initialData.schemaDataType === CmsCollectionSchemaDataTypeEnum.Ref;
  return (
    <Modal
      okButtonProps={{ loading: isLoading }}
      okText={actionText}
      onOk={onSubmit}
      onCancel={onCancel}
      title={actionText}
      width={isRef ? 1000 : 520}
      open={isShown}
    >
      <Form form={form} initialValues={initialData} labelCol={{ span: 7 }} labelWrap>
        {isRef && getSchemaDataFormItemsByType(initialData.schemaDataType)}
        <Form.Item label="Field Name" name="fieldName" rules={[REQUIRED_RULE]}>
          <Input
            maxLength={128}
            onChange={(e) => {
              isNew &&
                form.setFieldsValue({
                  slug: convertTitleToSlug(e.currentTarget.value.toLowerCase(), '_'),
                });
            }}
          />
        </Form.Item>
        <Form.Item label="Slug" name="slug" rules={[REQUIRED_RULE]}>
          <Input maxLength={128} disabled={true} />
        </Form.Item>
        <Form.Item label="Field Description" name="fieldDescription">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Is Required?" name="isRequired" valuePropName="checked">
          <Switch />
        </Form.Item>
        {!isRef && getSchemaDataFormItemsByType(initialData.schemaDataType)}
        <Form.Item hidden name="schemaDataType" rules={[REQUIRED_RULE]}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item hidden name="order" rules={[REQUIRED_RULE]}>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item hidden name="collectionId" rules={[REQUIRED_RULE]}>
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CmsCollectionSchemaDataModalForm;
