import { useMutation, useQueryClient } from 'react-query';
import type { PromoCodeModel } from './../../../models/promoCodes/PromoCodeModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { updatePromoCode } from './../../../services/PromoCodesService';
import type { TUpdateModel } from '../../../utils/hookUtils';

const useUpdatePromoCode = (): TUpdateModel<PromoCodeModel> => {
  const queryClient = useQueryClient();
  const { isLoading, isError, isSuccess, mutate } = useMutation(updatePromoCode, {
    onSuccess: (data) =>
      queryClient.setQueryData(QUERYKEYS.PROMO_CODES, (promoCodes: any) =>
        promoCodes.map((promoCode) => (promoCode.id === data!.id ? data : promoCode)),
      ),
  });

  return {
    update: mutate,
    isSuccess,
    isError,
    isLoading,
  };
};

export default useUpdatePromoCode;
