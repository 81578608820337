import type { TFileUploadModel } from '../../../utils/hookUtils';
import { parseVendorCodesCommercialCsvFile } from './../../../services/VendorCodesService';
import { useMutation } from 'react-query';

const useParseVendorCodesCommercialCsvFile = (): TFileUploadModel<File> => {
  const { isLoading, mutate } = useMutation(parseVendorCodesCommercialCsvFile);

  return {
    upload: mutate,
    isLoading,
  };
};

export default useParseVendorCodesCommercialCsvFile;
