import React, { useMemo } from 'react';
import { Table } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import COLORS from '../../../constants/colors';

import type { TVendorCodeCommercialUpsertModel } from './../../../models/vendor/VendorCodeModels';
import TextSearchColumnFilter from '../../shared/columnFilters/TextSearchColumnFilter';

type Props = {
  data: Array<TVendorCodeCommercialUpsertModel>;
  isLoading?: boolean;
};

const BulkVendorCodeCommercialTable = ({ data, isLoading = false }: Props) => {
  const columns = useMemo(() => getColumns(), []);

  return (
    <div className="s">
      <Table
        bordered
        columns={columns}
        dataSource={data}
        loading={isLoading}
        size="small"
        pagination={{
          hideOnSinglePage: false,
          responsive: true,
          showSizeChanger: true,
          defaultPageSize: 20,
          position: ['topRight', 'bottomRight'],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        rowKey="id"
      />
    </div>
  );
};

function getColumns(): Array<ColumnType<TVendorCodeCommercialUpsertModel>> {
  return [
    {
      title: '',
      dataIndex: 'id',
      render: (value) => <>{value > 0 ? 'Edit' : 'Create'}</>,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.code.toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.name.toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendorName',
      sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.vendorName.toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
    {
      title: 'Campaign',
      dataIndex: 'marketingCampaign',
      sorter: (a, b) => a.marketingCampaign?.localeCompare(b.marketingCampaign),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.marketingCampaign
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
    {
      title: 'Toll Free Number',
      dataIndex: 'tollFreeNumber',
      sorter: (a, b) => a.tollFreeNumber?.localeCompare(b.tollFreeNumber),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.tollFreeNumber
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
  ];
}

export default BulkVendorCodeCommercialTable;
