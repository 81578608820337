import '../../../css/core/PasswordInput.css';
import './../../../css/pages/vendor/VendorsPage.css';

import React from 'react';
import PageHeader from '../../shared/PageHeader';
import Section from '../../shared/Section';

import RequestErrorAlert from '../../shared/RequestErrorAlert';
import VendorsTable from '../../vendor/tables/VendorsTable';
import useVendors from './../../../hooks/services/vendor/useVendors';

const VendorPage = () => {
  const { data, isError, isLoading } = useVendors();

  return (
    <div className="vendorPageRoot">
      <PageHeader title="Vendors" />
      <Section isLoading={isLoading}>
        <div className="vendorMarginTopOffset20">
          <RequestErrorAlert
            isError={isError}
            description="An error occurred while attempting to load the data for this table."
          />
          <VendorsTable data={data} />
        </div>
      </Section>
    </div>
  );
};

export default VendorPage;
