const QUERYKEYS = {
  COLLECTION: 'collection',
  COLLECTIONS: 'collections',
  COLLECTION_ENTRY: 'collectionEntry',
  LAST_MODIFIED_COLLECTIONS: 'lastModifiedCollections',
  LAST_MODIFIED_PAGES: 'lastModifiedPages',
  LAST_MODIFIED_PAGE_GROUPS: 'lastModifiedPageGroups',
  PAGE_GROUPS: 'pageGroups',
  PAGE_GROUP: 'pageGroup',
  PAGE: 'page',
  PAGES: 'pages',
  PAGE_CONTENT: 'pageContent',
  PAGE_CONTENT_VERSION: 'pageContentVersion',
  PAGE_CONTENT_VERSIONS: 'pageContentVersions',
  PAGE_CONTENT_VERSION_ENVIRONMENTS: 'pageContentVersionEnvironments',
  PROMO_CODES: 'promoCodes',
  RELEASE: 'release',
  RELEASES: 'releases',
  RULE_INPUT_SCHEMAS: 'ruleInputSchemas',
  RULES: 'rules',
  VENDORS: 'vendors',
  VENDOR_CODES: 'vendorCodes',
  VENDOR_CODES_COMMERCIAL: 'vendorCodesCommercial',
  VENDOR_CODES_INTERNET: 'vendorCodesInternet',
  MARKETING_CAMPAIGN: 'marketingCampaign',
};
export default QUERYKEYS;
