import './../../../css/promoCodes/displays/PromoCodeDiscountStatusText.css';

import React from 'react';
import { PromoCodeDiscountStatus } from './../../../enums/promoCodes/PromoCodeDiscountStatusEnum';

type Props = {
  status: PromoCodeDiscountStatus;
};

const PromoCodeDiscountStatusText = ({ status }: Props) => {
  switch (status) {
    case PromoCodeDiscountStatus.UPCOMING:
      return <span className="promoCodeDiscountStatusUpcoming pill">Upcoming</span>;
    case PromoCodeDiscountStatus.ACTIVE:
      return <span className="promoCodeDiscountStatusActive pill">Active</span>;
    case PromoCodeDiscountStatus.EXPIRED:
      return <span className="promoCodeDiscountStatusExpired pill">Expired</span>;
    default:
      return <span className="promoCodeDiscountStatusNotSet pill">Unknown</span>;
  }
};

export default PromoCodeDiscountStatusText;
