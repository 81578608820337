import { Modal, Upload, message } from 'antd';
import React, { useCallback } from 'react';

import BulkVendorCodeCommercialTable from './../../vendor/tables/BulkVendorCodeCommercialTable';
import RequestErrorAlert from '../../shared/RequestErrorAlert';
import type { TVendorCodeCommercialUpsertModel } from './../../../models/vendor/VendorCodeModels';
import config from '../../../config';
import { convertKilobytesToBytes } from '../../../utils/bytesUtil';
import { getKiloBytesDisplayValue } from '../../../utils/displayUtils';
import useParseVendorCodesCommercialCsvFile from './../../../hooks/services/vendor/useParseVendorCodesCommercialCsvFile';
import useStatusNotifications from './../../../hooks/shared/useStatusNotifications';
import useUpsertVendorCodesCommercial from './../../../hooks/services/vendor/useUpsertVendorCodesCommercial';

type Props = {
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
};

const BulkVendorCodeCommercialModalForm = ({ isShown, setIsShown }: Props) => {
  const { Dragger } = Upload;
  const maxFileSizeKb = config.maxBulkUpdateFileSizeKb;
  const { upload } = useParseVendorCodesCommercialCsvFile();
  const {
    upsert,
    isSuccess: isUpsertSuccess,
    isLoading: isUpserting,
    isError: isUpsertError,
  } = useUpsertVendorCodesCommercial();
  const [data, setData] = React.useState<Array<TVendorCodeCommercialUpsertModel>>([]);
  const [fileErrorMessage, setFileErrorMessage] = React.useState<string>();

  const handleFileUpload = useCallback(
    async (file: File) => {
      upload(file, {
        onSuccess: (data) => setData(data ?? []),
      });
    },
    [upload, setData],
  );

  const onSubmit = useCallback(async () => {
    upsert(data, {
      onSuccess: () => {
        setFileErrorMessage('');
        setIsShown(false);
        setData([]);
      },
    });
  }, [upsert, data, setFileErrorMessage, setIsShown, setData]);

  const onCancel = useCallback(() => {
    setFileErrorMessage('');
    setIsShown(false);
    setData([]);
  }, [setFileErrorMessage, setIsShown, setData]);

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    onChange(info) {
      const { status, response } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        setData(response);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      const allowedExtensions = /(\.csv)$/i;
      if (!allowedExtensions.exec(file.name.toLowerCase())) {
        setFileErrorMessage(`'${file.name}' has an invalid file type. Please select a .csv file.`);
        return false;
      }

      if (file.size > convertKilobytesToBytes(maxFileSizeKb)) {
        setFileErrorMessage(
          `'${file.name}' is too large. Please select a file within the max file size.`,
        );
        return false;
      }
      handleFileUpload(file);
      return false;
    },
  };

  useStatusNotifications({
    isSuccess: !!isUpsertSuccess,
    isError: !!isUpsertError,
    successDescription: 'TFN Only vendor code records updated successfully.',
  });

  return (
    <Modal
      onOk={onSubmit}
      onCancel={onCancel}
      title={'Bulk Vendor Code TFN Only update'}
      open={isShown}
      width={900}
      okText={'Update'}
      okButtonProps={{ disabled: data.length === 0, loading: isUpserting }}
    >
      <RequestErrorAlert isError={isUpsertError} />
      {data.length === 0 && (
        <>
          {fileErrorMessage && <RequestErrorAlert isError={true} description={fileErrorMessage} />}
          <Dragger {...draggerProps}>
            <div className="invoiceUploadHorizontalSection">
              <div className="invoiceUploadBoxContents">
                <div>
                  Drag and drop CSV file here or <a>Upload</a>
                </div>
                <div>Max file size: {getKiloBytesDisplayValue(maxFileSizeKb)}</div>
              </div>
            </div>
          </Dragger>
        </>
      )}
      {data.length > 0 && <BulkVendorCodeCommercialTable data={data} />}
    </Modal>
  );
};

export default BulkVendorCodeCommercialModalForm;
