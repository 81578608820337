import { Button, Popconfirm } from 'antd';
import React from 'react';

import type { CmsSavePageContentModel } from './../../../models/cms/CmsSavePageContentModel';
import usePublishCmsPageContent from './../../../hooks/services/cms/usePublishCmsPageContent';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';

type Props = {
  data: CmsSavePageContentModel;
  isConfirmRequired?: boolean;
  isDisabled?: boolean;
};

const EnhancedButton = ButtonDisabledWithInsufficientPermission(Button);

export default function CmsPagePublishButton({
  data,
  isConfirmRequired = true,
  isDisabled = false,
}: Props) {
  const { publish, isError, isLoading, isSuccess } = usePublishCmsPageContent();
  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The page was successfully published.`,
  });

  return (
    <>
      {isConfirmRequired && (
        <Popconfirm
          title={`Are you sure you want to publish this page?`}
          onConfirm={() => publish(data)}
        >
          <EnhancedButton disabled={isDisabled} type="primary" loading={isLoading}>
            Publish
          </EnhancedButton>
        </Popconfirm>
      )}
      {!isConfirmRequired && (
        <EnhancedButton
          type="primary"
          disabled={isDisabled}
          onClick={() => publish(data)}
          loading={isLoading}
        >
          Publish
        </EnhancedButton>
      )}
    </>
  );
}
