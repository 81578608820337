import { Checkbox, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import type { CmsCollectionEntryModel } from './../../../../models/cms/collections/CmsCollectionEntryModel';
import LocaleSelector from '../../../shared/LocaleSelector';
import { REQUIRED_RULE } from '../../../../constants/formRules';
import useStatusNotifications from '../../../../hooks/shared/useStatusNotifications';
import { useUpdateCmsCollectionEntry } from '../../../../hooks/services/cms/collections/useUpdateCmsCollectionEntry';

const FORM_LAYOUT = {
  labelCol: { span: 6 },
};

type Props = {
  initialData: CmsCollectionEntryModel;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
};

export const CmsCollectionEntryModalForm = ({ initialData, isShown, setIsShown }: Props) => {
  const { update, isLoading, isSuccess, isError } = useUpdateCmsCollectionEntry();
  const [isChecked, setIsChecked] = useState(false);
  const [form] = Form.useForm();
  const onCancel = React.useCallback(() => {
    if (isLoading) {
      return;
    }

    form.resetFields();
    setIsShown(false);
  }, [isLoading, form, setIsShown]);

  const onSubmit = React.useCallback(async () => {
    const collectionEntry = {
      id: initialData.id,
      collectionId: initialData.collectionId,
      ...form.getFieldsValue(['name', 'localeId']),
    };

    try {
      await form.validateFields();

      update(collectionEntry, { onSuccess: onCancel });

      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [form, initialData.collectionId, initialData.id, onCancel, update]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: 'The collection was successfully updated',
  });

  // Keep the formInstance initialValues in sync with the initialData props
  useEffect(() => {
    isShown && form.setFieldsValue(initialData);
  }, [initialData, isShown, form]);

  return (
    <Modal
      okButtonProps={{ loading: isLoading, disabled: !isChecked }}
      onOk={onSubmit}
      onCancel={onCancel}
      destroyOnClose={true}
      title={'Update Collection Entry'}
      open={isShown}
    >
      <Form {...FORM_LAYOUT} form={form} initialValues={initialData}>
        <Form.Item label="Name" name="name" rules={[REQUIRED_RULE]}>
          <Input maxLength={128} />
        </Form.Item>

        <LocaleSelector />
        <Form.Item
          wrapperCol={{
            offset: 6,
            span: 16,
          }}
        >
          <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
            I understand that changing the <b>Locale</b> can impact production pages.
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
