import React, { useState } from 'react';
import { Button, Row, Table } from 'antd';

import type { $RuleModel } from '../../../models/rules/RuleModel';
import PageHeader from '../../shared/PageHeader';
import RuleDeleteConfirmModal from '../../rules/RuleDeleteConfirmModal';
import RuleModalForm from './../../rules/RuleModalForm';
import Section from '../../shared/Section';
import useRules from './../../../hooks/services/rules/useRules';
import useRulesTableSchema from './useRulesTableSchema';
import TableWithActionsColumn from '../../hocs/tables/TableWithActionsColumn';
import TableWithHeader from '../../hocs/tables/TableWithHeader';

const ActionableTableWithHeader = TableWithHeader(TableWithActionsColumn(Table));

const RulesPage = () => {
  const { data, isError, isLoading } = useRules();
  const [selectedRule, setSelectedRule] = useState<$RuleModel | null>(null);
  const [isUpdateModalShown, setIsUpdateModalShown] = useState(false);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const columns = useRulesTableSchema({
    data,
    updateAction: (record) => {
      setSelectedRule(record);
      setIsUpdateModalShown(true);
    },
    deleteAction: (record) => {
      setSelectedRule(record);
      setIsDeleteModalShown(true);
    },
  });

  const tableHeader = (
    <Row key="actionBar" justify="end">
      <Button
        type="primary"
        onClick={() => {
          setSelectedRule(null);
          setIsUpdateModalShown(true);
        }}
      >
        Create Rule
      </Button>
    </Row>
  );

  return (
    <div className="rulesPageRoot">
      <PageHeader title="Rules" />
      <Section
        errorMessage="An error occurred while attempting to load the data for this table."
        isError={isError}
        isLoading={isLoading}
      >
        <ActionableTableWithHeader
          header={tableHeader}
          className="marginTop16"
          bordered
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey="id"
          size="small"
        />
      </Section>
      <RuleModalForm
        initialData={selectedRule}
        isShown={isUpdateModalShown}
        setIsShown={setIsUpdateModalShown}
      />
      <RuleDeleteConfirmModal
        selectedRule={selectedRule}
        isShown={isDeleteModalShown}
        onCancel={() => setIsDeleteModalShown(false)}
        onSubmit={() => setIsDeleteModalShown(false)}
      />
    </div>
  );
};

export default RulesPage;
