import React, { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Badge, Button, Divider, Dropdown, Menu, Row, Space, Table } from 'antd';
import type { MenuProps } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type {
  TVendorCodeCommercial,
  TVendorCodeDirectMail,
  TVendorCodesInternet,
} from '../../../models/vendor/VendorCodeModels';

import BulkVendorCodeCommercialModalForm from './../../vendor/forms/BulkVendorCodeCommercialModalForm';
import ROUTES from '../../../constants/routes';
import COLORS from '../../../constants/colors';

import type { TTableColumn } from './../../../utils/tableUtils';
import VendorCodeCommercialModalForm from './../../vendor/forms/VendorCodeCommercialModalForm';
import VendorCodeDirectMailModalForm from './../../vendor/forms/VendorCodeDirectMailModalForm';
import VendorCodeInternetModalForm from './../../vendor/forms/VendorCodeInternetModalForm';
import { VendorCodeType } from '../../../enums/vendor/VendorCodeTypeEnum';
import VendorCodeTypeText from '../displays/VendorCodeTypeText';
import { getBooleanDisplayValue } from './../../../utils/displayUtils';
import { getVendorCodeTypeDisplayValue } from './../../../utils/displayUtils';
import TableWithActionsColumn from '../../hocs/tables/TableWithActionsColumn';
import TableWithHeader from '../../hocs/tables/TableWithHeader';
import TextSearchColumnFilter from '../../shared/columnFilters/TextSearchColumnFilter';

import { uniqBy } from 'lodash';

import './../../../css/vendor/tables/VendorCodesTable.css';

type Props = {
  data: Array<TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet>;
  isLoading?: boolean;
  vendorId?: number;
};

const ActionableTableWithHeader = TableWithHeader(TableWithActionsColumn(Table));

const VendorCodesTable = ({ data, isLoading = false, vendorId }: Props) => {
  // Disabling Direct Mail for now. Remove this following line to include them again.
  data = data.filter((vendorCode) => vendorCode.type !== VendorCodeType.DIRECT_MAIL);
  // --

  const navigate = useNavigate();
  const shouldShowVendorName = !vendorId;

  const [selectedVendorCode, setSelectedVendorCode] = useState<any>(null);
  const [isModalShown, setIsModalShown] = useState<VendorCodeType | boolean>(false);
  const [isBulkUpdateModalShown, setIsBulkUpdateModalShown] = useState(false);
  const [filteredDataLength, setFilteredDataLength] = useState<number>(0);

  const onUpdate = useCallback(
    (record: TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet) => {
      setSelectedVendorCode(record);
      setIsModalShown(record.type);
    },
    [],
  );

  const columns = useMemo(
    () => getColumns(data, shouldShowVendorName, onUpdate),
    [data, onUpdate, shouldShowVendorName],
  );

  const onCreate = useCallback(({ key }) => {
    setSelectedVendorCode(null);
    setIsModalShown(key);
  }, []);
  const createMenu = getCreateMenu(onCreate);

  const getFooter = (
    vendorCodes: Array<TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet>,
  ) => {
    const countOfCommercialVendorCodes = vendorCodes.filter(
      (vendorCode) => vendorCode.type === VendorCodeType.COMMERCIAL,
    ).length;
    const countOfDirectMailVendorCodes = vendorCodes.filter(
      (vendorCode) => vendorCode.type === VendorCodeType.DIRECT_MAIL,
    ).length;
    const countOfInternetVendorCodes = vendorCodes.filter(
      (vendorCode) => vendorCode.type === VendorCodeType.INTERNET,
    ).length;
    const countOfAllVendorCodes = vendorCodes.length;

    return (
      <div className="vendorCodesTableFooter">
        <Space split={<Divider type="vertical" />}>
          {countOfCommercialVendorCodes > 0 && (
            <Badge
              color={COLORS.VENDORS_TFN_PILL}
              text={`${getVendorCodeTypeDisplayValue(VendorCodeType.COMMERCIAL)}
              (${countOfCommercialVendorCodes})`}
            />
          )}
          {countOfDirectMailVendorCodes > 0 && (
            <Badge
              color={COLORS.VENDORS_DIRECT_MAIL_PILL}
              text={`${getVendorCodeTypeDisplayValue(VendorCodeType.DIRECT_MAIL)}
              (${countOfDirectMailVendorCodes})`}
            />
          )}
          {countOfInternetVendorCodes > 0 && (
            <Badge
              color={COLORS.VENDORS_INTERNET_PILL}
              text={`${getVendorCodeTypeDisplayValue(VendorCodeType.INTERNET)}
              (${countOfInternetVendorCodes})`}
            />
          )}
          {countOfAllVendorCodes > 0 && <span>Total ({countOfAllVendorCodes})</span>}
        </Space>
      </div>
    );
  };

  const tableHeader = (
    <Row key="actionBar" justify="end">
      <Space>
        {/* removing bulk button temporarly */}
        {/* <Button
          type="primary"
          onClick={() => {
            setIsBulkUpdateModalShown(true);
          }}
        >
          Bulk Update
        </Button> */}
        <Dropdown overlay={createMenu}>
          <Button type="primary">Create Vendor Code</Button>
        </Dropdown>
      </Space>
    </Row>
  );

  return (
    <div className="vendorCodesTableRoot vendorMarginTopOffset20">
      <ActionableTableWithHeader
        header={tableHeader}
        bordered
        columns={columns}
        onChange={(pagination, filters, sorter, extra) => {
          setFilteredDataLength(extra.currentDataSource.length);
        }}
        dataSource={data}
        loading={isLoading}
        onRow={(record) => ({
          onDoubleClick: () => navigate(`${ROUTES.VENDOR_CODES}/${record.type}/${record.id}`),
        })}
        size="small"
        rowKey={(record) => `${record.type}-${record.id}`}
        footer={getFooter}
        pagination={{
          hideOnSinglePage: false,
          responsive: true,
          total: filteredDataLength,
          showSizeChanger: true,
          defaultPageSize: 100,
          position: ['topRight', 'bottomRight'],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
      <VendorCodeDirectMailModalForm
        initialData={selectedVendorCode}
        isShown={isModalShown === VendorCodeType.DIRECT_MAIL}
        setIsShown={setIsModalShown}
        vendorId={vendorId}
      />
      <VendorCodeCommercialModalForm
        initialData={selectedVendorCode}
        isShown={isModalShown === VendorCodeType.COMMERCIAL}
        setIsShown={setIsModalShown}
        vendorId={vendorId}
      />
      <BulkVendorCodeCommercialModalForm
        isShown={isBulkUpdateModalShown}
        setIsShown={setIsBulkUpdateModalShown}
      />
      <VendorCodeInternetModalForm
        initialData={selectedVendorCode}
        isShown={isModalShown === VendorCodeType.INTERNET}
        setIsShown={setIsModalShown}
        vendorId={vendorId}
      />
    </div>
  );
};

function getColumns(
  data: Array<TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet>,
  shouldShowVendorName: boolean,
  onUpdate: (record: TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet) => void,
): Array<TTableColumn | null> {
  return [
    {
      title: 'Type',
      dataIndex: 'type',
      render: (value) => <VendorCodeTypeText status={value} />,
      sorter: (a, b) => a.type.localeCompare(b.type),
      filterSearch: true,
      // Disabling Direct Mail for now.
      // Add VendorCodeType.DIRECT_MAIL back to the line below when ready to include again.
      filters: [VendorCodeType.COMMERCIAL, VendorCodeType.INTERNET].map((vcType) => ({
        text: getVendorCodeTypeDisplayValue(vcType),
        value: vcType,
      })),
      onFilter: (
        vcType: VendorCodeType,
        record: TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet,
      ): boolean => record.type === vcType,
    },
    {
      title: 'Code',
      dataIndex: 'legacyVendorId',
      render: (value, row) => (
        <Link to={`${ROUTES.VENDOR_CODES}/${row.type.toLowerCase()}/${row.id}`}>{value}</Link>
      ),
      sorter: (a, b) => a.legacyVendorId.localeCompare(b.legacyVendorId),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.legacyVendorId
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <TextSearchColumnFilter
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      onFilter: (value, record) => {
        return record.name.toString().toLowerCase().includes(value.toString().toLowerCase());
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
        />
      ),
    },
    shouldShowVendorName
      ? {
          title: 'Vendor',
          dataIndex: 'vendorName',
          render: (value, row) => <Link to={`${ROUTES.VENDORS}/${row.vendorId}`}>{value}</Link>,
          sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
          filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <TextSearchColumnFilter
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
            />
          ),
          onFilter: (value, record) => {
            return record.vendorName
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase());
          },
          filterIcon: (filtered) => (
            <SearchOutlined
              style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
            />
          ),
        }
      : null,
    {
      title: 'Tier',
      dataIndex: 'marketingTier',
      render: (value, row) => {
        return row.type === VendorCodeType.INTERNET ? value : '-';
      },
      sorter: (a, b) => a.marketingTier - b.marketingTier,
      filterSearch: true,
      filters: uniqBy(data, 'marketingTier')
        .sort((a, b) => a.marketingTier - b.marketingTier)
        .map((record) => ({
          text: record.marketingTier,
          value: record.marketingTier,
        })),
      onFilter: (
        searchValue: number,
        record: TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet,
      ): boolean => searchValue === record.marketingTier,
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      render: getBooleanDisplayValue,
      sorter: (a, b) => a.isActive - b.isActive,
      filterSearch: true,
      filters: [true, false].map((tfnType) => ({
        text: getBooleanDisplayValue(tfnType),
        value: tfnType,
      })),
      onFilter: (
        isActive: boolean,
        record: TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet,
      ): boolean => record.isActive === isActive,
      defaultFilteredValue: ['true'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => onUpdate(record)}>
            Update
          </Button>
        </div>
      ),
    },
  ];
}

function getCreateMenu(onCreate: (key) => void): any {
  const menuItems: MenuProps['items'] = [
    {
      key: VendorCodeType.COMMERCIAL,
      label: 'TFN Only',
    },
    // Disabling Direct Mail for now. Uncomment code below when ready to include again.
    // {
    //   key: VendorCodeType.DIRECT_MAIL,
    //   label: 'Direct Mail',
    // },
    {
      key: VendorCodeType.INTERNET,
      label: 'Internet',
    },
  ];
  return <Menu onClick={onCreate} items={menuItems} />;
}

export default VendorCodesTable;
