import React, { useEffect } from 'react';
import { Button, Popconfirm } from 'antd';

import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import { pluralize } from '../../../utils/displayUtils';
import useDeleteCmsPages from '../../../hooks/services/cms/useDeleteCmsPages';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';

type Props = {
  data: Array<CmsPageMetadataModel>;
  isDisabled?: boolean;
  onDelete?: () => void;
};

const EnhancedButton = ButtonDisabledWithInsufficientPermission(Button);

const CmsPageArchiveButton = ({ data, isDisabled, onDelete }: Props) => {
  const { deleteMutation, isError, isSuccess, isLoading } = useDeleteCmsPages();
  const recordText = pluralize(data.length, 'this record', {
    plural: 'these records',
  });
  const successRecordText = pluralize(data.length, 'record was', {
    plural: 'records were',
  });

  useEffect(() => {
    isSuccess && onDelete?.();
  }, [isSuccess, onDelete]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The ${successRecordText} successfully deleted.`,
  });

  return (
    <Popconfirm
      title={`Are you sure you want to delete ${recordText}?`}
      onConfirm={() => deleteMutation(data.map((item) => item.id))}
    >
      <EnhancedButton type="primary" danger={true} disabled={isDisabled} loading={isLoading}>
        Archive
      </EnhancedButton>
    </Popconfirm>
  );
};

export default CmsPageArchiveButton;
