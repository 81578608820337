import { Button, Popconfirm } from 'antd';

import type { CmsReleaseModel } from '../../../../models/cms/releases/CmsReleaseModel';
import React from 'react';
import { pluralize } from '../../../../utils/displayUtils';
import useDeleteCmsReleases from '../../../../hooks/services/cms/releases/useDeleteCmsReleases';
import useStatusNotifications from '../../../../hooks/shared/useStatusNotifications';

type Props = {
  data: Array<CmsReleaseModel>;
  isDisabled?: boolean;
};

const CmsReleaseArchiveButton = ({ data, isDisabled }: Props) => {
  const { deleteMutation, isError, isSuccess, isLoading } = useDeleteCmsReleases();

  const recordText = pluralize(data.length, 'this record', {
    plural: 'these records',
  });
  const successRecordText = pluralize(data.length, 'record was', {
    plural: 'records were',
  });

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The ${successRecordText} successfully archived.`,
  });

  return (
    <Popconfirm
      title={`Are you sure you want to delete ${recordText}?`}
      onConfirm={() => deleteMutation(data.map((item) => item.id))}
    >
      <Button type="primary" danger={true} disabled={isDisabled} loading={isLoading}>
        Archive
      </Button>
    </Popconfirm>
  );
};

export default CmsReleaseArchiveButton;
