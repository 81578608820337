import { Button } from 'antd';
import React from 'react';

type Props = {
  buttonText: string;
  buttonIcon: React.ReactNode;
  styleType?: 'icon' | 'primary';
  isDisabled?: boolean;
  onClick?: () => void;
};

export const IconTextButton = ({
  buttonText,
  buttonIcon,
  styleType = 'icon',
  isDisabled,
  onClick,
}: Props) => {
  return styleType === 'icon' ? (
    <Button shape="round" size="small" disabled={isDisabled} onClick={onClick}>
      {buttonIcon}
    </Button>
  ) : (
    <Button type="primary" disabled={isDisabled} onClick={onClick}>
      {buttonText}
    </Button>
  );
};
