import type { CmsPageGroupMetadataModel } from '../models/cms/CmsPageGroupMetadataModel';
import type { CmsPageGroupModel } from '../models/cms/CmsPageGroupModel';
import type { CreatePageGroupRequestModel } from './types/CreatePageGroupRequestModel';
import WebAPIClient from './api/WebAPIClient';

const PATH = `cms/pagegroups`;

export const getLastModifiedPageGroups = async (
  limit = 10,
): Promise<Array<CmsPageGroupMetadataModel>> => {
  const response = await WebAPIClient.get(`${PATH}`, { limit: limit.toString() });

  return response?.data ?? [];
};

export const getPageGroupById = async (id: number): Promise<CmsPageGroupModel> => {
  const response = await WebAPIClient.get(`${PATH}/${id}`);
  return response?.data;
};

export const getPageGroups = async (): Promise<Array<CmsPageGroupMetadataModel>> => {
  const response = await WebAPIClient.get(PATH);

  return response?.data ?? [];
};

export const createPageGroup = async (
  request: CreatePageGroupRequestModel,
): Promise<CmsPageGroupMetadataModel> => {
  const response = await WebAPIClient.post(PATH, request);

  return response?.data;
};

export const updatePageGroup = async (
  cmsPageGroup: CmsPageGroupMetadataModel,
): Promise<CmsPageGroupMetadataModel> => {
  const response = await WebAPIClient.patch(PATH, cmsPageGroup);

  return response?.data;
};

// TODO: Intoduce Bulk Delete Gateway Endpoint
export const deletePageGroups = async (cmsPageGroupIds: Array<number>): Promise<void> => {
  await Promise.all(cmsPageGroupIds.map((id) => WebAPIClient.delete(`${PATH}/${id}`)));
};
