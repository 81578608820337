import React from 'react';
import { Button } from 'antd';
import type { PromoCodeDiscountModel } from '../../../models/promoCodes/PromoCodeDiscountModel';
import { PromoCodeDiscountType } from '../../../enums/promoCodes/PromoCodeDiscountTypeEnum';
import ButtonHiddenWithInsufficientPermission from '../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  promoCodeId: number;
  newRowIndex: number;
  isDisabled?: boolean;
  onClick?: (discount: PromoCodeDiscountModel) => void;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const PromoCodeDiscountCreateButton = ({
  promoCodeId,
  newRowIndex,
  isDisabled,
  onClick,
}: Props) => {
  const onNewDiscountClick = (): void => {
    const newRow: PromoCodeDiscountModel = {
      id: 0,
      promoCodeId: promoCodeId,
      newRowIndex: newRowIndex,
      discountType: PromoCodeDiscountType.FLAT_AMOUNT,
      discount: 0,
      status: 0,
      startDate: '',
      endDate: '',
    };
    onClick?.(newRow);
  };

  isDisabled ??= false;

  return (
    <EnhancedButton type="primary" onClick={onNewDiscountClick} disabled={isDisabled}>
      New Discount
    </EnhancedButton>
  );
};

export default PromoCodeDiscountCreateButton;
