import React from 'react';
import { Select } from 'antd';
import { marketingTier } from '../../../enums/vendor/VendorCodeMarketingTierArray';

type Props = {
  value?: number;
  onChange?: (e: any) => void;
};

const MarketingTierSelect = ({ value, onChange }: Props) => {
  React.useEffect(() => onChange && onChange(value), [value, onChange]);

  return (
    <Select onChange={onChange} className="vendorCodeMarketingTier" value={value}>
      {marketingTier.Ndata.map((item, index) => (
        <Select.Option key={index} value={index}>
          {item}
        </Select.Option>
      ))}
    </Select>
  );
};

export default MarketingTierSelect;
