import { Card, Collapse, Descriptions, Divider, Row, Typography } from 'antd';
import React, { useMemo } from 'react';
import { getDateDisplayValue, getModifiedBy } from '../../../utils/displayUtils';

import CmsPageContentDiffEditor from '../../cms/displays/CmsPageContentDiffEditor';
import CmsPageContentEnvironmentText from '../../cms/displays/CmsPageContentEnvironmentText';
import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import CmsReleaseEditButton from '../../cms/buttons/releases/CmsReleaseEditButton';
import { Link } from 'react-router-dom';
import PageHeader from '../../shared/PageHeader';
import ROUTES from '../../../constants/routes';
import Section from '../../shared/Section';
import useCmsRelease from '../../../hooks/services/cms/releases/useCmsRelease';
import { useParams } from 'react-router-dom';

export const CmsReleaseDetailsPage = () => {
  const { releaseId } = useParams();
  const { data, isLoading, isError } = useCmsRelease(+releaseId!);
  const { id, name, releaseContents } = data ?? {};

  const affectedPages = useMemo(() => {
    return releaseContents?.map((releaseContent) => releaseContent.currentPageContentVersion.page);
  }, [releaseContents]);

  const getPageCollapsePanelHeader = (page: CmsPageMetadataModel) => (
    <Row justify="space-between">
      {page.slug}
      <Link to={`${ROUTES.PAGE_GROUPS}/${page.group.id}/page/${page.id}`} target="_blank">
        View Page
      </Link>
    </Row>
  );

  return (
    <div className="cmsReleaseDetailsPageRoot">
      <PageHeader
        title={name ?? 'loading...'}
        breadcrumbRoutes={
          id && name
            ? [
                { breadcrumbName: 'Home', path: ROUTES.CMS },
                { breadcrumbName: 'Releases', path: ROUTES.CMS_RELEASES },
                { breadcrumbName: name, path: `${ROUTES.CMS_RELEASES}/${id}` },
              ]
            : undefined
        }
        extra={[
          <Row key="actionBar" justify="end">
            <CmsReleaseEditButton affectedPages={affectedPages} release={data} />
          </Row>,
        ]}
      />
      <Section isLoading={isLoading} isError={isError}>
        {data && affectedPages && releaseContents && (
          <>
            <Descriptions
              key={1}
              bordered={true}
              column={3}
              labelStyle={{ fontWeight: 'bold' }}
              size="small"
            >
              <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
              <Descriptions.Item label="Source Environment">
                <CmsPageContentEnvironmentText
                  environment={releaseContents[0]?.currentEnvironmentId ?? 0}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Updated By">
                {getModifiedBy(data?.updatedBy)}
              </Descriptions.Item>
              <Descriptions.Item label="Target Date">
                {getDateDisplayValue(data?.targetDate)}
              </Descriptions.Item>
              <Descriptions.Item label="Destination Environment">
                <CmsPageContentEnvironmentText
                  environment={releaseContents[0]?.targetEnvironmentId ?? 0}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Updated Date">
                {getDateDisplayValue(data?.updatedDate)}
              </Descriptions.Item>
            </Descriptions>

            <div className="paddingTop24" />
            <Divider orientation="left">Affected Pages ({affectedPages.length})</Divider>

            <Collapse accordion>
              {affectedPages.map((page, i) => (
                <Collapse.Panel header={getPageCollapsePanelHeader(page)} key={i}>
                  <Card title="Notes">
                    <Typography.Paragraph>{data.releaseContents[i].notes}</Typography.Paragraph>
                  </Card>
                  <Divider />
                  <CmsPageContentDiffEditor
                    // eslint-disable-next-line max-len
                    originalContentTitle={`Current Content - V${data.releaseContents[i].currentPageContentVersion.version}`}
                    originalContent={data.releaseContents[i].currentPageContentVersion.content}
                    // eslint-disable-next-line max-len
                    newContentTitle={`Target Content - V${data.releaseContents[i].targetPageContentVersion.version}`}
                    newContent={data.releaseContents[i].targetPageContentVersion.content}
                  />
                </Collapse.Panel>
              ))}
            </Collapse>
          </>
        )}
      </Section>
    </div>
  );
};
