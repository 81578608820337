import React from 'react';
import { Space, Table } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { last, sortBy } from 'lodash';

import CmsCollectionSchemaDataArchivedButton from '../buttons/collections/CmsCollectionSchemaDataArchivedButton';
import CmsCollectionSchemaDataCreateButton from '../buttons/collections/CmsCollectionSchemaDataCreateButton';
import CmsCollectionSchemaDataEditButton from '../buttons/collections/CmsCollectionSchemaDataEditButton';
import type { CmsCollectionSchemaDataModel } from './../../../models/cms/collections/schemaData/CmsCollectionSchemaDataModel';
import { getBooleanDisplayValue } from './../../../utils/displayUtils';
import { getCmsCollectionSchemaDataTypeDisplayName } from './../../../utils/cmsUtils';
import TableWithHeader from '../../hocs/tables/TableWithHeader';
import TableWithActionsColumn from '../../hocs/tables/TableWithActionsColumn';

type Props = {
  collectionId: number;
  data?: Array<CmsCollectionSchemaDataModel>;
  isLoading?: boolean;
};

const ActionableTableWithHeader = TableWithHeader(TableWithActionsColumn(Table));

const getColumns = (): Array<ColumnType<CmsCollectionSchemaDataModel>> => {
  return [
    {
      title: 'Field Slug',
      dataIndex: 'slug',
    },
    {
      title: 'Field Name',
      dataIndex: 'fieldName',
    },
    {
      title: 'Field Description',
      dataIndex: 'fieldDescription',
    },
    {
      title: 'Is Required',
      dataIndex: 'isRequired',
      render: getBooleanDisplayValue,
    },
    {
      title: 'Field Type',
      dataIndex: 'schemaDataType',
      render: getCmsCollectionSchemaDataTypeDisplayName,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <Space>
          <CmsCollectionSchemaDataEditButton schemaData={record} />
          <CmsCollectionSchemaDataArchivedButton schemaData={record} />
        </Space>
      ),
    },
  ];
};

const getHeader = (collectionId: number, nextOrder: number) => (
  <Space>
    <CmsCollectionSchemaDataCreateButton collectionId={collectionId} nextOrder={nextOrder} />
  </Space>
);

const CmsCollectionSchemaDataTable = ({ collectionId, data, isLoading = false }: Props) => {
  const sortedData = sortBy(data, ['order']);
  const nextOrder = (last(sortedData)?.order ?? 0) + 1;

  return (
    <ActionableTableWithHeader
      header={getHeader(collectionId, nextOrder)}
      bordered
      columns={getColumns()}
      dataSource={sortedData}
      pagination={false}
      loading={isLoading}
      size="small"
      rowKey="id"
    />
  );
};

export default CmsCollectionSchemaDataTable;
