import React, { useCallback } from 'react';

import { Button } from 'antd';
import ROUTES from '../../../../constants/routes';
import { useCmsCollectionEntryForm } from '../../../../hooks/services/cms/collections/useCmsCollectionEntryForm';
import { useCreateCmsCollectionEntry } from '../../../../hooks/services/cms/collections/useCreateCmsCollectionEntry';
import { useNavigate } from 'react-router-dom';
import useStatusNotifications from '../../../../hooks/shared/useStatusNotifications';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  isDisabled: boolean;
  saveAsDraft: boolean;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

export const CmsCollectionEntryFormCreateButton = ({ saveAsDraft, isDisabled = false }: Props) => {
  const navigate = useNavigate();
  const { create, isLoading, isError, isSuccess } = useCreateCmsCollectionEntry();
  const { form, getCollectionEntry } = useCmsCollectionEntryForm();

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      create(getCollectionEntry(saveAsDraft), {
        onSuccess: (data) => {
          navigate(`${ROUTES.CMS_COLLECTIONS}/${data.collectionId}/entries/${data.id}`);
        },
      });

      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [create, form, getCollectionEntry, navigate, saveAsDraft]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The collection item was successfully created.`,
  });

  return (
    <EnhancedButton type="primary" disabled={isDisabled} loading={isLoading} onClick={onSubmit}>
      {saveAsDraft ? 'Save' : 'Publish'}
    </EnhancedButton>
  );
};
