import './../../../css/promoCodes/forms/PromoCodeDetailModalForm.css';

import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Col, Form, Input, Modal, Row, Switch } from 'antd';

import type { PromoCodeDiscountModel } from '../../../models/promoCodes/PromoCodeDiscountModel';
import { PromoCodeDiscountType } from '../../../enums/promoCodes/PromoCodeDiscountTypeEnum';
import PromoCodeDiscountsTable from '../tables/PromoCodeDiscountsTable';
import type { PromoCodeModel } from '../../../models/promoCodes/PromoCodeModel';
import PromoCodeStatusText from '../displays/PromoCodeStatusText';
import PromoCodeDiscountCreateButton from '../buttons/PromoCodeDiscountCreateButton';
import PromoCodeSaveButton from '../buttons/PromoCodeSaveButton';
import { getErrorDisplayValues } from './../../../utils/displayUtils';

type Props = {
  initialData?: PromoCodeModel;
  isVisible: boolean;
  closeFn: () => void;
};

const PromoCodeDetailModalForm = ({ initialData, isVisible, closeFn }: Props) => {
  // States
  const [newRows, setNewRows] = useState<Array<PromoCodeDiscountModel>>([]);
  const [fieldsUpdated, setFieldsUpdated] = useState<Array<string>>([]);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [form] = Form.useForm();

  // Validation Rules
  const NO_SPECIAL_CHARS_REGEX = new RegExp('^[A-Za-z0-9]+$');
  const REQUIRED_RULE = { message: 'This field is required', required: true };
  const RULE_NO_SPECIAL_CHARS = {
    pattern: NO_SPECIAL_CHARS_REGEX,
    message: 'Code cannot contain special characters.',
  };

  // Default Values
  const DEFAULT_PROMO_CODE = {
    id: 0,
    name: '',
    code: '',
    isEnabled: true,
    status: 0,
    discounts: [],
  };

  const DEFAULT_FORM_DATA = {
    id: 0,
    promoCodeId: '',
    discountType: `${PromoCodeDiscountType.FLAT_AMOUNT}`,
    discount: '',
    name: initialData?.name ?? DEFAULT_PROMO_CODE.name,
  };

  // Effects
  useEffect(() => {
    form.setFieldsValue(initialData);
  }, [initialData, form]);

  // Events
  const trackChanges = (e, fieldName, oldValue) => {
    if (fieldName === 'name') {
      if (e.target.value !== oldValue[fieldName]) {
        addItemToFieldsUpdated(fieldName);
      } else {
        removeItemFromFieldsUpdated(fieldName);
      }
      return;
    }

    if (fieldName === 'isEnabled') {
      if (e !== oldValue[fieldName]) {
        addItemToFieldsUpdated(fieldName);
      } else {
        removeItemFromFieldsUpdated(fieldName);
      }
    }
  };

  const validateForm = async () => {
    const name = form.getFieldValue('name');
    const code = form.getFieldValue('code');
    const codeIsValid = code && code.match(NO_SPECIAL_CHARS_REGEX);

    if (name && code && codeIsValid) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  };

  const resetState = useCallback(() => {
    setNewRows([]);
    setFieldsUpdated([]);
    setErrors([]);
    form.resetFields();
  }, [form]);

  const onCancel = useCallback(() => {
    resetState();
    closeFn();
  }, [resetState, closeFn]);

  // Components
  const getErrorBanners = (errors: Array<string>) => {
    if (!errors || errors.length === 0) return null;
    return errors.map((error, index) => {
      return (
        <Alert
          className="promoCodeDetailModalAlert"
          type="error"
          message={error}
          showIcon
          closable
          onClose={() => removeErrorFromErrorsList(error)}
          key={index}
        />
      );
    });
  };

  // Helpers
  const getPromoCodeDiscounts = (): Array<PromoCodeDiscountModel> => {
    const discounts = initialData?.discounts || [];
    const unsavedRows = newRows || [];
    return [...discounts, ...unsavedRows];
  };

  const addItemToFieldsUpdated = (itemToAdd: string): void => {
    const index = fieldsUpdated.indexOf(itemToAdd);
    if (index >= 0) return;
    setFieldsUpdated([...fieldsUpdated, itemToAdd]);
  };

  const removeItemFromFieldsUpdated = (itemToRemove: string): void => {
    const index = fieldsUpdated.indexOf(itemToRemove);
    if (index >= 0) {
      const array = [...fieldsUpdated];
      array.splice(index, 1);
      setFieldsUpdated(array);
    }
  };

  const removeErrorFromErrorsList = (errorToRemove: string): void => {
    const index = errors.indexOf(errorToRemove);
    if (index >= 0) {
      const array = [...errors];
      array.splice(index, 1);
      setErrors(array);
    }
  };

  const shouldBeAbleToSave = (promoCode: PromoCodeModel): boolean => {
    return isNewRecord(promoCode) ? isFormValid : fieldsUpdated.length > 0;
  };

  const isNewRecord = (promoCode: PromoCodeModel): boolean => promoCode?.id <= 0;

  const getModalTitle = (promoCode: PromoCodeModel): string => {
    return isNewRecord(promoCode) ? `Promo Code ${promoCode?.code} Details` : 'New Promo Code';
  };

  initialData ??= DEFAULT_PROMO_CODE;

  // Return react node
  return (
    <Modal
      title={<strong>{getModalTitle(initialData)}</strong>}
      className="promoCodeDetailModalRoot"
      onCancel={onCancel}
      open={isVisible}
      width={1000}
      footer={null}
    >
      {getErrorBanners(errors)}
      <Form form={form} layout="vertical" requiredMark={false} initialValues={DEFAULT_FORM_DATA}>
        <Row className="promoCodeDetailsRow">
          <Col span={8} className="promoCodeColumn">
            <Form.Item
              label={<span className="label">Name</span>}
              name="name"
              rules={[REQUIRED_RULE]}
            >
              <Input
                maxLength={50}
                autoFocus
                onChange={async (e) => {
                  trackChanges(e, 'name', initialData);
                  await validateForm();
                }}
              />
            </Form.Item>
          </Col>
          {isNewRecord(initialData) && (
            <Col span={6} className="promoCodeColumn">
              <Form.Item
                label={<span className="label">Code</span>}
                name="code"
                rules={[REQUIRED_RULE, RULE_NO_SPECIAL_CHARS]}
              >
                <Input
                  className="code-input"
                  maxLength={20}
                  onChange={async () => await validateForm()}
                />
              </Form.Item>
            </Col>
          )}
          {!isNewRecord(initialData) && (
            <>
              <Col span={6} className="promoCodeDetailCodeColumn promoCodeColumn">
                <span className="promoCodeLabel label">Code</span>
                <span className="promoCodeValue value">{initialData?.code}</span>
              </Col>
              <Col span={4} className="promoCodeDetailStatusColumn promoCodeColumn">
                <span className="promoCodeStatusLabel label">Status</span>
                <span className="label">
                  <PromoCodeStatusText status={initialData?.status || 0} />
                </span>
              </Col>
            </>
          )}
          <Col span={4} className="promoCodeDetailEnabledColumn promoCodeColumn">
            <Form.Item
              valuePropName="checked"
              label={<span className="label">Enabled</span>}
              name="isEnabled"
            >
              <Switch
                defaultChecked={initialData?.isEnabled ?? false}
                onChange={(e) => trackChanges(e, 'isEnabled', initialData)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="promoCodeDiscountsRow">
          <Col span={24}>
            <PromoCodeDiscountsTable
              discounts={getPromoCodeDiscounts()}
              isNewRecord={isNewRecord(initialData)}
              clearNewRows={() => setNewRows([])}
              onError={(error) => setErrors(getErrorDisplayValues(error))}
            />
          </Col>
        </Row>
        <Row className="promoCodeNewDiscountRow">
          <PromoCodeDiscountCreateButton
            promoCodeId={initialData?.id || 0}
            newRowIndex={newRows.length + 1}
            isDisabled={newRows.length > 0}
            onClick={(newRow: PromoCodeDiscountModel) => {
              setNewRows([...newRows, newRow]);
              form.setFieldsValue({ discount: '', startDate: '', endDate: '' });
            }}
          />
          <PromoCodeSaveButton
            promoCode={initialData}
            isDisabled={!shouldBeAbleToSave(initialData)}
            onCreateSuccess={() => {
              resetState();
              closeFn();
            }}
            onUpdateSuccess={() => {
              resetState();
              closeFn();
            }}
            onError={(error) => setErrors(getErrorDisplayValues(error))}
          />
        </Row>
      </Form>
    </Modal>
  );
};

export default PromoCodeDetailModalForm;
