import type {
  TVendorCodeCommercial,
  TVendorCodeDirectMail,
  TVendorCodesInternet,
  TVendorCodeCommercialUpsertModel,
} from '../models/vendor/VendorCodeModels';
import { VendorCodeType } from '../enums/vendor/VendorCodeTypeEnum';
import WebAPIClient from './api/WebAPIClient';

const PATH = 'VendorCodes';
const PATH_INTERNET = 'VendorCodes/Internet';
const PATH_COMMERCIAL = 'VendorCodes/Commercial';
const PATH_DIRECT_MAIL = 'VendorCodes/DirectMail';

export const getAllVendorCodes = async (): Promise<
  Array<TVendorCodeCommercial | TVendorCodeDirectMail | TVendorCodesInternet>
> => {
  const response = await WebAPIClient.get(PATH);
  if (!response?.data) {
    return [];
  }

  const vendorCodeCommercials = response.data.vendorCodeCommercials.map((commercialCode) => {
    return { type: VendorCodeType.COMMERCIAL, ...commercialCode };
  });

  const vendorCodeDirectMails = response.data.vendorCodeDirectMails.map((directMailCode) => {
    return { type: VendorCodeType.DIRECT_MAIL, ...directMailCode };
  });

  const vendorCodeInternets = response.data.vendorCodeInternets.map((internetCode) => {
    return { type: VendorCodeType.INTERNET, ...internetCode };
  });

  return vendorCodeCommercials.concat(vendorCodeDirectMails, vendorCodeInternets);
};

export const getVendorCodeCommercial = async (
  vendorCodeId: string,
): Promise<TVendorCodeCommercial> => {
  const response = await WebAPIClient.get(`${PATH_COMMERCIAL}/${vendorCodeId}`);

  return response?.data;
};

export const createVendorCodeCommercial = async (
  vendorCodeCommercial: TVendorCodeCommercial,
): Promise<TVendorCodeCommercial | undefined> => {
  const response = await WebAPIClient.post(PATH_COMMERCIAL, vendorCodeCommercial);
  if (!response?.data) {
    return undefined;
  }
  return { type: VendorCodeType.COMMERCIAL, ...response?.data };
};

export const updateVendorCodeCommercial = async (
  vendorCodeCommercial: TVendorCodeCommercial,
): Promise<TVendorCodeCommercial | undefined> => {
  const response = await WebAPIClient.put(PATH_COMMERCIAL, vendorCodeCommercial);
  if (!response?.data) {
    return undefined;
  }
  return { type: VendorCodeType.COMMERCIAL, ...response?.data };
};

export const createVendorCodeDirectMail = async (
  vendorCodeDirectMail: TVendorCodeDirectMail,
): Promise<TVendorCodeDirectMail | undefined> => {
  const response = await WebAPIClient.post(PATH_DIRECT_MAIL, vendorCodeDirectMail);
  if (!response?.data) {
    return undefined;
  }
  return { type: VendorCodeType.DIRECT_MAIL, ...response?.data };
};

export const updateVendorCodeDirectMail = async (
  vendorCodeDirectMail: TVendorCodeDirectMail,
): Promise<TVendorCodeDirectMail | undefined> => {
  const response = await WebAPIClient.put(PATH_DIRECT_MAIL, vendorCodeDirectMail);
  if (!response?.data) {
    return undefined;
  }
  return { type: VendorCodeType.DIRECT_MAIL, ...response?.data };
};

export const getVendorCodesInternet = async (): Promise<Array<TVendorCodesInternet>> => {
  const response = await WebAPIClient.get(PATH_INTERNET);

  return response?.data ?? [];
};

export const getVendorCodeInternet = async (
  vendorCodeId: string,
): Promise<TVendorCodesInternet> => {
  const response = await WebAPIClient.get(`${PATH_INTERNET}/${vendorCodeId}`);

  return response?.data;
};

export const createVendorCodeInternet = async (
  vendorCodeInternet: TVendorCodesInternet,
): Promise<TVendorCodesInternet | undefined> => {
  const response = await WebAPIClient.post(PATH_INTERNET, vendorCodeInternet);
  if (!response?.data) {
    return undefined;
  }
  return { type: VendorCodeType.INTERNET, ...response?.data };
};

export const updateVendorCodeInternet = async (
  vendorCodeInternet: TVendorCodesInternet,
): Promise<TVendorCodesInternet | undefined> => {
  const response = await WebAPIClient.put(PATH_INTERNET, vendorCodeInternet);
  if (!response?.data) {
    return undefined;
  }
  return { type: VendorCodeType.INTERNET, ...response?.data };
};

export const parseVendorCodesCommercialCsvFile = async (
  file: File,
): Promise<Array<TVendorCodeCommercialUpsertModel>> => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await WebAPIClient.post(`${PATH_COMMERCIAL}/parsecsvfile`, formData, false, {
    'content-type': 'multipart/form-data',
  });

  return response?.data;
};

export const upsertVendorCodesCommercial = async (
  vendorCodes: Array<TVendorCodeCommercialUpsertModel>,
): Promise<void> => {
  const response = await WebAPIClient.post(`${PATH_COMMERCIAL}/bulkupsert`, vendorCodes);
  return response?.data;
};
