import { Button, Col, Form, Input, Row, Space, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import React from 'react';
import { convertTitleToSlug } from '../../../../utils/cmsUtils';

type Props = {
  isNew: boolean;
};

export const CmsSchemaDataSelectItems = ({ isNew }: Props) => {
  const form = Form.useFormInstance();
  return (
    <Form.List
      name="selectValues"
      rules={[
        {
          validator: (_, value) =>
            value?.length > 0
              ? Promise.resolve()
              : Promise.reject(new Error('A select value is required')),
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <Row>
            <Col span={6}>
              <Typography.Paragraph className="marginRight8 textAlignRight">
                Select Values:
              </Typography.Paragraph>
            </Col>
            <Col span={18}>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    rules={[{ required: true, message: 'A select name is required' }]}
                  >
                    <Input
                      placeholder="Name"
                      onChange={(e) => {
                        if (!isNew) {
                          return;
                        }
                        const data = form.getFieldValue('selectValues');
                        data[name] = {
                          ...data[name],
                          value: convertTitleToSlug(e.currentTarget.value),
                        };
                        form.setFieldValue('selectValues', data);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'value']}
                    rules={[{ required: true, message: 'A select value is required' }]}
                  >
                    <Input placeholder="Value" />
                  </Form.Item>
                  {isNew && <MinusCircleOutlined onClick={() => remove(name)} />}
                </Space>
              ))}
              {isNew && (
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add select value
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              )}
            </Col>
          </Row>
        </>
      )}
    </Form.List>
  );
};
