import type { CmsQueryResponse } from './../../../../models/cms/CmsResponseModels';
import type { CmsReleaseModel } from './../../../../models/cms/releases/CmsReleaseModel';
import QUERYKEYS from '../../../../constants/queryKeys';
import config from '../../../../config/';
import { getCmsReleases } from '../../../../services/CmsReleaseService';
import { useQuery } from 'react-query';

export default function useCmsReleases(): CmsQueryResponse<Array<CmsReleaseModel>> {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.RELEASES, async () => getCmsReleases(), {
    staleTime: config.cmsIdleTimeoutInMinutes * 60 * 1000,
  });

  return {
    data,
    isError,
    isLoading,
  };
}
