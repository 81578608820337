import QUERYKEYS from '../../../constants/queryKeys';
import type { TQueryModel } from '../../../utils/hookUtils';
import type { TVendorCodeCommercial } from './../../../models/vendor/VendorCodeModels';
import { getVendorCodeCommercial } from './../../../services/VendorCodesService';
import { useQuery } from 'react-query';

function useVendorCodeCommercial(vendorCodeId: string): TQueryModel<TVendorCodeCommercial> {
  const { isLoading, isError, data } = useQuery(
    [QUERYKEYS.VENDOR_CODES_COMMERCIAL, vendorCodeId],
    () => getVendorCodeCommercial(vendorCodeId),
  );

  return {
    data,
    isError,
    isLoading,
  };
}

export default useVendorCodeCommercial;
