export function getListOfDaysBetweenDates(
  startDate: string | undefined,
  endDate: string | undefined,
): Array<string> {
  const days: Array<string> = [];
  if (startDate && endDate) {
    for (let day = new Date(startDate); day <= new Date(endDate); day.setDate(day.getDate() + 1)) {
      day.setUTCHours(0, 0, 0, 0);
      days.push(day.toISOString());
    }
  }
  return days;
}
