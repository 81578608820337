import { useMutation, useQueryClient } from 'react-query';

import type { CmsCollectionModel } from '../../../../models/cms/collections/CmsCollectionModel';
import type { CmsUpdateMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { updateCollection } from '../../../../services/CmsCollectionService';

function useUpdateCmsCollection(): CmsUpdateMutationResponse<CmsCollectionModel> {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(updateCollection, {
    onSuccess: (_data, collection) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === QUERYKEYS.COLLECTION && query.queryKey[1] === collection.id,
      }),
  });

  return {
    update: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useUpdateCmsCollection;
