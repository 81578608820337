import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Space } from 'antd';
import routes from '../../../constants/routes';
import PageHeader from '../../shared/PageHeader';
import Section from '../../shared/Section';

import CmsCollectionArchivedButton from '../../cms/buttons/collections/CmsCollectionArchiveButton';
import CmsCollectionEditButton from '../../cms/buttons/collections/CmsCollectionEditButton';
import { CmsCollectionEntryCreateButton } from '../../cms/buttons/collections/CmsCollectionEntryCreateButton';
import CmsCollectionSchemaDataTable from '../../cms/tables/CmsCollectionSchemaDataTable';
import useCmsCollection from '../../../hooks/services/cms/collections/useCmsCollection';
import ButtonHiddenWithInsufficientPermission from '../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const CmsCollectionEditPage = () => {
  const { collectionId } = useParams();
  const { data, isLoading, isError } = useCmsCollection(Number(collectionId));
  const navigate = useNavigate();
  const { id, slug, title, schemaData } = data ?? {};
  return (
    <div>
      <PageHeader
        title={title ?? 'loading...'}
        subTitle={
          <>
            {slug ?? ''}
            <span className="marginLeft8">
              {data && <CmsCollectionEditButton collection={data} isDisabled={!data} />}
            </span>
          </>
        }
        breadcrumbRoutes={
          id && slug && title
            ? [
                { breadcrumbName: 'Home', path: routes.CMS },
                { breadcrumbName: 'Collections', path: routes.CMS_COLLECTIONS },
                { breadcrumbName: slug, path: `${routes.CMS_COLLECTIONS}/${id}/schema` },
              ]
            : undefined
        }
        extra={[
          <Row key="actionBar" justify="end">
            <Space>
              <CmsCollectionEntryCreateButton collectionId={data?.id ?? 0} isDisabled={!data} />
              <EnhancedButton
                type="primary"
                onClick={() => navigate(`${routes.CMS_COLLECTIONS}/${id}/entries`)}
              >
                Edit Items
              </EnhancedButton>
              <CmsCollectionArchivedButton
                collection={data}
                isDisabled={!data}
                onDelete={() => navigate(routes.CMS)}
              />
            </Space>
          </Row>,
        ]}
      />
      <Section isLoading={isLoading} isError={isError}>
        <CmsCollectionSchemaDataTable
          isLoading={isLoading}
          data={schemaData}
          collectionId={Number(collectionId)}
        />
      </Section>
    </div>
  );
};

export default CmsCollectionEditPage;
