import { Button, Popconfirm } from 'antd';
import React, { useEffect } from 'react';

import type { CmsPageGroupMetadataModel } from '../../../models/cms/CmsPageGroupMetadataModel';
import { pluralize } from '../../../utils/displayUtils';
import useDeleteCmsPageGroups from '../../../hooks/services/cms/useDeletePageGroups';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import ButtonHiddenWithInsufficientPermission from '../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  pageGroups: Array<CmsPageGroupMetadataModel>;
  isDisabled?: boolean;
  onDelete?: () => void;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const CmsPageGroupArchiveButton = ({ pageGroups, isDisabled, onDelete }: Props) => {
  const { deleteMutation, isError, isLoading, isSuccess } = useDeleteCmsPageGroups();
  const recordText = pluralize(pageGroups.length, 'this record', {
    plural: 'these records',
  });
  const successRecordText = pluralize(pageGroups.length, 'record was', {
    plural: 'records were',
  });

  useEffect(() => {
    isSuccess && onDelete?.();
  }, [isSuccess, onDelete]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The ${successRecordText} successfully deleted.`,
  });

  return (
    <Popconfirm
      placement="topRight"
      title={`Are you sure you want to delete ${recordText}?`}
      onConfirm={() => deleteMutation(pageGroups.map((item) => item.id))}
    >
      <EnhancedButton type="primary" disabled={isDisabled} danger={true} loading={isLoading}>
        Archive
      </EnhancedButton>
    </Popconfirm>
  );
};

export default CmsPageGroupArchiveButton;
