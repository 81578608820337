import { acquireTokenAsync } from '../AuthService';
import axios from 'axios';

type TQueryData = { [key: string]: string };

class WebAPI {
  private url: string;
  private version: string;

  constructor(url: string, version: string) {
    this.url = url;
    this.version = version;
  }

  async get(path: string, queryData?: TQueryData, isLocalStored = false): Promise<any> {
    if (isLocalStored) {
      return this.getFromLocalStorage(path);
    }

    const completeUrl = this.buildRequestUrl(this.url, this.version, path, queryData);
    const requestConfig = await this.createRequestConfig();
    return await axios.get(completeUrl, requestConfig);
  }

  getFromLocalStorage(path: string): any {
    const item = localStorage.getItem(path);

    return {
      data: item ? JSON.parse(item) : item,
    };
  }

  async post(path: string, data: any, isLocalStored = false, additionalHeaders = {}): Promise<any> {
    if (isLocalStored) {
      return this.postToLocalStorage(path, data);
    }

    const completeUrl = this.buildRequestUrl(this.url, this.version, path);
    const requestConfig = await this.createRequestConfig(additionalHeaders);
    return await axios.post(completeUrl, data, requestConfig);
  }

  postToLocalStorage(path: string, data: any): any {
    if (!data.id) {
      localStorage.setItem(path, JSON.stringify(data));

      return data;
    }

    const items = this.getFromLocalStorage(path)?.data ?? [];
    localStorage.setItem(
      path,
      JSON.stringify([...items.filter((item) => item.id !== data.id), data]),
    );

    return { data };
  }

  async put(path: string, data: any): Promise<any> {
    const completeUrl = this.buildRequestUrl(this.url, this.version, path);
    const requestConfig = await this.createRequestConfig();
    return await axios.put(completeUrl, data, requestConfig);
  }

  async patch(path: string, data: any): Promise<any> {
    const completeUrl = this.buildRequestUrl(this.url, this.version, path);
    const requestConfig = await this.createRequestConfig();
    return await axios.patch(completeUrl, data, requestConfig);
  }

  async delete(path: string): Promise<any> {
    const completeUrl = this.buildRequestUrl(this.url, this.version, path);
    const requestConfig = await this.createRequestConfig();
    return await axios.delete(completeUrl, requestConfig);
  }

  async getAuthToken(): Promise<string> {
    const token = await acquireTokenAsync();
    return token?.accessToken ?? '';
  }

  async createRequestConfig(data: any = {}, additionalHeaders = {}): Promise<any> {
    const token = await this.getAuthToken();
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        ...additionalHeaders,
      },
      ...data,
    };
  }

  buildRequestUrl(
    baseUrl: string,
    version: string,
    resourceUri: string,
    queryData?: TQueryData,
  ): string {
    const requestUrl = new URL([baseUrl, version, resourceUri].filter(Boolean).join('/'));

    if (queryData) {
      Object.keys(queryData).forEach((key) => {
        requestUrl.searchParams.append(key, queryData[key]);
      });
    }

    return requestUrl.toString();
  }
}

export default WebAPI;
