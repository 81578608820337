import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import type { Rule } from 'antd/lib/form';
import { isNil } from 'lodash';

import type { CmsCollectionEntryVersionDataModel } from '../../../../../models/cms/collections/entryVersionData/CmsCollectionEntryVersionDataModel';
import { CmsCollectionEntryVersionDataTypeEnum } from '../../../../../enums/cms/CmsCollectionEntryVersionDataTypeEnum';
import type { CmsCollectionSchemaDataNumberModel } from '../../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataNumberModel';
import InputDisabledWithInsufficientPermission from '../../../../hocs/inputs/InputDisabledWithInsufficientPermission';

type Props = {
  initialData?: CmsCollectionEntryVersionDataModel;
  schemaData: CmsCollectionSchemaDataNumberModel;
  formLabel: React.ReactNode;
  index: number;
};

const EnhancedInputNumber = InputDisabledWithInsufficientPermission(InputNumber);

export const CmsCollectionNumberItems = ({ initialData, schemaData, formLabel, index }: Props) => {
  const initialValues =
    initialData?.entryVersionDataType === CmsCollectionEntryVersionDataTypeEnum.String
      ? initialData
      : null;

  const getRules = (): Rule[] => {
    const numberRules: Rule[] = [
      { type: 'number', message: `${schemaData.fieldName} must be a number` },
    ];

    if (schemaData.isRequired) {
      numberRules.push({ required: true, message: `${schemaData.fieldName} is required` });
    }

    if (!isNil(schemaData.maxRange)) {
      numberRules.push({
        type: 'number',
        max: schemaData.maxRange,
        message: `${schemaData.fieldName} cannot be greater than ${schemaData.maxRange}`,
      });
    }

    if (!isNil(schemaData.minRange)) {
      numberRules.push({
        type: 'number',
        min: schemaData.minRange,
        message: `${schemaData.fieldName} cannot be less than ${schemaData.minRange}`,
      });
    }
    return numberRules;
  };

  return (
    <>
      <Form.Item
        label={formLabel}
        name={['entryVersions', 0, 'entryVersionData', index, 'value']}
        initialValue={!isNil(initialValues?.value) ? Number(initialValues?.value) : undefined}
        rules={getRules()}
      >
        <EnhancedInputNumber />
      </Form.Item>
      <Form.Item
        hidden
        initialValue={CmsCollectionEntryVersionDataTypeEnum.String}
        name={['entryVersions', 0, 'entryVersionData', index, 'entryVersionDataType']}
      >
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};
