import '../../../css/cms/displays/CmsPageHistoryList.css';

import { InfoCircleOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { List, Space, Typography } from 'antd';
import { find, orderBy } from 'lodash';
import { getDateTimeDisplayValue, getModifiedBy } from '../../../utils/displayUtils';

import CmsPageContentEnvironmentText from './CmsPageContentEnvironmentText';
import type { CmsPageContentVersionMetadataModel } from '../../../models/cms/CmsPageContentVersionMetadataModel';
import CmsPageHistoryMenuButton from '../buttons/CmsPageHistoryMenuButton';
import CmsPageHistoryModalButton from '../buttons/CmsPageHistoryModalButton';
import React from 'react';

type Props = {
  isLoading: boolean;
  title: string;
  versions: CmsPageContentVersionMetadataModel[];
};

const CmsPageHistoryList = ({ isLoading, title, versions }: Props) => {
  const draftVersion = find(versions, ['isDraft', true])?.version;

  const getStatusText = React.useCallback(
    (value: number) => {
      if (value === draftVersion) {
        return (
          <Typography.Text type="warning">
            <InfoCircleOutlined /> Draft
          </Typography.Text>
        );
      } else {
        return (
          <Typography.Text type="success">
            <LockOutlined /> Published
          </Typography.Text>
        );
      }
    },
    [draftVersion],
  );

  if (isLoading) {
    return null;
  }

  return (
    <div className="cmsPageHistoryListRoot">
      <Typography.Title level={5}>{title}</Typography.Title>
      <List
        bordered
        pagination={versions?.length > 8 ? { defaultPageSize: 8 } : false}
        dataSource={orderBy(versions, ['version'], ['desc'])}
        renderItem={(item: CmsPageContentVersionMetadataModel) => (
          <List.Item>
            {getStatusText(item.version)}
            {Boolean(item.versionEnvironments.length) && (
              <Space className="cmsPageHistoryListEnvSpace" size={4}>
                {item.versionEnvironments.map((env, i) => (
                  <CmsPageContentEnvironmentText key={i} environment={env.pageEnvironmentId} />
                ))}
              </Space>
            )}
            <div className="cmsPageHistoryListEllipsisButton">
              <CmsPageHistoryMenuButton
                selectedContentVersion={item}
                contentVersions={versions}
                isDraftVersion={item.version === draftVersion}
              />
            </div>
            <br />
            <Typography.Text>
              <b>V{item.version}</b> - {getDateTimeDisplayValue(item.updatedDate)}
            </Typography.Text>
            <br />
            <UserOutlined /> {getModifiedBy(item.updatedBy)}
            <CmsPageHistoryModalButton contentVersion={item} />
          </List.Item>
        )}
      />
    </div>
  );
};

export default CmsPageHistoryList;
