import { useMutation, useQueryClient } from 'react-query';

import type { CmsPageGroupMetadataModel } from '../../../models/cms/CmsPageGroupMetadataModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { updatePageGroup } from '../../../services/CmsPageGroupService';

type MutationCallbacks = {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
};

type $UpdateCmsPageGroup = {
  update: (cmsPageGroup: CmsPageGroupMetadataModel, callbacks: MutationCallbacks) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

function useUpdateCmsPageGroup(): $UpdateCmsPageGroup {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(updatePageGroup, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGE_GROUPS ||
          query.queryKey === QUERYKEYS.PAGE_GROUPS ||
          (query.queryKey[0] === QUERYKEYS.PAGE_GROUP && query.queryKey[1] === data.id),
      });
    },
  });

  return {
    update: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useUpdateCmsPageGroup;
