import '../../../css/cms/CmsPageEditPage.css';

import {
  setEditorPageContent,
  setPageModel,
  setPreviewFrameRef,
} from '../../../contexts/cms/cmsPageEdit/CmsPageEditContextActions';

import { CmsLocaleDisplayNames } from '../../../enums/cms/CmsLocaleEnum';
import CmsPageContentEditor from '../../cms/displays/CmsPageContentEditor';
import CmsPageEditActionButtons from '../../cms/buttons/CmsPageEditActionButtons';
import CmsPageEditButton from '../../cms/buttons/CmsPageEditButton';
import CmsPageRightSidebar from '../../cms/displays/CmsPageRightSidebar';
import PageHeader from '../../shared/PageHeader';
import ROUTES from '../../../constants/routes';
import React from 'react';
import Section from '../../shared/Section';
import { head } from 'lodash';
import useCmsPageEditContext from '../../../hooks/contexts/useCmsPageEditContext';
import useCmsPagesContent from '../../../hooks/services/cms/useCmsPagesContent';
import { useParams } from 'react-router-dom';

const CmsPageEditPage = () => {
  // Fetch data
  const { pageId } = useParams();
  const pages = useCmsPagesContent([+pageId!]);
  const { data, isLoading } = head(pages) ?? {};
  const iFrameRef = React.useRef<HTMLIFrameElement>(null);
  const pageContent = head(data?.contentVersions)?.content ?? '';

  // Initialize Page Edit Context
  const { state, dispatch } = useCmsPageEditContext();
  const { pageModel } = state;

  const { id, title, slug, contentVersions, group } = pageModel ?? {};
  const { version, isDraft } = head(contentVersions) ?? {};

  React.useEffect(() => {
    dispatch(setPageModel(data!));
  }, [data, dispatch]);

  React.useEffect(() => {
    dispatch(setPreviewFrameRef(iFrameRef));
  }, [iFrameRef, dispatch]);

  React.useEffect(() => {
    dispatch(setEditorPageContent(pageContent));
  }, [pageContent, dispatch]);

  return (
    <div className="cmsPageEditPageRoot">
      <PageHeader
        title={title ? `${title}` : 'loading...'}
        subTitle={
          <>
            {slug && version
              ? `${slug} (v${version} - ${isDraft ? 'Draft' : 'Published'} - ${
                  CmsLocaleDisplayNames[pageModel?.localeId ?? 0]
                } )`
              : ''}
            <span className="marginLeft8">
              {pageModel && <CmsPageEditButton pageModel={pageModel} />}
            </span>
          </>
        }
        breadcrumbRoutes={
          id && slug && group
            ? [
                { breadcrumbName: 'Home', path: ROUTES.CMS },
                { breadcrumbName: group?.slug, path: `${ROUTES.PAGE_GROUPS}/${group?.id}` },
                { breadcrumbName: slug, path: `${ROUTES.PAGE_GROUPS}/${group?.id}/page/${id}` },
              ]
            : undefined
        }
        extra={[<CmsPageEditActionButtons key="pageEditActions" />]}
      />
      <Section isLoading={isLoading}>
        <div className="cmsPageEditPageContainer">
          <div className="cmsPageEditPageContentEditorColumn">
            <CmsPageContentEditor />
          </div>
          <div className="cmsPageEditPageRightSidebarColumn">
            <CmsPageRightSidebar />
          </div>
        </div>
      </Section>
    </div>
  );
};

export default CmsPageEditPage;
