import { useNavigate, useParams } from 'react-router-dom';

import { CmsButtonTypeEnum } from '../../../enums/cms/CmsButtonTypeEnum';
import CmsPageGroupActionButtons from '../../cms/buttons/CmsPageGroupActionButtons';
import CmsPageGroupEditButton from '../../cms/buttons/CmsPageGroupEditButton';
import CmsPagesTable from '../../cms/tables/CmsPagesTable';
import PageHeader from '../../shared/PageHeader';
import React from 'react';
import Section from '../../shared/Section';
import routes from '../../../constants/routes';
import useCmsPageGroupPages from '../../../hooks/services/cms/useCmsPageGroupPages';

const CmsPageGroupPage = () => {
  const { pageGroupId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useCmsPageGroupPages(+pageGroupId!);
  const { title, slug, pages } = data ?? {};
  return (
    <div>
      <PageHeader
        title="CMS Page Group"
        subTitle={
          <>
            {!isLoading ? `${title} (${slug})` : 'loading...'}
            <span className="marginLeft8">
              {data && <CmsPageGroupEditButton pageGroup={data} isDisabled={!data} />}
            </span>
          </>
        }
        breadcrumbRoutes={
          data
            ? [
                { breadcrumbName: 'Home', path: routes.CMS },
                { breadcrumbName: 'Page Groups', path: routes.PAGE_GROUPS },
                { breadcrumbName: data.slug, path: `${routes.PAGE_GROUPS}/${data.id}` },
              ]
            : undefined
        }
        extra={[
          <CmsPageGroupActionButtons
            key="pageGroupActions"
            buttonTypes={[CmsButtonTypeEnum.CREATE, CmsButtonTypeEnum.ARCHIVE]}
            onDelete={() => navigate(routes.CMS)}
            data={data ? [data] : []}
          />,
        ]}
      />
      <Section isLoading={isLoading}>
        <CmsPagesTable
          key="table1"
          data={pages}
          columnConfig={['title', 'slug', 'status', 'lastModifiedDate', 'lastModifiedBy', 'locale']}
        />
      </Section>
    </div>
  );
};

export default CmsPageGroupPage;
