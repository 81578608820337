import '../../../css/cms/pages/CmsCollectionEntryEditPage.css';

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Row, Space } from 'antd';
import { last, sortBy } from 'lodash';
import routes from '../../../constants/routes';

import CmsCollectionEntryArchiveButton from '../../cms/buttons/collections/CmsCollectionEntryArchiveButton';
import { CmsCollectionEntryCreateButton } from '../../cms/buttons/collections/CmsCollectionEntryCreateButton';
import { CmsCollectionEntryDataItems } from '../../cms/forms/collections/entryVersionData/CmsCollectionEntryDataItems';
import { CmsCollectionEntryEditButton } from '../../cms/buttons/collections/CmsCollectionEntryEditButton';
import { CmsCollectionEntryFormSaveButton } from '../../cms/buttons/collections/CmsCollectionEntryFormSaveButton';
import { CmsLocaleDisplayNames, CmsLocaleEnum } from '../../../enums/cms/CmsLocaleEnum';
import CmsStatusText from '../../cms/displays/CmsStatusText';
import PageHeader from '../../shared/PageHeader';
import Section from '../../shared/Section';
import { useCmsCollectionEntry } from '../../../hooks/services/cms/collections/useCmsCollectionEntry';

export const CmsCollectionEntryEditPage = () => {
  const { collectionId, entryId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useCmsCollectionEntry({
    collectionId: Number(collectionId),
    entryId: Number(entryId),
  });
  const { id, collection, name, status } = data ?? {};
  const [form] = Form.useForm();
  const latestVersion = last(sortBy(data?.entryVersions, 'version'));

  return (
    <Form form={form} layout="vertical">
      <PageHeader
        title={name ? name : 'loading...'}
        subTitle={
          status && latestVersion ? (
            <>
              <span className="cmsCollectionEntryEditPageSubTitle">
                {`V${latestVersion.version} - ${CmsLocaleDisplayNames[data?.localeId ?? 0]} - `}
                <CmsStatusText status={status} />
              </span>
              <span className="cmsCollectionEntryEditPageSubTitle marginLeft8">
                {data && (
                  <CmsCollectionEntryEditButton collectionEntry={data} isDisabled={isLoading} />
                )}
              </span>
            </>
          ) : (
            ''
          )
        }
        breadcrumbRoutes={
          collection && data
            ? [
                { breadcrumbName: 'Home', path: routes.CMS },
                { breadcrumbName: 'Collections', path: routes.CMS_COLLECTIONS },
                {
                  breadcrumbName: collection.slug,
                  path: `${routes.CMS_COLLECTIONS}/${collection.id}/entries`,
                },
                {
                  breadcrumbName: data.name,
                  path: `${routes.CMS_COLLECTIONS}/${collection.id}/entries/${data.id}`,
                },
              ]
            : undefined
        }
        extra={[
          <Row key="actionBar" justify="end">
            <Space>
              <CmsCollectionEntryCreateButton
                collectionId={collection?.id ?? 0}
                isDisabled={isLoading}
              />
              <CmsCollectionEntryFormSaveButton saveAsDraft={true} isDisabled={isLoading} />
              <CmsCollectionEntryFormSaveButton saveAsDraft={false} isDisabled={isLoading} />
              <CmsCollectionEntryArchiveButton
                isDisabled={isLoading}
                selectedCollectionEntries={data ? [data] : []}
                onDelete={() =>
                  collection && navigate(`${routes.CMS_COLLECTIONS}/${collection.id}/entries`)
                }
              />
            </Space>
          </Row>,
        ]}
      />
      <Section isLoading={isLoading} isError={isError}>
        {collection && id && latestVersion && (
          <>
            <CmsCollectionEntryDataItems
              schemaData={collection.schemaData}
              initialData={latestVersion.entryVersionData}
            />
            <Form.Item hidden name="id" initialValue={id}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item hidden name="collectionId" initialValue={collection.id}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item hidden name="localeId" initialValue={data?.localeId ?? CmsLocaleEnum.EN_US}>
              <Input type="hidden" />
            </Form.Item>
          </>
        )}
      </Section>
    </Form>
  );
};
