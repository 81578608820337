import React, { useState } from 'react';
import { Button } from 'antd';
import type { PromoCodeModel } from '../../../models/promoCodes/PromoCodeModel';
import PromoCodesDetailModalForm from '../forms/PromoCodeDetailModalForm';

type Props = {
  promoCode: PromoCodeModel;
};

const PromoCodeOpenButton = ({ promoCode }: Props) => {
  // States
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  return (
    <>
      <Button type="link" onClick={() => setIsModalVisible(true)}>
        {promoCode.name}
      </Button>
      <PromoCodesDetailModalForm
        isVisible={isModalVisible}
        closeFn={() => setIsModalVisible(false)}
        initialData={promoCode}
      />
    </>
  );
};

export default PromoCodeOpenButton;
