import { Button, Popconfirm } from 'antd';

import React from 'react';
import { pluralize } from './../../../utils/displayUtils';
import useBulkPublishPages from './../../../hooks/services/cms/useBulkPublishPages';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';

type Props = {
  data: Array<number>;
  isConfirmRequired?: boolean;
  isDisabled?: boolean;
};

export default function CmsPageBulkPublishButton({
  data,
  isConfirmRequired = true,
  isDisabled = false,
}: Props) {
  const { publish, isError, isLoading, isSuccess } = useBulkPublishPages();
  const recordText = pluralize(data.length, 'this record', {
    plural: 'these records',
  });
  const successRecordText = pluralize(data.length, 'record was', {
    plural: 'records were',
  });
  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The ${successRecordText} successfully published.`,
  });

  return (
    <>
      {isConfirmRequired && (
        <Popconfirm
          title={`Are you sure you want to publish ${recordText}?`}
          onConfirm={() => publish(data)}
        >
          <Button disabled={isDisabled} type="primary" loading={isLoading}>
            Publish
          </Button>
        </Popconfirm>
      )}
      {!isConfirmRequired && (
        <Button
          type="primary"
          disabled={isDisabled}
          onClick={() => publish(data)}
          loading={isLoading}
        >
          Publish
        </Button>
      )}
    </>
  );
}
