import { Checkbox, Form, Modal } from 'antd';
import React, { useState } from 'react';

import type { CmsCollectionModel } from './../../../models/cms/collections/CmsCollectionModel';
import CmsTitleSlugInputs from './items/CmsTitleSlugInputs';
import routes from '../../../constants/routes';
import useCreateCmsCollection from './../../../hooks/services/cms/collections/useCreateCmsCollection';
import { useNavigate } from 'react-router-dom';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import useUpdateCmsCollection from './../../../hooks/services/cms/collections/useUpdateCmsCollection';

const FORM_LAYOUT = {
  labelCol: { span: 6 },
};

type Props = {
  initialData?: CmsCollectionModel;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
};

const DEFAULT_COLLECTION = {
  title: '',
  slug: '',
};

const CmsCollectionModalForm = ({ initialData, isShown, setIsShown }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const {
    create,
    isLoading: isCreating,
    isSuccess: isCreated,
    isError: isCreateError,
  } = useCreateCmsCollection();
  const {
    update,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateCmsCollection();

  const [form] = Form.useForm();
  const isLoading = isCreating || isUpdating;
  const isSuccess = isCreated || isUpdated;
  const isError = isCreateError || isUpdateError;
  const isNewCollection = !initialData;
  const navigate = useNavigate();
  const onCancel = React.useCallback(() => {
    if (isLoading) {
      return;
    }

    form.resetFields();
    setIsShown(false);
  }, [isLoading, form, setIsShown]);

  const onSubmit = React.useCallback(async () => {
    const collection = isNewCollection
      ? form.getFieldsValue()
      : { id: initialData?.id, ...form.getFieldsValue(['title', 'slug']) };

    try {
      await form.validateFields();

      if (isNewCollection) {
        create(collection, {
          onSuccess: (data) => {
            onCancel();
            navigate(`${routes.CMS_COLLECTIONS}/${data.id}/schema`);
          },
        });
      } else {
        update(collection, { onSuccess: onCancel });
      }

      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [create, form, initialData?.id, isNewCollection, navigate, onCancel, update]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The collection was successfully ${
      isNewCollection ? 'created' : 'updated'
    }.`,
  });

  return (
    <Modal
      okButtonProps={{ loading: isLoading, disabled: !isChecked && !isNewCollection }}
      onOk={onSubmit}
      onCancel={onCancel}
      destroyOnClose={true}
      title={`${isNewCollection ? 'Create' : 'Update'} Collection`}
      open={isShown}
    >
      <Form {...FORM_LAYOUT} form={form} initialValues={initialData ?? DEFAULT_COLLECTION}>
        <CmsTitleSlugInputs slugDelimiter="_" />
        {!isNewCollection && (
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 16,
            }}
          >
            <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
              I understand that changing the <b>Slug</b> can impact production pages.
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CmsCollectionModalForm;
