import React, { useMemo, useState } from 'react';
import { Button, Input, Row, Table } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import ROUTES from '../../../constants/routes';

import type { TVendor } from '../../../models/vendor/VendorModel';
import TableWithActionsColumn from '../../hocs/tables/TableWithActionsColumn';
import TableWithHeader from '../../hocs/tables/TableWithHeader';
import TextSearchColumnFilter from '../../shared/columnFilters/TextSearchColumnFilter';
import VendorModalForm from '../forms/VendorModalForm';
import VendorPageSearchCreateButton from '../buttons/VendorPageSearchCreateButton';
import { getBooleanDisplayValue, getDateTimeDisplayValue } from '../../../utils/displayUtils';

type Props = {
  data?: Array<TVendor>;
};

const ActionableTableWithHeader = TableWithHeader(TableWithActionsColumn(Table));

const VendorsTable = ({ data }: Props) => {
  const navigate = useNavigate();
  const [selectedVendor, setSelectedVendor] = useState<any>(undefined);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [filteredDataLength, setFilteredDataLength] = useState<number>(0);

  const columns: Array<ColumnType<TVendor>> = useMemo(
    () => [
      {
        dataIndex: 'name',
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (value, row) => <Link to={`${ROUTES.VENDORS}/${row.id}`}>{value}</Link>,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <TextSearchColumnFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            extra={
              <VendorPageSearchCreateButton
                clearFilters={clearFilters}
                searchValue={selectedKeys[0]?.toString()}
                dataIndex="name"
              />
            }
          />
        ),
        onFilter: (value, record) => {
          return record.name.toString().toLowerCase().includes(value.toString().toLowerCase());
        },
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
          />
        ),
        defaultSortOrder: 'ascend',
      },
      {
        dataIndex: 'shortName',
        title: 'Short Name',
        sorter: (a, b) => a.shortName.localeCompare(b.shortName),
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <TextSearchColumnFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
            extra={
              <VendorPageSearchCreateButton
                clearFilters={clearFilters}
                searchValue={selectedKeys[0]?.toString()}
                dataIndex="shortName"
              />
            }
          />
        ),
        onFilter: (value, record) => {
          return record.shortName.toString().toLowerCase().includes(value.toString().toLowerCase());
        },
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{ color: filtered ? COLORS.VENDORS_SEARCH_ICON_ACTIVE : undefined }}
          />
        ),
      },
      {
        dataIndex: 'createdDate',
        render: getDateTimeDisplayValue,
        title: 'Created Date',
        sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      },
      {
        dataIndex: 'secret',
        title: 'Secret',
        render: (secret) => (
          <Input.Password className="style-secret-disp" value={secret} readOnly />
        ),
      },
      {
        dataIndex: 'isActive',
        render: getBooleanDisplayValue,
        title: 'Active',
        sorter: (a, b) => Number(a.isActive) - Number(b.isActive),
        filterSearch: true,
        filters: [true, false].map((tfnType) => ({
          text: getBooleanDisplayValue(tfnType),
          value: tfnType,
        })),
        onFilter: (isActive: boolean, record: TVendor): boolean => record.isActive === isActive,
        defaultFilteredValue: ['true'],
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                setSelectedVendor(record);
                setIsModalShown(true);
              }}
            >
              Update
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  const tableHeader = (
    <Row key="actionBar" justify="end">
      <Button
        type="primary"
        onClick={() => {
          setSelectedVendor(null);
          setIsModalShown(true);
        }}
      >
        Create Vendor
      </Button>
    </Row>
  );

  return (
    <>
      <ActionableTableWithHeader
        header={tableHeader}
        bordered
        columns={columns}
        onChange={(pagination, filters, sorter, extra) => {
          setFilteredDataLength(extra.currentDataSource.length);
        }}
        dataSource={data}
        onRow={(record) => ({
          onDoubleClick: () => navigate(`${record.id}`),
        })}
        pagination={{
          hideOnSinglePage: false,
          responsive: true,
          total: filteredDataLength,
          showSizeChanger: true,
          defaultPageSize: 100,
          position: ['bottomRight', 'topRight'],
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        }}
        rowKey="id"
        size="small"
      />
      <VendorModalForm
        initialData={selectedVendor}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        isCreatingNew={false}
      />
    </>
  );
};

export default VendorsTable;
