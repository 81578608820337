import type { PromoCodeModel } from './../../../models/promoCodes/PromoCodeModel';
import type { TQueryModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { getPromoCodes } from './../../../services/PromoCodesService';
import { useQuery } from 'react-query';

const usePromoCodes = (): TQueryModel<Array<PromoCodeModel>> => {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.PROMO_CODES, getPromoCodes);

  return {
    data,
    isError,
    isLoading,
  };
};

export default usePromoCodes;
