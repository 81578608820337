import { useMutation, useQueryClient } from 'react-query';

import type { CmsCreateMutationResponse } from '../../../../models/cms/CmsResponseModels';
import type { CreateCmsReleaseRequestModel } from './../../../../services/types/CmsReleaseService/CreateCmsReleaseRequestModel';
import QUERYKEYS from '../../../../constants/queryKeys';
import { createCmsRelease } from '../../../../services/CmsReleaseService';

function useCreateCmsRelease(): CmsCreateMutationResponse<CreateCmsReleaseRequestModel> {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(createCmsRelease, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey === QUERYKEYS.RELEASES,
      }),
  });

  return {
    create: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useCreateCmsRelease;
