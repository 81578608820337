import React, { useState } from 'react';
import { Button } from 'antd';
import PromoCodesDetailModalForm from '../forms/PromoCodeDetailModalForm';

type Props = {
  isDisabled?: boolean;
};

const PromoCodeCreateButton = ({ isDisabled }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  isDisabled ??= false;

  return (
    <>
      <Button type="primary" disabled={isDisabled} onClick={() => setIsModalVisible(true)}>
        Add New Promo Code
      </Button>
      <PromoCodesDetailModalForm
        isVisible={isModalVisible}
        closeFn={() => setIsModalVisible(false)}
      />
    </>
  );
};

export default PromoCodeCreateButton;
