import React from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'antd';
import ROUTES from '../../../../constants/routes';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = Readonly<{
  collectionId?: number;
  isDisabled?: boolean;
}>;

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

export const CmsCollectionEditSchemaButton = ({
  collectionId,
  isDisabled,
}: Props): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <EnhancedButton
      type="primary"
      disabled={isDisabled || !collectionId}
      onClick={() => navigate(`${ROUTES.CMS_COLLECTIONS}/${collectionId}/schema`)}
    >
      Edit Schema
    </EnhancedButton>
  );
};
