import React, { createContext } from 'react';
import type { ReactNode } from 'react';
import { AreasEnum } from '../enums/shared/AreasEnum';

type Props = {
  children: ReactNode;
  area: AreasEnum;
};

export const AreaContext = createContext(AreasEnum.NONE);

const AreaContextProvider = ({ children, area }: Props) => {
  return <AreaContext.Provider value={area}>{children}</AreaContext.Provider>;
};

export default AreaContextProvider;
