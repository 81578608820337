import React from 'react';
import { Form, Input, Select } from 'antd';
import { head } from 'lodash';

import type { CmsCollectionEntryVersionDataModel } from '../../../../../models/cms/collections/entryVersionData/CmsCollectionEntryVersionDataModel';
import { CmsCollectionEntryVersionDataTypeEnum } from '../../../../../enums/cms/CmsCollectionEntryVersionDataTypeEnum';
import type { CmsCollectionSchemaDataSelectModel } from '../../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataSelectModel';
import InputDisabledWithInsufficientPermission from '../../../../hocs/inputs/InputDisabledWithInsufficientPermission';

type Props = {
  initialData?: CmsCollectionEntryVersionDataModel;
  schemaData: CmsCollectionSchemaDataSelectModel;
  formLabel: React.ReactNode;
  index: number;
};

const EnhancedSelect = InputDisabledWithInsufficientPermission(Select);

export const CmsCollectionSelectItems = ({ initialData, schemaData, formLabel, index }: Props) => {
  const initialValues =
    initialData?.entryVersionDataType === CmsCollectionEntryVersionDataTypeEnum.Select
      ? initialData
      : null;

  return (
    <>
      <Form.Item
        label={formLabel}
        name={[
          'entryVersions',
          0,
          'entryVersionData',
          index,
          'schemaEntrySelectValues',
          0,
          'schemaDataSelectValueId',
        ]}
        initialValue={head(initialValues?.schemaEntrySelectValues)?.schemaDataSelectValueId}
        rules={
          schemaData.isRequired
            ? [{ required: true, message: `${schemaData.fieldName} is required` }]
            : undefined
        }
      >
        <EnhancedSelect>
          {!schemaData.isRequired && (
            <Select.Option key={0} value={0}>
              {' '}
            </Select.Option>
          )}
          {schemaData.selectValues.map((selectValue) => (
            <Select.Option key={selectValue.id} value={selectValue.id}>
              {selectValue.name}
            </Select.Option>
          ))}
        </EnhancedSelect>
      </Form.Item>
      <Form.Item
        hidden
        initialValue={CmsCollectionEntryVersionDataTypeEnum.Select}
        name={['entryVersions', 0, 'entryVersionData', index, 'entryVersionDataType']}
      >
        <Input type="hidden" />
      </Form.Item>
    </>
  );
};
