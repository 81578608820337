import type { CmsPageModel } from '../../../models/cms/CmsPageModel';
import QUERYKEYS from '../../../constants/queryKeys';
import config from '../../../config/';
import { getPageContentById } from '../../../services/CmsPageService';
import { useQueries } from 'react-query';

type TUseCmsPageContent = {
  data?: CmsPageModel;
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsPagesContent(ids: Array<number>): Array<TUseCmsPageContent> {
  const response = useQueries(
    ids.map((id) => {
      return {
        queryKey: [QUERYKEYS.PAGE_CONTENT, { id }],
        queryFn: async () => getPageContentById(id),
        staleTime: config.cmsIdleTimeoutInMinutes * 60 * 1000,
      };
    }),
  );

  return response;
}
