import React from 'react';

import PageHeader from '../shared/PageHeader';

const HomePage = () => {
  return (
    <div>
      <PageHeader title="Admin Portal" subTitle="Welcome to the Admin Portal" />
    </div>
  );
};

export default HomePage;
