import React from 'react';
import { Select } from 'antd';
import useMarketingCampaigns from './../../../hooks/services/vendor/useMarketingCampaigns';

type Props = {
  value?: number;
  onChange?: (e: any) => void;
  disabled?: boolean;
};

const MarketingCampaignSelect = ({ value, onChange, disabled }: Props) => {
  const { data } = useMarketingCampaigns();

  return (
    <Select
      value={value}
      disabled={disabled}
      onChange={onChange}
      placeholder="Select a marketing campaign"
      optionFilterProp="label"
      showSearch
      options={data
        ?.map((marketingCampaign) => ({
          value: marketingCampaign?.id,
          label: marketingCampaign?.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
    />
  );
};

export default MarketingCampaignSelect;
