import '../../../css/pages/vendor/VendorCodeCommercialDetailPage.css';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Alert, Button, Descriptions, Divider, Spin, notification } from 'antd';
import Section from '../../shared/Section';
import PageHeader from '../../shared/PageHeader';
import ROUTES from '../../../constants/routes';

import VendorCodeCommercialModalForm from './../../vendor/forms/VendorCodeCommercialModalForm';
import { VendorCodeType } from '../../../enums/vendor/VendorCodeTypeEnum';
import {
  getBooleanDisplayValue,
  getVendorCodeTypeDisplayValue,
  getPhoneNumberDisplayValue,
} from './../../../utils/displayUtils';
import useVendorCodeCommercial from './../../../hooks/services/vendor/useVendorCodeCommercial';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';

interface LocationState {
  state: {
    isNew: boolean;
    name: string;
  };
}

const EnhancedButton = ButtonDisabledWithInsufficientPermission(Button);

const VendorCodeCommercialDetailPage = () => {
  const { vendorCodeId } = useParams();
  const { data, isError, isLoading } = useVendorCodeCommercial(vendorCodeId!);
  const [isModalShown, setIsModalShown] = React.useState<boolean>(false);
  const locationInfo = useLocation();
  const { state } = locationInfo as LocationState;

  useEffect(() => {
    if (state && state.isNew) {
      notification.success({
        message: <strong>Successfully Created</strong>,
        description: (
          <span>
            TFN Only vendor code <strong>{state?.name}</strong> was successfully created.
          </span>
        ),
        placement: 'bottomLeft',
      });
      state.isNew = false;
    }
  }, [state]);

  if (isLoading) {
    return <Spin />;
  }

  if (isError || !data) {
    return (
      <Alert
        message="Error"
        description="An error occurred while attempting to load the data for this vendor."
        type="error"
        showIcon
      />
    );
  }

  return (
    <div className="vendorDetailsPageRoot">
      <PageHeader
        breadcrumbRoutes={[
          { breadcrumbName: 'Vendor Codes', path: ROUTES.VENDOR_CODES },
          { breadcrumbName: data.vendorName, path: `${ROUTES.VENDORS}/${data.vendorId}` },
          { breadcrumbName: data.legacyVendorId, path: window.location.pathname },
        ]}
        extra={[
          <EnhancedButton
            type="primary"
            key="update"
            onClick={() => {
              setIsModalShown(true);
            }}
          >
            Update
          </EnhancedButton>,
        ]}
        title={data.legacyVendorId}
        subTitle={getVendorCodeTypeDisplayValue(VendorCodeType.COMMERCIAL)}
        isSubTitlePill={true}
        pillClassName="vendorCodeTypeCommercial"
      />
      <Section isLoading={isLoading}>
        <Descriptions
          key={data.name}
          bordered={true}
          column={3}
          labelStyle={{ fontWeight: 'bold' }}
          size="small"
        >
          <Descriptions.Item label="Active:">
            {getBooleanDisplayValue(data.isActive)}
          </Descriptions.Item>
          <Descriptions.Item label="Vendor:">{data.vendorName}</Descriptions.Item>
          <Descriptions.Item label="Creative:">{data.creative}</Descriptions.Item>
          <Descriptions.Item label="Media Type:">{data.mediaType}</Descriptions.Item>
          <Descriptions.Item label="Station:">{data.station}</Descriptions.Item>
          <Descriptions.Item label="Name:">{data.name}</Descriptions.Item>
          <Descriptions.Item label="Campaign:">{data.marketingCampaignName}</Descriptions.Item>
          <Descriptions.Item label="Toll Free Number:">
            {getPhoneNumberDisplayValue(data.tollFreeNumber)}
          </Descriptions.Item>
        </Descriptions>
      </Section>
      <Divider />
      <VendorCodeCommercialModalForm
        initialData={data}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </div>
  );
};

export default VendorCodeCommercialDetailPage;
