import type { CmsReleaseModel } from './../models/cms/releases/CmsReleaseModel';
import type { CreateCmsReleaseRequestModel } from './types/CmsReleaseService/CreateCmsReleaseRequestModel';
import type { UpdateCmsReleaseRequestModel } from './types/CmsReleaseService/UpdateCmsReleaseRequestModel';
import WebAPIClient from './api/WebAPIClient';

const PATH = 'cms/releases';

export const createCmsRelease = async (
  release: CreateCmsReleaseRequestModel,
): Promise<CmsReleaseModel> => {
  const response = await WebAPIClient.post(PATH, release);

  return response?.data;
};

export const getCmsRelease = async (releaseId: number): Promise<CmsReleaseModel> => {
  const response = await WebAPIClient.get(`${PATH}/${releaseId}`);
  return response?.data;
};

export const getCmsReleases = async (): Promise<Array<CmsReleaseModel>> => {
  const response = await WebAPIClient.get(PATH);

  return response?.data;
};

export const updateCmsRelease = async (
  release: UpdateCmsReleaseRequestModel,
): Promise<CmsReleaseModel> => {
  const response = await WebAPIClient.patch(`${PATH}/${release.releaseId}`, release);
  return response?.data;
};

export const deleteCmsReleases = async (releaseIds: Array<number>): Promise<void> => {
  await WebAPIClient.post(`${PATH}/delete`, { ids: releaseIds });
};
