import '../../../css/cms/displays/CmsPageContentEditor.css';

import React, { useCallback, useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { Alert } from 'antd';
import { isNil, throttle } from 'lodash';

import { setEditorPageContent } from '../../../contexts/cms/cmsPageEdit/CmsPageEditContextActions';
import useCmsPageEditContext from '../../../hooks/contexts/useCmsPageEditContext';
import EditorReadOnlyWithInsufficientPermission from '../../hocs/editors/EditorReadOnlyWithInsufficientPermission';

const PAGE_PREVIEW_MESSAGE_TYPE = 'page-preview';
const PAGE_PREVIEW_LOADED_MESSAGE_TYPE = 'page-preview-loaded';

const EnhancedEditor = EditorReadOnlyWithInsufficientPermission(Editor);

const CmsPageContentEditor = () => {
  const [frameError, setFrameError] = useState<string>('');
  const [frameWarning, setFrameWarning] = useState<string>('');
  const { state, dispatch } = useCmsPageEditContext();
  const { pageModel, editorPageContent, previewFrameRef } = state;
  const [isFrameLoaded, setIsFrameLoaded] = useState<boolean>(false);
  const { group } = pageModel ?? {};
  const targetSiteUrl = group?.targetSiteUrl;

  useEffect(() => {
    const messageListener = (e) => {
      if (e.data.type === PAGE_PREVIEW_MESSAGE_TYPE) {
        setFrameError(e.data.errorMessage);
        setFrameWarning(e.data.warningMessage);
      }

      if (e.data.type === PAGE_PREVIEW_LOADED_MESSAGE_TYPE) {
        setIsFrameLoaded(true);
      }
    };
    window.addEventListener('message', messageListener);

    return () => window.removeEventListener('message', messageListener);
  }, []);

  const previewContent = useCallback(() => {
    if (previewFrameRef?.current && targetSiteUrl && editorPageContent.length) {
      previewFrameRef.current?.contentWindow?.postMessage(
        { type: PAGE_PREVIEW_MESSAGE_TYPE, contentCode: editorPageContent },
        targetSiteUrl,
        [],
      );
    }
  }, [editorPageContent, targetSiteUrl, previewFrameRef]);

  const pageModelExist = !isNil(pageModel);
  useEffect(() => {
    isFrameLoaded && pageModelExist && previewContent();
  }, [isFrameLoaded, pageModelExist, previewContent]);

  return (
    <div className="cmsPageContentEditorRoot">
      <EnhancedEditor
        className="cmsPageCodeEditor"
        height="400px"
        theme="vs-dark"
        defaultLanguage="html"
        value={editorPageContent}
        onChange={throttle((value) => {
          if (!value) {
            return;
          }

          dispatch(setEditorPageContent(value));
        }, 1000)}
        // eslint-disable-next-line max-len
        //https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IStandaloneEditorConstructionOptions.html
        options={{
          minimap: {
            enabled: false,
          },
          colorDecorators: true,
          tabSize: 2,
          quickSuggestions: false,
        }}
      />
      {targetSiteUrl ? (
        <div>
          {frameError && (
            <Alert
              className="frameErrorAlert"
              message="Error"
              description={frameError}
              type="error"
              showIcon
            />
          )}
          {frameWarning && (
            <Alert
              className="frameWarningAlert"
              message="Warning!"
              description={frameWarning}
              type="warning"
              showIcon
            />
          )}
          <iframe
            className="cmsPageContentEditorIframe"
            scrolling="no"
            ref={previewFrameRef}
            src={targetSiteUrl + 'cms/preview'}
            width="100%"
            height="1050"
          />
        </div>
      ) : (
        <span>
          Set <b>TargetSiteUrl</b> for the page group to view preview
        </span>
      )}
    </div>
  );
};

export default CmsPageContentEditor;
