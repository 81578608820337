import type { CmsCollectionEntryModel } from '../models/cms/collections/CmsCollectionEntryModel';
import type { CmsCollectionModel } from '../models/cms/collections/CmsCollectionModel';
import type { CmsCollectionSchemaDataModel } from '../models/cms/collections/schemaData/CmsCollectionSchemaDataModel';
import type { CmsCollectionSchemaDataSelectModel } from '../models/cms/collections/schemaData/CmsCollectionSchemaDataSelectModel';
import { CmsCollectionSchemaDataTypeEnum } from '../enums/cms/CmsCollectionSchemaDataTypeEnum';
import WebAPIClient from './api/WebAPIClient';
import { head } from 'lodash';

const PATH = 'cms/collections';

export const createCollection = async (
  collection: CmsCollectionModel,
): Promise<CmsCollectionModel> => {
  const response = await WebAPIClient.post(PATH, { collection });

  return response?.data;
};

export const createCollectionEntry = async (
  collectionEntry: CmsCollectionEntryModel,
): Promise<CmsCollectionEntryModel> => {
  const response = await WebAPIClient.post(
    `${PATH}/${collectionEntry.collectionId}/entries`,
    collectionEntry,
  );

  return response?.data;
};

export const createCollectionSchemaData = async (
  collectionSchemaData: CmsCollectionSchemaDataModel,
): Promise<CmsCollectionSchemaDataModel | undefined> => {
  const response = await WebAPIClient.post(
    `${PATH}/${collectionSchemaData.collectionId}/schemadata`,
    [collectionSchemaData],
  );

  return head(response?.data);
};

export const deleteCollection = async (collectionId: number): Promise<void> => {
  await WebAPIClient.delete(`${PATH}/${collectionId}`);
};

export const deleteCollectionEntries = async (
  collectionEntries: Array<CmsCollectionEntryModel>,
): Promise<void> => {
  await WebAPIClient.post(
    `${PATH}/${head(collectionEntries)?.collectionId}/entries/delete`,
    collectionEntries.map((value) => value.id),
  );
};

export const deleteCollectionSchemaData = async (
  collectionSchemaData: CmsCollectionSchemaDataModel,
): Promise<void> => {
  await WebAPIClient.post(`${PATH}/${collectionSchemaData.collectionId}/schemadata/delete`, [
    collectionSchemaData.id,
  ]);
};

export const getCollections = async (): Promise<CmsCollectionModel[]> => {
  const response = await WebAPIClient.get(PATH);
  return response?.data;
};

export const getLastModifiedCollections = async (limit = 10): Promise<CmsCollectionModel[]> => {
  const response = await WebAPIClient.get(PATH, { limit: limit.toString() });

  return response?.data ?? [];
};

export const getCollection = async (collectionId: number): Promise<CmsCollectionModel> => {
  const response = await WebAPIClient.get(`${PATH}/${collectionId}`);

  return response?.data;
};

export const getCollectionEntry = async (
  collectionId: number,
  entryId: number,
  limit = 1,
): Promise<CmsCollectionEntryModel> => {
  const response = await WebAPIClient.get(
    `${PATH}/${collectionId}/entries/${entryId}?limit=${limit}`,
  );

  return response?.data;
};

export const saveCollectionEntryContent = async (
  cmsCollectionEntry: CmsCollectionEntryModel,
): Promise<CmsCollectionEntryModel> => {
  const response = await WebAPIClient.post(
    `${PATH}/${cmsCollectionEntry.collectionId}/entries/${cmsCollectionEntry.id}/content`,
    head(cmsCollectionEntry.entryVersions),
  );

  return response?.data;
};

export const updateCollectionEntry = async (
  cmsCollectionEntry: CmsCollectionEntryModel,
): Promise<CmsCollectionEntryModel> => {
  const response = await WebAPIClient.patch(
    `${PATH}/${cmsCollectionEntry.collectionId}/entries/${cmsCollectionEntry.id}`,
    {
      collectionId: cmsCollectionEntry.collectionId,
      collectionEntryId: cmsCollectionEntry.id,
      name: cmsCollectionEntry.name,
      localeId: cmsCollectionEntry.localeId,
    },
  );

  return response?.data;
};

export const updateCollectionSchemaData = async (
  cmsCollectionSchemaData: CmsCollectionSchemaDataModel,
): Promise<CmsCollectionSchemaDataModel> => {
  const requestBody =
    cmsCollectionSchemaData.schemaDataType === CmsCollectionSchemaDataTypeEnum.Select
      ? {
          schemaDataUpdate: [cmsCollectionSchemaData],
          schemaDataSelectValueUpdate: (
            cmsCollectionSchemaData as CmsCollectionSchemaDataSelectModel
          ).selectValues,
        }
      : { schemaDataUpdate: [cmsCollectionSchemaData] };

  const response = await WebAPIClient.patch(
    `${PATH}/${cmsCollectionSchemaData.collectionId}/schemadata`,
    requestBody,
  );

  return response?.data;
};

export const updateCollection = async (
  cmsCollection: CmsCollectionModel,
): Promise<CmsCollectionModel> => {
  const response = await WebAPIClient.patch(`${PATH}/${cmsCollection.id}`, cmsCollection);

  return response?.data;
};
