import '../../css/pages/AccessDeniedPage.css';
import React from 'react';
import { Button } from 'antd';
import ROUTES from '../../constants/routes';

const AccessDeniedPage = () => {
  return (
    <div className="access-denied-root">
      <div className="code">403</div>
      <div className="title">Access Denied</div>
      <div className="description">
        <p>The page you&apos;re trying to access has restricted access.</p>
        <p>Please refer to your system administrator.</p>
      </div>
      <div className="call-to-action">
        <Button type="primary" ghost size="large" href={ROUTES.HOME}>
          Return Home
        </Button>
      </div>
    </div>
  );
};

export default AccessDeniedPage;
