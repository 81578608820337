import { Checkbox, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import type { CmsPageGroupMetadataModel } from './../../../models/cms/CmsPageGroupMetadataModel';
import type { CmsPageGroupModel } from './../../../models/cms/CmsPageGroupModel';
import CmsPageGroupSelectInput from './items/CmsPageGroupSelectInput';
import CmsTitleSlugInputs from './items/CmsTitleSlugInputs';
import { get } from 'lodash';
import routes from '../../../constants/routes';
import useCreateCmsPageGroup from './../../../hooks/services/cms/useCreatePageGroup';
import { useNavigate } from 'react-router-dom';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import useUpdateCmsPageGroup from './../../../hooks/services/cms/useUpdatePageGroup';

const FORM_LAYOUT = {
  labelCol: { span: 6 },
};

type Props = {
  initialData?: CmsPageGroupMetadataModel | CmsPageGroupModel;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
};

const DEFAULT_PAGE_GROUP = {
  isActive: false,
  title: '',
  slug: '',
};

const CmsPageGroupModalForm = ({ initialData, isShown, setIsShown }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const {
    create,
    isLoading: isCreating,
    isSuccess: isCreated,
    isError: isCreateError,
  } = useCreateCmsPageGroup();
  const {
    update,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateCmsPageGroup();
  const [form] = Form.useForm();
  const isLoading = isCreating || isUpdating;
  const isSuccess = isCreated || isUpdated;
  const isError = isCreateError || isUpdateError;
  const isNewPageGroup = !initialData;
  const navigate = useNavigate();

  const onCancel = React.useCallback(() => {
    if (isLoading) {
      return;
    }

    form.resetFields();
    setIsShown(false);
  }, [isLoading, form, setIsShown]);

  const onSubmit = React.useCallback(async () => {
    const pageGroup = isNewPageGroup
      ? form.getFieldsValue()
      : { id: initialData?.id, ...form.getFieldsValue(['title', 'targetSiteUrl', 'slug']) };

    try {
      await form.validateFields();

      if (isNewPageGroup) {
        const request = {
          pageGroup: pageGroup,
          clonePagesPageGroupId: get(form.getFieldsValue(['pageGroupId']), 'pageGroupId', null),
        };
        create(request, {
          onSuccess: (data) => {
            onCancel();
            navigate(`${routes.PAGE_GROUPS}/${data.id}`);
          },
        });
      } else {
        update(pageGroup, { onSuccess: onCancel });
      }

      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [create, form, initialData?.id, isNewPageGroup, navigate, onCancel, update]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The page group was successfully ${
      isNewPageGroup ? 'created' : 'updated'
    } .`,
  });

  // Keep the formInstance initialValues in sync with the initialData props
  useEffect(() => {
    isShown && form.setFieldsValue(initialData);
  }, [initialData, isShown, form]);

  return (
    <Modal
      okButtonProps={{ loading: isLoading, disabled: !isChecked && !isNewPageGroup }}
      onOk={onSubmit}
      onCancel={onCancel}
      destroyOnClose={true}
      title={`${isNewPageGroup ? 'Create' : 'Update'} Page Group`}
      open={isShown}
    >
      <Form
        {...FORM_LAYOUT}
        className="cmsPageGroupModalFormRoot"
        form={form}
        preserve={false}
        initialValues={initialData ?? DEFAULT_PAGE_GROUP}
      >
        <CmsTitleSlugInputs slugDelimiter="_" />
        <Form.Item label="Target Site URL" name="targetSiteUrl">
          <Input maxLength={100} />
        </Form.Item>
        {isNewPageGroup && (
          <CmsPageGroupSelectInput
            isRequired={false}
            label="Clone Pages"
            tooltip="Clone pages from an already existing page group"
          />
        )}
        {!isNewPageGroup && (
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 16,
            }}
          >
            <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
              I understand that changing the <b>Slug</b> can impact production pages.
            </Checkbox>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CmsPageGroupModalForm;
