import CmsPageContentDiffEditor from './CmsPageContentDiffEditor';
import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import React from 'react';
import useCmsPage from '../../../hooks/services/cms/useCmsPage';

type Props = {
  page: CmsPageMetadataModel;
  newPageEnvironment: number;
  originalPageEnvironment: number;
};

export const CmsPageContentDiff = ({
  page,
  newPageEnvironment,
  originalPageEnvironment,
}: Props) => {
  const { data: newPage } = useCmsPage({
    groupSlug: page.group.slug,
    pageSlug: page.slug,
    localeId: page.localeId,
    environment: newPageEnvironment,
  });

  const { data: originalPage } = useCmsPage({
    groupSlug: page.group.slug,
    pageSlug: page.slug,
    localeId: page.localeId,
    environment: originalPageEnvironment,
  });

  return (
    <>
      {newPage && originalPage ? (
        <CmsPageContentDiffEditor
          originalContentTitle={`Current Content V${newPage?.contentVersions[0].version}`}
          originalContent={originalPage?.contentVersions[0].content}
          newContentTitle={`Target Content V${originalPage?.contentVersions[0].version}`}
          newContent={newPage?.contentVersions[0].content}
        />
      ) : (
        <>loading...</>
      )}
    </>
  );
};
