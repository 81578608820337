import '../../../css/core/PasswordInput.css';
import './../../../css/pages/vendor/VendorDetailPage.css';

import { Alert, Button, Descriptions, Divider, Input, Spin, Tabs } from 'antd';
import {
  getBooleanDisplayValue,
  getDateTimeDisplayValue,
  getModifiedBy,
} from './../../../utils/displayUtils';

import PageHeader from '../../shared/PageHeader';
import ROUTES from '../../../constants/routes';
import React, { useState } from 'react';
import Section from '../../shared/Section';
import VendorCodesCommercialTable from './../../vendor/tables/VendorCodesCommercialTable';
//import VendorCodesDirectMailTable from './../../vendor/tables/VendorCodesDirectMailTable';
import VendorCodesInternetTable from './../../vendor/tables/VendorCodesInternetTable';
import VendorModalForm from './../../vendor/forms/VendorModalForm';
import { useParams } from 'react-router-dom';
import useVendor from './../../../hooks/services/vendor/useVendor';
import type { TabsProps } from 'antd';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';

const VendorUpdateButton = ButtonDisabledWithInsufficientPermission(Button);

const VendorDetailPage = () => {
  const { vendorId } = useParams();
  const { data, isError, isLoading } = useVendor(vendorId!);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  if (isLoading) {
    return <Spin />;
  }

  if (isError || !data) {
    return (
      <Alert
        message="Error"
        description="An error occurred while attempting to load the data for this vendor."
        type="error"
        showIcon
      />
    );
  }

  const vendorCodeTabs: TabsProps['items'] = [
    {
      key: '1',
      label: 'Internet',
      children: (
        <Section isLoading={isLoading}>
          <VendorCodesInternetTable
            key={data.name + 'table'}
            data={data.vendorCodeInternets ?? []}
            vendorId={data.id}
            allowCreate={data.isActive}
          />
        </Section>
      ),
    },
    {
      key: '2',
      label: 'TFN Only',
      children: (
        <Section isLoading={isLoading}>
          <VendorCodesCommercialTable
            key={data.name + 'table'}
            data={data.vendorCodeCommercials ?? []}
            vendorId={data.id}
            allowCreate={data.isActive}
          />
        </Section>
      ),
    },
    // Disabling Direct Mail for now. Uncomment code below when ready to include again.
    // {
    //   key: '3',
    //   label: 'Direct Mail',
    //   children: (
    //     <Section isLoading={isLoading}>
    //       <VendorCodesDirectMailTable
    //         key={data.name + 'table'}
    //         data={data.vendorCodes ?? []}
    //         vendorId={data.id}
    //       />
    //     </Section>
    //   ),
    // },
  ];

  return (
    <div className="vendorDetailsPageRoot">
      <PageHeader
        breadcrumbRoutes={[
          { breadcrumbName: 'Vendors', path: ROUTES.VENDORS },
          { breadcrumbName: data.name, path: window.location.pathname },
        ]}
        extra={[
          <VendorUpdateButton
            key="update"
            type="primary"
            onClick={() => {
              setIsModalShown(true);
            }}
          >
            Update
          </VendorUpdateButton>,
        ]}
        subTitle={data.shortName}
        title={data.name}
      />
      <Section isLoading={isLoading}>
        <Descriptions
          key={data.name}
          bordered={true}
          column={3}
          labelStyle={{ fontWeight: 'bold' }}
          size="small"
        >
          <Descriptions.Item label="Is Active:">
            {getBooleanDisplayValue(data.isActive)}
          </Descriptions.Item>
          <Descriptions.Item label="Created By:">{getModifiedBy(data.createdBy)}</Descriptions.Item>
          <Descriptions.Item label="Updated By:">{getModifiedBy(data.updatedBy)}</Descriptions.Item>
          <Descriptions.Item label="Secret:">
            <Input.Password className="style-secret-disp" value={data.secret} readOnly />
          </Descriptions.Item>
          <Descriptions.Item label="Created Date:">
            {getDateTimeDisplayValue(data.createdDate)}
          </Descriptions.Item>
          <Descriptions.Item label="Updated Date:">
            {getDateTimeDisplayValue(data.updatedDate)}
          </Descriptions.Item>
        </Descriptions>
      </Section>
      <Divider />
      <PageHeader title="Vendor Codes" />
      <Tabs defaultActiveKey="1" className="VendorCodesTabs" items={vendorCodeTabs} />
      <VendorModalForm
        initialData={data}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        isCreatingNew={false}
      />
    </div>
  );
};

export default VendorDetailPage;
