import { useMutation, useQueryClient } from 'react-query';

import type { TVendorCodeCommercial } from './../../../models/vendor/VendorCodeModels';
import type { TUpdateModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { updateVendorCodeCommercial } from './../../../services/VendorCodesService';

const useUpdateVendorCodeCommercial = (): TUpdateModel<TVendorCodeCommercial> => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateVendorCodeCommercial, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.VENDOR_CODES ||
          query.queryKey[0] === QUERYKEYS.VENDORS ||
          query.queryKey[0] === QUERYKEYS.VENDOR_CODES_COMMERCIAL,
      }),
  });

  return {
    update: mutate,
    isLoading,
  };
};

export default useUpdateVendorCodeCommercial;
