import { useMutation, useQueryClient } from 'react-query';

import type { CmsUpdateMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import type { UpdateCmsReleaseRequestModel } from './../../../../services/types/CmsReleaseService/UpdateCmsReleaseRequestModel';
import { updateCmsRelease } from '../../../../services/CmsReleaseService';

// eslint-disable-next-line max-len
export default function useUpdateCmsRelease(): CmsUpdateMutationResponse<UpdateCmsReleaseRequestModel> {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(updateCmsRelease, {
    onSuccess: (release) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.RELEASES ||
          (query.queryKey[0] === QUERYKEYS.RELEASE && query.queryKey[1] === release.id),
      }),
  });

  return {
    update: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}
