import { useMutation, useQueryClient } from 'react-query';

import type { CmsCollectionSchemaDataModel } from '../../../../models/cms/collections/schemaData/CmsCollectionSchemaDataModel';
import type { CmsDeleteMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { deleteCollectionSchemaData } from '../../../../services/CmsCollectionService';

type CmsSchemaDataDeleteMutationResponse = CmsDeleteMutationResponse<CmsCollectionSchemaDataModel>;

function useDeleteCmsCollectionSchemaData(): CmsSchemaDataDeleteMutationResponse {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(deleteCollectionSchemaData, {
    onSuccess: (_data, variables) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === QUERYKEYS.COLLECTION &&
            query.queryKey[1] === variables.collectionId) ||
          query.queryKey[0] === QUERYKEYS.COLLECTION_ENTRY,
      }),
  });

  return {
    deleteMutation: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useDeleteCmsCollectionSchemaData;
