import CmsPageCloneButton from './CmsPageCloneButton';
import CmsPageDeleteButton from './CmsPageArchiveButton';
import CmsPagePublishButton from './CmsPagePublishButton';
import CmsPageSaveButton from './CmsPageSaveButton';
import type { CmsSavePageContentModel } from '../../../models/cms/CmsSavePageContentModel';
import React from 'react';
import { Space } from 'antd';
import routes from '../../../constants/routes';
import useCmsPageEditContext from '../../../hooks/contexts/useCmsPageEditContext';
import { useNavigate } from 'react-router-dom';

const CmsPageEditActionButtons = () => {
  const { state } = useCmsPageEditContext();
  const { pageModel, editorPageContent } = state;
  const navigate = useNavigate();

  if (!pageModel) {
    return null;
  }

  const pageContentModel: CmsSavePageContentModel = {
    pageId: pageModel.id,
    content: editorPageContent,
  };
  return (
    <Space>
      <CmsPageSaveButton pageContentModel={pageContentModel} />
      <CmsPagePublishButton isConfirmRequired={true} data={pageContentModel} />
      <CmsPageCloneButton pageIds={[pageModel.id]} />
      <CmsPageDeleteButton
        data={[pageModel]}
        onDelete={() => {
          navigate(routes.CMS);
        }}
      />
    </Space>
  );
};

export default CmsPageEditActionButtons;
