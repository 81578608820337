import '../../../css/cms/displays/CmsPageRightSidebar.css';

import { BranchesOutlined, TagOutlined } from '@ant-design/icons';
import { filter, orderBy } from 'lodash';

import type { CmsPageContentVersionMetadataModel } from '../../../models/cms/CmsPageContentVersionMetadataModel';
import CmsPageHistoryList from './CmsPageHistoryList';
import React from 'react';
import { Tabs, Tooltip } from 'antd';
import useCmsPageContentVersions from '../../../hooks/services/cms/useCmsPageContentVersions';
import { useParams } from 'react-router-dom';

const CmsPageRightSidebar = () => {
  const { pageId } = useParams();
  const { data, isLoading } = useCmsPageContentVersions(+pageId!);
  const versions = orderBy(data?.contentMetadata, ['version'], ['desc']);

  const versionsWithEnvs: CmsPageContentVersionMetadataModel[] = React.useMemo(() => {
    return filter(versions, (version) => version.versionEnvironments.length > 0);
  }, [versions]);

  return (
    <div className="cmsPageRightSidebarRoot">
      <Tabs
        defaultActiveKey="1"
        type="card"
        size="small"
        items={[
          {
            label: (
              <Tooltip title="History">
                <BranchesOutlined />
              </Tooltip>
            ),
            key: 'history',
            children: (
              <CmsPageHistoryList title="History" versions={versions} isLoading={isLoading} />
            ),
          },
          {
            label: (
              <Tooltip title="Tagged Versions">
                <TagOutlined />
              </Tooltip>
            ),
            key: 'environments',
            children: (
              <CmsPageHistoryList
                title="Tagged Versions"
                versions={versionsWithEnvs}
                isLoading={isLoading}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default CmsPageRightSidebar;
