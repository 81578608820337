import QUERYKEYS from '../../../constants/queryKeys';
import type { TQueryModel } from '../../../utils/hookUtils';
import type { TVendorCodesInternet } from './../../../models/vendor/VendorCodeModels';
import { getVendorCodeInternet } from './../../../services/VendorCodesService';
import { useQuery } from 'react-query';

function useVendorCodeInternet(vendorCodeId: string): TQueryModel<TVendorCodesInternet> {
  const { isLoading, isError, data } = useQuery(
    [QUERYKEYS.VENDOR_CODES_INTERNET, vendorCodeId],
    () => getVendorCodeInternet(vendorCodeId),
  );

  return {
    data,
    isError,
    isLoading,
  };
}

export default useVendorCodeInternet;
