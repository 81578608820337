import { Outlet, Route, Routes } from 'react-router-dom';

import AccessDeniedPage from '../pages/AccessDeniedPage';
import { AuthorizationPage } from '../pages/AuthorizationPage';
import AreaContextProvider from '../../contexts/AreaContextProvider';
import { AreasEnum } from '../../enums/shared/AreasEnum';
import CmsCollectionEditPage from '../pages/cms/CmsCollectionEditPage';
import CmsCollectionEntriesPage from '../pages/cms/CmsCollectionEntriesPage';
import { CmsCollectionEntryCreatePage } from '../pages/cms/CmsCollectionEntryCreatePage';
import { CmsCollectionEntryEditPage } from '../pages/cms/CmsCollectionEntryEditPage';
import { CmsCollectionsPage } from '../pages/cms/CmsCollectionsPage';
import CmsHomePage from './../pages/cms/CmsHomePage';
import CmsPageEditContextProvider from '../../contexts/cms/cmsPageEdit/CmsPageEditContextProvider';
import CmsPageEditPage from './../pages/cms/CmsPageEditPage';
import CmsPageGroupPage from './../pages/cms/CmsPageGroupPage';
import CmsPageGroupsPage from './../pages/cms/CmsPageGroupsPage';
import CmsPagesPage from '../pages/cms/CmsPagesPage';
import { CmsReleaseDetailsPage } from '../pages/cms/CmsReleaseDetailsPage';
import CmsReleasesPage from '../pages/cms/CmsReleasesPage';
import HomePage from './../pages/HomePage';
import MasterLayout from './../layouts/MasterLayout';
import PageNotFoundPage from '../pages/PageNotFoundPage';
import PromoCodesPage from './../pages/promoCodes/PromoCodesPage';
import ROUTES from '../../constants/routes';
import React from 'react';
import RulesPage from './../pages/rules/RulesPage';
import VendorCodeCommercialDetailPage from './../pages/vendor/VendorCodeCommercialDetailPage';
import VendorCodeInternetDetailPage from './../pages/vendor/VendorCodeInternetDetailPage';
import VendorCodesPage from '../pages/vendor/VendorCodesPage';
import VendorDetailPage from './../pages/vendor/VendorDetailPage';
import VendorsPage from '../pages/vendor/VendorsPage';

const AdminRoutes = () => {
  const isDevEnvironment =
    !process.env.CUSTOM_ENV ||
    process.env.CUSTOM_ENV === 'dev' ||
    process.env.CUSTOM_ENV === 'local';

  return (
    <Routes>
      <Route path={ROUTES.AUTHORIZE} element={<AuthorizationPage />} />
      <Route path={ROUTES.HOME} element={<MasterLayout />}>
        {/* For initial release, set default landing to Vendors page. 
        Dev can remain on Welcome landing page */}
        <Route path={ROUTES.HOME} element={!isDevEnvironment ? <VendorsPage /> : <HomePage />} />
        <Route path={ROUTES.CMS} element={<Outlet />}>
          <Route
            path={ROUTES.PAGES}
            element={
              <AreaContextProvider area={AreasEnum.CMS}>
                <CmsPagesPage />
              </AreaContextProvider>
            }
          />
          <Route path={ROUTES.PAGE_GROUPS} element={<Outlet />}>
            <Route
              path=":pageGroupId"
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsPageGroupPage />
                </AreaContextProvider>
              }
            />
            <Route
              path=":pageGroupId/page/:pageId"
              element={
                <CmsPageEditContextProvider>
                  <AreaContextProvider area={AreasEnum.CMS}>
                    <CmsPageEditPage />
                  </AreaContextProvider>
                </CmsPageEditContextProvider>
              }
            />
            <Route
              index
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsPageGroupsPage />
                </AreaContextProvider>
              }
            />
          </Route>
          <Route path={ROUTES.CMS_RELEASES} element={<Outlet />}>
            <Route
              path=":releaseId"
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsReleaseDetailsPage />
                </AreaContextProvider>
              }
            />
            <Route
              index
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsReleasesPage />
                </AreaContextProvider>
              }
            />
          </Route>
          <Route path={ROUTES.CMS_COLLECTIONS} element={<Outlet />}>
            <Route
              path=":collectionId/schema"
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsCollectionEditPage />
                </AreaContextProvider>
              }
            />
            <Route
              path=":collectionId/entries"
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsCollectionEntriesPage />
                </AreaContextProvider>
              }
            />
            <Route
              path=":collectionId/entries/new"
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsCollectionEntryCreatePage />
                </AreaContextProvider>
              }
            />
            <Route
              path=":collectionId/entries/:entryId"
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsCollectionEntryEditPage />
                </AreaContextProvider>
              }
            />
            <Route
              index
              element={
                <AreaContextProvider area={AreasEnum.CMS}>
                  <CmsCollectionsPage />
                </AreaContextProvider>
              }
            />
          </Route>
          <Route
            index
            element={
              <AreaContextProvider area={AreasEnum.CMS}>
                <CmsHomePage />
              </AreaContextProvider>
            }
          />
        </Route>
        <Route
          path={ROUTES.PROMO_CODES}
          element={
            <AreaContextProvider area={AreasEnum.PROMO_CODES}>
              <PromoCodesPage />
            </AreaContextProvider>
          }
        />
        <Route
          path={ROUTES.RULES}
          element={
            <AreaContextProvider area={AreasEnum.RULES}>
              <RulesPage />
            </AreaContextProvider>
          }
        />
        <Route path={ROUTES.VENDORS} element={<Outlet />}>
          <Route
            path=":vendorId"
            element={
              <AreaContextProvider area={AreasEnum.VENDORS}>
                <VendorDetailPage />
              </AreaContextProvider>
            }
          />
          <Route
            index
            element={
              <AreaContextProvider area={AreasEnum.VENDORS}>
                <VendorsPage />
              </AreaContextProvider>
            }
          />
        </Route>
        <Route
          path={ROUTES.VENDOR_CODES}
          element={
            <AreaContextProvider area={AreasEnum.VENDORS}>
              <VendorCodesPage />
            </AreaContextProvider>
          }
        />
        <Route
          path={`${ROUTES.VENDOR_CODES_COMMERCIAL}/:vendorCodeId`}
          element={
            <AreaContextProvider area={AreasEnum.VENDORS}>
              <VendorCodeCommercialDetailPage />
            </AreaContextProvider>
          }
        />
        <Route
          path={`${ROUTES.VENDOR_CODES_INTERNET}/:vendorCodeId`}
          element={
            <AreaContextProvider area={AreasEnum.VENDORS}>
              <VendorCodeInternetDetailPage />
            </AreaContextProvider>
          }
        />
        <Route path={ROUTES.ACCESS_DENIED} element={<AccessDeniedPage />} />
        <Route path="*" element={<PageNotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
