import { Button } from 'antd';
import type { CmsPageContentVersionMetadataModel } from '../../../models/cms/CmsPageContentVersionMetadataModel';
import CmsPageRevertModal from '../modals/CmsPageRevertModal';
import React from 'react';

type Props = {
  contentVersion: CmsPageContentVersionMetadataModel;
};

const CmsPageHistoryModalButton = ({ contentVersion }: Props) => {
  const [isModalShown, setIsModalShown] = React.useState(false);

  return (
    <>
      <Button type="link" onClick={() => setIsModalShown(true)}></Button>
      <CmsPageRevertModal
        contentVersion={contentVersion}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </>
  );
};

export default CmsPageHistoryModalButton;
