import { Form, Select } from 'antd';

import CmsPageContentEnvironmentText from '../../displays/CmsPageContentEnvironmentText';
import { CmsPageEnvironmentEnum } from './../../../../enums/cms/CmsPageEnvironmentEnum';
import React from 'react';

type Props = {
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  name?: string;
  onSelect?: (selectedEnv: number) => void;
  tooltip?: string;
};

const CmsEnvironmentDropdown = ({
  isDisabled = false,
  isRequired = true,
  label = 'Environment',
  name = 'environment',
  onSelect,
  tooltip,
}: Props) => {
  return (
    <>
      <Form.Item
        label={label}
        name={name}
        tooltip={tooltip}
        rules={[{ message: '"${label}" is required', required: isRequired }]}
      >
        <Select disabled={isDisabled} onSelect={onSelect}>
          <Select.Option key={1} value={CmsPageEnvironmentEnum.DEV}>
            <CmsPageContentEnvironmentText environment={CmsPageEnvironmentEnum.DEV} />
          </Select.Option>
          <Select.Option key={2} value={CmsPageEnvironmentEnum.QA}>
            <CmsPageContentEnvironmentText environment={CmsPageEnvironmentEnum.QA} />
          </Select.Option>
          <Select.Option key={3} value={CmsPageEnvironmentEnum.STAGING}>
            <CmsPageContentEnvironmentText environment={CmsPageEnvironmentEnum.STAGING} />
          </Select.Option>
          <Select.Option key={4} value={CmsPageEnvironmentEnum.PROD}>
            <CmsPageContentEnvironmentText environment={CmsPageEnvironmentEnum.PROD} />
          </Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};

export default CmsEnvironmentDropdown;
