import React, { useState } from 'react';
import { Button } from 'antd';
import CmsCollectionModalForm from './../../forms/CmsCollectionModalForm';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  isDisabled?: boolean;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const CmsCollectionCreateButton = ({ isDisabled }: Props) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <EnhancedButton type="primary" disabled={isDisabled} onClick={() => setIsModalShown(true)}>
        Add New Collection
      </EnhancedButton>
      <CmsCollectionModalForm isShown={isModalShown} setIsShown={setIsModalShown} />
    </>
  );
};

export default CmsCollectionCreateButton;
