import React from 'react';
import type { Key } from 'react';
import { Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { FilterConfirmProps } from 'antd/lib/table/interface';

import './../../../css/shared/columnFilters/TextSearchColumnFilter.css';

type Props = {
  extra?: React.ReactElement;
  setSelectedKeys: (selectedKeys: Key[]) => void;
  selectedKeys: Key[];
  confirm: (param?: FilterConfirmProps) => void;
  clearFilters: (() => void) | undefined;
};

const TextSearchColumnFilter = ({
  extra,
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
}: Props) => {
  return (
    <div className="textSearchFilterRoot">
      <Input
        autoFocus
        placeholder={'Search...'}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
          confirm({ closeDropdown: false });
        }}
        onPressEnter={() => confirm()}
        className="textSearchFilterInput"
      />
      <Button
        type="primary"
        onClick={() => confirm()}
        icon={<SearchOutlined />}
        className="textSearchFilterButtons"
      >
        Search
      </Button>
      <Button
        className="textSearchFilterButtons"
        onClick={() => {
          clearFilters?.();
          confirm();
        }}
      >
        Reset
      </Button>
      {extra}
    </div>
  );
};

export default TextSearchColumnFilter;
