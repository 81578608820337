import type { PromoCodeModel } from '../models/promoCodes/PromoCodeModel';
import type { PromoCodeDiscountModel } from '../models/promoCodes/PromoCodeDiscountModel';
import WebAPIClient from './api/WebAPIClient';

const PATH = 'promocodes';

export const getPromoCodes = async (): Promise<Array<PromoCodeModel>> => {
  const response = await WebAPIClient.get(PATH);
  return response?.data;
};

export const createPromoCode = async (promoCode: PromoCodeModel): Promise<PromoCodeModel> => {
  const response = await WebAPIClient.post(PATH, promoCode);
  return response?.data;
};

export const updatePromoCode = async (promoCode: PromoCodeModel): Promise<PromoCodeModel> => {
  const isEnabledParam =
    promoCode.isEnabled !== undefined && promoCode.isEnabled !== null
      ? `&isEnabled=${promoCode.isEnabled}`
      : '';
  const response = await WebAPIClient.put(
    `${PATH}/${promoCode.id}?name=${promoCode.name}${isEnabledParam}`,
    null,
  );
  return response?.data;
};

export const deletePromoCodes = async (promoCodeIds: Array<number>): Promise<void> => {
  await WebAPIClient.post(`${PATH}/delete`, promoCodeIds);
};

export const createPromoCodeDiscount = async (
  promoCodeDiscount: PromoCodeDiscountModel,
): Promise<PromoCodeDiscountModel> => {
  const response = await WebAPIClient.post(
    `${PATH}/${promoCodeDiscount.promoCodeId}/discounts`,
    promoCodeDiscount,
  );
  return response?.data;
};

export const deletePromoCodeDiscount = async (
  promoCodeId: number,
  promoCodeDiscountId: number,
): Promise<void> => {
  await WebAPIClient.delete(`${PATH}/${promoCodeId}/discounts/${promoCodeDiscountId}`);
};
