import '../../css/nav/SiteHeader.css';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ROUTES from '../../constants/routes';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AreasEnum } from '../../enums/shared/AreasEnum';
import useCurrentUser from '../../hooks/shared/useCurrentUser';
import useCurrentUserHasAccessToArea from '../../hooks/shared/useCurrentUserHasAccessToArea';

const SiteHeader = () => {
  const navigate = useNavigate();
  const [currentKey, setCurrentKey] = useState<string>('');
  const [currentUser] = useCurrentUser();
  const cmsAccess = useCurrentUserHasAccessToArea(AreasEnum.CMS);
  const promoCodesAccess = useCurrentUserHasAccessToArea(AreasEnum.PROMO_CODES);
  const rulesAccess = useCurrentUserHasAccessToArea(AreasEnum.RULES);
  const vendorAccess = useCurrentUserHasAccessToArea(AreasEnum.VENDORS);
  const { instance } = useMsal();

  const items = [
    {
      label: <Link to={ROUTES.HOME}>Admin Portal</Link>,
      key: 'admin-portal',
      icon: <img src="/static/images/thumb_Icons-END-Small@2x.png" height="18px" />,
    },
    {
      label: 'CMS',
      key: 'cms-home',
      disabled: !cmsAccess.hasAccessToArea,
      onTitleClick: () => {
        setCurrentKey('cms-home');
        navigate(ROUTES.CMS);
      },
      children: [
        {
          label: <Link to={ROUTES.PAGES}>Pages</Link>,
          key: 'pages',
        },
        {
          label: <Link to={ROUTES.PAGE_GROUPS}>Page Groups</Link>,
          key: 'page-groups',
        },
        {
          label: <Link to={ROUTES.CMS_RELEASES}>Releases</Link>,
          key: 'cms-releases',
        },
        {
          label: <Link to={ROUTES.CMS_COLLECTIONS}>Collections</Link>,
          key: 'cms-collections',
        },
      ],
    },
    {
      label: <Link to={ROUTES.PROMO_CODES}>Promo Codes</Link>,
      key: 'promo-codes-home',
      disabled: !promoCodesAccess.hasAccessToArea,
    },
    {
      label: <Link to={ROUTES.RULES}>Rules</Link>,
      key: 'rules',
      disabled: !rulesAccess.hasAccessToArea,
    },
    {
      label: 'Vendors',
      key: 'vendors-home',
      disabled: !vendorAccess.hasAccessToArea,
      onTitleClick: () => {
        setCurrentKey('vendors-home');
        navigate(ROUTES.VENDORS);
      },
      children: [
        {
          label: <Link to={ROUTES.VENDOR_CODES}>Vendor Codes</Link>,
          key: 'vendors-codes',
        },
      ],
    },
    {
      label: currentUser.name,
      key: 'logged-in-user',
      icon: <UserOutlined />,
      children: [
        {
          label: 'Log Out',
          key: 'log-out',
          onClick: () => {
            const logoutRequest = {
              account: instance.getAccountByHomeId(currentUser.homeAccountId || ''),
              postLogoutRedirectUri: ROUTES.HOME,
            };
            instance.logoutRedirect(logoutRequest);
          },
        },
      ],
    },
  ];

  return (
    <nav className="siteHeaderRoot">
      <Menu
        onClick={(e) => setCurrentKey(e.key)}
        selectedKeys={[currentKey]}
        items={items}
        mode="horizontal"
      />
    </nav>
  );
};

export default SiteHeader;
