import { useMutation, useQueryClient } from 'react-query';

import type { CmsDeleteMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { deleteCollection } from '../../../../services/CmsCollectionService';

export default function useDeleteCmsCollection(): CmsDeleteMutationResponse<number> {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(deleteCollection, {
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries(QUERYKEYS.COLLECTIONS);
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === QUERYKEYS.COLLECTION && query.queryKey[1] === id,
      });
    },
  });

  return {
    deleteMutation: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}
