import React from 'react';
import { Form, Select } from 'antd';
import type { NamePath } from 'antd/lib/form/interface';
import { sortBy } from 'lodash';

import type { CmsCollectionSchemaDataRefModel } from '../../../models/cms/collections/schemaData/CmsCollectionSchemaDataRefModel';
import { CmsCollectionSchemaDataRefTypeEnum } from '../../../enums/cms/CmsCollectionSchemaDataRefTypeEnum';
import useCmsCollection from '../../../hooks/services/cms/collections/useCmsCollection';
import InputDisabledWithInsufficientPermission from '../../hocs/inputs/InputDisabledWithInsufficientPermission';

type Props = {
  formItemName: NamePath;
  schemaData: CmsCollectionSchemaDataRefModel;
};

const EnhancedSelect = InputDisabledWithInsufficientPermission(Select);

export const CmsCollectionRefEntrySelect = ({ formItemName, schemaData }: Props) => {
  const form = Form.useFormInstance();

  const { data, isLoading } = useCmsCollection(schemaData.refCollectionId);
  const isMultiSelect = schemaData.refType === CmsCollectionSchemaDataRefTypeEnum.OneToMany;
  const entries = sortBy(data?.entries, ['name']);
  return (
    <EnhancedSelect
      loading={isLoading}
      showSearch={isMultiSelect}
      mode={isMultiSelect ? 'multiple' : undefined}
      defaultValue={form.getFieldValue(formItemName)?.map((item) => item.entryId) ?? []}
      filterOption={(input, option) =>
        option?.children?.toString().toLowerCase().includes(input.toLowerCase()) ?? false
      }
      onChange={(value) => {
        if (value === 0) {
          form.setFieldValue(formItemName, []);
          form.validateFields();
          return;
        }

        const refValues = isMultiSelect
          ? value.map((entry) => {
              return {
                entryId: entry,
              };
            })
          : [{ entryId: value }];
        form.setFieldValue(formItemName, refValues);
        form.validateFields();
      }}
    >
      {!isMultiSelect && !schemaData.isRequired && (
        <Select.Option key={-1} value={0}>
          {' '}
        </Select.Option>
      )}
      {entries?.map((entry, ii) => (
        <Select.Option value={entry.id} key={ii}>
          {entry.name}
        </Select.Option>
      ))}
    </EnhancedSelect>
  );
};
