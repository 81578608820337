import React from 'react';
import useCurrentUserHasAccessToArea from '../../../hooks/shared/useCurrentUserHasAccessToArea';
import type { AreasEnum } from '../../../enums/shared/AreasEnum';
import useAreaContext from '../../../hooks/contexts/useAreaContext';

const ButtonHiddenWithInsufficientPermission = (Button) => {
  return function GetButtonHiddenWithInsufficientPermission(props) {
    const areaContext: AreasEnum = useAreaContext();
    const userPermissions = useCurrentUserHasAccessToArea(areaContext);
    const isHidden = !userPermissions.canEdit;

    return !isHidden ? <Button {...props} /> : null;
  };
};

export default ButtonHiddenWithInsufficientPermission;
