import React, { useMemo } from 'react';
import { Button } from 'antd';
import type { ColumnType } from 'antd/lib/table';
import { uniqBy } from 'lodash';

import type { $RuleModel } from '../../../models/rules/RuleModel';
import { buildExpressionString } from '../../../utils/ruleUtils';
import { INPUT_SCHEMA_NAMES } from '../../../constants/rules/ruleInputSchemas';

type Config = Readonly<{
  data?: $RuleModel[];
  updateAction: (record: any) => void;
  deleteAction: (record: any) => void;
}>;

export default function useRulesTableSchema({
  data,
  updateAction,
  deleteAction,
}: Config): Array<ColumnType<$RuleModel>> {
  return useMemo(
    () => [
      {
        dataIndex: 'name',
        title: 'Name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        filterSearch: true,
        filters: uniqBy(data, 'name').map((record) => ({
          text: record.name,
          value: record.name,
        })),
        onFilter: (searchValue: string, record: $RuleModel): boolean => searchValue === record.name,
      },
      {
        dataIndex: 'inputSchema',
        render: (inputSchemaType) => INPUT_SCHEMA_NAMES[inputSchemaType],
        title: 'Input Schema',
        sorter: (a, b) =>
          INPUT_SCHEMA_NAMES[a.inputSchema].localeCompare(INPUT_SCHEMA_NAMES[b.inputSchema]),
        filterSearch: true,
        filters: uniqBy(data, 'inputSchemaName').map((record) => ({
          text: INPUT_SCHEMA_NAMES[record.inputSchema],
          value: record.inputSchema,
        })),
        onFilter: (searchValue: number, record: $RuleModel): boolean =>
          searchValue === record.inputSchema,
      },
      {
        dataIndex: 'booleanExpressions',
        render: buildExpressionString,
        title: 'Expression',
      },
      {
        title: 'Actions',
        key: 'action',
        width: '225px',
        render: (_, record) => (
          <>
            <Button type="primary" onClick={() => updateAction(record)}>
              Update
            </Button>
            <Button
              style={{ marginLeft: 6 }}
              type="primary"
              danger={true}
              onClick={() => deleteAction(record)}
            >
              Delete
            </Button>
          </>
        ),
      },
    ],
    [data, deleteAction, updateAction],
  );
}
