import type { CmsCollectionModel } from '../../../../models/cms/collections/CmsCollectionModel';
import type { CmsQueryResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { getCollection } from '../../../../services/CmsCollectionService';
import { isNil } from 'lodash';
import { useQuery } from 'react-query';

const useCmsCollection = (id: number): CmsQueryResponse<CmsCollectionModel> => {
  const { isLoading, isError, data } = useQuery(
    [QUERYKEYS.COLLECTION, id],
    async () => getCollection(id),
    {
      staleTime: 0,
      enabled: !isNil(id) && !isNaN(id) && id !== 0,
      retry: false,
    },
  );

  return {
    data,
    isError,
    isLoading,
  };
};

export default useCmsCollection;
