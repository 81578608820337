import '../../css/shared/PageHeader.css';

import React, { type ReactElement } from 'react';

import { PageHeader as AntdPageHeader } from 'antd';
import { Link } from 'react-router-dom';

type Props = {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  breadcrumbRoutes?: Array<{ breadcrumbName: string; path: string }>;
  extra?: Array<ReactElement>;
  onBack?: () => void;
  isSubTitlePill?: boolean;
  pillClassName?: string;
};

const PageHeader = ({
  title,
  subTitle,
  isSubTitlePill,
  pillClassName,
  breadcrumbRoutes,
  extra,
  onBack,
}: Props) => {
  const additionalClassName = pillClassName ? ` ${pillClassName}` : '';
  const additionalPillClassName = isSubTitlePill ? ' subTitlePill' : '';
  const className = 'pageHeaderRoot' + additionalClassName + additionalPillClassName;

  return (
    <div className={className}>
      <AntdPageHeader
        title={title}
        subTitle={subTitle}
        breadcrumb={
          breadcrumbRoutes && {
            routes: breadcrumbRoutes,
            itemRender: (route, params, routes) => {
              const last = routes.indexOf(route) === routes.length - 1;
              return last ? (
                <span>{route.breadcrumbName}</span>
              ) : (
                <Link to={route.path}>{route.breadcrumbName}</Link>
              );
            },
          }
        }
        extra={extra}
        onBack={onBack}
      />
    </div>
  );
};

export default PageHeader;
