import type { CmsPageContentVersionsModel } from '../../../models/cms/CmsPageContentVersionsModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { getPageContentVersions } from '../../../services/CmsPageService';
import { useQuery } from 'react-query';

type TUseCmsPageContent = {
  data?: CmsPageContentVersionsModel;
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsPagesContentVersions(id: number): TUseCmsPageContent {
  const response = useQuery({
    queryKey: [QUERYKEYS.PAGE_CONTENT_VERSIONS, { id }],
    queryFn: async () => getPageContentVersions(id),
    staleTime: 0,
  });

  return response;
}
