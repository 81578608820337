import ROUTES from '../../constants/routes';

const PageExists = (): boolean => {
  const path = window.location.pathname;
  const allRoutes = Object.values(ROUTES);
  allRoutes.sort(sortAlphabeticallyDescThenByLengthDesc);

  let routeIsReal = false;
  allRoutes.forEach((route) => {
    if (routeIsReal) return;

    const isHomeRoute = route === ROUTES.HOME;
    const isHomePath = path === ROUTES.HOME;
    if (isHomePath && isHomeRoute) {
      routeIsReal = true;
      return;
    }

    if (!isHomeRoute && path.toLowerCase().startsWith(route.toLowerCase())) routeIsReal = true;
  });

  return routeIsReal;
};

function sortAlphabeticallyDescThenByLengthDesc(a: string, b: string): number {
  if (a.localeCompare(b) === 0) {
    if (a.length < b.length) return -1;
    if (a.length > b.length) return 1;
    return 0;
  } else return b.localeCompare(a);
}

export default PageExists;
