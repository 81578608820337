import CmsPageCreateButton from '../../cms/buttons/CmsPageCreateButton';
import CmsPagesTable from '../../cms/tables/CmsPagesTable';
import PageHeader from '../../shared/PageHeader';
import React from 'react';
import { Row } from 'antd';
import Section from '../../shared/Section';
import routes from '../../../constants/routes';
import useCmsPages from '../../../hooks/services/cms/useCmsPages';

const CmsPagesPage = () => {
  const { data, isLoading } = useCmsPages();

  return (
    <div>
      <PageHeader
        title="CMS Pages"
        breadcrumbRoutes={[
          { breadcrumbName: 'Home', path: routes.CMS },
          { breadcrumbName: 'Pages', path: routes.PAGES },
        ]}
        extra={[
          <Row key="actionBar" justify="end">
            <CmsPageCreateButton />
          </Row>,
        ]}
      />
      <Section isLoading={isLoading}>
        <CmsPagesTable key="table1" data={data} />
      </Section>
    </div>
  );
};

export default CmsPagesPage;
