import { RuleExpressionBooleanOperatorEnum } from '../../enums/rules/RuleExpressionBooleanOperatorEnum';

// These are the symbols that represent each boolean operator. A null means there is no symbol for
// the operator.
export const RULE_BOOLEAN_OPERATOR_SYMBOLS: Record<
  RuleExpressionBooleanOperatorEnum,
  string | null
> = {
  [RuleExpressionBooleanOperatorEnum.CONTAINS]: null,
  [RuleExpressionBooleanOperatorEnum.DOES_NOT_EQUAL]: '!=',
  [RuleExpressionBooleanOperatorEnum.ENDS_WITH]: null,
  [RuleExpressionBooleanOperatorEnum.EQUALS]: '==',
  [RuleExpressionBooleanOperatorEnum.GREATER_THAN]: '>',
  [RuleExpressionBooleanOperatorEnum.GREATER_THAN_OR_EQUAL]: '>=',
  [RuleExpressionBooleanOperatorEnum.IN]: null,
  [RuleExpressionBooleanOperatorEnum.INCLUDES]: null,
  [RuleExpressionBooleanOperatorEnum.LESS_THAN]: '<',
  [RuleExpressionBooleanOperatorEnum.LESS_THAN_OR_EQUAL]: '<=',
  [RuleExpressionBooleanOperatorEnum.STARTS_WITH]: null,
};
