import { useMutation, useQueryClient } from 'react-query';
import type { PromoCodeModel } from './../../../models/promoCodes/PromoCodeModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { createPromoCode } from './../../../services/PromoCodesService';
import type { TCreateModel } from '../../../utils/hookUtils';

const useCreatePromoCode = (): TCreateModel<PromoCodeModel> => {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(createPromoCode, {
    onSuccess: (data) =>
      queryClient.setQueryData(QUERYKEYS.PROMO_CODES, (promoCodes: any) => [...promoCodes, data]),
  });

  return {
    create: mutate,
    isError,
    isLoading,
    isSuccess,
  };
};

export default useCreatePromoCode;
