import React, { useEffect } from 'react';
import { useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';
import { useLocation, useNavigate } from 'react-router-dom';

import { InteractionType } from '@azure/msal-browser';
import ROUTES from '../../constants/routes';
import { Spin } from 'antd';

type propState = {
  from: string;
};

export const AuthorizationPage = () => {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  // This is safe from CSRF because it's appended to the state guid instead of replacing it
  // eslint-disable-next-line max-len
  // https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-js-pass-custom-state-authentication-request
  const authRequest = {
    state: (state as propState)?.from,
  };
  const { error, result } = useMsalAuthentication(InteractionType.Redirect, authRequest);
  const redirectUrl =
    result?.state && Object.values(ROUTES).includes(result.state) ? result.state : ROUTES.HOME;

  useEffect(() => {
    if (error !== null) {
      setHasError(true);
      console.error('User failed to login', error);
    }
  }, [error]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectUrl, { replace: true });
    }
  }, [isAuthenticated, redirectUrl, navigate]);

  return <div> {hasError ? 'Something went wrong. Please reload and try again.' : <Spin />}</div>;
};
