import { Form, Input } from 'antd';
import { convertTitleToSlug, getSlugValidationRegex } from './../../../../utils/cmsUtils';

import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const REQUIRED_RULE = { message: '"${label}" is required', required: true };

type Props = {
  slugDelimiter?: string;
};

const CmsFormTitleSlugInputs = ({ slugDelimiter = '-' }: Props) => {
  const form = Form.useFormInstance();
  const [disableAutoComplete, setDisableAutoComplete] = useState(false);

  // If we have values on title and slug on mount, disable autocomplete.
  useEffect(() => {
    const { title, slug } = form.getFieldsValue(['title', 'slug']);
    if (title && slug) {
      setDisableAutoComplete(true);
    }
  }, [form]);

  return (
    <>
      <Form.Item label="Title" name="title" rules={[REQUIRED_RULE]}>
        <Input
          maxLength={128}
          onChange={(e) => {
            if (disableAutoComplete) return;
            form.setFieldsValue({
              slug: convertTitleToSlug(e.currentTarget.value.toLowerCase(), slugDelimiter),
            });
          }}
        />
      </Form.Item>
      <Form.Item
        label="Slug"
        name="slug"
        rules={[
          REQUIRED_RULE,
          {
            message: '"${label}" can only contain letters, numbers, dashes, and underscores.',
            pattern: getSlugValidationRegex(),
          },
        ]}
      >
        <Input maxLength={128} />
      </Form.Item>
    </>
  );
};

export default CmsFormTitleSlugInputs;
