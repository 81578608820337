import { useMutation, useQueryClient } from 'react-query';

import type { CmsCreateMutationResponse } from '../../../models/cms/CmsResponseModels';
import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { createCmsPage } from '../../../services/CmsPageService';

function useCreateCmsPage(): CmsCreateMutationResponse<CmsPageMetadataModel> {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(createCmsPage, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGES ||
          query.queryKey === QUERYKEYS.PAGES ||
          query.queryKey[0] === QUERYKEYS.PAGE_GROUP,
      }),
  });

  return {
    create: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useCreateCmsPage;
