import type { AreasEnum } from '../../enums/shared/AreasEnum';
import useCurrentUserRoles from './useCurrentUserRoles';

export type AccessPermission = {
  hasAccessToArea: boolean;
  canEdit: boolean;
  canRead: boolean;
};

// TODO: create a Higher Order Concept and apply logic there.
const useCurrentUserHasAccessToArea = (area: AreasEnum): AccessPermission => {
  const allAreaRoles = useCurrentUserRoles();
  const areaRoles = allAreaRoles.filter((item) => item.area === area);

  const hasAccessToArea = areaRoles.length > 0;
  const hasAdminAccess = areaRoles.some((areaRole) => areaRole.role === 'Admin');
  const hasReadOnlyAccess = areaRoles.every((areaRole) => areaRole.role === 'ReadOnly');

  const accessValue: AccessPermission = {
    hasAccessToArea: hasAccessToArea,
    canEdit: hasAccessToArea && hasAdminAccess,
    canRead: hasAccessToArea && (hasAdminAccess || hasReadOnlyAccess),
  };

  return accessValue;
};

export default useCurrentUserHasAccessToArea;
