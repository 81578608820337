import React from 'react';
import useCurrentUserHasAccessToArea from '../../../hooks/shared/useCurrentUserHasAccessToArea';
import type { AreasEnum } from '../../../enums/shared/AreasEnum';
import useAreaContext from '../../../hooks/contexts/useAreaContext';

const EditorReadOnlyWithInsufficientPermission = (Editor) => {
  return function GetEditorReadOnlyWithInsifficientPermission(props) {
    const areaContext: AreasEnum = useAreaContext();
    const userPermissions = useCurrentUserHasAccessToArea(areaContext);

    const { readOnly } = props;
    const isReadOnly = (readOnly ?? false) || !userPermissions.canEdit;
    const options = isReadOnly ? { ...props.options, readOnly: true } : { ...props.options };

    return <Editor {...props} options={options} />;
  };
};

export default EditorReadOnlyWithInsufficientPermission;
