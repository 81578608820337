import { useMutation, useQueryClient } from 'react-query';
import QUERYKEYS from '../../../constants/queryKeys';
import { deletePromoCodes } from '../../../services/PromoCodesService';

type MutationCallbacks = {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
};

type $DeletePromoCodes = {
  deleteMutation: (ids: Array<number>, callbacks?: MutationCallbacks) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useDeletePromoCodes(): $DeletePromoCodes {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(deletePromoCodes, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey === QUERYKEYS.PROMO_CODES,
      }),
    onError: (error) => {
      console.log(error);
    },
  });

  return {
    deleteMutation: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}
