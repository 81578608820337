import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import QUERYKEYS from '../../../constants/queryKeys';
import config from '../../../config/';
import { getLastModifiedPages } from '../../../services/CmsPageService';
import { useQuery } from 'react-query';

type $UseCmsLastModifiedPages = {
  data?: Array<CmsPageMetadataModel>;
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsLastModifiedPages(): $UseCmsLastModifiedPages {
  const { isLoading, isError, data } = useQuery(
    QUERYKEYS.LAST_MODIFIED_PAGES,
    async () => getLastModifiedPages(),
    {
      staleTime: config.cmsIdleTimeoutInMinutes * 60 * 1000,
    },
  );

  return {
    data,
    isError,
    isLoading,
  };
}
