import { Form, Select } from 'antd';

import React from 'react';
import useCmsPageGroups from './../../../../hooks/services/cms/useCmsPageGroups';

type Props = {
  initialGroupId?: number;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  onSelect?: (pageGroupId: number) => void;
  tooltip?: string;
};

const CmsPageGroupSelectInput = ({
  initialGroupId,
  isDisabled = false,
  isRequired = true,
  label = 'Group',
  onSelect,
  tooltip,
}: Props) => {
  const { data, isError, isLoading } = useCmsPageGroups();

  return (
    <>
      <Form.Item
        label={label}
        name="pageGroupId"
        tooltip={tooltip}
        rules={[{ message: '"${label}" is required', required: isRequired }]}
        initialValue={initialGroupId}
      >
        <Select
          disabled={isLoading || isError || isDisabled}
          loading={isLoading}
          onSelect={onSelect}
        >
          {data?.map((group, index) => (
            <Select.Option key={index} value={group.id}>
              {group.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default CmsPageGroupSelectInput;
