import { useMutation, useQueryClient } from 'react-query';

import type { CmsDeleteMutationResponse } from './../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { deleteCmsReleases } from '../../../../services/CmsReleaseService';

export default function useDeleteCmsReleases(): CmsDeleteMutationResponse<Array<number>> {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(deleteCmsReleases, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey === QUERYKEYS.RELEASES,
      }),
  });

  return {
    deleteMutation: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}
