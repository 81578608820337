import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import QUERYKEYS from '../../../constants/queryKeys';
import config from '../../../config';
import { getCmsPages } from '../../../services/CmsPageService';
import { useQuery } from 'react-query';

type $UseCmsPages = {
  data?: Array<CmsPageMetadataModel>;
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsPages(): $UseCmsPages {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.PAGES, async () => getCmsPages(), {
    staleTime: config.cmsIdleTimeoutInMinutes * 60 * 1000,
  });

  return {
    data,
    isError,
    isLoading,
  };
}
