import { useMutation, useQueryClient } from 'react-query';

import type { TVendorCodeDirectMail } from './../../../models/vendor/VendorCodeModels';
import type { TUpdateModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { updateVendorCodeDirectMail } from './../../../services/VendorCodesService';

const useUpdateVendorCodeDirectMail = (): TUpdateModel<TVendorCodeDirectMail> => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateVendorCodeDirectMail, {
    onSuccess: (data) =>
      queryClient.setQueryData(QUERYKEYS.VENDOR_CODES, (vendorCodesDirectMail: any) =>
        vendorCodesDirectMail.map((vendorCodeDirectMail) =>
          vendorCodeDirectMail.id === data!.id ? data : vendorCodeDirectMail,
        ),
      ),
  });

  return {
    update: mutate,
    isLoading,
  };
};

export default useUpdateVendorCodeDirectMail;
