import useCurrentUser from './useCurrentUser';

export type AreaRole = {
  area: string;
  role: string;
};

const useCurrentUserRoles = (): Array<AreaRole> => {
  const [currentUser] = useCurrentUser();
  const areasAndRoles = currentUser && currentUser.idTokenClaims && currentUser.idTokenClaims.roles;

  if (!areasAndRoles) return [];

  const roles = areasAndRoles.map((areaAndRole) => {
    const splitValue = areaAndRole.split('.');
    return {
      area: splitValue.length >= 1 ? splitValue[0] : 'Unknown',
      role: splitValue.length >= 2 ? splitValue[1] : 'Unknown',
    };
  });

  return roles;
};

export default useCurrentUserRoles;
