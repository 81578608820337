import 'antd/dist/antd.less';
import './css/index.css';
import './css/utils.css';

import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { authClient } from './services/AuthService';
import config from './config';

const root = document.getElementById('root');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: config.refetchOnWindowFocus,
      staleTime: config.idleTimeoutInMinutes * 60 * 1000, // 30 mins, in milliseconds
    },
  },
});

const IS_DEV_ENV = config.environment === 'dev' || config.environment === 'local';

if (root) {
  ReactDOM.render(
    <MsalProvider instance={authClient}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {IS_DEV_ENV && <ReactQueryDevtools />}
      </QueryClientProvider>
    </MsalProvider>,
    root,
  );
} else {
  alert('Cannot attach application to root element.');
  throw new Error('Cannot attach application to root element.');
}
