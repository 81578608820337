import React from 'react';
import { Card } from 'antd';
import useCurrentUserHasAccessToArea from '../../../hooks/shared/useCurrentUserHasAccessToArea';
import type { AreasEnum } from '../../../enums/shared/AreasEnum';
import useAreaContext from '../../../hooks/contexts/useAreaContext';
import { filterNulls } from '../../../utils/arrayUtils';

const TableWithHeader = (Table) => {
  return function GetTableWithHeader(props) {
    const areaContext: AreasEnum = useAreaContext();
    const userPermissions = useCurrentUserHasAccessToArea(areaContext);
    const isDisabled = !userPermissions.canEdit;
    const hasHeader = !!props.header;
    const columns = filterNulls(props.columns);

    const table = <Table {...props} columns={columns} />;

    return !isDisabled && hasHeader ? (
      <Card size="small" title={props.header}>
        {table}
      </Card>
    ) : (
      table
    );
  };
};

export default TableWithHeader;
