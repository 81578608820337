import React, { useState } from 'react';
import CmsCollectionModalForm from '../../forms/CmsCollectionModalForm';
import type { CmsCollectionModel } from '../../../../models/cms/collections/CmsCollectionModel';
import { EditOutlined } from '@ant-design/icons';
import { IconTextButton } from '../../../shared/IconTextButton';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  collection?: CmsCollectionModel;
  styleType?: 'icon' | 'primary';
  isDisabled?: boolean;
};

const EnhancedIconTextButton = ButtonHiddenWithInsufficientPermission(IconTextButton);

const CmsCollectionEditButton = ({ collection, styleType = 'icon', isDisabled }: Props) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <EnhancedIconTextButton
        buttonText="Edit"
        buttonIcon={<EditOutlined />}
        styleType={styleType}
        isDisabled={isDisabled}
        onClick={() => setIsModalShown(true)}
      />
      <CmsCollectionModalForm
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        initialData={collection}
      />
    </>
  );
};

export default CmsCollectionEditButton;
