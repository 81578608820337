import React from 'react';
import { VendorCodeType } from './../../../enums/vendor/VendorCodeTypeEnum';

import './../../../css/vendor/displays/VendorCodeTypeText.css';

type Props = {
  status: VendorCodeType;
};

const VendorCodeTypeText = ({ status }: Props) => {
  switch (status) {
    case VendorCodeType.COMMERCIAL:
      return <span className="VendorCodeTypeCommercial pill">TFN Only</span>;
    case VendorCodeType.DIRECT_MAIL:
      return <span className="VendorCodeTypeDirectMail pill">Direct Mail</span>;
    case VendorCodeType.INTERNET:
      return <span className="VendorCodeTypeInternet pill">Internet</span>;
    default:
      return <span className="VendorCodeTypeNotSet pill">Unknown</span>;
  }
};

export default VendorCodeTypeText;
