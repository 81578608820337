import { useMutation, useQueryClient } from 'react-query';

import type { CmsCollectionEntryModel } from '../../../../models/cms/collections/CmsCollectionEntryModel';
import type { CmsUpdateMutationResponse } from '../../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../../constants/queryKeys';
import { updateCollectionEntry } from '../../../../services/CmsCollectionService';

type CmsEntryUpdateMutationResponse = CmsUpdateMutationResponse<CmsCollectionEntryModel>;
export const useUpdateCmsCollectionEntry = (): CmsEntryUpdateMutationResponse => {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(updateCollectionEntry, {
    onSuccess: (data) =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          (query.queryKey[0] === QUERYKEYS.COLLECTION_ENTRY && query.queryKey[1] === data?.id) ||
          (query.queryKey[0] === QUERYKEYS.COLLECTION && query.queryKey[1] === data.collectionId),
      }),
  });

  return {
    update: mutate,
    isError,
    isLoading,
    isSuccess,
  };
};
