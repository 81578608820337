import useCurrentUserHasAccess from './useCurrentUserHasAccessToArea';
import { AreasEnum } from '../../enums/shared/AreasEnum';
import ROUTES from '../../constants/routes';

const useCurrentUserHasAccessToPage = (): boolean => {
  const cmsAccess = useCurrentUserHasAccess(AreasEnum.CMS);
  const promoCodesAccess = useCurrentUserHasAccess(AreasEnum.PROMO_CODES);
  const rulesAccess = useCurrentUserHasAccess(AreasEnum.RULES);
  const vendorAccess = useCurrentUserHasAccess(AreasEnum.VENDORS);

  const path = window.location.pathname;
  const isHomePath = path === ROUTES.HOME;
  const isCmsPath = path.startsWith(ROUTES.CMS);
  const isPromoCodePath = path.startsWith(ROUTES.PROMO_CODES);
  const isRulePath = path.startsWith(ROUTES.RULES);
  const isVendorPath = path.startsWith(ROUTES.VENDORS);

  if (isHomePath) return true;
  if (isCmsPath && cmsAccess.hasAccessToArea) return true;
  if (isPromoCodePath && promoCodesAccess.hasAccessToArea) return true;
  if (isRulePath && rulesAccess.hasAccessToArea) return true;
  if (isVendorPath && vendorAccess.hasAccessToArea) return true;
  return false;
};

export default useCurrentUserHasAccessToPage;
