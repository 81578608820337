import './../../../css/promoCodes/tables/PromoCodeDiscountsTable.css';

import React from 'react';
import { DatePicker, Form, InputNumber, Select, Table, Tooltip } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import type { ColumnType } from 'antd/lib/table';
import { CalendarOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

import { getDateDisplayValue, getDateUnixValueFromISO } from './../../../utils/displayUtils';
import type { PromoCodeDiscountModel } from '../../../models/promoCodes/PromoCodeDiscountModel';
import { PromoCodeDiscountStatus } from './../../../enums/promoCodes/PromoCodeDiscountStatusEnum';
import PromoCodeDiscountStatusText from '../displays/PromoCodeDiscountStatusText';
import PromoCodeDiscountText from '../displays/PromoCodeDiscountText';
import PromoCodeDiscountSaveButton from '../buttons/PromoCodeDiscountSaveButton';
import PromoCodeDiscountDeleteButton from '../buttons/PromoCodeDiscountDeleteButton';
import { PromoCodeDiscountType } from './../../../enums/promoCodes/PromoCodeDiscountTypeEnum';
import PromoCodeDiscountTypeText from '../displays/PromoCodeDiscountTypeText';
import { getListOfDaysBetweenDates as getListOfDaysBetweenDatesInclusive } from '../../../utils/dateUtils';
import DateRangeColumnFilter from '../../shared/columnFilters/DateRangeColumnFilter';
import TableWithActionsColumn from '../../hocs/tables/TableWithActionsColumn';

const { Option } = Select;
const ActionableTable = TableWithActionsColumn(Table);

type Props = {
  discounts: Array<PromoCodeDiscountModel>;
  clearNewRows: () => void;
  isNewRecord: boolean;
  onError: (errors: any) => void;
};

const PromoCodeDiscountsTable = ({ discounts, isNewRecord, clearNewRows, onError }: Props) => {
  // Table functions
  const getDaysToExclude = (): Array<string> => {
    let days: Array<string> = [];
    discounts.forEach((discount) => {
      const discountDays = getListOfDaysBetweenDatesInclusive(discount.startDate, discount.endDate);
      days = [...days, ...discountDays];
    });
    return days;
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current): boolean => {
    const daysToExclude = getDaysToExclude();
    const isInThePast = current && current < DateTime.now().plus({ days: -1 });
    const isExcludedDay =
      current &&
      daysToExclude.some((dayISO) => {
        const currentDate = new Date(current.toISOString());
        currentDate.setUTCHours(0, 0, 0, 0);
        return dayISO === currentDate.toISOString();
      });
    return isInThePast || isExcludedDay;
  };

  const getColumns = (): Array<ColumnType<PromoCodeDiscountModel>> => {
    return [
      {
        title: 'Discount Type',
        dataIndex: 'discountType',
        render: (value: PromoCodeDiscountType, record) =>
          isNewRow(record) ? (
            <Form.Item name="discountType">
              <Select className="promoCodeDiscountNewRowDiscountType">
                <Option value={`${PromoCodeDiscountType.FLAT_AMOUNT}`}>Flat Amount</Option>
                <Option value={`${PromoCodeDiscountType.PERCENTAGE}`}>Percentage</Option>
              </Select>
            </Form.Item>
          ) : (
            <PromoCodeDiscountTypeText status={value} />
          ),
        sorter: (a, b) =>
          getPromoCodeDiscountTypeText(a.discountType).localeCompare(
            getPromoCodeDiscountTypeText(b.discountType),
          ),
        filterSearch: true,
        filters: [PromoCodeDiscountType.FLAT_AMOUNT, PromoCodeDiscountType.PERCENTAGE].map(
          (promoCodeType) => ({
            text: getPromoCodeDiscountTypeText(promoCodeType),
            value: promoCodeType,
          }),
        ),
        onFilter: (
          promoCodeDiscountType: PromoCodeDiscountType,
          record: PromoCodeDiscountModel,
        ): boolean => record.discountType === promoCodeDiscountType,
      },
      {
        title: 'Discount',
        dataIndex: 'discount',
        render: (_, record) =>
          isNewRow(record) ? (
            <Form.Item
              name="discount"
              rules={[{ required: true, message: 'This field is required.', type: 'number' }]}
            >
              <InputNumber min={0.1} step={1} autoFocus={true} />
            </Form.Item>
          ) : (
            <PromoCodeDiscountText status={record.discountType} value={record.discount} />
          ),
        sorter: (a, b) => a.discount - b.discount,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        render: (value: PromoCodeDiscountStatus, record) =>
          !isNewRow(record) ? <PromoCodeDiscountStatusText status={value} /> : '',
        sorter: (a, b) =>
          getPromoCodeDiscountStatusText(a.status).localeCompare(
            getPromoCodeDiscountStatusText(b.status),
          ),
        filterSearch: true,
        filters: [
          PromoCodeDiscountStatus.UPCOMING,
          PromoCodeDiscountStatus.ACTIVE,
          PromoCodeDiscountStatus.EXPIRED,
        ].map((promoCodeStatus) => ({
          text: getPromoCodeDiscountStatusText(promoCodeStatus),
          value: promoCodeStatus,
        })),
        onFilter: (
          promoCodeDiscountStatus: PromoCodeDiscountStatus,
          record: PromoCodeDiscountModel,
        ): boolean => record.status === promoCodeDiscountStatus,
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        render: (value, record) =>
          isNewRow(record) ? (
            <Form.Item name="startDate">
              <DatePicker format="MM/DD/YYYY" disabledDate={disabledDate} />
            </Form.Item>
          ) : value ? (
            getDateDisplayValue(value)
          ) : (
            '-'
          ),
        sorter: (a, b) =>
          getDateUnixValueFromISO(a.startDate) - getDateUnixValueFromISO(b.startDate),
        defaultSortOrder: 'ascend',
        filterSearch: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <DateRangeColumnFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        ),
        filterIcon: (
          <Tooltip title="Filter to dates that fall between">
            <CalendarOutlined />
          </Tooltip>
        ),
        onFilter: (selectedDate: string, record: PromoCodeDiscountModel): boolean => {
          return (
            getDateUnixValueFromISO(record.startDate, true) ===
            getDateUnixValueFromISO(selectedDate, true)
          );
        },
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        render: (value, record) =>
          isNewRow(record) ? (
            <Form.Item name="endDate">
              <DatePicker format="MM/DD/YYYY" disabledDate={disabledDate} />
            </Form.Item>
          ) : value ? (
            getDateDisplayValue(value)
          ) : (
            '-'
          ),
        sorter: (a, b) => getDateUnixValueFromISO(a.endDate) - getDateUnixValueFromISO(b.endDate),
        filterSearch: true,
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <DateRangeColumnFilter
            setSelectedKeys={setSelectedKeys}
            selectedKeys={selectedKeys}
            confirm={confirm}
            clearFilters={clearFilters}
          />
        ),
        filterIcon: (
          <Tooltip title="Filter to dates that fall between">
            <CalendarOutlined />
          </Tooltip>
        ),
        onFilter: (selectedDate: string, record: PromoCodeDiscountModel): boolean => {
          return (
            getDateUnixValueFromISO(record.endDate, true) ===
            getDateUnixValueFromISO(selectedDate, true)
          );
        },
      },
      {
        title: 'Actions',
        align: 'center',
        render: (_, record: PromoCodeDiscountModel) => (
          <>
            <PromoCodeDiscountSaveButton
              promoCodeId={record.promoCodeId}
              isVisible={!isNewRecord && isNewRow(record)}
              onSuccess={() => clearNewRows()}
              onError={onError}
            />
            <PromoCodeDiscountDeleteButton
              discount={record}
              isNew={isNewRow(record)}
              onSuccess={() => clearNewRows()}
              onError={onError}
            />
          </>
        ),
      },
    ];
  };

  // Return react node
  return (
    <div className="promoCodeDiscountsTableRoot">
      <ActionableTable
        className="promoCodeDiscountTableRoot"
        bordered
        columns={getColumns()}
        dataSource={discounts}
        size="small"
        rowKey={(record) => `${record.promoCodeId}-${record.id}`}
        title={() => (
          <div className="promoCodeDiscountsTableHeader">
            <h2>Discounts</h2>
          </div>
        )}
        pagination={false}
      />
    </div>
  );
};

function isNewRow(promoCodeDiscount: PromoCodeDiscountModel): boolean {
  return promoCodeDiscount.id === 0 && (promoCodeDiscount.newRowIndex || 0) > 0;
}

function getPromoCodeDiscountTypeText(status: PromoCodeDiscountType): string {
  switch (status) {
    case PromoCodeDiscountType.FLAT_AMOUNT:
      return 'Flat Amount';
    case PromoCodeDiscountType.PERCENTAGE:
      return 'Percentage';
    default:
      return 'Unknown';
  }
}

function getPromoCodeDiscountStatusText(status: PromoCodeDiscountStatus | undefined): string {
  switch (status) {
    case PromoCodeDiscountStatus.UPCOMING:
      return 'Upcoming';
    case PromoCodeDiscountStatus.ACTIVE:
      return 'Active';
    case PromoCodeDiscountStatus.EXPIRED:
      return 'Expired';
    default:
      return 'Unknown';
  }
}

export default PromoCodeDiscountsTable;
