import type { CmsPageContentVersionModel } from '../../../models/cms/CmsPageContentVersionModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { getPageContentVersionById } from '../../../services/CmsPageService';
import { useQuery } from 'react-query';

type TUseCmsPageContent = {
  data?: CmsPageContentVersionModel;
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsPageContentVersion(
  pageId: number,
  version: number,
  shouldFetch: boolean,
): TUseCmsPageContent {
  const response = useQuery({
    queryKey: [QUERYKEYS.PAGE_CONTENT_VERSION, { pageId, version }],
    queryFn: async () => getPageContentVersionById(pageId, version),
    enabled: pageId > 0 && version > 0 && shouldFetch,
    staleTime: 0,
  });

  return response;
}
