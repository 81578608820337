/* eslint-disable max-len */

import type { TConfig } from './index.js';

const config: TConfig = {
  environment: 'qa',
  adminGateway: 'https://gateway.qa.enduranceapi.com/admin/api',
  idleTimeoutInMinutes: 30,
  cmsIdleTimeoutInMinutes: 0,
  auth: {
    clientId: 'ef0997d9-1ea1-4852-a13a-3bf59556ffe9',
    authority: 'https://login.microsoftonline.com/daae7bc1-c893-46c4-8df4-4464a9a6f43e',
  },
  refetchOnWindowFocus: false,
  maxBulkUpdateFileSizeKb: 20,
};

export default config;
