import { useMutation, useQueryClient } from 'react-query';

import type { CreatePageGroupRequestModel } from '../../../services/types/CreatePageGroupRequestModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { createPageGroup } from '../../../services/CmsPageGroupService';

type MutationCallbacks = {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: (data) => void;
};

type TCreateCmsPageGroup = {
  create: (cmsPageGroup: CreatePageGroupRequestModel, callbacks: MutationCallbacks) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

function useCreateCmsPageGroup(): TCreateCmsPageGroup {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(createPageGroup, {
    onSuccess: (data) => {
      queryClient.setQueryData(QUERYKEYS.PAGE_GROUPS, (cmsPageGroup: any) => [
        ...cmsPageGroup,
        data,
      ]);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGE_GROUPS,
      });
    },
  });

  return {
    create: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useCreateCmsPageGroup;
