import { useMutation, useQueryClient } from 'react-query';
import type { PromoCodeDiscountModel } from './../../../models/promoCodes/PromoCodeDiscountModel';
import type { TCreateModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { createPromoCodeDiscount } from './../../../services/PromoCodesService';

const useCreatePromoCodeDiscount = (): TCreateModel<PromoCodeDiscountModel> => {
  const queryClient = useQueryClient();
  const { isLoading, isError, mutate } = useMutation(createPromoCodeDiscount, {
    onSuccess: (data: PromoCodeDiscountModel) => {
      queryClient.setQueryData(QUERYKEYS.PROMO_CODES, (promoCodes: any) =>
        promoCodes.map((promoCode) => {
          if (promoCode.id === data!.promoCodeId) {
            promoCode.discounts = [...promoCode.discounts, data];
          }

          return promoCode;
        }),
      );

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey === QUERYKEYS.PROMO_CODES,
      });
    },
  });

  return {
    create: mutate,
    isError,
    isLoading,
  };
};

export default useCreatePromoCodeDiscount;
