import { Form, Select } from 'antd';

import { CmsCollectionSchemaDataCodeEditorTypeEnum } from '../../../../enums/cms/CmsCollectionSchemaDataCodeEditorTypeEnum';
import { REQUIRED_RULE } from '../../../../constants/formRules';
import React from 'react';

export const CmsSchemaDataCodeEditorItems = () => {
  return (
    <>
      <Form.Item label="Code Type" name="codeEditorType" rules={[REQUIRED_RULE]}>
        <Select>
          <Select.Option value={CmsCollectionSchemaDataCodeEditorTypeEnum.XML}>XML</Select.Option>
          <Select.Option value={CmsCollectionSchemaDataCodeEditorTypeEnum.JSON}>JSON</Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};
