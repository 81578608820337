import { Col, Row, Typography } from 'antd';

import { DiffEditor } from '@monaco-editor/react';
import React from 'react';

type Props = {
  originalContent: string;
  originalContentTitle?: string;
  newContent: string;
  newContentTitle?: string;
};

const CmsPageContentDiffEditor = ({
  originalContent,
  originalContentTitle = 'Selected Version Changes',
  newContent,
  newContentTitle = 'New Changes',
}: Props) => {
  return (
    <>
      <Row>
        <Col xl={12}>
          <Typography.Title level={5}>{originalContentTitle}</Typography.Title>
        </Col>
        <Col xl={12}>
          <Typography.Title level={5}>{newContentTitle}</Typography.Title>
        </Col>
      </Row>
      <DiffEditor
        height="400px"
        theme="vs-dark"
        language="xml"
        modified={newContent}
        original={originalContent}
        // eslint-disable-next-line max-len
        //https://microsoft.github.io/monaco-editor/api/interfaces/monaco.editor.IStandaloneEditorConstructionOptions.html
        options={{
          minimap: {
            enabled: false,
          },
          colorDecorators: true,
          quickSuggestions: false,
          readOnly: true,
        }}
      />
    </>
  );
};

export default CmsPageContentDiffEditor;
