const COLORS = {
  PROMO_CODES_ACTIVE_PILL: '#9fad3e',
  PROMO_CODES_CANCELLED_PILL: '#d88920',
  PROMO_CODES_EXPIRED_PILL: '#5a5255',
  PROMO_CODES_PLANNING_PILL: '#52b7c9',
  PROMO_CODES_UPCOMING_PILL: '#bf80ff',
  PROMO_CODES_CHECK_MARK: '#52c41a',
  VENDORS_DIRECT_MAIL_PILL: '#9fad3e',
  VENDORS_INTERNET_PILL: '#52b7c9',
  VENDORS_TFN_PILL: '#d88920',
  VENDORS_SEARCH_ICON_ACTIVE: '#1890ff',
};

export default COLORS;
