import type { $RuleModel } from '../../../models/rules/RuleModel';
import type { TQueryModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { getRules } from '../../../services/RuleService';
import { useQuery } from 'react-query';

const useRules = (): TQueryModel<$RuleModel[]> => {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.RULES, getRules);

  return {
    data,
    isError,
    isLoading,
  };
};

export default useRules;
