import './../../../css/cms/displays/CmsPageContentEnvironmentText.css';

import { CmsPageEnvironmentEnum } from './../../../enums/cms/CmsPageEnvironmentEnum';
import React from 'react';

type Props = {
  environment: CmsPageEnvironmentEnum | number;
};

const CmsPageContentEnvironmentText = ({ environment }: Props) => {
  switch (environment) {
    case CmsPageEnvironmentEnum.DEV:
      return <span className="cmsPageContentEnvDev cmsPageEnvPill">Dev</span>;
    case CmsPageEnvironmentEnum.QA:
      return <span className="cmsPageContentEnvQa cmsPageEnvPill">Qa</span>;
    case CmsPageEnvironmentEnum.STAGING:
      return <span className="cmsPageContentEnvStaging cmsPageEnvPill">Staging</span>;
    case CmsPageEnvironmentEnum.PROD:
      return <span className="cmsPageContentEnvProd cmsPageEnvPill">Prod</span>;
    default:
      return null;
  }
};

export default CmsPageContentEnvironmentText;
