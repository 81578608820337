import type { CmsPageGroupModel } from '../../../models/cms/CmsPageGroupModel';
import QUERYKEYS from '../../../constants/queryKeys';
import config from '../../../config/';
import { getPageGroupById } from '../../../services/CmsPageGroupService';
import { useQuery } from 'react-query';

type $UseCmsPageGroupPages = {
  data?: CmsPageGroupModel;
  isError: boolean;
  isLoading: boolean;
};

export default function useCmsPageGroupPages(id: number): $UseCmsPageGroupPages {
  const { isLoading, isError, data } = useQuery(
    [QUERYKEYS.PAGE_GROUP, id],
    async () => getPageGroupById(id),
    {
      staleTime: config.cmsIdleTimeoutInMinutes * 60 * 1000,
    },
  );

  return {
    data,
    isError,
    isLoading,
  };
}
