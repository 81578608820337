import type { TVendor } from './../../../models/vendor/VendorModel';
import type { TQueryModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { getVendor } from './../../../services/VendorService';
import { useQuery } from 'react-query';

function useVendor(vendorId: string): TQueryModel<TVendor> {
  const { isLoading, isError, data } = useQuery([QUERYKEYS.VENDORS, vendorId], () =>
    getVendor(vendorId),
  );

  return {
    data,
    isError,
    isLoading,
  };
}

export default useVendor;
