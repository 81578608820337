import React from 'react';
import { Row, Space, Table } from 'antd';

import type { CmsCollectionModel } from '../../../models/cms/collections/CmsCollectionModel';
import useCmsCollectionsTableSchema from '../../../hooks/services/cms/collections/useCmsCollectionsTableSchema';
import TableWithHeader from '../../hocs/tables/TableWithHeader';
import TableWithActionsColumn from '../../hocs/tables/TableWithActionsColumn';

type Props = {
  data?: CmsCollectionModel[];
  extraHeader?: React.ReactNode;
  isLoading?: boolean;
  isError?: boolean;
};

const ActionableTableWithHeader = TableWithHeader(TableWithActionsColumn(Table));

export const CmsCollectionsTable = ({ data, isLoading, extraHeader }: Props) => {
  const tableData = useCmsCollectionsTableSchema({ data });
  const tableHeader = extraHeader ? (
    <Row justify="end">
      <Space className="paddingVert8">{extraHeader}</Space>
    </Row>
  ) : null;

  return (
    <ActionableTableWithHeader
      header={tableHeader}
      bordered
      columns={tableData}
      dataSource={data}
      loading={isLoading}
      pagination={{
        hideOnSinglePage: true,
        responsive: true,
        total: data?.length ?? 0,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      }}
      size="small"
      rowKey="id"
    />
  );
};
