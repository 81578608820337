import type { CmsQueryResponse } from './../../../../models/cms/CmsResponseModels';
import type { CmsReleaseModel } from './../../../../models/cms/releases/CmsReleaseModel';
import QUERYKEYS from '../../../../constants/queryKeys';
import { getCmsRelease } from '../../../../services/CmsReleaseService';
import { useQuery } from 'react-query';

export default function useCmsRelease(releaseId: number): CmsQueryResponse<CmsReleaseModel> {
  const { isLoading, isError, data } = useQuery({
    queryKey: [QUERYKEYS.RELEASE, releaseId],
    queryFn: async () => getCmsRelease(releaseId),
    staleTime: 0,
  });

  return {
    data,
    isError,
    isLoading,
  };
}
