import React from 'react';
import { Button } from 'antd';

import type { CmsPageMetadataModel } from './../../../../models/cms/CmsPageMetadataModel';
import CmsReleaseModalForm from '../../forms/CmsReleaseModalForm';
import type { CmsReleaseModel } from '../../../../models/cms/releases/CmsReleaseModel';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  affectedPages?: Array<CmsPageMetadataModel>;
  release?: CmsReleaseModel;
  isDisabled?: boolean;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const CmsReleaseEditButton = ({ affectedPages, release, isDisabled }: Props) => {
  const [isModalShown, setIsModalShown] = React.useState(false);

  return (
    <>
      <EnhancedButton type="primary" disabled={isDisabled} onClick={() => setIsModalShown(true)}>
        Update
      </EnhancedButton>
      <CmsReleaseModalForm
        affectedPages={affectedPages}
        initialRelease={release}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        modalTitle="Edit Release"
      />
    </>
  );
};

export default CmsReleaseEditButton;
