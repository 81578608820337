import React from 'react';
import useCurrentUserHasAccessToArea from '../../../hooks/shared/useCurrentUserHasAccessToArea';
import type { AreasEnum } from '../../../enums/shared/AreasEnum';
import useAreaContext from '../../../hooks/contexts/useAreaContext';

const ModalOkButtonDisabledWithInsufficientPermission = (Modal) => {
  return function GetModalOkButtonDisabledWithInsufficientPermission(props) {
    const areaContext: AreasEnum = useAreaContext();
    const userPermissions = useCurrentUserHasAccessToArea(areaContext);

    const isDisabled = !userPermissions.canEdit || props.okButtonProps?.disabled;
    const okButtonProps = { ...props.okButtonProps, disabled: isDisabled };

    return <Modal {...props} okButtonProps={okButtonProps} />;
  };
};

export default ModalOkButtonDisabledWithInsufficientPermission;
