import type { TMarketingCampaign } from './../../../models/vendor/MarketingCampaignModel';
import type { TQueryModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { getMarketingCampaign } from './../../../services/MarketingCampaignService';
import { useQuery } from 'react-query';

const useMarketingCampaigns = (): TQueryModel<TMarketingCampaign[]> => {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.MARKETING_CAMPAIGN, getMarketingCampaign);

  return {
    data,
    isError,
    isLoading,
  };
};

export default useMarketingCampaigns;
