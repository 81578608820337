import { Form, Modal } from 'antd';

import type { $RuleModel } from '../../models/rules/RuleModel';
import React from 'react';
import RequestErrorAlert from '../shared/RequestErrorAlert';
import RuleForm from './RuleForm';
import useUpsertRule from './../../hooks/services/rules/useUpsertRule';

type Props = {
  initialData?: $RuleModel | null;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
};

export default function RuleModalForm({
  initialData,
  isShown,
  setIsShown,
}: Props): React.ReactElement {
  const { upsert, isError, isLoading } = useUpsertRule();
  const [form] = Form.useForm<$RuleModel>();
  const isNewRule = !initialData;

  const onCancel = React.useCallback(() => {
    if (isLoading) {
      return;
    }

    setIsShown(false);
  }, [isLoading, setIsShown]);

  const onSubmit = React.useCallback(async () => {
    const rule = { ...form.getFieldsValue(), id: initialData?.id };

    try {
      await form.validateFields();
      rule.booleanExpressions.length > 0 && upsert(rule, { onSuccess: onCancel });
      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [form, initialData?.id, upsert, onCancel]);

  React.useEffect(() => {
    form.resetFields();
  }, [initialData, isShown, form]);

  return (
    <Modal
      okButtonProps={{ loading: isLoading }}
      onOk={onSubmit}
      onCancel={onCancel}
      title={`${isNewRule ? 'Create' : 'Update'} Rule`}
      open={isShown}
      okText={isNewRule ? 'Create' : 'Update'}
    >
      <RequestErrorAlert isError={isError} />
      <RuleForm form={form} initialData={initialData} />
    </Modal>
  );
}
