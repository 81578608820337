import React from 'react';
import { PromoCodeDiscountType } from './../../../enums/promoCodes/PromoCodeDiscountTypeEnum';

type Props = {
  status: PromoCodeDiscountType;
};

const PromoCodeDiscountTypeText = ({ status }: Props) => {
  switch (status) {
    case PromoCodeDiscountType.FLAT_AMOUNT:
      return <span className="promoCodeDiscountTypeFlatAmount">Flat Amount</span>;
    case PromoCodeDiscountType.PERCENTAGE:
      return <span className="promoCodeDiscountTypePercentage">Percentage</span>;
    default:
      return <span className="promoCodeDiscountTypeNotSet">Unknown</span>;
  }
};

export default PromoCodeDiscountTypeText;
