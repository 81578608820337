import type { $RuleInputSchemaModel } from '../models/rules/RuleInputSchemaModel';
import type { $RuleModel } from '../models/rules/RuleModel';
import WebAPIClient from './api/WebAPIClient';

const PATH = 'rules';

export const deleteRule = async (ruleId: string): Promise<void> => {
  await WebAPIClient.delete(`${PATH}/${ruleId}`);
};

export const getInputSchemas = async (): Promise<Array<$RuleInputSchemaModel>> => {
  const response = await WebAPIClient.get(`${PATH}/schemas`);

  return response?.data ?? [];
};

export const getRules = async (): Promise<Array<$RuleModel>> => {
  const response = await WebAPIClient.get(PATH);

  return response?.data ?? [];
};

export const upsertRule = async (rule: $RuleModel): Promise<$RuleModel | undefined> => {
  let response;

  if (rule.id) {
    response = await WebAPIClient.patch(`${PATH}/${rule.id}`, rule);
  } else {
    response = await WebAPIClient.post(PATH, rule);
  }

  return response?.data;
};
