import React from 'react';
import { PromoCodeDiscountType } from './../../../enums/promoCodes/PromoCodeDiscountTypeEnum';
import { getMoneyDisplayValue, getPercentageDisplayValue } from './../../../utils/displayUtils';

type Props = {
  status: PromoCodeDiscountType;
  value: number;
};

const PromoCodeDiscountTypeText = ({ status, value }: Props) => {
  switch (status) {
    case PromoCodeDiscountType.FLAT_AMOUNT:
      return <span className="promoCodeDiscountFlatAmount">{getMoneyDisplayValue(value)}</span>;
    case PromoCodeDiscountType.PERCENTAGE:
      return (
        <span className="promoCodeDiscountPercentage">
          {getPercentageDisplayValue(value, false)}
        </span>
      );
    default:
      return <span className="promoCodeDiscountNotSet">Unknown</span>;
  }
};

export default PromoCodeDiscountTypeText;
