import { Col, Form, Input, InputNumber, Modal, Row, Switch, notification } from 'antd';
import React, { useState } from 'react';

import MarketingCampaignSelect from '../inputs/MarketingCampaignSelect';
import MarketingTierSelect from '../inputs/VendorCodeMarketingTierSelect';
import PhoneInput from '../../core/PhoneInput';
import ROUTES from '../../../constants/routes';
import RequestErrorAlert from '../../shared/RequestErrorAlert';
import type { TVendorCodesInternet } from './../../../models/vendor/VendorCodeModels';
import VendorCodeSecretInput from '../inputs/VendorCodeSecretInput';
import VendorInput from '../inputs/VendorInput';
import { getNumberDisplayValue } from '../../../utils/displayUtils';

import useCreateVendorCodeInternet from './../../../hooks/services/vendor/useCreateVendorCodeInternet';
import { useNavigate } from 'react-router-dom';
import useUpdateVendorCodeInternet from './../../../hooks/services/vendor/useUpdateVendorCodeInternet';
import useVendors from '../../../hooks/services/vendor/useVendors';
import { validatePhoneNumber } from '../../../utils/validators';

const FORM_LAYOUT = {
  labelCol: { span: 12 },
  wrapperCol: { span: 10 },
};

const REQUIRED_RULE = { message: 'This field is required', required: true };

const PHONE_RULE = {
  validator: (rule: any, value: string) => {
    return !value || validatePhoneNumber(value)
      ? Promise.resolve()
      : Promise.reject(new Error('Enter a valid phone number.'));
  },
};

type Props = {
  initialData?: TVendorCodesInternet;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
  vendorId?: number;
};

const DEFAULT_VENDOR_CODE_INTERNET = {
  isActive: true,
  name: '',
  vendorId: '',
  secret: '',
  duplicateDays: '',
  lastNYears: '',
  maxMileage: '',
  marketingTier: 1,
  legacyVendorId: '',
  tollFreeNumber: '',
};

const VendorCodeInternetModalForm = ({ initialData, isShown, setIsShown, vendorId }: Props) => {
  const { create, isLoading: isCreating } = useCreateVendorCodeInternet();
  const { update, isLoading: isUpdating } = useUpdateVendorCodeInternet();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isLoading = isCreating || isUpdating;
  const isNewVendorCodeInternet = !initialData;
  const { data } = useVendors();
  const [selectedVendorId, setSelectedVendorId] = useState<any>(vendorId);
  const [selectedVendorShortName, setSelectedVendorShortName] = useState<any>('');
  const [vendorCode, setVendorCode] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  const onCancel = React.useCallback(() => {
    // Reset error state in the event user cancels
    setErrorOccured(false);
    form.resetFields();
    const shortName =
      data?.filter((x) => x.id === selectedVendorId).map((x) => x.shortName)[0] ?? '';
    setSelectedVendorShortName(shortName);
    setSelectedVendorId(vendorId);
    setVendorCode('');
    setIsShown(false);
  }, [data, form, selectedVendorId, setIsShown, vendorId]);

  const onUpdate = React.useCallback(() => {
    form.resetFields();
    setIsShown(false);
    notification.success({
      message: <strong>Successfully Updated</strong>,
      description: <span>Internet vendor code successfully updated.</span>,
      placement: 'bottomLeft',
    });
  }, [form, setIsShown]);

  const navigateToDetailPage = React.useCallback(
    (data: TVendorCodesInternet) => {
      navigate(`${ROUTES.VENDOR_CODES_INTERNET}/${data.id}`, {
        state: { isNew: true, name: data.name },
      });
    },
    [navigate],
  );

  const onSubmit = React.useCallback(async () => {
    await form.validateFields();
    if (isNewVendorCodeInternet) {
      create(form.getFieldsValue(), {
        onSuccess: (data) => navigateToDetailPage(data),
        onError: (error) => {
          setErrorOccured(true);
          setErrorMessage(error.response.data.message);
        },
      });
      return;
    }

    update(
      {
        id: initialData?.id,
        moxyCustomerNumberPrefix: initialData?.moxyCustomerNumberPrefix,
        ...form.getFieldsValue(),
      },
      {
        onSuccess: onUpdate,
        onError: (error) => {
          setErrorOccured(true);
          setErrorMessage(error.response.data.message);
        },
      },
    );
  }, [
    initialData?.id,
    initialData?.moxyCustomerNumberPrefix,
    isNewVendorCodeInternet,
    form,
    create,
    navigateToDetailPage,
    onUpdate,
    update,
  ]);

  React.useEffect(() => {
    form.resetFields();
  }, [initialData, form]);

  React.useEffect(() => {
    if (isNewVendorCodeInternet) {
      if (selectedVendorShortName || vendorCode) {
        form.setFieldValue('name', selectedVendorShortName + '-INT-' + vendorCode);
      } else {
        form.setFieldValue('name', '');
      }
    }
  }, [isNewVendorCodeInternet, selectedVendorShortName, vendorCode, form]);

  React.useEffect(() => {
    const shortName =
      data?.filter((x) => x.id === selectedVendorId).map((x) => x.shortName)[0] ?? '';
    setSelectedVendorShortName(shortName);
  }, [selectedVendorId, data]);

  function handleVendorSelected(vendorId: any) {
    setSelectedVendorId(vendorId);
  }

  const [_, setMarketingTier] = useState(0);
  const handleMarketingTier = (value) => {
    setMarketingTier(value);
  };
  return (
    <Modal
      okButtonProps={{ loading: isLoading }}
      onOk={onSubmit}
      onCancel={onCancel}
      title={`${isNewVendorCodeInternet ? 'Create' : 'Update'} Vendor Code - Internet`}
      open={isShown}
      width={900}
      okText={isNewVendorCodeInternet ? 'Create' : 'Update'}
    >
      <Form
        {...FORM_LAYOUT}
        form={form}
        initialValues={initialData ?? { ...DEFAULT_VENDOR_CODE_INTERNET, vendorId: vendorId ?? '' }}
      >
        <RequestErrorAlert isError={errorOccured} description={errorMessage} />
        <Row>
          <Col span={12}>
            <Form.Item label="Code" name="legacyVendorId" rules={[REQUIRED_RULE]}>
              <Input
                maxLength={64}
                disabled={!!initialData?.legacyVendorId}
                onChange={(value) => setVendorCode(value.target.value)}
              />
            </Form.Item>
            <Form.Item label="Vendor" name="vendorId" rules={[REQUIRED_RULE]}>
              <VendorInput
                disabled={!!vendorId || !isNewVendorCodeInternet}
                onChange={handleVendorSelected}
              />
            </Form.Item>
            <Form.Item label="API Username" name="name" rules={[REQUIRED_RULE]}>
              <Input maxLength={50} disabled />
            </Form.Item>
            <Form.Item label="API Secret" name="secret" rules={[REQUIRED_RULE]}>
              <VendorCodeSecretInput secretLength={16} value={'initialData?.secret'} />
            </Form.Item>
            <Form.Item label="Campaign" name="marketingCampaignId" rules={[REQUIRED_RULE]}>
              <MarketingCampaignSelect />
            </Form.Item>
            <Form.Item
              label="Toll Free Number"
              name="tollFreeNumber"
              validateFirst={true}
              validateTrigger="onOk"
              rules={[PHONE_RULE]}
            >
              <PhoneInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Duplicate Days" name="duplicateDays" rules={[REQUIRED_RULE]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label="Max Years Old" name="lastNYears" rules={[REQUIRED_RULE]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item label="Max Odometer" name="maxMileage" rules={[REQUIRED_RULE]}>
              <InputNumber
                min={0}
                step={1000}
                max={999999}
                formatter={(value: number) => getNumberDisplayValue(Number(value))}
                parser={(value: string) => Number(value!.replace(/\$\s?|(,*)/g, ''))}
              />
            </Form.Item>
            <Form.Item label="Tier" name="marketingTier" rules={[REQUIRED_RULE]}>
              <MarketingTierSelect
                value={initialData?.marketingTier}
                onChange={handleMarketingTier}
              />
            </Form.Item>
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch disabled={isNewVendorCodeInternet} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default VendorCodeInternetModalForm;
