import { Row, Space } from 'antd';

import { CmsCollectionEditSchemaButton } from '../../cms/buttons/collections/CmsCollectionEditSchemaButton';
import CmsCollectionEntriesTable from '../../cms/tables/CmsCollectionEntriesTable';
import { CmsCollectionEntryCreateButton } from '../../cms/buttons/collections/CmsCollectionEntryCreateButton';
import PageHeader from '../../shared/PageHeader';
import React from 'react';
import Section from '../../shared/Section';
import routes from '../../../constants/routes';
import useCmsCollection from '../../../hooks/services/cms/collections/useCmsCollection';
import { useParams } from 'react-router-dom';

const CmsCollectionEntriesPage = () => {
  const { collectionId } = useParams();
  const { data, isLoading, isError } = useCmsCollection(Number(collectionId));
  const { id, slug, title, entries } = data ?? {};

  return (
    <div>
      <PageHeader
        title={title ?? 'loading...'}
        subTitle={slug ?? ''}
        breadcrumbRoutes={
          id && slug && title
            ? [
                { breadcrumbName: 'Home', path: routes.CMS },
                { breadcrumbName: 'Collections', path: routes.CMS_COLLECTIONS },
                { breadcrumbName: slug, path: `${routes.CMS_COLLECTIONS}/${id}/schema` },
                { breadcrumbName: 'Items', path: `${routes.CMS_COLLECTIONS}/${id}/entries` },
              ]
            : undefined
        }
        extra={[
          <Row key="actionBar" justify="end">
            <Space>
              <CmsCollectionEntryCreateButton collectionId={id} isDisabled={!data} />
              <CmsCollectionEditSchemaButton collectionId={id} />
            </Space>
          </Row>,
        ]}
      />
      <Section isError={isError}>
        <CmsCollectionEntriesTable isLoading={isLoading} data={entries} />
      </Section>
    </div>
  );
};

export default CmsCollectionEntriesPage;
