import type { CmsPageGroupMetadataModel } from './../../../models/cms/CmsPageGroupMetadataModel';
import CmsPageGroupModalForm from './../forms/CmsPageGroupModalForm';
import type { CmsPageGroupModel } from './../../../models/cms/CmsPageGroupModel';
import { EditOutlined } from '@ant-design/icons';
import { IconTextButton } from '../../shared/IconTextButton';
import React, { useState } from 'react';
import ButtonHiddenWithInsufficientPermission from '../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  pageGroup?: CmsPageGroupMetadataModel | CmsPageGroupModel;
  styleType?: 'icon' | 'primary';
  isDisabled?: boolean;
};

const EnhancedIconTextButton = ButtonHiddenWithInsufficientPermission(IconTextButton);

const CmsPageGroupEditButton = ({ pageGroup, styleType = 'icon', isDisabled }: Props) => {
  const [isModalShown, setIsModalShown] = useState<boolean>(false);

  return (
    <>
      <EnhancedIconTextButton
        buttonText="Edit"
        buttonIcon={<EditOutlined />}
        styleType={styleType}
        isDisabled={isDisabled}
        onClick={() => setIsModalShown(true)}
      />
      <CmsPageGroupModalForm
        initialData={pageGroup}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </>
  );
};

export default CmsPageGroupEditButton;
