import CmsCollectionCreateButton from '../../cms/buttons/collections/CmsCollectionCreateButton';
import { CmsCollectionsTable } from '../../cms/tables/CmsCollectionsTable';
import CmsPageCreateButton from '../../cms/buttons/CmsPageCreateButton';
import CmsPageGroupCreateButton from '../../cms/buttons/CmsPageGroupCreateButton';
import CmsPageGroupsTable from '../../cms/tables/CmsPageGroupsTable';
import CmsPagesTable from '../../cms/tables/CmsPagesTable';
import PageHeader from '../../shared/PageHeader';
import React from 'react';
import RequestErrorAlert from '../../shared/RequestErrorAlert';
import Section from '../../shared/Section';
import useCmsLastModifiedCollections from '../../../hooks/services/cms/collections/useCmsLastModifiedCollections';
import useCmsLastModifiedPageGroups from '../../../hooks/services/cms/useCmsLastModifiedPageGroups';
import useCmsLastModifiedPages from '../../../hooks/services/cms/useCmsLastModifiedPages';

const CmsHomePage = () => {
  const {
    data: pages,
    isLoading: isLoadingPages,
    isError: isPagesError,
  } = useCmsLastModifiedPages();
  const {
    data: pageGroups,
    isLoading: isLoadingPageGroups,
    isError: isPageGroupsError,
  } = useCmsLastModifiedPageGroups();
  const {
    data: collections,
    isLoading: isLoadingCollections,
    isError: isCollectionsError,
  } = useCmsLastModifiedCollections();

  return (
    <div>
      <PageHeader title="CMS" subTitle="Content Management System" />
      <Section headerLabel="RECENT PAGE GROUPS" isLoading={isLoadingPageGroups}>
        <div className="marginTop16">
          <RequestErrorAlert key="errorAlert" isError={isPageGroupsError} />
          <CmsPageGroupsTable
            key="page_groups_table"
            data={pageGroups}
            extraHeader={<CmsPageGroupCreateButton />}
          />
        </div>
      </Section>
      <Section headerLabel="RECENT PAGES" isLoading={isLoadingPages}>
        <div className="marginTop16">
          <RequestErrorAlert key="errorAlert" isError={isPagesError} />
          <CmsPagesTable key="pages_table" data={pages} extraHeader={<CmsPageCreateButton />} />
        </div>
      </Section>
      <Section headerLabel="RECENT COLLECTIONS" isLoading={isLoadingCollections}>
        <div className="marginTop16">
          <RequestErrorAlert key="errorAlert" isError={isCollectionsError} />
          <CmsCollectionsTable
            key="collections_table"
            data={collections}
            extraHeader={<CmsCollectionCreateButton />}
          />
        </div>
      </Section>
    </div>
  );
};

export default CmsHomePage;
