import type { CmsCollectionEntryModel } from '../../../../models/cms/collections/CmsCollectionEntryModel';
import { CmsCollectionEntryVersionDataTypeEnum } from '../../../../enums/cms/CmsCollectionEntryVersionDataTypeEnum';
import { type FormInstance, Form } from 'antd';
import { useCallback } from 'react';
import { nullThrows } from '../../../../utils/nullUtils';

type CmsCollectionEntryFormResponse = {
  form: FormInstance<any>;
  getCollectionEntry: (saveAsDraft: boolean) => CmsCollectionEntryModel;
};

export const useCmsCollectionEntryForm = (): CmsCollectionEntryFormResponse => {
  const form = Form.useFormInstance();
  const getCollectionEntry = useCallback(
    (saveAsDraft) => {
      const collectionEntry = { ...form.getFieldsValue() };
      const collectionContent = nullThrows(collectionEntry.entryVersions[0]);
      collectionContent.isDraft = saveAsDraft;
      collectionContent.entryVersionData.map((value) => {
        if (value.entryVersionDataType === CmsCollectionEntryVersionDataTypeEnum.Select) {
          if (!value.schemaEntrySelectValues[0].schemaDataSelectValueId) {
            value.schemaEntrySelectValues = [];
          }
        }
        return value;
      });
      return collectionEntry;
    },
    [form],
  );

  return { form, getCollectionEntry };
};
