import { useMutation, useQueryClient } from 'react-query';

import type { CmsMutationCallbacks } from '../../../models/cms/CmsResponseModels';
import QUERYKEYS from '../../../constants/queryKeys';
import { cloneCmsPages } from '../../../services/CmsPageService';

type $CloneCmsPages = {
  cloneMutation: (pageIds: Array<number>, callbacks?: CmsMutationCallbacks) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

function useCloneCmsPages(): $CloneCmsPages {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(cloneCmsPages, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGES ||
          query.queryKey === QUERYKEYS.PAGES ||
          query.queryKey[0] === QUERYKEYS.PAGE_GROUP,
      }),
  });

  return {
    cloneMutation: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useCloneCmsPages;
