import { Checkbox, Col, Modal, Row } from 'antd';
import { filter, flatten, pull, sortBy } from 'lodash';

import CmsPageContentEnvironmentText from '../displays/CmsPageContentEnvironmentText';
import type { CmsPageContentVersionEnvironmentModel } from '../../../models/cms/CmsPageContentVersionEnvironmentModel';
import type { CmsPageContentVersionMetadataModel } from '../../../models/cms/CmsPageContentVersionMetadataModel';
import React from 'react';
import useCmsPageContentVersion from '../../../hooks/services/cms/useCmsPageContentVersion';
import useCmsPageEditContext from '../../../hooks/contexts/useCmsPageEditContext';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import useUpdatePageContentEnvironment from '../../../hooks/services/cms/useUpdatePageContentEnvironment';
import ModalOkButtonDisabledWithInsufficientPermission from '../../hocs/modals/ModalOkButtonDisabledWithInsufficientPermission';

type Props = {
  contentVersions: CmsPageContentVersionMetadataModel[];
  isShown: boolean;
  selectedContentVersion: CmsPageContentVersionMetadataModel;
  setIsShown: (value) => void;
};

const EnhancedModal = ModalOkButtonDisabledWithInsufficientPermission(Modal);

const CmsPageSetEnvironmentsModal = ({
  selectedContentVersion,
  contentVersions,
  isShown,
  setIsShown,
}: Props) => {
  const [selectedEnvironments, setSelectedEnvironments] = React.useState<
    CmsPageContentVersionEnvironmentModel[]
  >([]);
  const [isChangeConfirmed, setIsChangeConfirmed] = React.useState<boolean>(false);
  const { isSuccess, isLoading, isError, update } = useUpdatePageContentEnvironment();

  const { state } = useCmsPageEditContext();
  const { pageModel } = state;
  const pageId = pageModel?.id ?? 0;
  const { data } = useCmsPageContentVersion(pageId, selectedContentVersion.version, isShown);
  const pageContentId = data?.id ?? 0;

  React.useEffect(() => {
    if (!isShown) {
      setSelectedEnvironments([]);
      setIsChangeConfirmed(false);
    }
  }, [isShown]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: 'Successfully updated environment tags',
  });

  const contentVersionsWithEnvironments: CmsPageContentVersionMetadataModel[] =
    React.useMemo(() => {
      return filter(contentVersions, (version) => version.versionEnvironments.length > 0);
    }, [contentVersions]);

  const contentEnvironments = React.useMemo(() => {
    return sortBy(
      flatten(
        contentVersionsWithEnvironments.map((contentVersion) => {
          return contentVersion.versionEnvironments;
        }),
      ),
      'pageEnvironmentId',
    );
  }, [contentVersionsWithEnvironments]);

  const onSubmit = () => {
    if (pageContentId) {
      selectedEnvironments.map((selectedEnvironment) => {
        selectedEnvironment.pageContentVersionId = pageContentId;
        update(selectedEnvironment, { onSuccess: () => setIsShown(false) });
      });
    }
  };

  const onCancel = React.useCallback(() => {
    if (isLoading) {
      return;
    }
    setIsShown(false);
  }, [isLoading, setIsShown]);

  const onSelect = (isChecked: boolean, env: CmsPageContentVersionEnvironmentModel) => {
    if (isChecked) {
      setSelectedEnvironments([...selectedEnvironments, env]);
    } else {
      const newList = pull(selectedEnvironments, env);
      setSelectedEnvironments(newList.length ? newList : []);
    }
  };

  return (
    <EnhancedModal
      open={isShown}
      okButtonProps={{
        loading: isLoading,
        disabled: !isChangeConfirmed || !selectedEnvironments.length,
      }}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Save Environment Selection"
      destroyOnClose={true}
      title={`Select Environments For V${selectedContentVersion.version}`}
    >
      <>
        {contentEnvironments.map((contentEnvironment) => (
          <div className="marginBottom12 " key={contentEnvironment.pageEnvironmentId}>
            <Row wrap={false} gutter={2}>
              <Col span={2}>
                <Checkbox
                  disabled={selectedContentVersion.versionEnvironments.includes(contentEnvironment)}
                  defaultChecked={
                    selectedContentVersion.versionEnvironments.includes(contentEnvironment) ??
                    undefined
                  }
                  onChange={(e) => onSelect(e.target.checked, contentEnvironment)}
                />
              </Col>
              <Col span={4}>
                <CmsPageContentEnvironmentText environment={contentEnvironment.pageEnvironmentId} />
              </Col>
            </Row>
          </div>
        ))}
      </>
      <div className="paddingTop16 marginHorz24">
        <Checkbox
          checked={isChangeConfirmed}
          onChange={(e) => setIsChangeConfirmed(e.target.checked)}
        >
          I understand that changing the <b>Environments</b> can impact production pages.
        </Checkbox>
      </div>
    </EnhancedModal>
  );
};

export default CmsPageSetEnvironmentsModal;
