import { NotificationPlacementEnum } from '../../enums/shared/NotificationPlacementEnum';
import { NotificationTypeEnum } from '../../enums/shared/NotificationTypeEnum';
import React from 'react';
import useNotification from './useNotification';

const DEFAULT_SUCCESS_MESSAGE = 'Success';
const DEFAULT_ERROR_MESSAGE = 'Error';
const DEFAULT_SUCCESS_DESCRIPTION = 'The request was successfully processed';
const DEFAULT_ERROR_DESCRIPTION = 'An error occurred during the request.';

type Config = {
  isSuccess: boolean;
  isError: boolean;
  successDescription?: React.ReactNode;
  errorDescription?: React.ReactNode;
  afterSuccess?: () => void;
  afterError?: () => void;
};

export default function useStatusNotifications({
  isError,
  isSuccess,
  successDescription,
  errorDescription,
}: Config): void {
  const { openNotification } = useNotification();
  React.useEffect(() => {
    if (!isSuccess) {
      return;
    }
    openNotification({
      type: NotificationTypeEnum.SUCCESS,
      content: {
        message: DEFAULT_SUCCESS_MESSAGE,
        description: successDescription ?? DEFAULT_SUCCESS_DESCRIPTION,
        placement: NotificationPlacementEnum.BOTTOM_LEFT,
      },
    });
  }, [isSuccess, openNotification, successDescription]);

  React.useEffect(() => {
    if (!isError) {
      return;
    }
    openNotification({
      type: NotificationTypeEnum.ERROR,
      content: {
        message: DEFAULT_ERROR_MESSAGE,
        description: errorDescription ?? DEFAULT_ERROR_DESCRIPTION,
        placement: NotificationPlacementEnum.BOTTOM_LEFT,
      },
    });
  }, [isError, errorDescription, openNotification]);
}
