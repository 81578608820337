import type { TVendor } from './../../../models/vendor/VendorModel';
import type { TQueryModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { getVendors } from './../../../services/VendorService';
import { useQuery } from 'react-query';

const useVendors = (): TQueryModel<TVendor[]> => {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.VENDORS, getVendors);

  return {
    data,
    isError,
    isLoading,
  };
};

export default useVendors;
