import '../../css/core/PhoneInput.css';
import React, { type FC } from 'react';
import { InputNumber } from 'antd';
import { getPhoneNumberDisplayValue, removeNonNumericalCharacters } from '../../utils/displayUtils';

const TEXT_BOX_MAX_LENGTH = 14;
const MASK = '(XXX) XXX-XXXX';

interface InputProps {
  value?: string;
  onChange?: (value: any) => void;
}

type Props = {
  value?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
};

const PhoneInput: FC<InputProps> = ({ value, defaultValue, disabled, onChange }: Props) => {
  return (
    <InputNumber
      className="phone-input"
      value={value}
      defaultValue={defaultValue}
      disabled={disabled}
      placeholder={MASK}
      maxLength={TEXT_BOX_MAX_LENGTH}
      formatter={(currentValue) => getPhoneNumberDisplayValue(currentValue || '')}
      parser={removeNonNumericalCharacters}
      onChange={onChange}
    />
  );
};

export default PhoneInput;
