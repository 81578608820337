import { Button, Dropdown, Menu } from 'antd';

import CmsCollectionSchemaDataModalForm from '../../forms/collections/CmsCollectionSchemaDataModalForm';
import { CmsCollectionSchemaDataTypeEnum } from '../../../../enums/cms/CmsCollectionSchemaDataTypeEnum';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { getCmsCollectionSchemaDataTypeDisplayName } from '../../../../utils/cmsUtils';

type Props = {
  collectionId?: number;
  nextOrder: number;
  isDisabled?: boolean;
};

const CmsCollectionSchemaDataCreateButton = ({ collectionId, nextOrder, isDisabled }: Props) => {
  const [isModalShown, setIsModalShown] = React.useState(false);
  const [schemaDataType, setSchemaDataType] = React.useState(CmsCollectionSchemaDataTypeEnum.Text);
  const onMenuItemClick = (item) => {
    setSchemaDataType(Number(item.key));
    setIsModalShown(true);
  };

  return (
    <>
      <Dropdown
        disabled={isDisabled}
        overlay={
          <Menu
            onClick={onMenuItemClick}
            items={Object.values(CmsCollectionSchemaDataTypeEnum)
              .filter((value) => !isNaN(Number(value)))
              .map((key) => {
                const enumValue = key as CmsCollectionSchemaDataTypeEnum;
                return {
                  key: enumValue,
                  label: getCmsCollectionSchemaDataTypeDisplayName(enumValue),
                };
              })}
          />
        }
      >
        <Button type="primary" icon={<PlusOutlined />} disabled={isDisabled}>
          Add New Field
        </Button>
      </Dropdown>
      <CmsCollectionSchemaDataModalForm
        initialData={{
          collectionId: collectionId ?? 0,
          schemaDataType,
          order: Number(nextOrder),
        }}
        isNew={true}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </>
  );
};

export default CmsCollectionSchemaDataCreateButton;
