import { PublicClientApplication } from '@azure/msal-browser';
import config from '../config';

const msalConfig = {
  auth: {
    ...config.auth,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const authClient = new PublicClientApplication(msalConfig);

export const acquireTokenAsync = async (): Promise<any> => {
  const account = authClient.getAllAccounts()[0];
  try {
    return await authClient.acquireTokenSilent({
      account: account,
      scopes: [`${config.auth.clientId}/.default`],
      forceRefresh: false,
    });
  } catch {
    return await authClient.acquireTokenRedirect({
      account: account,
      scopes: [`${config.auth.clientId}/.default`],
    });
  }
};

export const logout = () => {
  authClient.logoutRedirect();
};
