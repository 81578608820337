import '../../../css/cms/forms/CmsReleaseModalForm.css';

import { Col, Collapse, DatePicker, Divider, Form, Input, Modal, Row, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import CmsEnvironmentDropdown from './items/CmsEnvironmentDropdown';
import { CmsPageContentDiff } from '../displays/CmsPageContentDiff';
import CmsPageGroupSelectInput from './items/CmsPageGroupSelectInput';
import type { CmsPageMetadataModel } from './../../../models/cms/CmsPageMetadataModel';
import type { CmsReleaseModel } from '../../../models/cms/releases/CmsReleaseModel';
import { CmsStatus } from '../../../enums/cms/CmsStatusEnum';
import { Link } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import { getErrorDisplayValues } from '../../../utils/displayUtils';
import { getPageGroupById } from '../../../services/CmsPageGroupService';
import moment from 'moment';
import useCreateCmsRelease from '../../../hooks/services/cms/releases/useCreateCmsRelease';
import { useNavigate } from 'react-router-dom';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import useUpdateCmsRelease from '../../../hooks/services/cms/releases/useUpdateCmsRelease';

const REQUIRED_RULE = { message: '"${label}" is required', required: true };

const FORM_LAYOUT = {
  labelCol: { span: 6 },
};

type Props = {
  affectedPages?: Array<CmsPageMetadataModel>;
  initialRelease?: CmsReleaseModel;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
  modalTitle: string;
};

const CmsReleaseModalForm = ({
  affectedPages,
  initialRelease,
  isShown,
  setIsShown,
  modalTitle,
}: Props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // States
  const [pages, setPages] = useState<Array<CmsPageMetadataModel>>([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>();
  const [newPageEnvironment, setNewPageEnvironment] = useState<number | null>();
  const [originalPageEnvironment, setOriginalPageEnvironment] = useState<number | null>();

  // Mutators
  const {
    create,
    isLoading: isCreating,
    isSuccess: isCreated,
    isError: isCreateError,
  } = useCreateCmsRelease();

  const {
    update,
    isLoading: isUpdating,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateCmsRelease();

  // Organize Data
  useEffect(() => {
    if (affectedPages?.length && initialRelease && isShown) {
      setPages(affectedPages);
    }
  }, [affectedPages, initialRelease, isShown]);

  useEffect(() => {
    if (initialRelease && isShown) {
      setNewPageEnvironment(initialRelease.releaseContents[0].currentEnvironmentId);
      setOriginalPageEnvironment(initialRelease.releaseContents[0].targetEnvironmentId);
    }

    if (!isShown) {
      setNewPageEnvironment(null);
      setOriginalPageEnvironment(null);
    }
  }, [initialRelease, isShown]);

  const initialValues = initialRelease
    ? {
        name: initialRelease.name,
        targetDate: moment(initialRelease.targetDate),
        newPageEnvironment: initialRelease.releaseContents[0].currentEnvironmentId,
        originalPageEnvironment: initialRelease.releaseContents[0].targetEnvironmentId,
        pageGroup:
          Number(initialRelease.releaseContents[0].currentPageContentVersion.page.group.id) ??
          undefined,
      }
    : undefined;

  const isNewRelease = !initialRelease;
  const isLoading = isCreating || isUpdating;
  const isSuccess = isCreated || isUpdated;
  const isError = isCreateError || isUpdateError;

  const filteredPages = useMemo(() => {
    return pages.filter(
      (page) => page.status !== CmsStatus.Unpublished && page.status !== CmsStatus.Scheduled,
    );
  }, [pages]);

  // Mutation Events
  const onCreate = async () => {
    const formValues = form.getFieldsValue();

    const newRelease = {
      name: formValues.name,
      targetDate: formValues.targetDate.format(),
      releaseContents: formValues.notes.map((note, i) => ({
        pageSlug: filteredPages[i].slug,
        pageGroupSlug: filteredPages[i].group.slug,
        localeId: filteredPages[i].localeId,
        notes: note,
      })),
      newPageEnvironment: formValues.newPageEnvironment,
      originalPageEnvironment: formValues.originalPageEnvironment,
    };

    create(newRelease, {
      onSuccess: (data) => {
        onCancel();
        if (isNewRelease) navigate(`${ROUTES.CMS_RELEASES}/${data.id}`);
      },
      onError: (err) => {
        const errors = getErrorDisplayValues(err);
        if (errors.length > 0) {
          const errorMessage = errors.join(' ');
          setErrorMessage(errorMessage);
        }
      },
    });
  };

  const onUpdate = async () => {
    const formValues = form.getFieldsValue();

    if (!initialRelease) return;

    const updateRelease = {
      releaseId: initialRelease.id ?? 0,
      name: formValues.name,
      targetDate: formValues.targetDate.format(),
      releaseContentMetaData: formValues.notes.map((note, i) => ({
        id: initialRelease.releaseContents[i].id,
        notes: note,
      })),
    };

    update(updateRelease, {
      onSuccess: () => {
        onCancel();
      },
      onError: (err) => {
        const errors = getErrorDisplayValues(err);
        if (errors.length > 0) {
          const errorMessage = errors.join(' ');
          setErrorMessage(errorMessage);
        }
      },
    });
  };

  // Form Events
  const onSelectPageGroup = async (pageGroupId: number) => {
    getPageGroupById(pageGroupId).then((result) => setPages(result.pages));
  };

  const isFormValid = (): boolean => {
    const formValues = form.getFieldsValue();

    if (formValues.newPageEnvironment === formValues.originalPageEnvironment) {
      setFormErrorMessage('Environments cannot be the same');
      return false;
    }

    if (filteredPages.length === 0) {
      setFormErrorMessage('Page group cannot be empty');
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    try {
      await form.validateFields();

      if (!isFormValid()) {
        return;
      }

      if (!isNewRelease) {
        onUpdate();
      } else {
        onCreate();
      }
      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  };

  const onCancel = () => {
    if (isLoading) {
      return;
    }

    form.resetFields();
    setPages([]);
    setIsShown(false);
  };

  const getPageCollapsePanelHeader = (page: CmsPageMetadataModel) => (
    <div className="cmsReleaseModalFormPageCollapseHeader">
      {page.slug}
      <Link to={`${ROUTES.PAGE_GROUPS}/${page.group.id}/page/${page.id}`} target="_blank">
        View Page
      </Link>
    </div>
  );

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The release was successfully  ${isNewRelease ? 'created' : 'updated'} `,
    errorDescription: errorMessage,
  });

  return (
    <Modal
      destroyOnClose={true}
      okText="Save"
      onOk={onSubmit}
      okButtonProps={{ loading: isLoading }}
      onCancel={onCancel}
      open={isShown}
      title={<strong>{modalTitle}</strong>}
      width={1000}
    >
      <Form
        {...FORM_LAYOUT}
        className="cmsReleaseModalFormRoot"
        form={form}
        initialValues={initialValues}
        layout="vertical"
        requiredMark={false}
      >
        <Row justify="space-between" className="cmsReleaseModalFormRow">
          <Col span={8} className="cmsReleaseModalFormCol">
            <Form.Item label="Name" name="name" rules={[REQUIRED_RULE]}>
              <Input maxLength={128} />
            </Form.Item>
          </Col>
          <Col span={8} className="cmsReleaseModalFormCol">
            <CmsPageGroupSelectInput
              initialGroupId={initialValues?.pageGroup}
              isDisabled={!isNewRelease}
              isRequired={true}
              label="Page Group"
              onSelect={onSelectPageGroup}
            />
          </Col>
          <Col span={4} className="cmsReleaseModalFormCol">
            <Form.Item
              label="Release Date"
              name="targetDate"
              tooltip={{
                title:
                  'If the target date is the current date the release will execute immediately.',
              }}
              rules={[REQUIRED_RULE]}
            >
              <DatePicker
                allowClear={false}
                disabledDate={(currentDate) =>
                  currentDate && currentDate < moment().subtract(1, 'days').endOf('day')
                }
                format="MM/DD/YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="cmsReleaseModalFormRow">
          <Col span={5} className="cmsReleaseModalFormCol">
            <CmsEnvironmentDropdown
              isDisabled={!isNewRelease}
              label="Source Environment"
              name="newPageEnvironment"
              onSelect={(selectedEnv) => setNewPageEnvironment(selectedEnv)}
            />
          </Col>
          <Col span={2} className="cmsReleaseModalFormCol cmsReleaseModalFormEnvArrow">
            <ArrowRightOutlined />
          </Col>
          <Col span={5} className="cmsReleaseModalFormCol">
            <CmsEnvironmentDropdown
              isDisabled={!isNewRelease}
              label="Destination Environment"
              name="originalPageEnvironment"
              onSelect={(selectedEnv) => setOriginalPageEnvironment(selectedEnv)}
            />
          </Col>
        </Row>
        {formErrorMessage && (
          <span className="cmsReleaseModalFormErrorMessage">{formErrorMessage}</span>
        )}
        <Divider orientation="left">Affected Pages ({filteredPages.length})</Divider>
        {filteredPages.length && newPageEnvironment && originalPageEnvironment ? (
          <Collapse accordion>
            {filteredPages.map((page, i) => (
              <Collapse.Panel forceRender header={getPageCollapsePanelHeader(page)} key={i}>
                <Form.Item
                  label="Notes"
                  name={['notes', i]}
                  initialValue={initialRelease?.releaseContents[i].notes ?? ''}
                >
                  <Input.TextArea maxLength={4000} />
                </Form.Item>
                <Collapse>
                  <Collapse.Panel header="View Changes" key={1}>
                    <CmsPageContentDiff
                      page={page}
                      newPageEnvironment={newPageEnvironment}
                      originalPageEnvironment={originalPageEnvironment}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <Typography.Text>
            Select a <b>page group</b> and <b>environments</b> to show affected pages
          </Typography.Text>
        )}
      </Form>
    </Modal>
  );
};

export default CmsReleaseModalForm;
