import '../../../css/core/PasswordInput.css';

import { Input } from 'antd';
import React from 'react';
import { createSecret } from './../../../utils/displayUtils';

type Props = {
  value?: string;
  secretLength?: number;
  onChange?: (e: any) => void;
};

const VendorCodeSecretInput = ({ secretLength, value, onChange }: Props) => {
  if (!value) {
    value = createSecret(secretLength);
  }
  React.useEffect(() => onChange && onChange(value), [value, onChange]);
  return (
    <Input.Password
      className="style-secret-component"
      maxLength={64}
      onChange={onChange}
      defaultValue={value}
      readOnly
    />
  );
};

export default VendorCodeSecretInput;
