import type { $RuleBooleanExpressionModel } from '../models/rules/RuleExpressionModel';
import { RULE_BOOLEAN_OPERATOR_SYMBOLS } from './../constants/rules/ruleBooleanOperatorSymbols';

export function buildExpressionString(booleanExpressions: $RuleBooleanExpressionModel[]): string {
  return booleanExpressions
    .map(({ booleanOperator, inputField, rawValue }) => {
      const operatorSymbol = RULE_BOOLEAN_OPERATOR_SYMBOLS[booleanOperator];

      return `${inputField.name} ${
        operatorSymbol ?? booleanOperator.toLowerCase()
      } ${JSON.stringify(rawValue)}`;
    })
    .join(' AND ');
}
