import { useMutation, useQueryClient } from 'react-query';

import QUERYKEYS from '../../../constants/queryKeys';
import type { TUpsertModel } from '../../../utils/hookUtils';
import type { TVendorCodeCommercialUpsertModel } from './../../../models/vendor/VendorCodeModels';
import { upsertVendorCodesCommercial } from './../../../services/VendorCodesService';

const useUpsertVendorCodesCommercial = (): TUpsertModel<
  Array<TVendorCodeCommercialUpsertModel>
> => {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(upsertVendorCodesCommercial, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.VENDOR_CODES ||
          query.queryKey[0] === QUERYKEYS.VENDOR_CODES_COMMERCIAL,
      }),
  });

  return {
    upsert: mutate,
    isSuccess,
    isError,
    isLoading,
  };
};

export default useUpsertVendorCodesCommercial;
