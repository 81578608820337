import type {
  CmsPageEditContextAction,
  CmsPageEditContextState,
} from './CmsPageEditContextActions';
import { createContext, useReducer } from 'react';

import { CmsPageEditContextReducer } from './CmsPageEditContextReducer';
import React from 'react';
import type { ReactNode } from 'react';

const initialCmsPageEditContextState = {
  editorPageContent: '',
};

export const CmsPageEditContext = createContext<{
  state: CmsPageEditContextState;
  dispatch: (action: CmsPageEditContextAction) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
}>({ state: initialCmsPageEditContextState, dispatch: () => {} });

type Props = {
  children: ReactNode;
};

export default function CmsPageEditContextProvider({ children }: Props) {
  const [state, dispatch] = useReducer(CmsPageEditContextReducer, initialCmsPageEditContextState);
  return (
    <CmsPageEditContext.Provider value={{ state, dispatch }}>
      {children}
    </CmsPageEditContext.Provider>
  );
}
