import './../../css/core/ListInput.css';

import { Form, Input, InputNumber, Modal, Tag, Tooltip } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import { capitalize } from 'lodash';
import { isNullOrEmpty } from './../../utils/booleanUtils';
import { nullThrows } from './../../utils/nullUtils';

const REQUIRED_RULE = { message: '"${label}" is required', required: true };

type Props = {
  onChange: (values: Array<number | string>) => void;
  shouldAllowDuplicates?: boolean;
  type: 'number' | 'text';
  value?: Array<number | string>;
  valueName?: string;
};

export default function ListInput({
  onChange,
  shouldAllowDuplicates = false,
  type,
  value,
  valueName = 'value',
}: Props): React.ReactElement {
  const [form] = Form.useForm();
  const [isInputModalShown, setIsInputModalShown] = React.useState<boolean>(false);
  const [newValue, setNewValue] = React.useState<number | string | null>();
  const nonNullValue = value ?? [];

  const onClose = React.useCallback(() => {
    setIsInputModalShown(false);
    setNewValue(null);
    form.resetFields();
  }, [form]);
  // Use index to remove values to handle for allowing duplicates
  const onRemove = React.useCallback((index: number) => {
    onChange(nonNullValue.filter((_value, i) => i !== index));
  }, []);

  const onSubmit = React.useCallback(() => {
    if (isNullOrEmpty(newValue)) {
      return;
    }

    const nonnullNewValue = nullThrows(newValue);

    if (shouldAllowDuplicates || !nonNullValue.includes(nonnullNewValue)) {
      onChange([...nonNullValue, nonnullNewValue]);
    }

    onClose();
  }, [newValue, onClose, onChange, shouldAllowDuplicates, nonNullValue]);

  return (
    <div className="listInputRoot">
      {nonNullValue.map((value, i) => (
        <Tooltip title={value} key={`${value}-${i}`}>
          <Tag className="listInputValue" closable={true} onClose={() => onRemove(i)}>
            {value}
          </Tag>
        </Tooltip>
      ))}
      <Tag className="listInputNewValue" onClick={() => setIsInputModalShown(true)}>
        <PlusOutlined /> New {valueName}
      </Tag>
      <Modal
        onOk={onSubmit}
        onCancel={onClose}
        title={`Add ${valueName}`}
        open={isInputModalShown}
        width={900}
      >
        <Form form={form}>
          <Form.Item label={capitalize(valueName)} name="value" rules={[REQUIRED_RULE]}>
            {type === 'number' ? (
              <InputNumber onChange={(value) => setNewValue(value)} value={newValue} />
            ) : (
              <Input onChange={(e) => setNewValue(e.target.value)} value={newValue ?? ''} />
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
