import { useMutation, useQueryClient } from 'react-query';

import type { TVendor } from './../../../models/vendor/VendorModel';
import type { TCreateModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { createVendor } from './../../../services/VendorService';

const useCreateVendor = (): TCreateModel<TVendor> => {
  const queryClient = useQueryClient();
  const { isError, isLoading, mutate } = useMutation(createVendor, {
    onSuccess: (data) =>
      queryClient.setQueryData(QUERYKEYS.VENDORS, (vendors: any) => [...vendors, data]),
  });

  return {
    create: mutate,
    isError,
    isLoading,
  };
};

export default useCreateVendor;
