import React, { useCallback } from 'react';
import { Button } from 'antd';

import { useCmsCollectionEntryForm } from '../../../../hooks/services/cms/collections/useCmsCollectionEntryForm';
import { useSaveCmsCollectionEntryContent } from '../../../../hooks/services/cms/collections/useSaveCmsCollectionEntryVersion';
import useStatusNotifications from '../../../../hooks/shared/useStatusNotifications';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  isDisabled: boolean;
  saveAsDraft: boolean;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

export const CmsCollectionEntryFormSaveButton = ({ saveAsDraft, isDisabled = false }: Props) => {
  const { save, isLoading, isError, isSuccess } = useSaveCmsCollectionEntryContent();
  const { form, getCollectionEntry } = useCmsCollectionEntryForm();

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      save(getCollectionEntry(saveAsDraft));

      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [form, getCollectionEntry, save, saveAsDraft]);

  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The collection item was successfully ${
      saveAsDraft ? 'saved' : 'published'
    }.`,
  });

  return (
    <EnhancedButton type="primary" disabled={isDisabled} loading={isLoading} onClick={onSubmit}>
      {saveAsDraft ? 'Save' : 'Publish'}
    </EnhancedButton>
  );
};
