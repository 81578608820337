import localConfig from './config.local';
import devConfig from './config.dev';
import qaConfig from './config.qa';
import stagingConfig from './config.staging';
import prodConfig from './config.prod';

export type TConfig = {
  environment: string;
  idleTimeoutInMinutes: number;
  cmsIdleTimeoutInMinutes: number;
  adminGateway: string;
  auth: {
    clientId: string;
    authority: string;
    redirectUri?: string;
  };
  refetchOnWindowFocus: boolean;
  maxBulkUpdateFileSizeKb: number;
};

let configToExport: TConfig;
switch (process.env.CUSTOM_ENV) {
  case 'prod':
    configToExport = prodConfig;
    break;
  case 'staging':
    configToExport = stagingConfig;
    break;
  case 'qa':
    configToExport = qaConfig;
    break;
  case 'dev':
    configToExport = devConfig;
    break;
  case 'local':
  default:
    configToExport = localConfig;
    break;
}
export default configToExport;
