import { Button } from 'antd';
import CmsPageModalForm from './../forms/CmsPageModalForm';
import React, { useState } from 'react';
import ButtonHiddenWithInsufficientPermission from '../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  pageGroupId?: string;
  isDisabled?: boolean;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

const CmsPageCreateButton = ({ pageGroupId, isDisabled }: Props) => {
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <EnhancedButton type="primary" disabled={isDisabled} onClick={() => setIsModalShown(true)}>
        Add New Page
      </EnhancedButton>
      <CmsPageModalForm
        initialFormValues={{ pageGroupId }}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </>
  );
};

export default CmsPageCreateButton;
