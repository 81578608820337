import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover } from 'antd';
import useDeletePromoCodeDiscount from '../../../hooks/services/promoCodes/useDeletePromoCodeDiscount';
import type { PromoCodeDiscountModel } from '../../../models/promoCodes/PromoCodeDiscountModel';

type Props = {
  discount: PromoCodeDiscountModel;
  isNew: boolean;
  isVisible?: boolean;
  isDisabled?: boolean;
  onSuccess?: () => void;
  onError?: (errors: any) => void;
};

const PromoCodeDiscountDeleteButton = ({
  discount,
  isNew,
  isVisible,
  isDisabled,
  onSuccess,
  onError,
}: Props) => {
  const deleteMutation = useDeletePromoCodeDiscount();

  const onDeleteClick = async (promoCodeDiscount: PromoCodeDiscountModel) => {
    if (isNew) {
      onSuccess?.();
      return;
    }

    await deleteMutation.delete(promoCodeDiscount, {
      onSuccess: () => onSuccess?.(),
      onError: (error) => onError?.(error),
    });
  };

  isVisible ??= true;
  isDisabled ??= false;

  return isVisible ? (
    <Popconfirm
      title="Are you sure to delete this discount?"
      onConfirm={() => onDeleteClick(discount)}
      okText="Yes"
      cancelText="No"
    >
      <Popover content={'Delete'} placement="right">
        <Button
          className="promoCodeDiscountRowButton"
          icon={<DeleteOutlined />}
          loading={deleteMutation.isLoading}
          shape="circle"
          disabled={isDisabled}
        />
      </Popover>
    </Popconfirm>
  ) : null;
};

export default PromoCodeDiscountDeleteButton;
