import React from 'react';
import { Row } from 'antd';
import { PromoCodeButtonTypeEnum } from '../../../enums/promoCodes/PromoCodeButtonTypeEnum';
import type { PromoCodeModel } from '../../../models/promoCodes/PromoCodeModel';
import PromoCodeArchiveButton from './PromoCodeArchiveButton';
import PromoCodeCreateButton from './PromoCodeCreateButton';

type Props = {
  data: Array<PromoCodeModel>;
  buttonTypes?: Array<PromoCodeButtonTypeEnum>;
  onDelete?: () => void;
};

const PromoCodeActionButtons = ({
  data,
  onDelete,
  buttonTypes = [PromoCodeButtonTypeEnum.ARCHIVE, PromoCodeButtonTypeEnum.CREATE],
}: Props) => {
  const getButtonByType = (type: PromoCodeButtonTypeEnum, key: number) => {
    switch (type) {
      case PromoCodeButtonTypeEnum.ARCHIVE:
        return (
          <PromoCodeArchiveButton
            data={data}
            key={key}
            isDisabled={!(data?.length > 0)}
            onClick={onDelete}
          />
        );
      case PromoCodeButtonTypeEnum.CREATE:
        return <PromoCodeCreateButton key={key} />;
      default:
        return null;
    }
  };
  return (
    <Row justify="space-between">
      {buttonTypes.map((type, index) => getButtonByType(type, index))}
    </Row>
  );
};

export default PromoCodeActionButtons;
