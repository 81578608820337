import { Form, Input, Row, Space } from 'antd';
import React, { Fragment } from 'react';

import { CmsCollectionEntryDataItems } from '../../cms/forms/collections/entryVersionData/CmsCollectionEntryDataItems';
import { CmsCollectionEntryFormCreateButton } from '../../cms/buttons/collections/CmsCollectionEntryFormCreateButton';
import PageHeader from '../../shared/PageHeader';
import Section from '../../shared/Section';
import routes from '../../../constants/routes';
import useCmsCollection from '../../../hooks/services/cms/collections/useCmsCollection';
import { useParams } from 'react-router-dom';

export const CmsCollectionEntryCreatePage = () => {
  const { collectionId } = useParams();
  const { data, isLoading, isError } = useCmsCollection(Number(collectionId));
  const { id, slug } = data ?? {};

  const [form] = Form.useForm();

  return (
    <Form form={form} layout="vertical">
      <PageHeader
        title="New Collection Item"
        breadcrumbRoutes={
          id && slug
            ? [
                { breadcrumbName: 'Home', path: routes.CMS },
                { breadcrumbName: 'Collections', path: routes.CMS_COLLECTIONS },
                { breadcrumbName: slug, path: `${routes.CMS_COLLECTIONS}/${id}/entries` },
                {
                  breadcrumbName: 'New Collection Item',
                  path: `${routes.CMS_COLLECTIONS}/${id}/entries/new`,
                },
              ]
            : undefined
        }
        extra={[
          <Row key="actionBar" justify="end">
            <Space>
              <CmsCollectionEntryFormCreateButton saveAsDraft={true} isDisabled={isLoading} />
              <CmsCollectionEntryFormCreateButton saveAsDraft={false} isDisabled={isLoading} />
            </Space>
          </Row>,
        ]}
      />
      <Section isLoading={isLoading} isError={isError}>
        {data && (
          <>
            <CmsCollectionEntryDataItems
              schemaData={data.schemaData}
              initialData={[]}
              includeMetadataFields={true}
            />
            <Form.Item hidden name="collectionId" initialValue={data.id}>
              <Input type="hidden" />
            </Form.Item>
          </>
        )}
      </Section>
    </Form>
  );
};
