import { Col, Form, Input, Modal, notification, Row, Switch } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import type { TVendorCodeCommercial } from '../../../models/vendor/VendorCodeModels';
import RequestErrorAlert from '../../shared/RequestErrorAlert';
import VendorInput from '../inputs/VendorInput';
import MarketingCampaignSelect from '../inputs/MarketingCampaignSelect';
import useCreateVendorCodeCommercial from './../../../hooks/services/vendor/useCreateVendorCodeCommercial';
import useUpdateVendorCodeCommercial from './../../../hooks/services/vendor/useUpdateVendorCodeCommercial';
import useVendors from '../../../hooks/services/vendor/useVendors';
import PhoneInput from '../../core/PhoneInput';
import { validatePhoneNumber } from '../../../utils/validators';

const FORM_LAYOUT = {
  labelCol: { span: 12 },
  wrapperCol: { span: 10 },
};

const REQUIRED_RULE = { message: 'This field is required', required: true };

const PHONE_RULE = {
  validator: (rule: any, value: string) => {
    return !value || validatePhoneNumber(value)
      ? Promise.resolve()
      : Promise.reject(new Error('Enter a valid phone number.'));
  },
};
type Props = {
  initialData?: TVendorCodeCommercial;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
  vendorId?: number;
};

const DEFAULT_VENDOR_CODE = {
  isActive: true,
  name: '',
  vendorId: '',
  marketingTier: '',
  legacyVendorId: '',
  creative: '',
  mediaType: '',
  station: '',
  tollFreeNumber: '',
};

const VendorCodeCommercialModalForm = ({ initialData, isShown, setIsShown, vendorId }: Props) => {
  const { create, isLoading: isCreating } = useCreateVendorCodeCommercial();
  const { update, isLoading: isUpdating } = useUpdateVendorCodeCommercial();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isLoading = isCreating || isUpdating;
  const isNewVendorCode = !initialData;
  const { data } = useVendors();
  const [selectedVendorId, setSelectedVendorId] = useState<any>(vendorId);
  const [selectedVendorShortName, setSelectedVendorShortName] = useState<any>('');
  const [vendorCode, setVendorCode] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    // Reset error state in the event user cancels
    setErrorOccured(false);
    form.resetFields();
    const shortName =
      data?.filter((x) => x.id === selectedVendorId).map((x) => x.shortName)[0] ?? '';
    setSelectedVendorShortName(shortName);
    setSelectedVendorId(vendorId);
    setVendorCode('');
    setIsShown(false);
  }, [data, form, selectedVendorId, setIsShown, vendorId]);

  const onUpdate = useCallback(() => {
    form.resetFields();
    setIsShown(false);
    notification.success({
      message: <strong>Successfully Updated</strong>,
      description: <span>TFN Only vendor code successfully updated.</span>,
      placement: 'bottomLeft',
    });
  }, [form, setIsShown]);

  const navigateToDetailPage = useCallback(
    (data: TVendorCodeCommercial) => {
      navigate(`${ROUTES.VENDOR_CODES_COMMERCIAL}/${data.id}`, {
        state: { isNew: true, name: data.name },
      });
    },
    [navigate],
  );

  const onSubmit = useCallback(async () => {
    await form.validateFields();
    if (isNewVendorCode) {
      create(form.getFieldsValue(), {
        onSuccess: (data) => navigateToDetailPage(data),
        onError: (error) => {
          setErrorOccured(true);
          setErrorMessage(error.response.data.message);
        },
      });
      return;
    }

    update(
      { id: initialData?.id, ...form.getFieldsValue() },
      {
        onSuccess: onUpdate,
        onError: (error) => {
          setErrorOccured(true);
          setErrorMessage(error.response.data.message);
        },
      },
    );
  }, [initialData?.id, isNewVendorCode, form, create, navigateToDetailPage, onUpdate, update]);

  useEffect(() => {
    form.resetFields();
  }, [initialData, form]);

  useEffect(() => {
    if (isNewVendorCode) {
      if (selectedVendorShortName || vendorCode) {
        form.setFieldValue('name', selectedVendorShortName + '-COMM-' + vendorCode);
      } else {
        form.setFieldValue('name', '');
      }
    }
  }, [isNewVendorCode, selectedVendorShortName, vendorCode, form, initialData?.name]);

  useEffect(() => {
    const shortName =
      data?.filter((x) => x.id === selectedVendorId).map((x) => x.shortName)[0] ?? '';
    setSelectedVendorShortName(shortName);
  }, [selectedVendorId, data]);

  function handleVendorSelected(vendorId: any) {
    setSelectedVendorId(vendorId);
  }

  return (
    <Modal
      okButtonProps={{ loading: isLoading }}
      onOk={onSubmit}
      onCancel={onCancel}
      title={`${isNewVendorCode ? 'Create' : 'Update'} Vendor Code - TFN Only`}
      open={isShown}
      width={900}
      okText={isNewVendorCode ? 'Create' : 'Update'}
    >
      <Form
        {...FORM_LAYOUT}
        form={form}
        initialValues={initialData ?? { ...DEFAULT_VENDOR_CODE, vendorId: vendorId ?? '' }}
      >
        <RequestErrorAlert isError={errorOccured} description={errorMessage} />
        <Row>
          <Col span={12}>
            <Form.Item label="Code" name="legacyVendorId" rules={[REQUIRED_RULE]}>
              <Input
                maxLength={64}
                disabled={!!initialData?.legacyVendorId}
                onChange={(value) => setVendorCode(value.target.value)}
              />
            </Form.Item>
            <Form.Item label="Vendor" name="vendorId" rules={[REQUIRED_RULE]}>
              <VendorInput
                disabled={!!vendorId || !isNewVendorCode}
                onChange={handleVendorSelected}
              />
            </Form.Item>
            <Form.Item label="Name" name="name" rules={[REQUIRED_RULE]}>
              <Input maxLength={50} disabled />
            </Form.Item>
            <Form.Item label="Campaign" name="marketingCampaignId" rules={[REQUIRED_RULE]}>
              <MarketingCampaignSelect />
            </Form.Item>
            <Form.Item
              label="Toll Free Number"
              name="tollFreeNumber"
              validateFirst={true}
              validateTrigger="onOk"
              rules={[PHONE_RULE]}
            >
              <PhoneInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Creative" name="creative">
              <Input maxLength={64} />
            </Form.Item>
            <Form.Item label="Media Type" name="mediaType">
              <Input maxLength={64} />
            </Form.Item>
            <Form.Item label="Station" name="station">
              <Input maxLength={64} />
            </Form.Item>
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch disabled={isNewVendorCode} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default VendorCodeCommercialModalForm;
