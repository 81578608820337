import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { TableColumnsType } from 'antd';
import { uniqBy } from 'lodash';
import { getDateTimeDisplayValue, getModifiedBy } from '../../../../utils/displayUtils';
import { nullThrows } from '../../../../utils/nullUtils';

import CmsCollectionArchivedButton from '../../../../components/cms/buttons/collections/CmsCollectionArchiveButton';
import { CmsCollectionEditSchemaButton } from '../../../../components/cms/buttons/collections/CmsCollectionEditSchemaButton';
import type { CmsCollectionModel } from '../../../../models/cms/collections/CmsCollectionModel';

type Config = Readonly<{
  data?: CmsCollectionModel[];
}>;

export default function useCmsCollectionsTableSchema({
  data,
}: Config): TableColumnsType<CmsCollectionModel> {
  return useMemo(
    () => [
      {
        title: 'Title',
        dataIndex: 'title',
        sorter: (a, b) => a.title.localeCompare(b.title),
        render: (value: string, row) => (
          <Link to={`/cms/collections/${row.id}/entries`}>{value}</Link>
        ),
        onFilter: (searchValue, record) => searchValue === record.title,
        filterSearch: true,
        filters: uniqBy(data, 'title').map((record) => ({
          text: record.title,
          value: record.title,
        })),
      },
      {
        title: 'Slug',
        dataIndex: 'slug',
        sorter: (a, b) => a.slug.localeCompare(b.slug),
      },
      {
        title: 'Total Items',
        dataIndex: 'totalEntries',
        sorter: (a, b) => a.totalEntries - b.totalEntries,
      },
      {
        title: 'Last Modified Date',
        dataIndex: 'updatedDate',
        render: (value: string): string => getDateTimeDisplayValue(value),
      },
      {
        title: 'Last Modified',
        dataIndex: 'updatedBy',
        sorter: (a, b) => a.updatedBy.localeCompare(b.updatedBy),
        render: (value: string) => getModifiedBy(value),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
          <>
            <CmsCollectionArchivedButton collection={record} style={{ marginRight: 8 }} />
            <CmsCollectionEditSchemaButton collectionId={nullThrows<number>(record.id)} />
          </>
        ),
      },
    ],
    [data],
  );
}
