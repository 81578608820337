import { useMutation, useQueryClient } from 'react-query';

import type { TVendorCodesInternet } from './../../../models/vendor/VendorCodeModels';
import type { TUpdateModel } from '../../../utils/hookUtils';
import QUERYKEYS from '../../../constants/queryKeys';
import { updateVendorCodeInternet } from './../../../services/VendorCodesService';

const useUpdateVendorCodeInternet = (): TUpdateModel<TVendorCodesInternet> => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateVendorCodeInternet, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.VENDOR_CODES ||
          query.queryKey[0] === QUERYKEYS.VENDORS ||
          query.queryKey[0] === QUERYKEYS.VENDOR_CODES_INTERNET,
      }),
  });

  return {
    update: mutate,
    isLoading,
  };
};

export default useUpdateVendorCodeInternet;
