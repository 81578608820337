import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import ROUTES from '../../../../constants/routes';
import ButtonHiddenWithInsufficientPermission from '../../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  collectionId?: number;
  isDisabled?: boolean;
};

const EnhancedButton = ButtonHiddenWithInsufficientPermission(Button);

export const CmsCollectionEntryCreateButton = ({ collectionId, isDisabled }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <EnhancedButton
        type="primary"
        disabled={isDisabled}
        onClick={() => navigate(`${ROUTES.CMS_COLLECTIONS}/${collectionId}/entries/new`)}
      >
        Add New Item
      </EnhancedButton>
    </>
  );
};
