import { useMutation, useQueryClient } from 'react-query';
import type { PromoCodeModel } from './../../../models/promoCodes/PromoCodeModel';
import type { PromoCodeDiscountModel } from './../../../models/promoCodes/PromoCodeDiscountModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { deletePromoCodeDiscount } from './../../../services/PromoCodesService';
import type { TDeleteModel } from '../../../utils/hookUtils';

const useDeletePromoCodeDiscount = (): TDeleteModel<PromoCodeDiscountModel> => {
  const queryClient = useQueryClient();
  const { isLoading, isError, mutate } = useMutation(
    async (promoCodeDiscount: PromoCodeDiscountModel) =>
      deletePromoCodeDiscount(promoCodeDiscount.promoCodeId, promoCodeDiscount.id),
    {
      onSuccess: async (data, variables: PromoCodeDiscountModel) => {
        const queryKey = QUERYKEYS.PROMO_CODES;
        await queryClient.cancelQueries(queryKey);
        const cachedPromoCodes: Array<PromoCodeModel> | undefined =
          queryClient.getQueryData(queryKey);

        if (cachedPromoCodes === undefined) return cachedPromoCodes;

        cachedPromoCodes.map((promoCode: PromoCodeModel) => {
          if (promoCode.id === variables!.promoCodeId) {
            const index = promoCode.discounts.findIndex((discount) => discount.id === variables.id);
            if (index >= 0) promoCode.discounts.splice(index, 1);
          }
          return promoCode;
        });

        queryClient.setQueryData(queryKey, cachedPromoCodes);

        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey === QUERYKEYS.PROMO_CODES,
        });

        return cachedPromoCodes;
      },
    },
  );

  return {
    delete: mutate,
    isError,
    isLoading,
  };
};

export default useDeletePromoCodeDiscount;
