import PageHeader from '../../shared/PageHeader';
import React from 'react';
import RequestErrorAlert from '../../shared/RequestErrorAlert';
import Section from '../../shared/Section';
import VendorCodesTable from './../../../components/vendor/tables/VendorCodesTable';
import useVendorCodes from './../../../hooks/services/vendor/useVendorCodes';

const VendorCodesPage = () => {
  const { data, isError, isLoading } = useVendorCodes();

  return (
    <div className="vendorCodesPageRoot">
      <PageHeader title="Vendor Codes" />
      <Section isLoading={isLoading}>
        <RequestErrorAlert
          isError={isError}
          description="An error occurred while attempting to load the data for this table."
        />
        <VendorCodesTable data={data ?? []} isLoading={false} />
      </Section>
    </div>
  );
};
export default VendorCodesPage;
