import { useMutation, useQueryClient } from 'react-query';

import type { TVendor } from './../../../models/vendor/VendorModel';
import QUERYKEYS from '../../../constants/queryKeys';
import { updateVendor } from './../../../services/VendorService';
import type { TUpdateModel } from '../../../utils/hookUtils';

function useUpdateVendor(): TUpdateModel<TVendor> {
  const queryClient = useQueryClient();
  const { isError, isLoading, mutate } = useMutation(updateVendor, {
    onSuccess: () => queryClient.invalidateQueries(QUERYKEYS.VENDORS),
  });

  return {
    update: mutate,
    isLoading,
    isError,
  };
}

export default useUpdateVendor;
