import React from 'react';
import { Button } from 'antd';
import type { CmsSavePageContentModel } from './../../../models/cms/CmsSavePageContentModel';
import useSaveCmsPageContent from './../../../hooks/services/cms/useSaveCmsPageContent';
import useStatusNotifications from '../../../hooks/shared/useStatusNotifications';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';

type Props = {
  pageContentModel: CmsSavePageContentModel;
};

const EnhancedButton = ButtonDisabledWithInsufficientPermission(Button);

export default function CmsPageSaveButton({ pageContentModel }: Props) {
  const { save, isError, isLoading, isSuccess } = useSaveCmsPageContent();
  useStatusNotifications({
    isSuccess,
    isError,
    successDescription: `The object was successfully saved.`,
  });

  return (
    <EnhancedButton type="primary" onClick={() => save(pageContentModel)} loading={isLoading}>
      Save
    </EnhancedButton>
  );
}
