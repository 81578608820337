import { useMutation, useQueryClient } from 'react-query';

import type { $RuleModel } from '../../../models/rules/RuleModel';
import QUERYKEYS from '../../../constants/queryKeys';
import type { TUpsertModel } from '../../../utils/hookUtils';
import { upsertRule } from './../../../services/RuleService';

const useUpsertRule = (): TUpsertModel<$RuleModel> => {
  const queryClient = useQueryClient();
  const { isError, isLoading, mutate } = useMutation(upsertRule, {
    onSuccess: (data) =>
      data &&
      queryClient.setQueryData(QUERYKEYS.RULES, (rules: any) => [
        ...rules.filter((rule) => rule.id !== data.id),
        data,
      ]),
  });

  return {
    upsert: mutate,
    isError,
    isLoading,
  };
};

export default useUpsertRule;
