import { useMutation, useQueryClient } from 'react-query';

import QUERYKEYS from '../../../constants/queryKeys';
import { deleteCmsPages } from '../../../services/CmsPageService';

type MutationCallbacks = {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
};

type $DeleteCmsPageGroup = {
  deleteMutation: (ids: Array<number>, callbacks?: MutationCallbacks) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

export default function useDeleteCmsPages(): $DeleteCmsPageGroup {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(deleteCmsPages, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGES ||
          query.queryKey === QUERYKEYS.PAGES ||
          query.queryKey[0] === QUERYKEYS.PAGE_GROUP ||
          query.queryKey[0] === QUERYKEYS.PAGE,
      }),
  });

  return {
    deleteMutation: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}
