import type { TMarketingCampaign } from '../models/vendor/MarketingCampaignModel';
import WebAPIClient from './api/WebAPIClient';

const PATH = 'MarketingCampaigns';

export const getMarketingCampaign = async (): Promise<Array<TMarketingCampaign> | undefined> => {
  const response = await WebAPIClient.get(PATH);

  return response?.data ?? [];
};
