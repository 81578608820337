import { useMutation, useQueryClient } from 'react-query';

import QUERYKEYS from '../../../constants/queryKeys';
import { publishPages } from '../../../services/CmsPageService';

type MutationCallbacks = {
  onError?: () => void;
  onSettled?: () => void;
  onSuccess?: () => void;
};

type $PublishCmsPageContent = {
  publish: (pageIds: Array<number>, callbacks?: MutationCallbacks) => void;
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
};

function useBulkPublishPages(): $PublishCmsPageContent {
  const queryClient = useQueryClient();
  const { isError, isLoading, isSuccess, mutate } = useMutation(publishPages, {
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey === QUERYKEYS.LAST_MODIFIED_PAGES ||
          query.queryKey === QUERYKEYS.PAGES ||
          query.queryKey[0] === QUERYKEYS.PAGE_GROUP ||
          query.queryKey[0] === QUERYKEYS.PAGE ||
          query.queryKey[0] === QUERYKEYS.PAGE_CONTENT ||
          query.queryKey[0] === QUERYKEYS.PAGE_CONTENT_VERSIONS,
      }),
  });

  return {
    publish: mutate,
    isError,
    isLoading,
    isSuccess,
  };
}

export default useBulkPublishPages;
