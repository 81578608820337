// The types of operations supported in the rules engine logic that compare 2 values and yields a
// boolean.
export enum RuleExpressionBooleanOperatorEnum {
  CONTAINS = 'Contains',
  DOES_NOT_EQUAL = 'Does not equal',
  ENDS_WITH = 'Ends with',
  EQUALS = 'Equals',
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUAL = 'Greater than or equal to',
  IN = 'In',
  INCLUDES = 'Includes',
  LESS_THAN = 'Less than',
  LESS_THAN_OR_EQUAL = 'Less than or equal to',
  STARTS_WITH = 'Starts with',
}
