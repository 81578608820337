import { Form, Input, Modal, notification, Switch } from 'antd';
import React, { useState } from 'react';

import RequestErrorAlert from '../../shared/RequestErrorAlert';
import type { TVendor } from './../../../models/vendor/VendorModel';
import VendorCodeSecretInput from '../inputs/VendorCodeSecretInput';

import useCreateVendor from './../../../hooks/services/vendor/useCreateVendor';
import useUpdateVendor from './../../../hooks/services/vendor/useUpdateVendor';

const FORM_LAYOUT = {
  labelCol: { span: 9 },
};

const REQUIRED_RULE = { message: '"${label}" is required', required: true };

type Props = {
  initialData: TVendor;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
  isCreatingNew?: boolean;
};

const DEFAULT_VENDOR = {
  isActive: true,
  name: '',
  secret: '',
  shortName: '',
};

const VendorModalForm = ({ initialData, isShown, setIsShown, isCreatingNew }: Props) => {
  const { create, isLoading: isCreating } = useCreateVendor();
  const { update, isLoading: isUpdating } = useUpdateVendor();
  const [form] = Form.useForm();
  const isLoading = isCreating || isUpdating;
  const isNewVendor = !initialData;
  const mutate = isNewVendor ? create : update;
  const [errorMessage, setErrorMessage] = useState('');
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  const onCancel = React.useCallback(() => {
    // Reset error state in the event user cancels
    setErrorOccured(false);
    if (isLoading) {
      return;
    }

    setIsShown(false);
  }, [isLoading, setIsShown]);

  const onUpdate = React.useCallback(() => {
    form.resetFields();
    setIsShown(false);
    notification.success({
      message: <strong>Successfully Updated</strong>,
      description: <span>Vendor successfully updated.</span>,
      placement: 'bottomLeft',
    });
  }, [form, setIsShown]);

  const onCreate = React.useCallback(() => {
    form.resetFields();
    setIsShown(false);
    notification.success({
      message: <strong>Successfully Created</strong>,
      description: <span>Vendor was successfully created.</span>,
      placement: 'bottomLeft',
    });
  }, [form, setIsShown]);

  const onSubmit = React.useCallback(async () => {
    const vendor =
      isNewVendor || isCreatingNew
        ? form.getFieldsValue()
        : { id: initialData?.id, ...form.getFieldsValue() };

    try {
      await form.validateFields();

      if (mutate === update && isCreatingNew === false) {
        update(vendor, {
          onSuccess: onUpdate,
          onError(error) {
            setErrorOccured(true);
            setErrorMessage(error.response.data.message);
          },
        });
      } else {
        create(vendor, {
          onSuccess: onCreate,
          onError(error) {
            setErrorOccured(true);
            setErrorMessage(error.response.data.message);
          },
        });
      }
      // eslint-disable-next-line no-empty
    } catch (errorInfo) {}
  }, [
    isNewVendor,
    isCreatingNew,
    form,
    initialData?.id,
    mutate,
    update,
    onUpdate,
    create,
    onCreate,
  ]);

  React.useEffect(() => {
    form.resetFields();
  }, [initialData, isShown, form]);

  return (
    <Modal
      okButtonProps={{ loading: isLoading }}
      onOk={onSubmit}
      onCancel={onCancel}
      title={`${isNewVendor || isCreatingNew ? 'Create' : 'Update'} Vendor`}
      open={isShown}
      okText={isNewVendor || isCreatingNew ? 'Create' : 'Update'}
    >
      <Form
        {...FORM_LAYOUT}
        className="vendorModalFormRoot"
        form={form}
        initialValues={initialData ?? DEFAULT_VENDOR}
      >
        <RequestErrorAlert isError={errorOccured} description={errorMessage} />
        <Form.Item label="Name" name="name" rules={[REQUIRED_RULE]}>
          <Input maxLength={100} />
        </Form.Item>
        <Form.Item label="Short Name" name="shortName" rules={[REQUIRED_RULE]}>
          <Input maxLength={50} />
        </Form.Item>
        <Form.Item label="Secret" name="secret" rules={[REQUIRED_RULE]}>
          <VendorCodeSecretInput secretLength={16} value={'initialData?.secret'} />
        </Form.Item>
        <Form.Item
          label="Is Active"
          name="isActive"
          valuePropName="checked"
          rules={[REQUIRED_RULE]}
        >
          <Switch disabled={isNewVendor || isCreatingNew} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default VendorModalForm;
