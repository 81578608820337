import { CmsLocaleEnum } from '../enums/cms/CmsLocaleEnum';
import type { CmsPageContentVersionModel } from '../models/cms/CmsPageContentVersionModel';
import type { CmsPageContentVersionsModel } from '../models/cms/CmsPageContentVersionsModel';
import type { CmsPageMetadataModel } from '../models/cms/CmsPageMetadataModel';
import type { CmsPageModel } from '../models/cms/CmsPageModel';
import type { CmsSavePageContentModel } from '../models/cms/CmsSavePageContentModel';
import type { GetCmsPageRequest } from './types/CmsPages/GetCmsPageRequest';
import WebAPIClient from './api/WebAPIClient';

const PATH = `cms/pages`;

export const getLastModifiedPages = async (limit = 10): Promise<Array<CmsPageMetadataModel>> => {
  const response = await WebAPIClient.get(`${PATH}`, { limit: limit.toString() });

  return response?.data ?? [];
};

export const getCmsPages = async (): Promise<Array<CmsPageMetadataModel>> => {
  const response = await WebAPIClient.get(`${PATH}`);

  return response?.data ?? [];
};

export const createCmsPage = async (config: {
  title: string;
  slug: string;
  localeId: CmsLocaleEnum;
}): Promise<CmsPageModel> => {
  const response = await WebAPIClient.post(PATH, config);
  return response?.data;
};

export const cloneCmsPages = async (pageIds: Array<number>): Promise<Array<number>> => {
  const response = await WebAPIClient.post(`${PATH}/clone`, pageIds);
  return response?.data;
};

export const deleteCmsPages = async (pageIds: Array<number>): Promise<void> => {
  await WebAPIClient.post(`${PATH}/delete`, { ids: pageIds });
};

export const updateCmsPage = async (
  page: CmsPageMetadataModel | CmsPageModel,
): Promise<CmsPageMetadataModel> => {
  const response = await WebAPIClient.patch(`${PATH}/${page.id}`, page);
  return response?.data;
};

export const getPageContentById = async (
  pageId: number,
  contentLimit = 1,
): Promise<CmsPageModel> => {
  const response = await WebAPIClient.get(`${PATH}/${pageId}/content`, {
    contentLimit: contentLimit.toString(),
  });

  return response?.data ?? {};
};

export const getPage = async (request: GetCmsPageRequest): Promise<CmsPageModel> => {
  request.localeId ??= CmsLocaleEnum.EN_US;

  const response = await WebAPIClient.get(`${PATH}/content`, {
    groupSlug: request.groupSlug,
    pageSlug: request.pageSlug,
    localeId: request.localeId?.toString() ?? CmsLocaleEnum.EN_US.toString(),
    environment: request.environment?.toString() ?? '',
    contentLimit: request.contentLimit?.toString() ?? '',
  });

  return response?.data ?? {};
};

export const getPageContentVersions = async (
  pageId: number,
): Promise<CmsPageContentVersionsModel> => {
  const response = await WebAPIClient.get(`${PATH}/${pageId}/metadata`);

  return response?.data ?? {};
};

export const getPageContentVersionById = async (
  pageId: number,
  version: number,
): Promise<CmsPageContentVersionModel> => {
  const response = await WebAPIClient.get(`${PATH}/${pageId}/content/${version}`);

  return response?.data ?? {};
};

export const savePageContent = async (
  cmsPageContentModel: CmsSavePageContentModel,
): Promise<CmsSavePageContentModel> => {
  const { pageId, ...content } = cmsPageContentModel;
  const response = await WebAPIClient.post(`${PATH}/${pageId}/draft`, content);

  return response?.data;
};

export const publishPages = async (pageIds: Array<number>): Promise<void> => {
  await WebAPIClient.post(`${PATH}/publish`, { pageIds: pageIds });
};

export const publishPageContent = async (
  cmsPageContentModel: CmsSavePageContentModel,
): Promise<CmsSavePageContentModel> => {
  const { pageId, ...content } = cmsPageContentModel;
  const response = await WebAPIClient.post(`${PATH}/${pageId}/publish`, content);

  return response?.data;
};
