import type { CmsCollectionModel } from '../../../../models/cms/collections/CmsCollectionModel';
import type { CmsQueryResponse } from '../../../../models/cms/CmsResponseModels';

import QUERYKEYS from '../../../../constants/queryKeys';
import { getCollections } from '../../../../services/CmsCollectionService';
import { useQuery } from 'react-query';

export default function useCmsCollections(): CmsQueryResponse<CmsCollectionModel[]> {
  const { isLoading, isError, data } = useQuery(QUERYKEYS.COLLECTIONS, () => getCollections(), {});

  return {
    data,
    isError,
    isLoading,
  };
}
