import { Form, Select } from 'antd';

import { CmsCollectionSchemaDataRefTypeEnum } from '../../../../enums/cms/CmsCollectionSchemaDataRefTypeEnum';
import { REQUIRED_RULE } from '../../../../constants/formRules';
import React from 'react';
import useCmsCollections from '../../../../hooks/services/cms/collections/useCmsCollections';

type Props = {
  collectionId: number;
};

export const CmsSchemaDataRefItems = ({ collectionId }: Props) => {
  const { data, isLoading } = useCmsCollections();
  return (
    <>
      <Form.Item
        label="Which collection do you want to nest?"
        name="refCollectionId"
        rules={[REQUIRED_RULE]}
      >
        <Select loading={isLoading}>
          {data?.map((collection, ii) =>
            collection.id !== collectionId ? (
              <Select.Option key={ii} value={collection.id}>
                {collection.title}
              </Select.Option>
            ) : null,
          )}
        </Select>
      </Form.Item>
      <Form.Item label="How many items can be referenced?" name="refType" rules={[REQUIRED_RULE]}>
        <Select>
          <Select.Option value={CmsCollectionSchemaDataRefTypeEnum.OneToOne}>One</Select.Option>
          <Select.Option value={CmsCollectionSchemaDataRefTypeEnum.OneToMany}>
            One or more
          </Select.Option>
        </Select>
      </Form.Item>
    </>
  );
};
