import React, { useState } from 'react';
import { Button } from 'antd';
import type { TVendor } from '../../../models/vendor/VendorModel';
import VendorModalForm from '../forms/VendorModalForm';
import ButtonDisabledWithInsufficientPermission from '../../hocs/buttons/ButtonDisabledWithInsifficientPermission';

type Props = {
  isDisabled?: boolean;
  searchValue: string;
  clearFilters?: any;
  dataIndex: string;
};

const EnhancedButton = ButtonDisabledWithInsufficientPermission(Button);

const VendorPageSearchCreateButton = ({
  isDisabled,
  searchValue,
  clearFilters,
  dataIndex,
}: Props) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');

  const model = {
    isActive: true,
    name: dataIndex === 'name' ? searchedValue : '',
    shortName: dataIndex === 'shortName' ? searchedValue : '',
  };

  return (
    <>
      <EnhancedButton
        type="primary"
        disabled={isDisabled}
        onClick={() => {
          setIsModalShown(true);
          setSearchedValue(searchValue);
          clearFilters({ closeDropdown: true });
        }}
      >
        Create
      </EnhancedButton>
      <VendorModalForm
        initialData={model as TVendor}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
        isCreatingNew={true}
      />
    </>
  );
};

export default VendorPageSearchCreateButton;
