import React from 'react';
import type { CmsPageMetadataModel } from '../../../models/cms/CmsPageMetadataModel';
import CmsPageModalForm from './../forms/CmsPageModalForm';
import type { CmsPageModel } from '../../../models/cms/CmsPageModel';
import { EditOutlined } from '@ant-design/icons';
import { IconTextButton } from '../../shared/IconTextButton';
import ButtonHiddenWithInsufficientPermission from '../../hocs/buttons/ButtonHiddenWithInsufficientPermission';

type Props = {
  pageModel?: CmsPageMetadataModel | CmsPageModel;
  styleType?: 'icon' | 'primary';
  isDisabled?: boolean;
};

const EnhancedIconTextButton = ButtonHiddenWithInsufficientPermission(IconTextButton);

const CmsPageEditButton = ({ pageModel, styleType = 'icon', isDisabled }: Props) => {
  const [isModalShown, setIsModalShown] = React.useState(false);

  return (
    <>
      <EnhancedIconTextButton
        buttonText="Edit"
        buttonIcon={<EditOutlined />}
        styleType={styleType}
        isDisabled={isDisabled}
        onClick={() => setIsModalShown(true)}
      />
      <CmsPageModalForm
        initialData={pageModel}
        isShown={isModalShown}
        setIsShown={setIsModalShown}
      />
    </>
  );
};

export default CmsPageEditButton;
