import React from 'react';
import type { Key } from 'react';
import { Button, DatePicker, Space } from 'antd';
import type { FilterConfirmProps } from 'antd/lib/table/interface';
import moment from 'moment';
import { getListOfDaysBetweenDates } from '../../../utils/dateUtils';

import '../../../css/shared/columnFilters/DateRangeColumnFilter.css';

type Props = {
  setSelectedKeys: (selectedKeys: Key[]) => void;
  selectedKeys: Key[];
  confirm: (param?: FilterConfirmProps) => void;
  clearFilters?: () => void;
};

const DatePickerColumnFilter = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: Props) => {
  const { RangePicker } = DatePicker;
  return (
    <div className="column-filter-date-picker">
      <Space>
        <RangePicker
          format="MM/DD/YYYY"
          onChange={(dates: [any, any], dateStrings: [string, string]) => {
            const [startDate, endDate] = dateStrings;
            const days = getListOfDaysBetweenDates(startDate, endDate);
            setSelectedKeys(days);
          }}
          value={[moment(selectedKeys[0]), moment(selectedKeys[selectedKeys.length - 1])]}
        />
        <Button type="primary" onClick={() => confirm()}>
          Ok
        </Button>
        <Button
          onClick={() => {
            clearFilters?.();
            confirm();
          }}
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};

export default DatePickerColumnFilter;
