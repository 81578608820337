export function nullThrows<T>(
  value: T | null,
  errorMessage = 'The given value cannot be null.',
): NonNullable<T> {
  if (value !== null && value !== undefined) {
    return value;
  }

  throw new Error(errorMessage);
}
