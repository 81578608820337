import { DateTime } from 'luxon';
import { VendorCodeType } from '../enums/vendor/VendorCodeTypeEnum';
import { toNumber } from 'lodash';

// TODO: Change function signatures to `const` arrow functions

export function getBooleanDisplayValue(value: boolean): string {
  return value ? 'Yes' : 'No';
}

export function getNumberDisplayValue(value: number): string {
  return value.toLocaleString('en-US');
}

export function getDateTimeDisplayValue(timestamp?: string): string {
  if (!timestamp) {
    return '';
  }

  return new Date(timestamp)
    .toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(/, /g, ' ');
}

export function getDateDisplayValue(timestamp?: string): string {
  return !timestamp
    ? DateTime.now().toLocaleString()
    : DateTime.fromISO(timestamp).toLocaleString();
}

export function getErrorDisplayValues(error: any): Array<string> {
  const errors: string[] = [];

  const errorArray = error?.response?.data?.errors;
  if (errorArray) {
    for (const [, value] of Object.entries(errorArray)) {
      if (!Array.isArray(value)) return [];
      for (let i = 0; i < value.length; i++) {
        errors.push(value[i]);
      }
    }
    return errors;
  }

  const errorMessage = error?.response?.data?.message;
  if (errorMessage && typeof errorMessage === 'string') {
    return [errorMessage];
  }

  return [];
}

export function pluralize(
  itemCount: number,
  singular: string,
  config?: { plural?: string; includeCount?: boolean },
): string {
  const { plural, includeCount = false } = config ?? {};
  const countText = includeCount ? `${itemCount} ` : '';
  if (itemCount < 2) {
    return countText + singular;
  }

  return countText + (plural ?? `${singular}s`);
}

export function getMoneyDisplayValue(value: number, includeDollarSign = true): string {
  value = !isNaN(value) ? value : 0;

  const formattedNumber = value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  const prefix = includeDollarSign ? '$' : '';

  return !value || value >= 0
    ? `${prefix}${formattedNumber}`
    : `-${prefix}${formattedNumber.slice(1)}`;
}

export function getPercentageDisplayValue(value: number, isZeroToOneRepresentation = true): string {
  if (isZeroToOneRepresentation) value = value * 100;
  return `${toNumber(value)}%`;
}

export function getVendorCodeTypeDisplayValue(type: VendorCodeType): string {
  if (type === VendorCodeType.DIRECT_MAIL) {
    return 'Direct Mail';
  }
  if (type === VendorCodeType.COMMERCIAL) {
    return 'TFN Only';
  }
  return type;
}

export const getModifiedBy = (value: string) => {
  const result = value?.includes('@') ? value.split('@')[0] : value;
  return result?.includes('.') ? result.replace('.', ' ') : result;
};

export function getPhoneNumberDisplayValue(phone: string | undefined | null): string {
  if (!phone) return '';

  const strippedValue = removeNonNumericalCharacters(phone);

  const areaCode = strippedValue.slice(0, 3);
  const part1 = strippedValue.length > 3 && strippedValue.slice(3, 6);
  const part2 = strippedValue.length > 6 && strippedValue.slice(6, 10);

  if (part2 && part1) {
    return `(${areaCode}) ${part1}-${part2}`;
  } else if (part1) {
    return `(${areaCode}) ${part1}`;
  } else if (areaCode) {
    return `(${areaCode}`;
  }

  return '';
}

export function removeNonNumericalCharacters(value: string): string {
  if (!value) return '';
  return value.toString().replace(/\D/g, '');
}

export function createSecret(secretLength): string {
  let secret = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < secretLength; i++) {
    secret += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return secret;
}

export function getKiloBytesDisplayValue(value: number): string {
  if (value < 1000) {
    return `${value} KB`;
  } else {
    return `${value / 1000} MB`;
  }
}

export function getDateUnixValueFromISO(date: string | undefined, removeHours = false): number {
  if (!date) return 0;
  if (!removeHours) return DateTime.fromISO(date).toUnixInteger();

  const dateObject = new Date(date);
  dateObject.setUTCHours(0, 0, 0, 0);

  return DateTime.fromObject({
    year: dateObject.getFullYear(),
    month: dateObject.getMonth() + 1,
    day: dateObject.getDate(),
  }).toUnixInteger();
}

export function getDateUnixValueFromUSCalendarFormat(date: string | undefined): number {
  if (!date) return 0;

  const dateObject = new Date(date);
  return DateTime.fromObject({
    year: dateObject.getFullYear(),
    month: dateObject.getMonth() + 1,
    day: dateObject.getDate(),
  }).toUnixInteger();
}
