import './../../css/layout/MasterLayout.css';

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from '@azure/msal-react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import ROUTES from '../../constants/routes';
import React from 'react';
import SiteHeader from './../nav/SiteHeader';
import useCurrentUserHasAccessToPage from '../../hooks/shared/useCurrentUserHasAccessToPage';
import usePageExists from '../../hooks/shared/usePageExists';

const MasterLayout = () => {
  const navigate = useNavigate();
  const userHasAccessToPage = useCurrentUserHasAccessToPage();
  const pageExists = usePageExists();
  const isAccessDeniedPage = window.location.pathname === ROUTES.ACCESS_DENIED;
  const isAuthorizationPage = window.location.pathname === ROUTES.AUTHORIZE;
  const isAuthenticated = useIsAuthenticated();

  if (!isAccessDeniedPage && pageExists && !userHasAccessToPage && isAuthenticated) {
    navigate(ROUTES.ACCESS_DENIED);
  }

  return (
    <div className="masterLayoutRoot">
      <AuthenticatedTemplate>
        <SiteHeader />
        <main className="masterLayoutContent">
          <Outlet />
        </main>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <main className="masterLayoutContent">
          {!isAuthenticated && !isAuthorizationPage ? (
            <Navigate to={ROUTES.AUTHORIZE} replace state={{ from: location.pathname }} />
          ) : (
            <span>Something went wrong. Please reload and try again.</span>
          )}
        </main>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default MasterLayout;
