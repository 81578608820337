import { Checkbox, Col, Form, Input, InputNumber, Modal, Row, Switch } from 'antd';

import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { TVendorCodeDirectMail } from '../../../models/vendor/VendorCodeModels';
import VendorInput from '../inputs/VendorInput';
import useCreateVendorCodeDirectMail from './../../../hooks/services/vendor/useCreateVendorCodeDirectMail';
import useUpdateVendorCodeDirectMail from './../../../hooks/services/vendor/useUpdateVendorCodeDirectMail';

const FORM_LAYOUT = {
  labelCol: { span: 12 },
  wrapperCol: { span: 10 },
};

const REQUIRED_RULE = { message: 'This field is required', required: true };

type Props = {
  initialData?: TVendorCodeDirectMail;
  isShown: boolean;
  setIsShown: (isShown: boolean) => void;
  vendorId?: number;
};

const DEFAULT_VENDOR_CODE = {
  isActive: true,
  name: '',
  vendorId: '',
  moxyCustomerNumberPrefix: null,
  marketingTier: '',
  shouldScore: false,
  adheresTo90DaySuppression: false,
  adheresToCustomerSuppression: false,
  isFillData: false,
  dataCostPerRecord: null,
  cppCostPerRecord: null,
  postageProductionCost: null,
  tollFreePhoneNumber: null,
};

const VendorCodeDirectMailModalForm = ({ initialData, isShown, setIsShown, vendorId }: Props) => {
  const { create, isLoading: isCreating } = useCreateVendorCodeDirectMail();
  const { update, isLoading: isUpdating } = useUpdateVendorCodeDirectMail();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const isLoading = isCreating || isUpdating;
  const isNewVendorCode = !initialData;

  const onCancel = React.useCallback(() => {
    form.resetFields();
    setIsShown(false);
  }, [form, setIsShown]);

  const navigateToDetailPage = React.useCallback(
    (data: TVendorCodeDirectMail) => {
      navigate(`/vendor-codes/directmail/${data.id}`, { state: { isNew: true, name: data.name } });
    },
    [navigate],
  );

  const onSubmit = React.useCallback(async () => {
    await form.validateFields();
    if (isNewVendorCode) {
      create(form.getFieldsValue(), { onSuccess: (data) => navigateToDetailPage(data) });
      return;
    }

    update({ id: initialData?.id, ...form.getFieldsValue() }, { onSuccess: onCancel });
  }, [initialData?.id, isNewVendorCode, form, create, navigateToDetailPage, onCancel, update]);

  React.useEffect(() => {
    form.resetFields();
  }, [initialData, form]);

  return (
    <Modal
      okButtonProps={{ loading: isLoading }}
      onOk={onSubmit}
      onCancel={onCancel}
      title={`${isNewVendorCode ? 'Create' : 'Update'} Vendor Code - Direct Mail`}
      open={isShown}
      width={900}
      okText={isNewVendorCode ? 'Create' : 'Update'}
    >
      <Form
        {...FORM_LAYOUT}
        form={form}
        initialValues={initialData ?? { ...DEFAULT_VENDOR_CODE, vendorId: vendorId ?? '' }}
      >
        <Row>
          <Col span={12}>
            <Form.Item label="Name" name="name" rules={[REQUIRED_RULE]}>
              <Input maxLength={50} />
            </Form.Item>
            <Form.Item label="Moxy Customer Number Prefix" name="moxyCustomerNumberPrefix">
              <Input maxLength={3} />
            </Form.Item>
            <Form.Item label="Vendor" name="vendorId" rules={[REQUIRED_RULE]}>
              <VendorInput disabled={!!vendorId} />
            </Form.Item>
            <Form.Item label="Toll Free Number" name="tollFreePhoneNumber">
              <Input maxLength={20} />
            </Form.Item>
            <Form.Item label="Tier" name="marketingTier" rules={[REQUIRED_RULE]}>
              <InputNumber min={0} />
            </Form.Item>
            <Form.Item
              label="Data Cost per Record"
              name="dataCostPerRecord"
              rules={[REQUIRED_RULE]}
            >
              <InputNumber min={0} step={0.01} />
            </Form.Item>
            <Form.Item label="CPP Cost per Record" name="cppCostPerRecord" rules={[REQUIRED_RULE]}>
              <InputNumber min={0} step={0.01} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Postage Production Cost"
              name="postageProductionCost"
              rules={[REQUIRED_RULE]}
            >
              <InputNumber min={0} step={0.01} />
            </Form.Item>
            <Form.Item label="Should Score" name="shouldScore" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item
              label="90 Day Suppression"
              name="adheresTo90DaySuppression"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item
              label="Customer Suppression"
              name="adheresToCustomerSuppression"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item label="Is Fill Data" name="isFillData" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item label="Active" name="isActive" valuePropName="checked">
              <Switch disabled={isNewVendorCode} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default VendorCodeDirectMailModalForm;
