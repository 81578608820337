import { Form, InputNumber } from 'antd';

import React from 'react';

export const CmsSchemaDataNumberItems = () => {
  return (
    <>
      <Form.Item label="Min Range" name="minRange">
        <InputNumber />
      </Form.Item>
      <Form.Item label="Max Range" name="maxRange">
        <InputNumber />
      </Form.Item>
    </>
  );
};
